import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
    Table, Input, Button, Space, notification, Typography, Row, Col,
    Spin, Select, Tooltip, Modal, Form, Tag, Radio
} from 'antd';
import Highlighter from 'react-highlight-words';
import locale from 'antd/es/locale/pt_BR';
import { SearchOutlined, CrownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
// import { useLocation } from 'react-router-dom';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import moment from 'moment';
import { format, addDays } from 'date-fns'
import ptLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import axios from 'axios';
import {
    urlCargo,
    urlBuscaUserByCelular,
    urlContratoAtivos,
    urlUsersAutomatico,
    urlUsersContratoCidades,
    urlContratoCidadesQuantidadeUsuarios,
    urlContratosGestorContrato,
    urlContratosUsuariosNotificacao,
    urlContratosGestor,
    urlPoder,
    urlContratosPlano
} from '../../services/request/urls';

import { useSelector } from 'react-redux';
import { stateGlobal } from '../../interfaces/Global';

import { getToken } from './../../services/token';
import Icon from '@ant-design/icons';
import { mask, unMask } from '../../utils/MascaraDeCampos';
import './styles.css';
// import { useForm } from 'antd/lib/form/Form';
import {
    colModalVincularUsuarioInputs,
    colModalVincularUsuarioInputs2,
    colBotaoSelecionar,
} from './GridStyle';
import {
    convertDataBrParaUS
} from './../../services/Conversores';

import {
    removeAcento
} from './../../utils/RemoveAcentos';

import { FaSearch, FaUserPlus, FaUsers } from 'react-icons/fa';
import { useGetColumnSearchProps } from '../../hooks/FilterTable/stringCLearFiltros';

const { Text, Title } = Typography;

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>
const IconUsuarios = (props: any) => <Icon {...props} component={FaUsers} ></Icon>
const IconUsuariosPlus = (props: any) => <Icon {...props} component={FaUserPlus} ></Icon>

// const useQuery = () => {
//     return new URLSearchParams(useLocation().search);
// }

interface interfDadosTabela {
    id: number;
    uuid: string;
    nome: string;
    status: boolean;
    envio_proposta: string;
    vigencia: string | number | null | Date | boolean | any;
    valor_contratado: string;
    cadastro_processos: string;
    observacao: string;
    created_at: string;
    updated_at: string;
    modalidade: {
        id: number;
        nome: string;
    },
    esfera: {
        id: number;
        nome: string;
    },
    poder: {
        id: number;
        nome: string;
    },
    situacao_contrato: {
        id: number;
        nome: string;
    },
    tipo_contrato: {
        id: number;
        nome: string;
    },
    controladorContrato: [
        {
            id: number | null;
            contratoId: number | null;
            cidadeId: number | null;
            estadoId: number | null;
            usuarioId: number | null;
            permissaoNotificacaoId: number | null;
        }
    ]
}

interface interfaceConfigs {
    nome: string,
    id: number
}

interface tabelaCidades {
    estadoId: number,
    estadoNome: string,
    estadoSigla: string,
    ibge_id_antigo: number | null,
    id: number,
    latitude: string | null,
    longitude: string | null,
    microrregiaoId: number | null,
    nome: string | null,
    populacao: number | null,
    uf: string | null,
    uuid: string,
    usuarios: string,
}

interface dadosUsuariosTabelaModal {
    id: number,
    nome: string,
    ativo: boolean,
    uuid: string,
    validade_teste: string,
    celular: string,
    cargoId: number,
    created_at: string,
    sistema: string,
}

interface interfDadosListUserGestor {
    "uuid": string,
    "id": number,
    "nome": string,
    "celular": string,
    "email": string | null,
    "sistema": string
}

interface interfDadosGestorChecked {
    cidadeId: number,
    contratoId: number,
    created_at: string | null,
    id: number,
    updated_at: string | null,
    usuarioId: number,
}

interface interfaceNewConfigs {
    nome: string,
    uuid: number
}

const MunicipiosPorContrato: React.FC = () => {

    // const formRef = useRef<any>(null);
    // formRef = React.createRef();

    const refContagemDoFiltro = React.useRef<Array<any>>([]);
    const refContagemDoFiltroTabelaGestorModal = React.useRef<Array<any>>([]);
    const refIDCidade = useRef<number | null>(null);
    const refDadosUsuarioModal = useRef<any>(null);
    const refFuncaoLimparFiltrosString = useRef<any>(null);

    const propsState = useSelector((state: stateGlobal) => state.global);
    // const queryParans = useQuery();

    // const refContratoSituacao = useRef<{ nome: string, id: number } | null>(null);

    const refAllDadosContrato = useRef<Array<interfDadosTabela>>([]);

    const refIDContrato = useRef<any>(null);
    const refNomeContrato = useRef<any>(null);
    const refFormModalVincularUsuario = useRef<any>(null);
    const inputNomeModalVinUser = useRef<any>(null);

    // const history = useHistory();
    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();
    const signal1 = axios.CancelToken.source();

    const [stateContratoSituacao, setStateContratoSituacao] = useState<{ nome: string, id: number } | null>(null);

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string>>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dadosTabelaState, setDadosTabelaState] = useState<Array<tabelaCidades>>([]);
    const [hasSelectedAdd, setHasSelectedAdd] = useState(true);
    const [hasSelectedView, setHasSelectedView] = useState(false);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);
    const [hasSelectedDel, setHasSelectedDel] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [dataListContrato, setDataListContrato] = useState<Array<interfDadosTabela>>([]);

    const [dadosDoCargoList, setDadosDoCargoList] = useState<Array<interfaceConfigs>>([]);
    const [loadingCargoListSelected, setLoadingCargoListSelected] = useState(false);
    const refDadosCargoList = React.useRef<Array<interfaceConfigs>>([]);

    const [loadingPlanoSelected, setLoadingPlanoSelected] = useState(false);
    const [dadosPlanoList, setDadosPlanoList] = useState<Array<interfaceNewConfigs>>([]);
    const refDadosPlanoList = useRef<Array<interfaceNewConfigs>>([]);

    const [modalVincularUsuario, setModalVincularUsuario] = useState(false);
    const [loadingFormAdd, setLoadingFormAdd] = useState(false);
    const [selectedDateVincularUser, setSelectedDateVincularUser] = useState<any>(null);

    const [modalListUserVinculados, setModalListUserVinculados] = useState(false);
    const [dadosTabelaListUserVinculados, setDadosTabelaListUserVinculados] = useState<Array<dadosUsuariosTabelaModal>>([]);

    const [modalGestorVisible, setModalGestorVisible] = useState(false);
    const [loadingTableModalGestor, setLoadingTableModalGestor] = useState(false);
    const [selectedRowKeysModal, setSelectedRowKeysModal] = useState<Array<string>>([]);
    const [dadosTabelaGestorModal, setDadosTabelaUsuariosModal] = useState<Array<dadosUsuariosTabelaModal>>([]);


    const encontrarCargoPorId = useCallback((id): any => {

        const recebeFilter = refDadosCargoList.current.filter((element, index) => (
            element.id === id
        ))

        return recebeFilter[0]

    }, []);

    const encontrarPoderPorContratoId = useCallback((id): any => {

        const recebeFilter = refAllDadosContrato.current.filter((element, index) => (
            element.id === id
        ))

        return recebeFilter[0].poder

    }, []);

    const buscaDadosContratosAndSetSelected = useCallback((): void => {

        const buscaDeDadosContratos = async () => {
            try {

                setLoadingTable(true)
                let result = await axios.get(urlContratoAtivos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                refAllDadosContrato.current = result.data

                setDataListContrato(result.data);
                setLoadingTable(false)


            } catch (error) {

                setLoadingTable(false)

                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosContratos();

    }, []);

    const buscaDadosCidadesPorContrato = useCallback((id: string | number): void => {

        const buscaDeDadosContratos = async () => {
            try {

                setLoadingTable(true)

                let result = await axios.get(urlContratoCidadesQuantidadeUsuarios + '/' + id,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setDadosTabelaState(result.data);

                if (result.data.length === 1) {

                    setSelectedRowKeys([result.data[0].uuid]);
                    setHasSelectedEdit(true);
                } else {
                    setSelectedRowKeys([]);
                    setHasSelectedEdit(false);

                    // setHasSelectedAdd(false);
                    // setHasSelectedView(false);
                    // setHasSelectedDel(true);
                }



                setLoadingTable(false)


            } catch (error) {

                setLoadingTable(false)

                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosContratos();

    }, []);

    const buscarDadosPlanoList = useCallback((setValue?: string) => {

        setLoadingPlanoSelected(true);
        const requestAsync = async () => {
            try {
                let result = await axios.get(urlContratosPlano,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                refDadosPlanoList.current = result.data;
                setDadosPlanoList(result.data)

                if (setValue) {

                    const plano = result.data.filter((plano: any) => plano?.nome == setValue)

                    if (plano.length > 0) {
                        refFormModalVincularUsuario.current?.setFieldsValue({
                            plano_uuid: plano[0].uuid,
                        });
                    }
                } else {
                    if (result.data.length > 0) {
                        refFormModalVincularUsuario.current?.setFieldsValue({
                            plano_uuid: result.data[0].uuid,
                        });
                    }
                }

                setLoadingPlanoSelected(false);
            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingPlanoSelected(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        requestAsync();

    }, []);

    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const buscarDadosCargoList = useCallback(() => {

        setLoadingCargoListSelected(true);
        const buscaDadosPoder = async () => {
            try {
                let result = await axios.get(urlCargo,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });
                setDadosDoCargoList(result.data);
                refDadosCargoList.current = result.data

                setLoadingCargoListSelected(false);
            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingCargoListSelected(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDadosPoder();

    }, []);

    //executa apenas ao iniciar o component
    useEffect(() => {

        buscaDadosContratosAndSetSelected();
        buscarDadosCargoList();
        buscarDadosPlanoList()

        // let idParam = queryParans.get("id")

        // if (idParam && parseInt(idParam)) {

        //     buscaDadosUserPorContratoSetTable(idParam)

        // } else {
        //     //busca todos users ativos
        //     buscaDadosUsuariosAtivosAndSetTable();
        // }

        // mensagemDeAtencaoNaTelaPeloCache();

    }, []);

    //executa apenas quando desmonta o component
    const comonentExecutaAoDestroy = useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
        signal1.cancel("Requisicao cancelada!")
    }, []);

    const encontrarContratoPorId = useCallback((id): void => {

        const recebeFilter = refAllDadosContrato.current.filter((element, index) => (
            element.id === id
        ))

        setStateContratoSituacao(recebeFilter[0]?.tipo_contrato)

    }, []);

    //Search da tabela de listagem
    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {

                let trecord = removeAcento(record[dataIndex].toString());

                return record[dataIndex] ? trecord.includes(removeAcento(value)) : false

            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    const getColumnSearchPropsData = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let texto: string | undefined = ''
                if (record[dataIndex]) texto = moment(record[dataIndex]).format('DD/MM/YYYY HH:mm')

                return (
                    texto ? texto.toString().toLowerCase().includes(value.toLowerCase()) : false
                )
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    const getColumnSearchPropsObjNome = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let trecord = removeAcento(record[dataIndex]?.nome.toString());

                return record[dataIndex]?.nome ? trecord.includes(removeAcento(value)) : false
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    //Search da tabela de listagem
    const getColumnSearchPropsBolean = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let valor: string = record[dataIndex] ? 'ativo' : 'inativo'

                return valor ? valor.includes(value.toLowerCase()) : ''
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, [searchText]);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);
    // fim do search da tabela de listagem


    const clickCancelModalListUserVinculados = useCallback(() => {
        setModalListUserVinculados(false);
    }, []);

    const columnsModalListUserVinculados = [
        {
            title: 'Nome do Usuario',
            dataIndex: 'nome',
            ...getColumnSearchProps('nome'),
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
            width: '15%',
        },
        {
            title: 'Telefone',
            dataIndex: 'celular',
            ...getColumnSearchProps('celular'),
            sorter: (a: any, b: any) => a.celular.localeCompare(b.celular),
            showSorterTooltip: false,
            width: '10%',
            render: (celular: any) => {
                const originalValue = unMask(celular);
                const maskedValue = mask(originalValue, [
                    "(99) 9999-9999",
                    "(99) 9 9999-9999"
                ]);
                return (
                    maskedValue
                )
            }
        },
        {
            title: 'Cargo',
            dataIndex: 'cargo',
            ...getColumnSearchPropsObjNome('cargo'),
            width: '10%',
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            render: (cargo: any) => (
                cargo?.nome
            ),
        },
        {
            title: 'Sistema',
            dataIndex: 'sistema',
            ...getColumnSearchProps('sistema'),
            width: '8%',
            render: (status: any) => (
                <span>
                    <Tag color={'blue'} key={status}>
                        {status?.toUpperCase()}
                    </Tag>
                </span>
            ),
        },
        // {
        //     title: 'Validade teste',
        //     dataIndex: 'validade_teste',
        //     ...getColumnSearchPropsData('validade_teste'),
        //     width: '15%',
        //     render: (criado: any) => {
        //         let date = criado ? moment(criado).format('DD/MM/YYYY') : ''
        //         return (
        //             date ? date : ''
        //         )
        //     },
        // },
    ];

    const onClickListarUsuariosDoContratoPelaCidade = useCallback((): void => {
        const buscaDeDados = async () => {

            if (selectedRowKeys.length == 1) {

                setLoadingTable(true);

                let linha: any = [];
                dadosTabelaState.forEach(({ nome, uuid, id }): void => {

                    selectedRowKeys.forEach((val, i) => {
                        if (uuid == val) {
                            linha.push({ id, nome })
                        }
                    });

                })

                try {

                    if (refIDContrato.current != null) {

                        setLoadingFormAdd(true);

                        //abrir modal
                        setModalListUserVinculados(true)

                        let resultUsuarios = await axios.get(urlUsersContratoCidades + "/" + refIDContrato.current + "/" + linha[0].id, {
                            headers: { 'Authorization': 'Bearer ' + getToken() }
                        });

                        setDadosTabelaListUserVinculados(resultUsuarios.data);

                        setLoadingFormAdd(false);
                    } else {
                        notification.error({
                            message: 'Erro',
                            description: `Contrato inexistente!`
                        });
                    }

                    setLoadingTable(false);

                } catch (error) {
                    setLoadingFormAdd(false);
                    setLoadingTable(false);
                    notification.error({
                        message: 'Erro',
                        description: `Não foi possivel buscar, entre em contato com suporte!`
                    });
                }
            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState]);

    const onChangeSelectContratos = useCallback((value, obj): void => {

        encontrarContratoPorId(value)

        refIDContrato.current = value;
        refNomeContrato.current = obj?.children;

        buscaDadosCidadesPorContrato(value);

    }, []);

    const onChangeInputTelefoneModalVinUsr = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        refFormModalVincularUsuario.current.setFieldsValue(
            { celular: maskedValue }
        );
        if (maskedValue.length == 16) {
            refFormModalVincularUsuario.current.validateFields(["celular"])
        }

    }, []);

    const buscarCelularSeJaExiste = useCallback((celular: string): Promise<boolean> => {

        const buscaDadosTipoDeContrato = async (): Promise<boolean> => {
            try {
                let result = await axios.get(urlBuscaUserByCelular + celular,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                if (typeof result.data == 'string') {
                    return false;
                } else {
                    return true;
                }
            } catch (error) {
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel consultar celular!',
                });
                return false
            }
        }

        return buscaDadosTipoDeContrato();

    }, []);

    const clickCancelModalVincularUsuario = useCallback(() => {
        setModalVincularUsuario(false);
        refFormModalVincularUsuario.current.resetFields();
    }, []);

    const clickSubmitModalVincularUsuario = useCallback((): void => {

        const salvarDadosModalVincularUsuario = async (values: any) => {
            try {
                setLoadingFormAdd(true);

                let resultCidade = await axios.post(urlUsersAutomatico, values,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Usuário criado com sucesso!',
                });

                setLoadingFormAdd(false);

                //atualizar dados da tabela, ao salvar usuario
                buscaDadosCidadesPorContrato(refIDContrato.current);

                clickCancelModalVincularUsuario();
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingFormAdd(false);
                notification.error({
                    message: 'Erro',
                    description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel salvar, entre em contato com suporte!',
                });
            }
        }

        let linha: any = [];
        dadosTabelaState.forEach(({ nome, uuid, id }): void => {

            selectedRowKeys.forEach((val, i) => {
                if (uuid == val) {
                    linha.push({ id, nome })
                }
            });

        })

        if (selectedRowKeys.length == 1) {

            refFormModalVincularUsuario.current?.validateFields()
                .then((values: any) => {


                    values = {
                        ...values,
                        nome: values.nome,
                        cargo_id: values.cargo_id,
                        celular: unMask(values.celular),
                        validade_teste: convertDataBrParaUS(selectedDateVincularUser),
                        contrato_id: refIDContrato.current,
                        // cidades: [{ id: refAllDados.current?.cidadeId }]
                        cidade_id: linha[0].id
                    }


                    const nomePoder = encontrarPoderPorContratoId(values.contrato_id)?.nome
                    const nomeCargo = encontrarCargoPorId(values.cargo_id)?.nome
                    if (
                        nomePoder &&
                        nomePoder === 'Executivo'
                    ) {
                        if (nomeCargo &&
                            nomeCargo === 'Vereador(a)') {
                            Modal.confirm({
                                className: 'modalContratoAtivoInativo',
                                title: 'Cuidado',
                                icon: <ExclamationCircleOutlined />,
                                content: 'O usuário cadastrado é Vereador no contrato de poder Executivo! Tem certeza que deseja continuar?',
                                okText: 'Sim',
                                okType: 'danger',
                                cancelText: 'Rever campos',
                                onOk() {
                                    salvarDadosModalVincularUsuario(values);
                                },
                                onCancel() {
                                },
                            });
                            return false
                        }
                        if (nomeCargo &&
                            nomeCargo === 'Presidente da Câmara') {
                            Modal.confirm({
                                className: 'modalContratoAtivoInativo',
                                title: 'Cuidado',
                                icon: <ExclamationCircleOutlined />,
                                content: 'O usuário cadastrado é Presidente da Câmara no contrato de poder Executivo! Tem certeza que deseja continuar?',
                                okText: 'Sim',
                                okType: 'danger',
                                cancelText: 'Rever campos',
                                onOk() {
                                    salvarDadosModalVincularUsuario(values);
                                },
                                onCancel() {
                                },
                            });
                            return false
                        }

                    }

                    if (
                        nomePoder &&
                        nomePoder === 'Legislativo'
                    ) {
                        if (nomeCargo &&
                            nomeCargo === 'Prefeito(a)') {
                            Modal.confirm({
                                className: 'modalContratoAtivoInativo',
                                title: 'Cuidado',
                                icon: <ExclamationCircleOutlined />,
                                content: 'O usuário cadastrado é Prefeito(a) no contrato de poder Legislativo! Tem certeza que deseja continuar?',
                                okText: 'Sim',
                                okType: 'danger',
                                cancelText: 'Rever campos',
                                onOk() {
                                    salvarDadosModalVincularUsuario(values);
                                },
                                onCancel() {
                                },
                            });
                            return false

                        }
                        if (nomeCargo &&
                            nomeCargo === 'Ex-Prefeito(a)') {
                            Modal.confirm({
                                className: 'modalContratoAtivoInativo',
                                title: 'Cuidado',
                                icon: <ExclamationCircleOutlined />,
                                content: 'O usuário cadastrado é Ex-Prefeito(a) no contrato de poder Legislativo! Tem certeza que deseja continuar?',
                                okText: 'Sim',
                                okType: 'danger',
                                cancelText: 'Rever campos',
                                onOk() {
                                    salvarDadosModalVincularUsuario(values);
                                },
                                onCancel() {
                                },
                            });
                            return false
                        }
                        if (nomeCargo &&
                            nomeCargo === 'Vice Prefeito(a)') {
                            Modal.confirm({
                                className: 'modalContratoAtivoInativo',
                                title: 'Cuidado',
                                icon: <ExclamationCircleOutlined />,
                                content: 'O usuário cadastrado é Vice Prefeito(a) no contrato de poder Legislativo! Tem certeza que deseja continuar?',
                                okText: 'Sim',
                                okType: 'danger',
                                cancelText: 'Rever campos',
                                onOk() {
                                    salvarDadosModalVincularUsuario(values);
                                },
                                onCancel() {
                                },
                            });
                            return false
                        }
                    }


                    salvarDadosModalVincularUsuario(values);

                })
                .catch((errorInfo: any) => {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Preencha os campos obrigatorios!',
                    });
                });

        }

    }, [selectedDateVincularUser, selectedRowKeys, dadosTabelaState]);

    const onClickButtonVincularUsuario = useCallback(() => {

        let linha: any = [];
        dadosTabelaState.forEach(({ nome, uuid, id }): void => {

            selectedRowKeys.forEach((val, i) => {
                if (uuid == val) {
                    linha.push({ id, nome })
                }
            });

        })

        if (selectedRowKeys.length == 1) {

            let dataSomada = format(
                addDays(
                    new Date(),
                    7
                )
                , 'yyyy-MM-dd'
            )

            setModalVincularUsuario(true)

            setTimeout(() => {
                refFormModalVincularUsuario.current.setFieldsValue({
                    // validade_teste: dataSomada,
                    viewContrato: refNomeContrato.current,
                    viewCidade: linha[0].nome,
                    cargo_id: 28,
                });

                setSelectedDateVincularUser(moment(dataSomada))
                inputNomeModalVinUser.current?.focus();
            }, 500);

        }//fim do if

    }, [selectedRowKeys, dadosTabelaState]);

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeys.includes(record.uuid)) {
            let filter = selectedRowKeys.filter((val) => {
                return val != record.uuid
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(true);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length == 0) {
                setSelectedRowKeys([record.uuid]);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else {
                let teste = selectedRowKeys.concat(record.uuid);
                setSelectedRowKeys(teste);
                setHasSelectedAdd(false);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
                setHasSelectedDel(true);
            }
        }
    }, [selectedRowKeys]);

    //checkbox da tabela de listagem
    const onSelectChange = useCallback((selectedRowKeys): void => {
        if (selectedRowKeys.length > 1) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
            setHasSelectedDel(true);
        } else if (selectedRowKeys.length === 0) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(true);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
            setHasSelectedDel(false);
        } else {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(true);
            setHasSelectedEdit(true);
            setHasSelectedDel(true);
        }
    }, [selectedRowKeys]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '1%'
    };

    const columns = [
        {
            title: 'Cidade',
            dataIndex: 'nome',
            ...getColumnSearchProps('nome'),
            width: '20%',
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
        },
        {
            title: 'Estado',
            dataIndex: 'estadoNome',
            ...getColumnSearchProps('estadoNome'),
            width: '8%',
            sorter: (a: any, b: any) => a.estadoNome.localeCompare(b.estadoNome),
            showSorterTooltip: false,
        },
        // {
        //     title: 'Sigla',
        //     dataIndex: 'estadoSigla',
        //     ...getColumnSearchProps('estadoSigla'),
        //     width: '10%',
        //     sorter: (a: any, b: any) => a.estadoSigla.localeCompare(b.estadoSigla),
        //     showSorterTooltip: false,
        // },
        {
            title: 'Quantidade de usuários',
            dataIndex: 'usuarios',
            ...getColumnSearchProps('usuarios'),
            width: '12%',
            sorter: (a: any, b: any) => Number(b.usuarios) - Number(a.usuarios),
            showSorterTooltip: false,
        },
    ];

    const clickCancelModalGestor = useCallback(() => {
        setModalGestorVisible(false);
        refFuncaoLimparFiltrosString.current.limparFiltros();
    }, []);

    const handleClickSubmitModalGestor = useCallback(() => {
        const alterarDadosUsuario = async () => {

            setLoadingTableModalGestor(true);

            try {
                let arrayGestores: Array<number> = []
                selectedRowKeysModal.forEach(async (element) => {
                    arrayGestores.push(Number(element))
                });

                await axios.post(urlContratosGestor, {
                    gestores: arrayGestores,
                    contratoId: refIDContrato.current,
                    cidadeId: refIDCidade.current,
                }, {
                    headers: { 'Authorization': 'Bearer ' + getToken() }
                });


                notification.success({
                    message: 'Sucesso',
                    description: `Alterações feitas com sucesso!`
                });

                clickCancelModalGestor();
                setLoadingTableModalGestor(false);
            } catch (error) {
                setLoadingTableModalGestor(false);
                notification.error({
                    message: 'Erro',
                    description: `Não foi possivel salvar, entre em contato com suporte!`
                });
            }

        }

        alterarDadosUsuario();
    }, [selectedRowKeysModal]);

    const handleClickModalGestor = useCallback((): void => {
        setLoadingTableModalGestor(true);
        const buscaDeDados = async () => {

            if (selectedRowKeys.length == 1) {
                let linha: any = [];
                dadosTabelaState.forEach((element): void => {

                    selectedRowKeys.forEach((val, i) => {
                        if (element.uuid == val) {
                            linha.push(element)
                        }
                    });

                })

                try {

                    //abrir modal antes da requisição
                    setModalGestorVisible(true);

                    let resultGestores = await axios.get(urlContratosGestorContrato + "/" + refIDContrato.current +
                        "/" + linha[0].id, {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });
                    let resultUsuariosContrato = await axios.get(urlContratosUsuariosNotificacao + "/" + refIDContrato.current +
                        "/" + linha[0].id, {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                    if (resultGestores.data.length > 0) {
                        let userAtivoCheck: any = [];
                        let isBooleanSelecionado: boolean = false;

                        resultUsuariosContrato.data.forEach((todosUsuarios: interfDadosListUserGestor): void => {
                            let objStatus: string = '';
                            isBooleanSelecionado = false;

                            objStatus = todosUsuarios.id.toString();

                            resultGestores.data.forEach((element: interfDadosGestorChecked) => {
                                if (element.usuarioId.toString() == todosUsuarios.id.toString()) {
                                    isBooleanSelecionado = true;
                                }
                            });

                            if (isBooleanSelecionado) {
                                userAtivoCheck.push(objStatus);
                            }


                        });



                        //setandop check na tabela
                        setSelectedRowKeysModal(userAtivoCheck);
                    }


                    setDadosTabelaUsuariosModal(resultUsuariosContrato.data);

                    refDadosUsuarioModal.current = resultUsuariosContrato.data;
                    refIDCidade.current = linha[0].id;

                    setLoadingTableModalGestor(false);

                } catch (error) {
                    setLoadingTableModalGestor(false);
                    notification.error({
                        message: 'Erro',
                        description: `Não foi possivel buscar, entre em contato com suporte!`
                    });
                }
            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState]);

    // click na linha da tabela de listagem de usuario
    const onclickTableModal = useCallback((record: any, row: any): void => {
        if (selectedRowKeysModal.includes(record.id.toString())) {
            let filter = selectedRowKeysModal.filter((val) => {
                return val != record.id.toString()
            })
            if (filter.length === 1) {
                setSelectedRowKeysModal(filter);
            } else if (filter.length === 0) {
                setSelectedRowKeysModal(filter);
            } else {
                setSelectedRowKeysModal(filter);
            }

        } else {
            if (selectedRowKeysModal.length == 0) {
                setSelectedRowKeysModal([record.id.toString()]);
            } else {
                let teste = selectedRowKeysModal.concat(record.id.toString());
                setSelectedRowKeysModal(teste);
            }
        }
    }, [selectedRowKeysModal, setSelectedRowKeysModal]);

    //checkbox da tabela de listagem
    const onSelectChangeModal = useCallback((selectedRowKeysModal): void => {
        if (selectedRowKeysModal.length > 1) {
            setSelectedRowKeysModal(selectedRowKeysModal);
        } else if (selectedRowKeysModal.length === 0) {
            setSelectedRowKeysModal(selectedRowKeysModal);
        } else {
            setSelectedRowKeysModal(selectedRowKeysModal);
        }
    }, [selectedRowKeysModal]);

    const rowSelectionModal = {
        selectedRowKeys: selectedRowKeysModal,
        onChange: onSelectChangeModal,
        columnWidth: '1%'
    };

    const columnsModal = [
        {
            title: 'Nome do Usuario',
            dataIndex: 'nome',
            ...useGetColumnSearchProps('nome', refFuncaoLimparFiltrosString),
            width: '10%',
        },
        {
            title: 'Cargo',
            dataIndex: 'cargo',
            ...useGetColumnSearchProps('cargo', refFuncaoLimparFiltrosString),
            width: '10%',
        },
        {
            title: 'Celular',
            dataIndex: 'celular',
            ...getColumnSearchProps('celular'),
            width: '8%',
            render: (celular: any) => {
                if (celular) {

                    const originalValue = unMask(celular);
                    const maskedValue = mask(originalValue, [
                        "(99) 9999-9999",
                        "(99) 9 9999-9999"
                    ]);
                    return maskedValue;
                }
                return celular
            }
            // sorter: (a, b) => a.age - b.age //para quando é numero
        },
    ];

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    zIndex: 2,
                    // background: propsState.theme === "light" ? '#fff' : '#141414',
                    //
                    width: '90%',
                    // backgroundColor: 'blue',
                    paddingTop: 24,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24
                }}
            >
                <Row
                // style={{ marginBottom: 16 }}
                >
                    <Col
                        {...colBotaoSelecionar}
                        style={{
                            // backgroundColor: 'red'
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Select
                            showSearch
                            placeholder="Selecione um Contrato"
                            optionFilterProp="children"
                            // className="botaoListVendedorUser"
                            onChange={onChangeSelectContratos}
                            // disabled={!hasSelectedAdd}
                            // defaultValue='Usuários'
                            style={{
                                width: '100%',
                                marginBottom: 5,
                                marginRight: 5,
                            }}
                            filterOption={(input: any, option: any) => {
                                let textDigit = removeAcento(input)
                                let listCidade = removeAcento(option?.children);
                                return listCidade.indexOf(textDigit) >= 0
                            }}
                        >
                            {
                                dataListContrato.map((item) => {
                                    return (
                                        <Select.Option
                                            value={item.id}
                                            key={item.id}
                                        >
                                            {
                                                item?.poder?.nome ?
                                                    item.nome + ' - ' + item?.poder?.nome
                                                    :
                                                    item.nome
                                            }
                                        </Select.Option>
                                    )
                                })
                            }
                        </Select>
                        <Tooltip title="Vincular Usuários" color="green" key="greenVincularUsuarios">
                            <div>
                                <Button
                                    type="primary"
                                    className='botaoVincularUsuarioInAtendimentos'
                                    style={{
                                        marginBottom: 5,
                                        marginRight: 5,
                                    }}
                                    disabled={!hasSelectedEdit}
                                    onClick={onClickButtonVincularUsuario}
                                // className="botaoListarUsuarioContrato"
                                >
                                    <IconUsuariosPlus style={{ fontSize: 20 }} />
                                </Button>
                            </div>
                        </Tooltip>
                    </Col>
                    <Col>
                        <Tooltip title="Listar Usuários" color="blue" key="blueListarUsuarios">
                            <Button
                                type="primary"
                                style={{
                                    marginBottom: 5,
                                    marginRight: 5,
                                }}
                                disabled={!hasSelectedEdit}
                                onClick={onClickListarUsuariosDoContratoPelaCidade}
                            // className="botaoListarUsuarioContrato"
                            >
                                <IconUsuarios style={{ fontSize: 20 }} />
                            </Button>
                        </Tooltip>

                    </Col>
                    <Col>
                        {hasSelectedEdit ?
                            <Tooltip title="Gestor" color="orange" key="laranjaListarUsuarios">
                                <Button
                                    type="primary"
                                    className="botaoGestorMunicipiosPorContrato"
                                    style={{
                                        marginBottom: 5,
                                        marginRight: 5,
                                    }}
                                    // disabled={!hasSelectedEdit}
                                    onClick={handleClickModalGestor}
                                >
                                    {/* <IconUsuarios style={{ fontSize: 20 }} /> */}
                                    <CrownOutlined style={{
                                        fontSize: 20
                                    }} />
                                </Button>
                            </Tooltip>
                            :
                            <Button
                                type="primary"
                                // className="botaoGestorMunicipiosPorContrato"
                                style={{
                                    marginBottom: 5,
                                    marginRight: 5,
                                }}
                                disabled={true}
                                onClick={handleClickModalGestor}
                            >
                                {/* <IconUsuarios style={{ fontSize: 20 }} /> */}
                                <CrownOutlined style={{
                                    fontSize: 20
                                }} />
                            </Button>
                        }

                    </Col>
                </Row>

            </div>
            <div
                style={{
                    marginTop: 50
                }}
                className='marginTopTables'
            >

                <Table
                    rowKey={(record) => record.uuid}
                    loading={loadingTable}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => { onclickTable(record, rowIndex) }, // click row
                            onDoubleClick: event => { }, // double click row
                            onContextMenu: event => { }, // right button click row
                            onMouseEnter: event => { }, // mouse enter row
                            onMouseLeave: event => { }, // mouse leave row
                        };
                    }}
                    rowSelection={rowSelection}
                    columns={columns} dataSource={dadosTabelaState}
                    scroll={{ x: 1000 }}
                    onChange={
                        (pagination, filters, sorter, extra) => {
                            refContagemDoFiltro.current = extra.currentDataSource
                        }
                    }
                    title={(registros) => {
                        return (
                            <div
                                style={{
                                    backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                    padding: 10,
                                    margin: -8,
                                    marginBottom: 0.5
                                }}
                            >
                                <Text>
                                    {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                        dadosTabelaState.length :
                                        refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                                </Text>
                            </div>
                        )
                    }}
                    footer={(registros) => {
                        return (
                            <div
                                style={{
                                    textAlign: 'end'
                                }}
                            >
                                <Text>
                                    {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                        dadosTabelaState.length :
                                        refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                                </Text>
                            </div>
                        )
                    }}
                    size="small"
                    pagination={{
                        locale: { items_per_page: '' },
                        showSizeChanger: true,
                        defaultPageSize: 100,
                        position: ['topRight', 'bottomRight'],
                        pageSizeOptions: ['10', '20', '50', '100']
                    }}
                    locale={locale.Table}
                />
            </div>
            <Modal
                title="Vincular Usuário"
                visible={modalVincularUsuario}
                className="modalVincularUsuario"
                onOk={clickSubmitModalVincularUsuario}
                onCancel={clickCancelModalVincularUsuario}
                footer={[
                    <Button key="back" disabled={loadingFormAdd} onClick={clickCancelModalVincularUsuario}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary"
                        loading={loadingFormAdd}
                        onClick={clickSubmitModalVincularUsuario}
                    >
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingFormAdd}>
                    <Form
                        ref={refFormModalVincularUsuario}
                        name="formModalAddVincularUsuario"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                        onFinishFailed={() => { }}
                    >
                        <Row>
                            <Col {...colModalVincularUsuarioInputs}>
                                <Form.Item
                                    name="viewContrato"
                                    label="Contrato"
                                    style={{ margin: 0, marginBottom: 15 }}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Por favor selecione o Contrato!',
                                        },
                                    ]}
                                >
                                    <Input
                                        disabled
                                        autoComplete="off"
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...colModalVincularUsuarioInputs2}>
                                <Form.Item
                                    name="viewCidade"
                                    label="Cidade"
                                    style={{ margin: 0, marginBottom: 15 }}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Por favor selecione o Contrato!',
                                        },
                                    ]}
                                >
                                    <Input
                                        disabled
                                        autoComplete="off"
                                    />
                                </Form.Item>
                            </Col>
                            {/* <Col {...colModalVincularUsuarioInputs}>
                                <Form.Item
                                    name="plano_uuid"
                                    label="Selecione o Plano"
                                    style={{ margin: 0, marginBottom: 15 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor selecione o plano.',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        loading={loadingPlanoSelected}
                                        notFoundContent={loadingPlanoSelected ? <Spin size="small" /> : null}
                                        optionFilterProp="children"
                                        placeholder="Selecione..."
                                        filterOption={(input: any, option: any) => {
                                            let textDigit = removeAcento(input)
                                            let listCidade = removeAcento(option?.children);
                                            return listCidade.indexOf(textDigit) >= 0
                                        }}
                                    >
                                        {
                                            dadosPlanoList.map((item) => {
                                                return (

                                                    <Select.Option
                                                        value={item.uuid}
                                                        key={item.uuid}
                                                    >

                                                        {item.nome}

                                                    </Select.Option>

                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col> */}
                            <Col {...colModalVincularUsuarioInputs}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Preencha o nome!",
                                        }
                                    ]}
                                    name="nome"
                                    label="Nome do usuário"
                                    style={{ margin: 0, marginBottom: 15 }}
                                >
                                    <Input
                                        ref={inputNomeModalVinUser}
                                        autoComplete="off"
                                        // onChange={onChangeInputNumberNumero}
                                        placeholder="Nome"
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...colModalVincularUsuarioInputs2}>
                                <Form.Item
                                    name="celular"
                                    label="Telefone"
                                    style={{ margin: 0, marginBottom: 15 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Preencha o telefone!",
                                            max: 16,
                                        },
                                        () => ({
                                            validator(rule, value) {
                                                return new Promise((resolve: (value?: any) => void, reject) => {
                                                    //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                                    if (value != '' && value != null) {
                                                        value = unMask(value);
                                                        if (value.length > 10) {
                                                            buscarCelularSeJaExiste(value).then(valorPromessa => {
                                                                if (valorPromessa) {
                                                                    reject("Celular já existe!");
                                                                } else {
                                                                    resolve();
                                                                }
                                                            }).catch(error => {
                                                                resolve();
                                                            })
                                                        } else {
                                                            reject("Celular inválido");
                                                        }
                                                    } else {
                                                        reject('');
                                                    }
                                                });
                                            },
                                        }),
                                    ]}
                                >
                                    <Input
                                        type="tel"
                                        autoComplete="off"
                                        onChange={(e) => onChangeInputTelefoneModalVinUsr(e)}
                                        placeholder="(44) 9 9999-9999"
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...colModalVincularUsuarioInputs}>
                                <Form.Item
                                    name="cargo_id"
                                    label="Cargo"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor selecione o cargo!',
                                        },
                                    ]}
                                    style={{ margin: 0, marginBottom: 15 }}
                                >
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Selecione..."
                                        // mode="multiple"
                                        loading={loadingCargoListSelected}
                                        notFoundContent={loadingCargoListSelected ? <Spin size="small" /> : null}
                                        filterOption={(input: any, option: any) => {
                                            let textDigit = removeAcento(input)
                                            let listCargo = removeAcento(option?.children);
                                            return listCargo.indexOf(textDigit) >= 0
                                        }}
                                    >
                                        {
                                            dadosDoCargoList.map((item) => {
                                                return (
                                                    <Select.Option
                                                        value={item.id}
                                                        key={item.id}
                                                    >
                                                        {item.nome}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...colModalVincularUsuarioInputs2}>
                                <Form.Item
                                    initialValue={false}
                                    name="enviar_whatsapp"
                                    label="Enviar WhatsApp Cadastro"
                                    style={{ margin: 1, marginBottom: 15 }}
                                    rules={[
                                    ]}
                                >
                                    <Radio.Group>
                                        <Radio.Button value={true}>Sim</Radio.Button>
                                        <Radio.Button value={false}>Não</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col
                                hidden={
                                    stateContratoSituacao?.nome === 'Período de Teste' ||
                                        stateContratoSituacao?.nome === 'Automático' ?
                                        false : true
                                }
                                {...colModalVincularUsuarioInputs}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: false,
                                            message: "Preencha o data!",
                                        },
                                        // () => ({
                                        //     validator(rule, value) {
                                        //         if (value != '' && value != null) {
                                        //             if (value.length < 10) {
                                        //                 return Promise.reject('Data Inválida');
                                        //             } else {
                                        //                 if (moment(value, 'DD/MM/YYYY', true).isValid()) {
                                        //                     return Promise.resolve();
                                        //                 } else {
                                        //                     return Promise.reject('Data Inválida');
                                        //                 }
                                        //             }

                                        //         } else {
                                        //             return Promise.resolve();
                                        //         }
                                        //     },
                                        // }),
                                    ]}
                                    name="validade_teste"
                                    label="Validade do Teste"
                                    style={{ margin: 0, marginBottom: 15 }}
                                >
                                    {/* <Input
                                            autoComplete="off"
                                            placeholder="31/12/2000"
                                            onChange={onChangeDataValidadeTeste}
                                        /> */}
                                    <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                        <DatePicker
                                            value={selectedDateVincularUser}
                                            onChange={setSelectedDateVincularUser}
                                            required={false}
                                            style={{
                                                width: '100%'
                                            }}
                                            okLabel="Ok"
                                            clearLabel="Limpar"
                                            cancelLabel="Cancelar"
                                            // clearable
                                            format="dd/MM/yyyy"
                                            className="datePickerCOlors"
                                        />
                                    </MuiPickersUtilsProvider>
                                </Form.Item>
                            </Col>

                        </Row>
                    </Form>
                </Spin>
            </Modal>
            <Modal
                title="Lista de usuários vinculados ao contrato"
                visible={modalListUserVinculados}
                className="modalVincularUsuario"
                onOk={clickCancelModalListUserVinculados}
                onCancel={clickCancelModalListUserVinculados}
                footer={[
                    <Button key="back" disabled={loadingFormAdd} onClick={clickCancelModalListUserVinculados}>
                        Fechar
                    </Button>,
                    // <Button key="submit" type="primary"
                    //     loading={loadingFormAdd}
                    //     onClick={clickSubmitModalListUserVinculados}
                    // >
                    //     Salvar
                    // </Button>,
                ]}
            >
                <Table
                    rowKey={(record) => record.uuid}
                    loading={loadingFormAdd}
                    columns={columnsModalListUserVinculados}
                    dataSource={dadosTabelaListUserVinculados}
                    scroll={{ y: 200, x: 450 }}
                    size="small"
                    title={(registros) => {
                        return (
                            <div
                                style={{
                                    backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                    padding: 10,
                                    margin: -8,
                                    marginBottom: 0.5
                                }}
                            >
                                <Text>
                                    {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${dadosTabelaListUserVinculados.length} `}
                                </Text>
                            </div>
                        )
                    }}
                    footer={(registros) => {
                        return (
                            <div
                                style={{
                                    textAlign: 'end'
                                }}
                            >
                                <Text>
                                    {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${dadosTabelaListUserVinculados.length} `}
                                </Text>
                            </div>
                        )
                    }}
                    pagination={{
                        locale: { items_per_page: '' },
                        showSizeChanger: true,
                        defaultPageSize: 10,
                        // position: ['topRight', 'bottomRight'],
                        // pageSizeOptions: ['10', '20', '50', '100']
                    }}
                    locale={locale.Table}
                />
            </Modal>
            <Modal
                title="Gestor"
                visible={modalGestorVisible}
                className="modalVincularUsuario"
                onOk={handleClickSubmitModalGestor}
                onCancel={clickCancelModalGestor}
                footer={[
                    <Button key="back" disabled={loadingTableModalGestor}
                        onClick={clickCancelModalGestor}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loadingTableModalGestor}
                        onClick={handleClickSubmitModalGestor}>
                        Salvar
                    </Button>,
                ]}
            >
                {modalGestorVisible &&
                    <Table
                        rowKey={(record) => record.id.toString()}
                        loading={loadingTableModalGestor}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => { onclickTableModal(record, rowIndex) }, // click row
                            };
                        }}
                        rowSelection={rowSelectionModal}
                        columns={columnsModal} dataSource={dadosTabelaGestorModal}
                        scroll={{ y: 200, x: 680 }}
                        onChange={
                            (pagination, filters, sorter, extra) => {
                                refContagemDoFiltroTabelaGestorModal.current = extra.currentDataSource
                            }
                        }
                        title={(registrosTabela) => {

                            return (
                                <div
                                    style={{
                                        backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                        padding: 10,
                                        margin: -8,
                                        marginBottom: 0.5
                                    }}
                                >
                                    <Text>
                                        {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                        {`Mostrando: ${registrosTabela.length} de ${refContagemDoFiltroTabelaGestorModal.current.length === 0 ?
                                            dadosTabelaGestorModal.length :
                                            refContagemDoFiltroTabelaGestorModal.current.length} num total de ${dadosTabelaGestorModal.length} `}
                                    </Text>
                                </div>
                            )
                        }}
                        size="small"
                        pagination={{ locale: { items_per_page: '' } }}
                        locale={locale.Table}
                    />
                }
            </Modal>
        </>
    );
}

export default MunicipiosPorContrato;
