export const boatoCancelarStep = {
  xs: { span: 2, offset: 0 }, //<576
  sm: { span: 2, offset: 0 }, //>576
  md: { span: 2, offset: 0 }, // >768
  lg: { span: 2, offset: 0 }, // > 992
  xl: { span: 2, offset: 0 }, // > 1200
  xxl: { span: 2, offset: 0 }, // > 1600
};
export const botaoProximoStep = {
  xs: { span: 22, offset: 0 }, //<576
  sm: { span: 22, offset: 0 }, //>576
  md: { span: 22, offset: 0 }, // >768
  lg: { span: 22, offset: 0}, // > 992
  xl: { span: 22, offset: 0 }, // > 1200
  xxl: { span: 22, offset: 0 }, // > 1600
};
export const botaoCancelarESalvarEmAddContrato = {
  xs: { span: 24, offset: 0 }, //<576
  sm: { span: 12, offset: 10 }, //>576
  md: { span: 15, offset: 4 }, // >768
  lg: { span: 9, offset: 15 }, // > 992
  xl: { span: 6, offset: 16 }, // > 1200
  xxl: { span: 6, offset: 19 }, // > 1600
};
