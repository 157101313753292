import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
// import Route from './Route';
import RouteVerify from './RouteVerify';
import * as GlobalActions from '../store/modules/global/actions';

import { lightTheme, darkTheme } from "./../layouts/ThemeCss/theme";
import { stateGlobal } from '../interfaces/Global';
import { ThemeProvider } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import GlobalTheme from "./../globalscss";

// import SignIn from '../pages/Signin';//primeira pagina
// import SignUp from '../pages/Signup';

// import Dashboard from '../pages/Dashboard';
// import Profile from '../pages/Profile';//page perfil

const LightTheme = React.lazy(() => import('./../layouts/ThemeCss/Light'));
const DarkTheme = React.lazy(() => import('./../layouts/ThemeCss/Dark'));

//STEP 2:
//create a parent component that will load the components conditionally using React.Suspense
const ThemeSelector = ({ children }: any) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const localTheme = localStorage.getItem("@GovFacil:theme");
        localTheme && dispatch(GlobalActions.updateTheme(localTheme));
    }, []);

    const CHOSEN_THEME = localStorage.getItem('@GovFacil:theme') || "light";

    return (
        <>
            <React.Suspense fallback={<></>}>
                {(CHOSEN_THEME === "light") && <LightTheme />}
                {(CHOSEN_THEME === "dark") && <DarkTheme />}
            </React.Suspense>
            {children}
        </>
    )
}

const Routes: React.FC = () => {
    const propsState = useSelector((state: stateGlobal) => state.global);

    return (
        <>
            <ThemeProvider theme={propsState.theme === "light" ? lightTheme : darkTheme}>
                <GlobalTheme />
                <ThemeSelector>
                    <BrowserRouter>
                        <RouteVerify />
                    </BrowserRouter>
                </ThemeSelector>
            </ThemeProvider>
        </>
    )
}

export default Routes;
