import React from 'react';
import { Row, Col, Tabs } from 'antd';
import Pesquisas from './components/Pesquisas'
import Respostas from './components/Respostas'
import Perguntas from './components/Perguntas'
import './style.css';

const { TabPane } = Tabs;

const PesquisaDeSatisfacao: React.FC = () => {

    return (
        <Row>
            <Col span={24}>
                <Tabs
                    type="card"
                    defaultActiveKey="2ListRespostas"
                    style={{
                        marginTop: 15,
                    }}
                    className='tab-municipios'

                >
                    <TabPane
                        tab="Listar Pesquisas"
                        key="1"
                    >
                        <Pesquisas />
                    </TabPane>
                    <TabPane
                        tab="Listar Respostas"
                        key="2ListRespostas"
                    >
                        <Respostas />
                    </TabPane>
                    <TabPane
                        tab="Listar Perguntas"
                        key="3ListPerguntas"
                    >
                        <Perguntas />
                    </TabPane>
                </Tabs>
            </Col>
        </Row>
    );
}

export default PesquisaDeSatisfacao;
