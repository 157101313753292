import {
    Button,
    Divider,
    Form,
    Input,
    List,
    Modal,
    Pagination,
    Row,
    Select,
    Typography,
    message,
    notification,
} from "antd";
import Title from "antd/lib/skeleton/Title";
import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router";
import { urlContratosEntidade } from "../../../../../services/request/urls";
import axios from "axios";
import { getToken } from "../../../../../services/token";

export const ModalFormUsuario = ({
    isModalUsuarioOpen,
    handleUsuarioCancel,
    usuarios,
    usuariosVinculados,
    entidade,
    vinculaUsuario,
    usuario,
    setUsuario,
    formUsuario,
    refFormUsuario,
}: any) => {
    const [perfil, setPerfil] = useState<any>();
    const [perfis, setPerfis] = useState<any>();
    const [permissao, setPermissao] = useState<any>();
    const [isContentVinculoOpen, setIsContentVinculoOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const signal = axios.CancelToken.source();
    // Função para calcular o índice inicial e final da página atual
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const refFormPermissionSelect = useRef<any>(null);

    const handlePageChange = (page: any) => {
        setCurrentPage(page);
    };

    const handleSearch = (value: any) => {
        setSearchTerm(value);
        setCurrentPage(1);
    };

    const removeAccents = (str: any) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const includesIgnoreCase = (str1: any, str2: any) => {
        return removeAccents(str1.toLowerCase()).includes(
            removeAccents(str2.toLowerCase())
        );
    };

    const filteredUsuarios = usuarios?.filter((item: any) =>
        includesIgnoreCase(item.nome, searchTerm)
    );

    let paginatedUsuarios = filteredUsuarios.slice(startIndex, endIndex);

    //Remove os usuários já vinculados da lista do paginador
    paginatedUsuarios = paginatedUsuarios.filter((item: any) => {
        let isVinculado = false;
        usuariosVinculados.forEach((usuarioVinculado: any) => {
            if (
                usuarioVinculado.nome === item.nome &&
                usuarioVinculado.telefone === item.celular
            ) {
                isVinculado = true;
            }
        });
        return !isVinculado;
    });
    const getPerfis = async () => {
        try {
            let result = await axios.get(urlContratosEntidade + "/perfil", {
                headers: { Authorization: "Bearer " + getToken() },
                cancelToken: signal.token,
            });

            setPerfis(result.data);
        } catch (error) {
            let msgErro: any = error as Error;
            if (msgErro?.message != "Requisicao cancelada!") {
                notification.error({
                    message: "Erro",
                    description: "Não foi possivel buscar os dados!",
                });
            }
        }
    };
    const getPermissao = async () => {
        try {
            let result = await axios.get(urlContratosEntidade + "/permissao", {
                headers: { Authorization: "Bearer " + getToken() },
                cancelToken: signal.token,
            });

            setPermissao(result.data);
        } catch (error) {
            let msgErro: any = error as Error;
            if (msgErro?.message != "Requisicao cancelada!") {
                notification.error({
                    message: "Erro",
                    description: "Não foi possivel buscar os dados!",
                });
            }
        }
    };

    useState(() => {

        getPerfis();
        getPermissao();
    });


    return (
        <>
            <Modal
                title={"Vincular Usuário"}
                width={1000}
                footer={[]}
                visible={isModalUsuarioOpen}
                onOk={() => {
                    if (isContentVinculoOpen) {
                        //se select for vazio, não vincula
                        if (usuario.perfil === undefined) {
                            message.error("Selecione o perfil do usuário");
                            return;
                        }

                        vinculaUsuario(usuario);
                        setIsContentVinculoOpen(false);
                    } else {
                        setIsContentVinculoOpen(false);
                        handleUsuarioCancel();
                    }
                }}
                onCancel={() => {
                    setIsContentVinculoOpen(false);
                    handleUsuarioCancel();
                }}
            >
                {!isContentVinculoOpen && !usuario ? (
                    <>
                        <Input.Search
                            placeholder="Buscar usuário"
                            onSearch={handleSearch}
                            enterButton
                        />
                        <List
                            style={{
                                marginTop: 10,
                                maxHeight: 500,
                                overflowY: "auto",
                                overflowX: "hidden",
                                border: "1px solid #ddd",
                                borderRadius: 5,
                            }}
                            size="small"
                            bordered
                            dataSource={paginatedUsuarios}
                            renderItem={(item: any) => (
                                <List.Item
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                    actions={[
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                //Limpa o id
                                                // item.id = null;
                                                setUsuario(item);
                                                //reseta o form
                                                formUsuario.setFieldsValue({
                                                    perfilId: null,
                                                    permissoes: [],
                                                });
                                                setIsContentVinculoOpen(true);

                                            }}
                                        >
                                            Selecionar
                                        </Button>
                                    ]}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}>

                                    <Typography.Text
                                    style={{ marginRight: 10, width: 250, fontWeight: "bold" }}
                                    >

                                        {item?.nome}
                                    </Typography.Text>


                                    <Typography.Text
                                    style={{ marginRight: 10, width: 150 }}
                                    >
                                        {item?.celular.replace(
                                            /(\d{2})(\d{5})(\d{4})/,
                                            "($1) $2-$3"
                                        )}
                                    </Typography.Text>
                                    <Typography.Text>
                                        {item?.cargo?.nome}
                                    </Typography.Text>
                                    </div>

                                </List.Item>
                            )}
                        />
                        <Pagination
                            style={{ marginTop: 10 }}
                            current={currentPage}
                            onChange={handlePageChange}
                            pageSize={itemsPerPage}
                            total={usuarios.length}
                            showSizeChanger={false}
                        />
                    </>
                ) : (
                    <>
                        <Typography.Text>
                            Deseja vincular o usuário <b>{usuario?.nome ? usuario?.nome : usuario?.usuario?.nome}</b> na
                            entidade <b>{entidade}</b>?
                        </Typography.Text>
                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                        {/* Option pra selecionar o perfil */}
                        <Typography.Text style={{ marginTop: 10 }}>
                            Selecione o perfil:
                        </Typography.Text>
                        <Form
                            form={formUsuario}
                            ref={refFormUsuario}
                            name="formUsuario"
                        >
                        <Form.Item
                            name="perfilId"
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione o perfil do usuário",
                                },
                            ]}>
                        <Select
                            style={{ width: "100%", marginTop: 10 }}
                            placeholder="Selecione o perfil"
                            onChange={(value) => {
                                setPerfil(value);
                                //Completa as permissões do perfil
                                let perfilSelecionado = perfis?.find(
                                    (perfil: any) => perfil.uuid === value
                                );
                                usuario.permissoes = perfilSelecionado?.permissoes;
                                setUsuario(usuario);
                                //Preenche o select de permissões
                                formUsuario.setFieldsValue({
                                    permissoes: perfilSelecionado?.permissoes?.map((permissao: any) => permissao.uuid),
                                });
                            }}
                            // defaultValue={usuario?.perfilId}
                        >
                            {perfis?.map((perfil: any) => (
                                <Select.Option
                                key={perfil.uuid}
                                value={perfil.uuid}>{perfil?.nome}
                                </Select.Option>
                            ))}
                        </Select>
                        </Form.Item>
                        <div style={{ marginTop: 10 }}>
                            <Typography.Text>
                                Selecione as permissões:
                            </Typography.Text>
                            <Form.Item
                                name="permissoes"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Selecione pelo menos uma permissão",
                                    },
                                ]}
                                >
                            <Select
                                ref={refFormPermissionSelect}
                                style={{ width: "100%", marginTop: 10 }}
                                placeholder="Selecione as permissões"
                                mode="multiple"
                                onChange={(value) => {
                                    usuario.permissoes = value;

                                    setUsuario(usuario);
                                }}
                                // defaultValue={usuario.permissoes?.map((permissao: any) => permissao.uuid)}
                            >
                                {permissao?.map((permissao: any) => (
                                    <Select.Option
                                        value={permissao.uuid}
                                        key={permissao.uuid}
                                    >
                                        {permissao?.nome}
                                    </Select.Option>
                                ))}
                            </Select>
                            </Form.Item>
                        </div>
                        </Form>
                        <Row
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: 20,
                            }}
                            >
                                <Button
                            onClick={() => {
                                setIsContentVinculoOpen(false);
                                handleUsuarioCancel();
                            }}
                        >
                            Cancelar

                        </Button>
                        <Button
                            type="primary"
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                                usuario.perfilId = perfil;

                                if(usuario.perfilId === undefined){
                                    usuario.perfilId = usuario.perfil?.uuid;
                                }

                                if(usuario.perfilId === undefined){
                                    message.error("Selecione o perfil do usuário");
                                    return;
                                }
                                if (usuario.permissoes.length === 0) {
                                    message.error(
                                        "Selecione pelo menos uma permissão"
                                    );
                                    return;
                                }
                                vinculaUsuario(usuario);
                                setIsContentVinculoOpen(false);
                            }}
                        >
                            Vincular
                        </Button>
                            </Row>

                    </>
                )}
            </Modal>
        </>
    );
};
