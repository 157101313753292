import React, { useState, useCallback, useEffect } from 'react';
import {
    Table, Input, Button, Space, notification, Typography, Row, Col,
    Divider, Tag
} from 'antd';
import Highlighter from 'react-highlight-words';
import locale from 'antd/es/locale/pt_BR';
import { SearchOutlined, ExclamationCircleOutlined, MobileOutlined } from '@ant-design/icons';
import moment from 'moment';
// import { format, addDays } from 'date-fns'
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
    urlUsersStatusAtivos,
    urlRelatorioDispositivos
} from '../../../services/request/urls';

import { getToken } from './../../../services/token';
import Icon from '@ant-design/icons';
import { mask, unMask } from '../../../utils/MascaraDeCampos';
// import { useForm } from 'antd/lib/form/Form';
import {
    cardLayout,
} from './GridStyle';
// import {
//     convertDataBrParaUS
// } from './../../../services/Conversores';
import { stateGlobal } from '../../../interfaces/Global';

import {
    removeAcento
} from './../../../utils/RemoveAcentos';

import { FaSearch } from 'react-icons/fa';

const { Title, Text } = Typography;

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>

interface interfaceDispositivos {
    "id": number,
    "nome": string,
    "celular": string,
    "data_registro": string,
    "marca": string,
    "modelo": string,
    "versao": string,
    "contratos": Array<string>
}

const ListDispositivosAtivos: React.FC = () => {

    const propsState = useSelector((state: stateGlobal) => state.global);

    const refContagemDoFiltro = React.useRef<Array<any>>([]);

    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();
    const signal1 = axios.CancelToken.source();

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string>>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dadosTabelaState, setDadosTabelaState] = useState<Array<interfaceDispositivos>>([]);
    const [hasSelectedAdd, setHasSelectedAdd] = useState(true);
    const [hasSelectedView, setHasSelectedView] = useState(false);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);
    const [hasSelectedDel, setHasSelectedDel] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);

    const buscaDadosUsuariosAtivosAndSetTable = useCallback((): void => {

        setLoadingTable(true)
        const buscaDeDadosUsuarios = async () => {
            try {
                let result = await axios.get(urlRelatorioDispositivos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setDadosTabelaState(result.data);
                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosUsuarios();

    }, []);

    //executa apenas ao iniciar o component
    useEffect(() => {
        //busca todos users ativos
        buscaDadosUsuariosAtivosAndSetTable();

    }, []);

    //executa apenas quando desmonta o component
    const comonentExecutaAoDestroy = useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
        signal1.cancel("Requisicao cancelada!")
    }, []);

    //Search da tabela de listagem
    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {

                let trecord = removeAcento(record[dataIndex]?.toString());

                return record[dataIndex] ? trecord.includes(removeAcento(value)) : false

            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text?.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    const getColumnSearchPropsData = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let texto: string | undefined = ''
                if (record[dataIndex]) texto = moment(record[dataIndex]).format('DD/MM/YYYY HH:mm')

                return (
                    texto ? texto.toString().toLowerCase().includes(value.toLowerCase()) : false
                )
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    const getColumnSearchPropsObjNome = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) =>
                record[dataIndex]?.nome ? record[dataIndex].nome.toString().toLowerCase().includes(value.toLowerCase()) : '',
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    //Search da tabela de listagem
    const getColumnSearchPropsBolean = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let valor: string = record[dataIndex] ? 'ativo' : 'inativo'

                return valor ? valor.includes(value.toLowerCase()) : ''
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, [searchText]);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);
    // fim do search da tabela de listagem


    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeys.includes(record.uuid)) {
            let filter = selectedRowKeys.filter((val) => {
                return val != record.uuid
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(true);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length == 0) {
                setSelectedRowKeys([record.uuid]);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else {
                let teste = selectedRowKeys.concat(record.uuid);
                setSelectedRowKeys(teste);
                setHasSelectedAdd(false);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
                setHasSelectedDel(true);
            }
        }
    }, [selectedRowKeys]);

    //checkbox da tabela de listagem
    const onSelectChange = useCallback((selectedRowKeys): void => {
        if (selectedRowKeys.length > 1) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
            setHasSelectedDel(true);
        } else if (selectedRowKeys.length === 0) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(true);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
            setHasSelectedDel(false);
        } else {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(true);
            setHasSelectedEdit(true);
            setHasSelectedDel(true);
        }
    }, [selectedRowKeys]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '1%'
    };

    const columns = [
        {
            title: <div style={{
                width: '130px',
                // backgroundColor: "red"
            }}>
                <Text style={{
                    width: '100%',
                    // backgroundColor: "blue"
                }}>Nome</Text>
            </div>,
            dataIndex: 'nome',
            ...getColumnSearchProps('nome'),
            width: '1%',
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
        },
        {
            title: 'Último Acesso',
            dataIndex: 'ultimo_acesso',
            ...getColumnSearchPropsData('ultimo_acesso'),
            width: '2%',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.ultimo_acesso) {
                    aMonMen = moment(a.ultimo_acesso).unix()
                } else {
                    aMonMen = 0
                }
                if (b.ultimo_acesso) {
                    bMonMen = moment(b.ultimo_acesso).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            render: (data: any) => {
                let stringData: string = ''
                if (data) {
                    stringData = moment(data).format('DD/MM/YYYY HH:mm').toString()
                }
                return (
                    stringData
                )
            },
        },
        {
            title: 'Contratos',
            dataIndex: 'contratos',
            ...getColumnSearchProps('contratos'),
            width: '1.5%',
            render: (contratos: any) => (
                <span>
                    {
                        contratos.map((element: string, index: number) => (
                            // element+' '
                            <span key={'span' + index}>
                                <Tag color={''} key={index}>
                                    {element}
                                </Tag>
                            </span>
                        ))
                    }
                </span>
            ),
        },
        {
            title: 'Vendedores',
            dataIndex: 'vendedores',
            ...getColumnSearchProps('vendedores'),
            width: '1.5%',
            render: (vendedores: any) => (
                <span>
                    {
                        vendedores?.map((element: string, index: number) => (
                            // element+' '
                            <span key={'span' + index}>
                                <Tag color={''} key={index}>
                                    {element}
                                </Tag>
                            </span>
                        ))
                    }
                </span>
            ),
        },
        {
            title: 'Celular',
            dataIndex: 'celular',
            ...getColumnSearchProps('celular'),
            width: '5%',
            render: (celular: any) => {
                if (celular) {
                    const originalValue = unMask(celular);
                    const maskedValue = mask(originalValue, [
                        "(99) 9999-9999",
                        "(99) 9 9999-9999"
                    ]);
                    return maskedValue;
                }
                return ''
            }
            // sorter: (a, b) => a.age - b.age //para quando é numero
        },
        {
            title: 'Cargo',
            dataIndex: 'cargo',
            ...getColumnSearchProps('cargo'),
            width: '1.5%',
            showSorterTooltip: false,
        },
        // {
        //     title: 'Data de Registro',
        //     dataIndex: 'data_registro',
        //     ...getColumnSearchPropsData('data_registro'),
        //     width: '2%',
        //     showSorterTooltip: false,
        //     sorter: (a: any, b: any) => {

        //         let aMonMen: any = null
        //         let bMonMen: any = null
        //         if (a.data_registro) {
        //             aMonMen = moment(a.data_registro).unix()
        //         } else {
        //             aMonMen = 0
        //         }
        //         if (b.data_registro) {
        //             bMonMen = moment(b.data_registro).unix()
        //         } else {
        //             bMonMen = 0
        //         }

        //         return aMonMen - bMonMen
        //     },
        //     // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
        //     render: (data: any) => (
        //         moment(data).format('DD/MM/YYYY HH:mm')
        //     ),
        // },
        // {
        //     title: 'Marca',
        //     dataIndex: 'marca',
        //     ...getColumnSearchProps('marca'),
        //     width: '2%',
        // },
        // {
        //     title: 'Modelo',
        //     dataIndex: 'modelo',
        //     ...getColumnSearchProps('modelo'),
        //     width: '2%',
        // },
        {
            title: 'Versão',
            dataIndex: 'versao',
            ...getColumnSearchProps('versao'),
            width: '2%',
            sorter: (a: any, b: any) => {

                let vA: any = a.versao.toString()
                let vB: any = b.versao.toString()

                return vA.localeCompare(vB)
            },
            showSorterTooltip: false,
        },
    ];

    return (
        <>
            <Row>
                <Col {...cardLayout}>
                    <Title level={4}><MobileOutlined style={{ fontSize: 25, marginRight: 10 }} />{"Dispositivos Ativos"}</Title>
                    <Divider />
                </Col>
            </Row>
            <Table
                rowKey={(record) => record.id.toString()}
                loading={loadingTable}
                onRow={(record, rowIndex) => {
                    return {
                        // onClick: event => { onclickTable(record, rowIndex) }, // click row
                        onDoubleClick: event => { }, // double click row
                        onContextMenu: event => { }, // right button click row
                        onMouseEnter: event => { }, // mouse enter row
                        onMouseLeave: event => { }, // mouse leave row
                    };
                }}
                // rowSelection={rowSelection}
                columns={columns} dataSource={dadosTabelaState}
                scroll={{ x: 1150 }}
                size="small"
                onChange={
                    (pagination, filters, sorter, extra) => {
                        refContagemDoFiltro.current = extra.currentDataSource
                    }
                }
                title={(registros) => {
                    return (
                        <div
                            style={{
                                backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                padding: 10,
                                margin: -8,
                                marginBottom: 0.5
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: 'end'
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                pagination={{
                    locale: { items_per_page: '' },
                    showSizeChanger: true,
                    defaultPageSize: 100,
                    position: ['topRight', 'bottomRight'],
                    pageSizeOptions: ['10', '20', '50', '100']
                }}
                locale={locale.Table}
            />
        </>
    );
}

export default ListDispositivosAtivos;
