import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
    Table, Input, Button, Cascader, Select, Spin, Space, Row, Col, notification,
    Typography
} from 'antd';
import Highlighter from 'react-highlight-words';
import {
    selectedNomeOrgaoAdd,
    selectedEstadosInAddAssociacao
} from './GridStyle';
import { SearchOutlined } from '@ant-design/icons';
import locale from 'antd/es/locale/pt_BR';
import Icon from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
// import { useForm } from 'antd/lib/form/Form';
import { getToken } from './../../services/token';
import { useSelector } from 'react-redux';
import {
    urlAssocFederal, urlAssocEstadual, urlAssocRegional, urlAssocRegionalCidades, urlAssocEstadualCidades,
    urlAssocFederalCidades, urlAssociacaoAll
} from '../../services/request/urls';
import axios from 'axios';

import { FaSearch } from 'react-icons/fa';
import { stateGlobal } from '../../interfaces/Global';

const { Text } = Typography;

interface interfaceCidadeEstados {
    id: number,
    uuid: string,
    ibge_id_antigo: number,
    nome: string,
    latitude: string,
    longitude: string,
    populacao: number,
    microrregiaoId: number,
    estadoId: number,
    estadoNome: string
};

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>

const options = [
    {
        value: 'regional',
        label: 'Regional',
        children: [
            {
                "value": 12,
                "label": "Acre",
            },
            {
                "value": 27,
                "label": "Alagoas",
            },
            {
                "value": 16,
                "label": "Amapá",
            },
            {
                "value": 13,
                "label": "Amazonas",
            },
            {
                "value": 29,
                "label": "Bahia",
            },
            {
                "value": 23,
                "label": "Ceará",
            },
            {
                "value": 53,
                "label": "Distrito Federal",
            },
            {
                "value": 32,
                "label": "Espírito Santo",
            },
            {
                "value": 52,
                "label": "Goiás",
            },
            {
                "value": 21,
                "label": "Maranhão",
            },
            {
                "value": 51,
                "label": "Mato Grosso",
            },
            {
                "value": 50,
                "label": "Mato Grosso do Sul",
            },
            {
                "value": 31,
                "label": "Minas Gerais",
            },
            {
                "value": 15,
                "label": "Pará",
            },
            {
                "value": 25,
                "label": "Paraíba",
            },
            {
                "value": 41,
                "label": "Paraná",
            },
            {
                "value": 26,
                "label": "Pernambuco",
            },
            {
                "value": 22,
                "label": "Piauí",
            },
            {
                "value": 33,
                "label": "Rio de Janeiro",
            },
            {
                "value": 24,
                "label": "Rio Grande do Norte",
            },
            {
                "value": 43,
                "label": "Rio Grande do Sul",
            },
            {
                "value": 11,
                "label": "Rondônia",
            },
            {
                "value": 14,
                "label": "Roraima",
            },
            {
                "value": 42,
                "label": "Santa Catarina",
            },
            {
                "value": 35,
                "label": "São Paulo",
            },
            {
                "value": 28,
                "label": "Sergipe",
            },
            {
                "value": 17,
                "label": "Tocantins",
            }
        ],
    },
    {
        value: 'estadual',
        label: 'Estadual',
        children: [
            {
                "value": 12,
                "label": "Acre",
            },
            {
                "value": 27,
                "label": "Alagoas",
            },
            {
                "value": 16,
                "label": "Amapá",
            },
            {
                "value": 13,
                "label": "Amazonas",
            },
            {
                "value": 29,
                "label": "Bahia",
            },
            {
                "value": 23,
                "label": "Ceará",
            },
            {
                "value": 53,
                "label": "Distrito Federal",
            },
            {
                "value": 32,
                "label": "Espírito Santo",
            },
            {
                "value": 52,
                "label": "Goiás",
            },
            {
                "value": 21,
                "label": "Maranhão",
            },
            {
                "value": 51,
                "label": "Mato Grosso",
            },
            {
                "value": 50,
                "label": "Mato Grosso do Sul",
            },
            {
                "value": 31,
                "label": "Minas Gerais",
            },
            {
                "value": 15,
                "label": "Pará",
            },
            {
                "value": 25,
                "label": "Paraíba",
            },
            {
                "value": 41,
                "label": "Paraná",
            },
            {
                "value": 26,
                "label": "Pernambuco",
            },
            {
                "value": 22,
                "label": "Piauí",
            },
            {
                "value": 33,
                "label": "Rio de Janeiro",
            },
            {
                "value": 24,
                "label": "Rio Grande do Norte",
            },
            {
                "value": 43,
                "label": "Rio Grande do Sul",
            },
            {
                "value": 11,
                "label": "Rondônia",
            },
            {
                "value": 14,
                "label": "Roraima",
            },
            {
                "value": 42,
                "label": "Santa Catarina",
            },
            {
                "value": 35,
                "label": "São Paulo",
            },
            {
                "value": 28,
                "label": "Sergipe",
            },
            {
                "value": 17,
                "label": "Tocantins",
            }
        ],
    },
    {
        value: 'federal',
        label: 'Federal',
    },
    {
        value: 'todos',
        label: 'Todos',
    },
];

const ListagemAssociacoes: React.FC = () => {

    const propsState = useSelector((state: stateGlobal) => state.global);

    const formRef = useRef<any>(null);
    // formRef = React.createRef();

    const refContagemDoFiltro = React.useRef<Array<any>>([]);

    const history = useHistory();

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string>>([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dadosTabelaState, setDadosTabelaState] = useState<any>([]);
    const [hasSelectedAdd, setHasSelectedAdd] = useState(true);
    const [hasSelectedView, setHasSelectedView] = useState(false);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);

    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();



    //Search da tabela de listagem
    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) =>
                record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, [searchText]);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);
    // fim do search da tabela de listagem


    //abrir navegar em add contrato
    const clickBotaoEditarAssoc = useCallback((): void => {

        const buscaDeDados = async () => {

            if (selectedRowKeys.length == 1) {

                let objSelecionado: any = {}
                dadosTabelaState.forEach((dados: any): void => {
                    if (dados.uuid == selectedRowKeys[0]) {
                        objSelecionado = dados
                    }
                })

                try {

                    switch (objSelecionado.tipo) {
                        case "Regional":
                            let resultRegional = await axios.get(urlAssocRegionalCidades + "/" + objSelecionado.id,
                                {
                                    headers: { 'Authorization': 'Bearer ' + getToken() }
                                });

                            let estadosIDRegional: any = []
                            resultRegional.data.forEach((vende: interfaceCidadeEstados) => {
                                if (estadosIDRegional.length === 0) {
                                    estadosIDRegional.push(vende.estadoId)
                                } else {
                                    let indice = estadosIDRegional.indexOf(vende.estadoId);
                                    if (indice === -1) {
                                        estadosIDRegional.push(vende.estadoId)
                                    }
                                }
                            });

                            objSelecionado.cidades = resultRegional.data;
                            objSelecionado.estados = estadosIDRegional;

                            break;
                        case "Estadual":

                            let resultEstadual = await axios.get(urlAssocEstadualCidades + "/" + objSelecionado.id,
                                {
                                    headers: { 'Authorization': 'Bearer ' + getToken() }
                                });

                            let estadosIDEstadual: any = []
                            resultEstadual.data.forEach((vende: interfaceCidadeEstados) => {
                                if (estadosIDEstadual.length === 0) {
                                    estadosIDEstadual.push(vende.estadoId)
                                } else {
                                    let indice = estadosIDEstadual.indexOf(vende.estadoId);
                                    if (indice === -1) {
                                        estadosIDEstadual.push(vende.estadoId)
                                    }
                                }
                            });

                            objSelecionado.cidades = resultEstadual.data;
                            objSelecionado.estados = estadosIDEstadual;

                            break;
                        case "Federal":

                            let resultFederal = await axios.get(urlAssocFederalCidades + "/" + objSelecionado.id,
                                {
                                    headers: { 'Authorization': 'Bearer ' + getToken() }
                                });

                            let estadosIDFederal: any = []
                            resultFederal.data.forEach((vende: interfaceCidadeEstados) => {
                                if (estadosIDFederal.length === 0) {
                                    estadosIDFederal.push(vende.estadoId)
                                } else {
                                    let indice = estadosIDFederal.indexOf(vende.estadoId);
                                    if (indice === -1) {
                                        estadosIDFederal.push(vende.estadoId)
                                    }
                                }
                            });

                            objSelecionado.cidades = resultFederal.data;
                            objSelecionado.estados = estadosIDFederal;

                            break;

                        default:
                            break;
                    }

                    localStorage.setItem('@GovFacil:associacao', JSON.stringify(objSelecionado));


                    history.push('/associacao/adicionar');
                } catch (error) {
                    let msgErro: any = (error as Error);
                    notification.error({
                        message: 'Erro',
                        description:
                            msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }

            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState]);

    const clickSubmitModalAddAssociacao = useCallback((): void => {
        // formRef.current.submit((err: any, values: any) => {

        // })
    }, []);

    //abrir modal
    const clickBotaoAddAssociacao = useCallback((): void => {
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:associacao');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@GovFacil:associacao');
        }

        history.push("/associacao/adicionar");
    }, []);

    // validatorConfmSenha = (rule, value, callback) => {
    //     try {
    //         let password = this.formRef.current.getFieldValue("senha");
    //         if (password != undefined && value === password) {
    //         } else {
    //             throw new Error('Something wrong!');
    //         }

    //     } catch (error) {
    //         callback(error)
    //     }

    // }

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeys.includes(record.uuid)) {
            let filter = selectedRowKeys.filter((val) => {
                return val != record.uuid
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(true);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length == 0) {
                setSelectedRowKeys([record.uuid]);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
            } else {
                let teste = selectedRowKeys.concat(record.uuid);
                setSelectedRowKeys(teste);
                setHasSelectedAdd(false);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
            }
        }
    }, [selectedRowKeys]);

    //checkbox da tabela de listagem
    const onSelectChange = useCallback((selectedRowKeys): void => {
        if (selectedRowKeys.length > 1) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
        } else if (selectedRowKeys.length === 0) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(true);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
        } else {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(true);
            setHasSelectedEdit(true);
        }
    }, [selectedRowKeys]);

    const filterCascade = useCallback((inputValue: any, path: any): any => {
        return path.some((option: any) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }, []);

    const buscaAssocFederal = useCallback((): void => {

        setLoadingTable(true)
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlAssocFederal,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setDadosTabelaState(result.data);
                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDados();

    }, []);

    const buscaAssocEstadual = useCallback((valorIdEstrado): void => {

        setLoadingTable(true)
        const buscaDeDadosContratos = async () => {
            try {
                let result = await axios.get(urlAssocEstadual + "/" + valorIdEstrado,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setDadosTabelaState(result.data);
                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosContratos();

    }, []);

    const buscaAssocRegional = useCallback((valorIdEstrado): void => {

        setLoadingTable(true)
        const buscaDeDadosContratos = async () => {
            try {
                let result = await axios.get(urlAssocRegional + "/" + valorIdEstrado,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setDadosTabelaState(result.data);
                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosContratos();

    }, []);

    const buscaTodasAssoc = useCallback((): void => {

        setLoadingTable(true)
        const buscaDeDadosContratos = async () => {
            try {
                let result = await axios.get(urlAssociacaoAll,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setDadosTabelaState(result.data);
                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosContratos();

    }, []);

    //Select
    const onChangeCascader = useCallback((value, objCascade): void => {


        switch (objCascade[0]?.value) {
            case "regional":
                buscaAssocRegional(objCascade[1].value);
                break;
            case "estadual":
                buscaAssocEstadual(objCascade[1].value);

                break;
            case "federal":
                buscaAssocFederal();

                break;
            case "todos":
                buscaTodasAssoc();

                break;

            default:
                //esse tem que buscar o parana
                buscaAssocFederal();
                break;
        }
    }, []);



    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '1%'
    };

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...getColumnSearchProps('nome'),
            width: '15%',
        },
        // {
        //     title: 'Estado',
        //     dataIndex: 'estado',
        //     ...getColumnSearchProps('estado'),
        //     width: '15%',
        // },
        {
            title: 'Tipo',
            dataIndex: 'tipo',
            ...getColumnSearchProps('tipo'),
            width: '2%',
        }
    ];

    const comonentDidMount = useEffect((): void => {
        //buscando e setando na tabela de listagem o parana
        buscaAssocRegional(41);
    }, [buscaAssocRegional]);

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    zIndex: 2,
                    // background: propsState.theme === "light" ? '#fff' : '#141414',
                    //
                    // width: '80%',
                    paddingTop: 24,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24
                }}
            >
                <Row style={{ marginBottom: 0 }}>
                    <Col>
                        <Button type="primary" className="botaoAdicionarAssociacao" disabled={!hasSelectedAdd} onClick={clickBotaoAddAssociacao} >
                            Adicionar
                        </Button>
                        {/* <Button className="botaoViewAssociacao" type="primary" onClick={start} disabled={!hasSelectedView} >
                        Visualizar
                    </Button> */}
                    </Col>
                    <Col>
                        <Button type="primary" className="botaoEditarAssociacao" onClick={clickBotaoEditarAssoc} disabled={!hasSelectedEdit} >
                            Editar
                        </Button>
                    </Col>
                    <Col>
                        <Cascader
                            options={options}
                            onChange={onChangeCascader}
                            className="botaoListSelectCascaderAssoc"
                            defaultValue={["regional", 41]}
                            placeholder="Filtro"
                            expandTrigger="hover"
                            showSearch={{ filter: filterCascade }}
                        />
                    </Col>
                </Row>
            </div>
            <Table
                style={{
                    marginTop: 50
                }}
                className='marginTopTables'
                loading={loadingTable}
                rowKey={(record) => record.uuid}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onclickTable(record, rowIndex) }, // click row
                    };
                }}
                rowSelection={rowSelection}
                columns={columns} dataSource={dadosTabelaState}
                scroll={{ x: 1000 }}
                size="small"
                onChange={
                    (pagination, filters, sorter, extra) => {
                        refContagemDoFiltro.current = extra.currentDataSource
                    }
                }
                title={(registros) => {
                    return (
                        <div
                            style={{
                                backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                padding: 10,
                                margin: -8,
                                marginBottom: 0.5
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: 'end'
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                pagination={{
                    locale: { items_per_page: '' },
                    showSizeChanger: true,
                    defaultPageSize: 10,
                    position: ['topRight', 'bottomRight'],
                    pageSizeOptions: ['10', '20', '50', '100']
                }}
                locale={locale.Table}
            />
        </>
    );
}

export default ListagemAssociacoes;
