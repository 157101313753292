import React from 'react';
// import './index.css';
import { Router } from 'react-router-dom';

import Routes from './routes';
import history from './services/history';

import { Provider } from 'react-redux';

import store from './store';

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <Router history={history}>
                <Routes />
            </Router>
        </Provider>
    )
}

export default App;
