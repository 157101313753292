export const Layout1_1 = {
    xs: { span: 16, offset: 6 }, //<576
    sm: { span: 14, offset: 6 }, //>576
    md: { span: 16, offset: 6 }, // >768
    lg: { span: 16, offset: 6 }, // > 992
    xl: { span: 16, offset: 6 }, // > 1200
    xxl: { span: 16, offset: 6 }, // > 1600
};

export const selectedContratoUserAdd = {
    xs: { span: 21, offset: 0 },
    sm: { span: 22, offset: 0 },
    md: { span: 22, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 8, offset: 0 },
    xxl: { span: 8, offset: 0 },
};

export const botaoRemoverSelected = {
    xs: { span: 2, offset: 0 },
    sm: { span: 2, offset: 0 },
    md: { span: 2, offset: 0 },
    lg: { span: 3, offset: 0 },
    xl: { span: 3, offset: 0 },
    xxl: { span: 3, offset: 0 },
};

export const modaisDeConfig = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 24, offset: 0 }, // > 992
    xl: { span: 24, offset: 0 }, // > 1200
    xxl: { span: 24, offset: 0 }, // > 1600
  };
