import React, { useState, useCallback, useEffect } from 'react';
import {
    Table, Input, Button, Space, notification, Row, Col,
    Modal, Typography, Select, Spin, Tag, Tooltip
} from 'antd';
import Highlighter from 'react-highlight-words';
import locale from 'antd/es/locale/pt_BR';
import { SearchOutlined, ExclamationCircleOutlined, RedoOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { urlMensagem, urlPesquisa, urlPesquisaResposta } from '../../../../services/request/urls';
import { getToken } from './../../../../services/token';
import Icon from '@ant-design/icons';
// import { useForm } from 'antd/lib/form/Form';
import { stateGlobal } from '../../../../interfaces/Global';

import { FaSearch } from 'react-icons/fa';
import { GiBroom } from 'react-icons/gi';
import { colSelect } from './GridStyle';
import { removeAcento } from '../../../../utils/RemoveAcentos';
import { useGetColumnSearchPropsData } from '../../../../hooks/FilterTable/date';
import './style.css';

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>
const IconBroom = (props: any) => <Icon {...props} component={GiBroom} ></Icon>

const { Text } = Typography;

interface interfaceListPesquisa {
    "id": number,
    "uuid": string,
    "nome": string,
    "perguntasPesquisa": Array<
        {
            "id": number,
            "uuid": string,
            "pergunta": string,
            "tipo": string,
            "created_at": string,
            "updated_at": string
        }
    >,
    "created_at": string,
    "updated_at": string
}

interface interfaceListRespostasPesquisa {
    "id": number,
    "usuario": string,
    "contratos": Array<string>,
    "data_resposta": string,
    "tipo": string,
    "pergunta": string,
    "resposta": string
}

const ListRespostas: React.FC = () => {

    // const formRef = useRef<any>(null);
    // formRef = React.createRef();

    const refContagemDoFiltro = React.useRef<Array<any>>([]);
    const refCloneDataTable = React.useRef<Array<interfaceListRespostasPesquisa>>([]);
    const refDataTableNullProxCont = React.useRef<Array<interfaceListRespostasPesquisa>>([]);

    const propsState = useSelector((state: stateGlobal) => state.global);

    const history = useHistory();
    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();
    const signal1 = axios.CancelToken.source();

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchTextNomeUsuario, setSearchTextNomeUsuario] = useState('');
    const [searchedColumnNomeUsuario, setSearchedColumnNomeUsuario] = useState('');
    const [dadosTabelaState, setDadosTabelaState] = useState<Array<interfaceListRespostasPesquisa>>([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [valueInputPesquisa, setValueInputPesquisa] = useState();

    const [dadosViewDadosRespostaInModal, setDadosViewDadosRespostaInModal] = useState<any>(undefined);
    const [modalViewDadosRespostas, setModalViewDadosRespostas] = useState(false);

    const [dataListPesquisas, setDataListPesquisas] = useState<Array<interfaceListPesquisa>>([]);
    const [loadingListPesquisas, setLoadingListPesquisas] = useState(false);

    //setando dados das cidades no selected
    const buscaDadosSelect = useCallback(() => {

        const buscaDadosfunc = async () => {
            try {
                setLoadingListPesquisas(true);

                let resultCidade = await axios.get(urlPesquisa,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });


                setDataListPesquisas(resultCidade.data);

                if (resultCidade.data.length > 0) {

                    setValueInputPesquisa(resultCidade.data[0].id)
                    buscaDadosSetTable(resultCidade.data[0].id)
                }
                setLoadingListPesquisas(false);


            } catch (error) {

                setLoadingListPesquisas(false);
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }


            }
        }

        buscaDadosfunc();

    }, []);

    const buscaDadosSetTable = useCallback((id: number) => {

        const buscaDeDados = async () => {
            setLoadingTable(true)
            try {
                let result = await axios.get(urlPesquisaResposta + '/' + id,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal1.token
                    });
                //para clear da vassoura
                refDataTableNullProxCont.current = []
                refCloneDataTable.current = result.data

                setDadosTabelaState(result.data);
                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDados();

    }, []);

    //executa apenas ao iniciar o component
    useEffect(() => {
        buscaDadosSelect();

    }, []);

    //executa apenas quando desmonta o component
    const comonentExecutaAoDestroy = useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
        signal1.cancel("Requisicao cancelada!")
    }, []);

    const buscaDadosUsuariosAndSetTable = useCallback((): void => {

        setLoadingTable(true)
        const buscaDeDadosUsuarios = async () => {
            try {
                let result = await axios.get(urlMensagem,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                //para clear da vassoura
                refDataTableNullProxCont.current = []
                refCloneDataTable.current = result.data

                setDadosTabelaState(result.data);
                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosUsuarios();

    }, []);

    //Search da tabela de listagem

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, [searchText]);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);

    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) =>
                record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn, handleSearch, handleReset]);

    //seact da tabela nome usuario
    const handleSearchNomeUsuario = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchTextNomeUsuario(selectedKeys[0]);
        setSearchedColumnNomeUsuario(dataIndex);
    }, []);

    const handleResetNomeUsuario = useCallback((clearFilters): void => {
        clearFilters();
        setSearchTextNomeUsuario('');
    }, []);

    const getColumnSearchPropsNomeUsuario = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearchNomeUsuario(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearchNomeUsuario(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleResetNomeUsuario(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) =>
                record[dataIndex]?.nome ? record[dataIndex].nome.toString().toLowerCase().includes(value.toLowerCase()) : '',
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumnNomeUsuario === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchTextNomeUsuario]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchTextNomeUsuario, searchedColumnNomeUsuario]);

    // fim do search da tabela de listagem


    // const rowSelection = {
    //     selectedRowKeys,f
    //     onChange: onSelectChange,
    //     columnWidth: '1%'
    // };

    const clickBotaoViewDadosRespostaModal = useCallback((dados): void => {

        let objView = {
            ...dados,
            data_resposta: dados?.data_resposta ? moment(dados?.data_resposta).format('DD/MM/YYYY HH:mm') : '-',
        }

        setModalViewDadosRespostas(true);

        setDadosViewDadosRespostaInModal(objView)

    }, []);

    const columns = [
        {
            title: 'Usuário',
            dataIndex: 'usuario',
            ...getColumnSearchProps('usuario'),
            width: '5%',
            showSorterTooltip: false,
            // render: (usuario: any) => (
            //     usuario.nome
            // ),
        },
        {
            title: 'Contratos',
            dataIndex: 'contratos',
            ...getColumnSearchProps('contratos'),
            width: '3%',
            render: (contratos: any) => (
                <span>
                    {
                        contratos?.length > 0 ?
                            contratos.map((element: string, index: number) => (
                                // element+' '
                                <span key={'span' + index}>
                                    <Tag color={''} key={index}>
                                        {element}
                                    </Tag>
                                </span>
                            ))
                            :
                            <></>
                    }
                </span>
            ),
        },
        {
            title: 'Pergunta',
            dataIndex: 'pergunta',
            ...getColumnSearchProps('pergunta'),
            width: '5%',
            showSorterTooltip: false,
        },
        {
            title: 'Resposta',
            dataIndex: 'resposta',
            ...getColumnSearchProps('resposta'),
            width: '5%',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => {
                let aa = ''
                let bb = ''
                if (a?.resposta) {
                    aa = a.resposta
                }
                if (b?.resposta) {
                    bb = b.resposta
                }
                return aa.toString().trim().length - bb?.toString().trim().length
            },
            render(resposta: any, record: any) {
                if (record.tipo === 'star') {
                    if (resposta) {

                        let percorrer: Array<number> = []
                        for (let index = 0; index < resposta; index++) {
                            // const element = array[index];

                            percorrer.push(index)
                        }

                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >

                                {
                                    percorrer.map((elem, index) => (
                                        <Text key={index} style={{ fontSize: 25, color: '#FFC107' }} >{'★'}</Text>
                                    ))
                                }
                            </div>
                        )
                    }
                    return (
                        resposta
                    )
                } else {
                    return (
                        <Text>{resposta?.length > 25 ? resposta.substring(0, 25) + '...' : resposta}</Text>
                    )
                }
            }
        },
        // {
        //     title: 'Tipo',
        //     dataIndex: 'tipo',
        //     ...getColumnSearchPropsNomeUsuario('tipo'),
        //     width: '5%',
        //     showSorterTooltip: false,
        //     // render: (usuario: any) => (
        //     //     usuario.nome
        //     // ),
        // },
        {
            title: 'Data Resposta',
            dataIndex: 'data_resposta',
            ...useGetColumnSearchPropsData('data_resposta'),
            width: '3%',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.data_resposta) {
                    aMonMen = moment(a.data_resposta).unix()
                } else {
                    aMonMen = 0
                }
                if (b.data_resposta) {
                    bMonMen = moment(b.data_resposta).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            render: (data: any) => (
                moment(data).format('DD/MM/YYYY HH:mm')
            ),
        },
        {
            title: 'Ações',
            dataIndex: 'resposta',
            // ...getColumnSearchProps('resposta'),
            width: '2%',
            showSorterTooltip: false,
            render(resposta: any, record: any) {

                if (record.tipo === 'star') {
                    return (
                        <></>
                    )
                } else {
                    return (
                        <Tooltip title="Visualizar" color="blue" key="visualizarblue">
                            <Button
                                type="primary"
                                // style={{ marginLeft: 5 }}
                                onClick={() => clickBotaoViewDadosRespostaModal(record)}
                            >
                                <SearchOutlined />
                            </Button>
                        </Tooltip>
                    )
                }
            }
        },
    ];

    const onChangeSelectPesquisas = useCallback((value: any, obj: any): void => {

        setValueInputPesquisa(value)
        buscaDadosSetTable(value)

    }, []);

    const onClickCleanProxContTable = useCallback((): void => {

        const buscaDeDados = async () => {

            try {

                let objTableNullProx: Array<interfaceListRespostasPesquisa> = [];
                let objTable: Array<interfaceListRespostasPesquisa> = [];

                if (refDataTableNullProxCont.current.length === 0) {
                    dadosTabelaState.forEach((element, index) => {
                        if (
                            element.resposta === undefined ||
                            element.resposta === null ||
                            element.resposta === ''
                        ) {

                            objTableNullProx.push(element)

                        } else {
                            objTable.push(element)
                        }
                    })

                    refDataTableNullProxCont.current = objTableNullProx
                    setDadosTabelaState(objTable)


                } else {
                    refDataTableNullProxCont.current = []
                    setDadosTabelaState(refCloneDataTable.current)

                }

            } catch (error) {
                let msgErro: any = (error as Error);

            }


        }

        buscaDeDados();

    }, [dadosTabelaState]);

    return (
        <>
            {/* <div
                style={{
                    position: 'fixed',
                    zIndex: 2,
                    // background: propsState.theme === "light" ? '#fff' : '#141414',
                    //
                    // width: '80%',
                    paddingTop: 24,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24
                }}
            >
                <Row style={{ marginBottom: 16 }}>
                    <Col>
                        <Button type="primary" className="botaoAdicionarProcessoJuridico" disabled={!hasSelectedAdd} onClick={navigateAdicionar} >
                            Adicionar
                        </Button>
                        <Button type="primary" className="botaoEditarProcessoJuridico"
                            onClick={clickBotaoEditar}
                            disabled={!hasSelectedEdit} >
                            Editar
                        </Button>
                    </Col>
                </Row>
            </div> */}
            <Row style={{
                display: 'flex',
                alignItems: 'flex-end'
            }}>
                <Col {...colSelect} style={{ marginBottom: 5, marginRight: 5 }}>
                    <div
                        style={{ marginBottom: 5 }}
                    >
                        <Text style={{ margin: 1 }} >{'Selecione uma pesquisa:'}</Text>
                    </div>
                    <Select
                        showSearch
                        placeholder="Digite o nome de uma pesquisa"
                        optionFilterProp="children"
                        // className="botaoListVendedorUser"
                        onChange={onChangeSelectPesquisas}
                        loading={loadingListPesquisas}
                        value={valueInputPesquisa}
                        notFoundContent={loadingListPesquisas ? <Spin size="small" /> : null}
                        // disabled={!hasSelectedAdd}
                        // defaultValue='Usuários'
                        style={{
                            width: '100%',
                        }}
                        filterOption={(input: any, option: any) => {
                            let textDigit = removeAcento(input)
                            let listCidade = removeAcento(option?.children);
                            return listCidade.indexOf(textDigit) >= 0
                        }}
                    >
                        {
                            dataListPesquisas.map((item) => {
                                return (
                                    <Select.Option
                                        value={item.id}
                                        key={item.id}
                                    >
                                        {item.nome}
                                    </Select.Option>
                                )
                            })
                        }
                    </Select>
                </Col>
            </Row>
            {/* <Row
                style={{
                    marginTop: 30
                }}
            >
                <div>

                </div>
            </Row> */}
            <Table
                className='marginTopTables'
                rowKey={(record) => record.id.toString()}
                loading={loadingTable}
                // onRow={(record, rowIndex) => {
                //     return {
                //         onClick: event => { onclickTable(record, rowIndex) }, // click row
                //         onDoubleClick: event => { }, // double click row
                //         onContextMenu: event => { }, // right button click row
                //         onMouseEnter: event => { }, // mouse enter row
                //         onMouseLeave: event => { }, // mouse leave row
                //     };
                // }}
                // rowSelection={rowSelection}
                columns={columns} dataSource={dadosTabelaState}
                scroll={{ x: 1000 }}
                size="small"
                onChange={
                    (pagination, filters, sorter, extra) => {
                        refContagemDoFiltro.current = extra.currentDataSource
                    }
                }
                title={(registros) => {
                    return (
                        <>
                            <div
                                style={{
                                    backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                    padding: 10,
                                    margin: -8,
                                    marginBottom: 0.5,

                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >

                                <Text>
                                    {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                        dadosTabelaState.length :
                                        refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                                </Text>
                                <div
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <Tooltip title="Atualizar" color="green" key="atualizarTabela">
                                        <Button
                                            type="primary"
                                            className='pesquisaDeSatisfacao_Respostas_botaoDeAdd_lchjvrewqy'
                                            style={{
                                                marginRight: 5,
                                                paddingLeft: 5,
                                                paddingRight: 5
                                                // marginBottom: 5,
                                            }}
                                            onClick={() => {
                                                if (valueInputPesquisa) buscaDadosSetTable(valueInputPesquisa)
                                            }}
                                        >
                                            <RedoOutlined
                                                style={{
                                                    fontSize: 20
                                                }}
                                            />
                                        </Button>
                                    </Tooltip>
                                    {
                                        refDataTableNullProxCont.current.length === 0 ?
                                            <Tooltip title="Remover brancos" color="blue" key="LimparBlueAtendimento">
                                                <Button
                                                    type="primary"
                                                    // className="botaoAdicionarProcessoJuridico"
                                                    // disabled={!hasSelectedAdd}
                                                    style={{
                                                        fontSize: 20,
                                                        paddingTop: 0,
                                                        paddingBottom: 5,
                                                        paddingLeft: 4,
                                                        paddingRight: 4,
                                                    }}
                                                    onClick={onClickCleanProxContTable}
                                                >
                                                    <IconBroom />
                                                </Button>
                                            </Tooltip>
                                            :
                                            <Tooltip title="Add brancos" color="blue" key="AddBlueAtendimento">
                                                <Button
                                                    type="primary"
                                                    // className="botaoAdicionarProcessoJuridico"
                                                    // disabled={!hasSelectedAdd}
                                                    style={{
                                                        fontSize: 20,
                                                        paddingTop: 0,
                                                        paddingBottom: 5,
                                                        paddingLeft: 4,
                                                        paddingRight: 4,
                                                    }}
                                                    onClick={onClickCleanProxContTable}
                                                >
                                                    <IconBroom />
                                                </Button>
                                            </Tooltip>
                                    }
                                </div>
                            </div>
                        </>
                    )
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: 'end'
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                pagination={{
                    locale: { items_per_page: '' },
                    showSizeChanger: true,
                    defaultPageSize: 100,
                    // showTotal: (total, range) => {
                    //     return (`Mostrando: ${range[0]} de ${range[1]} num total de ${total} itens`)
                    // },
                    position: ['topRight', 'bottomRight'],
                    pageSizeOptions: ['10', '20', '50', '100']
                }}
                locale={locale.Table}
            />
            <Modal
                title="Dados Resposta"
                visible={modalViewDadosRespostas}
                className="classmodalDadosContatoInatendimento"
                onOk={() => setModalViewDadosRespostas(false)}
                onCancel={() => setModalViewDadosRespostas(false)}
                footer={[
                    <Button key="back" onClick={() => setModalViewDadosRespostas(false)}>
                        Fechar
                    </Button>,
                ]}
            >
                <Row>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Text
                            strong={false}
                        >
                            <Text strong style={{ marginRight: 5 }}>{'Usuário:'}</Text>
                            {dadosViewDadosRespostaInModal?.usuario}
                        </Text>
                    </Col>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Row>
                            <Text strong style={{ marginRight: 5 }}>{'Contratos:'}</Text>
                            <span>
                                {
                                    dadosViewDadosRespostaInModal?.contratos?.length > 0 ?
                                        dadosViewDadosRespostaInModal?.contratos.map((element: string, index: number) => (
                                            // element+' '
                                            <span key={'span' + index}>
                                                <Tag color={''} key={index}>
                                                    {element}
                                                </Tag>
                                            </span>
                                        ))
                                        :
                                        <></>
                                }
                            </span>
                        </Row>
                    </Col>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Text>
                            <Text strong style={{ marginRight: 5 }}>Pergunta: </Text>
                            {dadosViewDadosRespostaInModal?.pergunta}
                        </Text>
                    </Col>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Row>
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <Text strong>Resposta: </Text>
                                    <Text strong={false} style={{ marginLeft: 5 }}>{dadosViewDadosRespostaInModal?.data_resposta}</Text>
                                </div>
                                <Text>
                                    {dadosViewDadosRespostaInModal?.resposta}
                                </Text>
                            </div>
                        </Row>
                    </Col>
                    {/* <Col span={24} style={{ marginBottom: 5 }}>
                        <Row>
                            <Text strong>Data Resposta: </Text>
                            <Text style={{ marginLeft: 5 }}>
                                {dadosViewDadosRespostaInModal?.data_resposta}
                            </Text>
                        </Row>
                    </Col> */}
                </Row>
            </Modal>
        </>
    );
}

export default ListRespostas;
