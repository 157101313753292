export const cardLayout = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 23, offset: 0 }, // > 992
    xl: { span: 20, offset: 0 }, // > 1200
    xxl: { span: 20, offset: 0 }, // > 1600
};
export const inputSelectTipo = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 11, offset: 0 }, //>576
    md: { span: 11, offset: 0 }, // >768
    lg: { span: 11, offset: 0 }, // > 992
    xl: { span: 6, offset: 0 }, // > 1200
    xxl: { span: 6, offset: 0 }, // > 1600
};

export const inputSelectUsuario = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 11, offset: 1 }, //>576
    md: { span: 11, offset: 1 }, // >768
    lg: { span: 11, offset: 1 }, // > 992
    xl: { span: 6, offset: 1 }, // > 1200
    xxl: { span: 6, offset: 1 }, // > 1600
};

export const inputSelecioneUsuario = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 11, offset: 0 }, //>576
    md: { span: 11, offset: 0 }, // >768
    lg: { span: 11, offset: 0 }, // > 992
    xl: { span: 6, offset: 1 }, // > 1200
    xxl: { span: 6, offset: 1 }, // > 1600
};

export const inputTitulo = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 11, offset: 0 }, //>576
    md: { span: 11, offset: 0 }, // >768
    lg: { span: 11, offset: 0 }, // > 992
    xl: { span: 6, offset: 0 }, // > 1200
    xxl: { span: 6, offset: 0 }, // > 1600

};

export const inputMensagem = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 23, offset: 0 }, //>576
    md: { span: 23, offset: 0 }, // >768
    lg: { span: 23, offset: 0 }, // > 992
    xl: { span: 23, offset: 0 }, // > 1200
    xxl: { span: 23, offset: 0 }, // > 1600

};

export const inputPath = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 11, offset: 1 }, //>576
    md: { span: 11, offset: 1 }, // >768
    lg: { span: 11, offset: 1 }, // > 992
    xl: { span: 6, offset: 1 }, // > 1200
    xxl: { span: 6, offset: 1 }, // > 1600

};
export const inputFuncao = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 11, offset: 1 }, //>576
    md: { span: 11, offset: 1 }, // >768
    lg: { span: 11, offset: 1 }, // > 992
    xl: { span: 6, offset: 1 }, // > 1200
    xxl: { span: 6, offset: 1 }, // > 1600

};
export const inputAddImagem = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 23, offset: 0 }, //>576
    md: { span: 23, offset: 0 }, // >768
    lg: { span: 23, offset: 0 }, // > 992
    xl: { span: 23, offset: 0 }, // > 1200
    xxl: { span: 23, offset: 0 }, // > 1600

};
export const inputNome = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 11, offset: 0 }, //>576
    md: { span: 11, offset: 0 }, // >768
    lg: { span: 11, offset: 0 }, // > 992
    xl: { span: 6, offset: 0 }, // > 1200
    xxl: { span: 6, offset: 0 }, // > 1600

};


export const inputSelectCidades = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 11, offset: 1 }, //>576
    md: { span: 11, offset: 1 }, // >768
    lg: { span: 11, offset: 1 }, // > 992
    xl: { span: 6, offset: 1 }, // > 1200
    xxl: { span: 6, offset: 1 }, // > 1600

};




export const botaoSalvar = {
    xs: { span: 16, offset: 7 }, //<576
    sm: { span: 8, offset: 15 }, //>576
    md: { span: 10, offset: 13 }, // >768
    lg: { span: 7, offset: 17 }, // > 992
    xl: { span: 5, offset: 19 }, // > 1200
    xxl: { span: 3, offset: 21 }, // > 1600
};
