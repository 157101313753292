import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  body {

    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .ant-layout-footer {
      padding: 15px 50px !important;
  }

  /*configurações do dashboard*/

  .site-layout-background {
    background: ${({ theme }: any) => theme.body};
    margin: 10px;
    margin-top: 70px;
    padding: 24px;
    min-height: 160px;
  }

`;
