import React, { useRef, useCallback, useState, useEffect } from 'react';
import {
    Col, Button, Row, Form, Select, Divider, notification, Modal, Popconfirm, Spin
} from 'antd';
import {
    selectedContratoUserAdd,
    modaisDeConfig, botaoRemoverSelected
} from './GridStyle';
import { DeleteOutlined } from '@ant-design/icons';
// import { useSelector } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import * as UsuariosActions from '../../../../store/modules/usuario/actions';
import { UserState } from '../../../../interfaces/UserAdd';
import FormCriarContrato from './../../../../components/FormCriarContrato';
import {
    urlContratoAtivos, urlContrato,
    urlModulosContrato, urlContratosUsuarioControlador,
    urlUsers
} from '../../../../services/request/urls';
import axios from 'axios';
import { getToken } from './../../../../services/token';
// import { stateGlobal } from '../../../../interfaces/Global';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { convertMoedaBRParaUS, convertDataBrParaUS } from './../../../../services/Conversores';
import { removeAcento } from '../../../../utils/RemoveAcentos';

interface dadosDaTabelaAssoc {
    id: string;
    nome: string;
    estado: string;
    isAssoc: boolean;
};

interface tipoCorpoContrato {
    id: number,
    uuid: string,
    nome: string,
    status: boolean,
    envio_proposta: string,
    vigencia: string,
    valor_contratado: string,
    cadastro_processos: string,
    observacao: any | null,
    created_at: string,
    updated_at: string,
    modalidade: {
        id: number,
        nome: string
    },
    esfera: {
        id: number,
        nome: string
    },
    poder: {
        id: number,
        nome: string
    },
    situacao_contrato: {
        id: number,
        nome: string
    },
    tipo_contrato: {
        id: number,
        nome: string
    },
    eUsuario: boolean | null | undefined
};

interface dadosDaTabela {
    id: number;
    nome: string;
    estado: string;
    isAssoc?: boolean;
};

interface dadosDaTabelaCityMicro {
    id: number;
    nome: string;
    microregiao: Array<any>;
    isAssoc?: boolean;
};

const AddContratoInUser: React.FC = () => {

    const formRef = useRef<any>(null);
    const formRefModal = useRef<any>(null);
    const refDadosTotaiContrato = useRef<any>(null);
    const refDadosTotaiContratoUsuario = useRef<any>(null);
    const refButonAddOrgao = useRef<any>(null);

    const dispatch = useDispatch();

    const propsState = useSelector((state: UserState) => state.usuario);

    const [loadingFormAdd, setLoadingFormAdd] = useState(false);
    const [loadingListContrato, setLoadingListContrato] = useState(false);
    const [dataListContrato, setDataListContrato] = useState<any | Array<tipoCorpoContrato>>([]);
    const [dataListModalContrato, setDataListModalContrato] = useState<any>([]);

    const [isEditandoUser, setIsEditandoUser] = useState(false);
    const [modalAddListContrato, setModalAddListContrato] = useState(false);
    const [contratoEstaSalvo, setContratoEstaSalvo] = useState(false);
    const [naoTemContratoVinculado, setnaoTemContratoVinculado] = useState(false);

    const [disableCampoListContrato, setDisableCampoListContrato] = useState(false);

    const [propsDataTableState, setPropsDataTableState] = useState<Array<dadosDaTabela | dadosDaTabelaAssoc | dadosDaTabelaCityMicro>>([]);

    const buscaContratoPorID = useCallback((id): tipoCorpoContrato | null => {
        let contrato = null;
        refDadosTotaiContrato.current.forEach((val: any) => {
            if (val.id == id) {
                contrato = val
            }
        });
        return contrato;

    }, []);

    const buscaDadosContratosAndSetSelected = useCallback((isEditando: boolean): void => {

        if (isEditando) {
            setLoadingListContrato(true);
        }
        const buscaDeDadosContratos = async () => {
            try {
                let result = await axios.get(urlContratoAtivos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                if (isEditando) {
                    setDataListContrato(result.data);
                }

                refDadosTotaiContrato.current = result.data;
                setDataListModalContrato(result.data);
                setLoadingListContrato(false);

            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingListContrato(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosContratos();

    }, []);

    const buscaDadosContratoDoUsuario = useCallback((idUsuario: number, idContratolocalStorage?: number | null | undefined): void => {

        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        setLoadingListContrato(true);
        const buscaDeDadosContratos = async () => {
            try {

                let result: any = []

                let dadosContarto: any = [];
                let isContratoSelecionado: boolean = false
                //se existir dados no local storage nao precisa fazer a requisição
                if (localUserData?.dadosTotaisContratosDoUsuario) {

                    dadosContarto = localUserData?.dadosTotaisContratosDoUsuario

                    //para poder setar true na lixeira de excluir o item
                    dadosContarto.forEach((element: any) => {

                        if (localUserData?.step2?.contrato == element.id) {

                            if (!element?.eUsuario) {
                                isContratoSelecionado = true
                            }
                        }
                    });

                } else {
                    result = await axios.get(urlUsers + "/" + idUsuario + "/contratos",
                        {
                            headers: { 'Authorization': 'Bearer ' + getToken() }
                        });

                    result.data.forEach((element: any) => {
                        element.eUsuario = true

                        dadosContarto.push(element);
                    });

                }

                if (isContratoSelecionado) {
                    setContratoEstaSalvo(true);
                    setDisableCampoListContrato(true);
                }

                if (dadosContarto.length === 0) {
                    setnaoTemContratoVinculado(true);
                    //caso nao tenha contrato vinculado ao usuario setar contratos nao selecionados
                    buscaDadosContratosAndSetSelected(true);
                } else {

                    setDataListContrato(dadosContarto);
                    refDadosTotaiContratoUsuario.current = dadosContarto;

                    //buscar os dados e setar em dados contrato
                    buscaDadosContratosAndSetSelected(false);

                    //caso ele tenha contrato vinculado e for apenas um e não tiver selecionado ainda
                    //setar automaticamente no campo
                    if (dadosContarto.length === 1) {
                        if (typeof idContratolocalStorage !== 'number' && typeof idContratolocalStorage !== 'string') {
                            setTimeout(() => {
                                formRef.current.setFieldsValue(
                                    {
                                        contrato: dadosContarto[0].id,
                                    }
                                );
                            }, 50);
                        }
                    }

                }

                setLoadingListContrato(false);

            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingListContrato(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosContratos();

    }, []);

    const comonentDidMount = useEffect((): void => {
        dispatch(UsuariosActions.formularioRefUsers(formRef));

        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }
        if (localUserData?.step2) {
            setTimeout(() => {
                formRef.current.setFieldsValue(
                    {
                        contrato: localUserData?.step2.contrato ? localUserData?.step2.contrato : undefined,
                    }
                );
            }, 50);
        }

        //verificar se esta editando a pagina
        if (localUserData?.isEdit === true) {
            setIsEditandoUser(true);

            if (localUserData?.step2 && localUserData.step2?.contrato) {
                buscaDadosContratoDoUsuario(localUserData.idUser, localUserData.step2.contrato);
            } else {
                buscaDadosContratoDoUsuario(localUserData.idUser);
            }
        } else {
            buscaDadosContratosAndSetSelected(true);
        }
    }, []);

    const addContratoClickBotton = useCallback((): void => {
        refButonAddOrgao.current?.blur();
        formRef.current.setFieldsValue({ contrato: undefined });

        dispatch(UsuariosActions.setBotaoAddContrato(true));

        setContratoEstaSalvo(false);

    }, [propsState.botaoAddContrato]);

    const addListContrato = useCallback((): void => {

        let dadosTodosContrato = [...refDadosTotaiContrato?.current]

        refDadosTotaiContratoUsuario.current.forEach((elementoContratoUser: any) => {

            dadosTodosContrato = dadosTodosContrato.filter((val) => {
                return val.id != elementoContratoUser.id
            })

        });

        setDataListModalContrato(dadosTodosContrato);

        setModalAddListContrato(true);
    }, []);

    const clickCancelModalAddListContrato = useCallback((): void => {
        setModalAddListContrato(false);
    }, []);

    const requestVincularUser = useCallback((objSalvarStatus): void => {

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        const buscaDeDados = async () => {

            setLoadingFormAdd(true)
            setLoadingListContrato(true);

            try {
                // [{"usuarioId":56,"contratoId":28,"ativo":true}]
                let resultUsuarios = await axios.patch(urlContratosUsuarioControlador, objSalvarStatus, {
                    headers: { 'Authorization': 'Bearer ' + getToken() }
                });

                let dadosContarto: any = [];

                let result = await axios.get(urlUsers + "/" + objSalvarStatus[0]?.usuarioId + "/contratos",
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                result.data.forEach((element: any) => {
                    element.eUsuario = true

                    dadosContarto.push(element);
                });


                setDataListContrato(dadosContarto);
                refDadosTotaiContratoUsuario.current = dadosContarto;

                //setando dados no local storage
                localUserData = {
                    ...localUserData,
                    "step2": {
                        "contrato": objSalvarStatus[0]?.contratoId
                    },
                    "dadosTotaisContratosDoUsuario": dadosContarto
                }

                if (localUserData?.step3) {
                    localUserData.step3 = undefined;
                }
                if (localUserData?.step4) {
                    localUserData.step4 = undefined;
                }

                localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));


                formRef.current.setFieldsValue({
                    contrato: objSalvarStatus[0]?.contratoId
                });

                formRefModal.current.setFieldsValue({
                    contratos: undefined
                });

                setContratoEstaSalvo(false);
                clickCancelModalAddListContrato();
                setLoadingFormAdd(false)
                setLoadingListContrato(false);

                notification.success({
                    message: 'Sucesso',
                    description: `Usuário vinculado com sucesso!`
                });

            } catch (error) {
                setLoadingFormAdd(false)
                setLoadingListContrato(false);
                notification.error({
                    message: 'Erro',
                    description: `Não foi possivel vincular, entre em contato com suporte!`
                });
            }
        }

        buscaDeDados();
    }, []);

    const clickSubmitModalAddListContrato = useCallback((): void => {

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        const verificaContratoIsAdd = async (dadosContrato: any, values: any) => {
            try {

                let result = await axios.get(urlModulosContrato + '/' + localUserData?.idUser + '/' + dadosContrato?.id,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                if (result?.data?.msg == 'Usuário pode ser vinculado e este contrato') {

                    let copyDataListContrato: any = [...dataListContrato];

                    copyDataListContrato.push(dadosContrato);
                    refDadosTotaiContratoUsuario.current = copyDataListContrato;

                    setDataListContrato(copyDataListContrato);

                    //setando dados no local storage
                    localUserData = {
                        ...localUserData,
                        "step2": {
                            "contrato": values.contratos
                        },
                        "dadosTotaisContratosDoUsuario": copyDataListContrato
                    }

                    if (localUserData?.step3) {
                        localUserData.step3 = undefined;
                    }
                    if (localUserData?.step4) {
                        localUserData.step4 = undefined;
                    }

                    localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));

                    clickCancelModalAddListContrato();

                    formRef.current.setFieldsValue({
                        contrato: values.contratos
                    });

                    formRefModal.current.setFieldsValue({
                        contratos: undefined
                    });

                    setContratoEstaSalvo(true);
                    setDisableCampoListContrato(true);
                }


            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {

                    if (msgErro?.response?.data?.message == 'Usuario já esta relacionado ao contrato') {
                        // notification.warning({
                        //     message: 'Atenção',
                        //     description:
                        //         'Usuário já esta relacionado a esse contrato, va para tela de contrato e vincule o usuario novamente!',
                        // });

                        let objVincularUser = [{ "usuarioId": localUserData?.idUser, "contratoId": dadosContrato?.id, "ativo": true }]

                        Modal.confirm({
                            className: 'modalContratoAtivoInativo',
                            title: 'Atenção',
                            icon: <ExclamationCircleOutlined />,
                            content: 'Usuário já esta relacionado a esse contrato, deseja vincular o usuário novamente!',
                            okText: 'Ok',
                            // okType: 'danger',
                            cancelText: 'Cancelar',
                            onOk() {
                                requestVincularUser(objVincularUser);
                            },
                            onCancel() {
                            },
                        });
                    } else {
                        notification.error({
                            message: 'Erro',
                            description:
                                'Não foi possivel buscar os dados!',
                        });
                    }
                    setLoadingListContrato(false);

                }
            }
        }

        formRefModal.current?.validateFields()
            .then((values: any) => {
                // setLoadingFormAdd(true);

                let dadosContratoAdd = buscaContratoPorID(values.contratos);

                verificaContratoIsAdd(dadosContratoAdd, values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [dataListContrato]);

    const onClickDeleteArrayListagem = useCallback((): void => {

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        let valorCampoContrato: any = formRef.current.getFieldValue('contrato');

        let dadosContratoAdd = buscaContratoPorID(valorCampoContrato);

        let copyDataListContrato = [...dataListContrato];

        let filter = copyDataListContrato.filter((val: any) => {
            return val.id != dadosContratoAdd?.id
        });

        //setando dados no local storage
        localUserData = {
            ...localUserData,
            "dadosTotaisContratosDoUsuario": filter
        }

        localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));

        setDataListContrato(filter);
        refDadosTotaiContratoUsuario.current = filter;

        formRef.current.setFieldsValue({ contrato: undefined });
        formRef.current.validateFields(['contrato']);

        setDisableCampoListContrato(false);

    }, [dataListContrato]);

    const onFinish = useCallback((formValues): void => {

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        //valorda tabela no salvar
        const salvarDadosContrato = async (valores: any) => {
            dispatch(UsuariosActions.setSpinnerStep(true));
            try {

                let objIdVendedores: any = [];

                if (valores.vendedores && valores.vendedores.length != 0) {
                    valores.vendedores.forEach((val: any, i: any) => {
                        objIdVendedores.push({ id: val })
                    });

                    valores.vendedores = objIdVendedores
                }

                let objIDEstados: any = [];

                if (valores.estados && valores.estados.length != 0) {
                    valores.estados.forEach((val: any, i: any) => {
                        objIDEstados.push({ id: val })
                    });

                    valores.estados = objIDEstados
                }

                //PARA PODER FAZER A REQUISIÇAO APENAS COM ID
                let cityEditID: any = []
                valores.cidades.forEach((vende: any) => {
                    cityEditID.push({ id: vende.id })
                });

                valores.cidades = cityEditID

                let result = await axios.post(urlContrato, valores,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram criados com sucesso!',
                });
                dispatch(UsuariosActions.setSpinnerStep(false));


                if (result.data?.id) {
                    //debloquear o input setar o valor id contrato no input e fechar campos
                    formRef.current.setFieldsValue({ contrato: result.data?.id });
                    dispatch(UsuariosActions.setBotaoAddContrato(false));

                    //**ADICIONAR CONTRATO ADD NA LISTA
                    if (localUserData?.isEdit === true) {
                        //se estiver editando usuario ao clicar no salvar do criar contrato
                        buscaDadosContratosAndSetSelected(false);

                        let copyDataListContrato: any = [...dataListContrato];

                        copyDataListContrato.push(result.data);
                        refDadosTotaiContratoUsuario.current = copyDataListContrato;

                        setDataListContrato(copyDataListContrato);

                        //setando dados no local storage
                        localUserData = {
                            ...localUserData,
                            "step2": {
                                "contrato": result.data?.id
                            },
                            "dadosTotaisContratosDoUsuario": copyDataListContrato
                        }

                        if (localUserData?.step3) {
                            localUserData.step3 = undefined;
                        }
                        if (localUserData?.step4) {
                            localUserData.step4 = undefined;
                        }

                        localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));

                        clickCancelModalAddListContrato();

                        setContratoEstaSalvo(true);
                        setDisableCampoListContrato(true);

                    } else {
                        buscaDadosContratosAndSetSelected(true);
                    }
                }

            } catch (error) {
                let msgErro: any = (error as Error);
                dispatch(UsuariosActions.setSpinnerStep(false));
                notification.error({
                    message: 'Erro',
                    description:
                        msgErro?.response?.data?.message,
                });
            }
        }

        if (formRef.current.getFieldValue('estados') != undefined &&
            formRef.current.getFieldValue('estados').length != 0 ||
            propsDataTableState.length != 0) {
            //seta dados da tabela em obj da requisição
            formValues.cidades = propsDataTableState

            //converte dinheiro
            let valorContratado = convertMoedaBRParaUS(formValues.valor_contratado)
            formValues.valor_contratado = valorContratado ? valorContratado : null;

            //para formatar data em formato americano :D
            let envioPropsota = convertDataBrParaUS(formValues.envio_proposta);
            formValues.envio_proposta = envioPropsota ? envioPropsota : null;

            let vigencia = convertDataBrParaUS(formValues.vigencia);
            formValues.vigencia = vigencia ? vigencia : null;

            let validadeTeste = convertDataBrParaUS(formValues.validade_teste);
            formValues.validade_teste = validadeTeste ? validadeTeste : null;

            formValues.situacao_contrato = formValues?.situacao_contrato ? { id: formValues?.situacao_contrato } : null
            formValues.esfera = formValues?.esfera ? { id: formValues?.esfera } : null
            formValues.poder = formValues?.poder ? { id: formValues?.poder } : null
            formValues.tipo_contrato = formValues?.tipo_contrato ? { id: formValues?.tipo_contrato } : null
            formValues.modalidade = formValues?.modalidade ? { id: formValues?.modalidade } : null

            salvarDadosContrato(formValues);
        } else {
            notification.error({
                message: 'Erro',
                description:
                    'Selecione uma cidade ou modo estado!',
            });
        }

    }, [propsDataTableState, dataListContrato]);

    //funcao injetada no formulario para poder pegar o valor da tabela dos itens
    const funcSetValorTabela = useCallback((valor: Array<dadosDaTabela | dadosDaTabelaAssoc | dadosDaTabelaCityMicro>): void => {
        setPropsDataTableState(valor)
    }, []);

    //Ao digitar o campo salva no localStorage
    const onchangeSelectContrato = useCallback((): void => {

        //Para poder caso exista step 3 ele remova a selecao do modo_estado e cidades da tela permissao APP
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);

            if (localUserData?.step3) {
                localUserData.step3 = undefined;
            }
            if (localUserData?.step4) {
                localUserData.step4 = undefined;
            }

            localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));
        }
    }, []);

    const onSelectContrato = useCallback((valor, obj): void => {

        let elementoExiste: boolean = false;

        if (refDadosTotaiContratoUsuario.current != null) {
            refDadosTotaiContratoUsuario.current?.forEach((element: any) => {

                if (element.id == valor) {
                    if (element?.eUsuario) {
                        elementoExiste = true
                    } else {
                        elementoExiste = false
                    }
                }
            });

        } else {
            elementoExiste = true
        }

        if (elementoExiste) {
            setContratoEstaSalvo(false);
        } else {
            setContratoEstaSalvo(true);
        }

    }, []);

    //Ao digitar o campo salva no localStorage
    const onFieldsChange = useCallback((changedFields, allFields): void => {
        if (changedFields[0]?.name[0] == "contrato") {
            let obj: any = new Object;
            allFields.forEach((element: any) => {
                let name = element.name[0];
                let value = element.value;
                obj[name] = value;
            });
            let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
            let localUserData: any = null;
            if (typeof dadosLocalStorage == 'string') {
                localUserData = JSON.parse(dadosLocalStorage);
                localUserData = {
                    ...localUserData,
                    "step2": obj
                }
                localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));
            } else {
                localStorage.setItem('@GovFacil:userData', JSON.stringify({ "step2": obj }));
            }
        }
    }, []);

    return (
        <>
            {/* <Spin spinning={loadingFormAdd}> */}
            <Form
                ref={formRef}
                name="formNewUserStep2"
                layout="vertical"
                initialValues={{
                    remember: true,
                }}
                onFieldsChange={onFieldsChange}
                onFinish={onFinish}
                onFinishFailed={() => { }}
            >
                <div>
                    <Row>
                        <Col {...selectedContratoUserAdd}>
                            <Form.Item
                                name="contrato"
                                label="Selecione o Contrato"
                                rules={[
                                    {
                                        required: !propsState.botaoAddContrato,
                                        message: 'Por favor selecione o Contrato!',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Selecione..."
                                    optionFilterProp="children"
                                    onChange={onchangeSelectContrato}
                                    onSelect={onSelectContrato}
                                    disabled={propsState.botaoAddContrato || disableCampoListContrato}
                                    loading={loadingListContrato}
                                    notFoundContent={loadingListContrato ? <Spin size="small" /> : null}
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                    dropdownRender={menu => (
                                        <div>
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Button block onClick={addContratoClickBotton} ref={refButonAddOrgao} type="dashed">Criar Contrato</Button>
                                                {isEditandoUser && !naoTemContratoVinculado ?
                                                    <Button block onClick={addListContrato} type="dashed">Adicionar Contrato</Button>
                                                    : ""}
                                            </div>
                                            <Divider style={{ margin: '4px 0' }} />
                                            {menu}
                                        </div>
                                    )}
                                >
                                    {
                                        dataListContrato.map((item: any) => {
                                            return (
                                                <Select.Option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {
                                                        item?.poder?.nome ?
                                                            item.nome + ' - ' + item?.poder?.nome
                                                            :
                                                            item.nome
                                                    }
                                                </Select.Option>
                                            )
                                        })
                                    }
                                    {/* <Select.Option value="abatia">Abatia - PR</Select.Option>
                                        <Select.Option value="umuarama">Umuarama - PR</Select.Option> */}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...botaoRemoverSelected}>
                            {contratoEstaSalvo ?
                                <Popconfirm
                                    title="Deseja excluir?"
                                    onConfirm={onClickDeleteArrayListagem}
                                    onCancel={() => { }}
                                    okText="Sim"
                                    cancelText="Não"
                                >
                                    <Button style={{ marginTop: 30 }} type="primary" danger >
                                        <DeleteOutlined />
                                    </Button>
                                </Popconfirm>
                                : ""
                            }
                        </Col>
                    </Row>
                    {propsState.botaoAddContrato ?
                        <FormCriarContrato
                            formRef={formRef}
                            funcSetValorTabela={funcSetValorTabela}
                            setLoadingFormAdd={setLoadingFormAdd}
                        />
                        : ""}
                </div>
            </Form>
            {/* </Spin> */}
            <Modal
                title="Adicionar novo contrato"
                visible={modalAddListContrato}
                className="modalAddListContrato"
                onOk={clickSubmitModalAddListContrato}
                onCancel={clickCancelModalAddListContrato}
                footer={[
                    <Button key="back" disabled={loadingFormAdd} onClick={clickCancelModalAddListContrato}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loadingFormAdd} onClick={clickSubmitModalAddListContrato}>
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingFormAdd}>
                    <Form
                        ref={formRefModal}
                        name="formModalAddListContrato"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                        onFinishFailed={() => { }}
                    >
                        <Row>
                            <Col {...modaisDeConfig}>
                                <Form.Item
                                    name="contratos"
                                    label="Selecione o contrato"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor selecione o contrato!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Selecione..."
                                        optionFilterProp="children"
                                        loading={loadingListContrato}
                                        notFoundContent={loadingListContrato ? <Spin size="small" /> : null}
                                        filterOption={(input: any, option: any) => {
                                            let textDigit = removeAcento(input)
                                            let listCidade = removeAcento(option?.children);
                                            return listCidade.indexOf(textDigit) >= 0
                                        }}
                                    >
                                        {
                                            dataListModalContrato.map((item: any) => {
                                                return (
                                                    <Select.Option
                                                        value={item.id}
                                                        key={item.id}
                                                    >
                                                        {
                                                            item?.poder?.nome ?
                                                                item.nome + ' - ' + item?.poder?.nome
                                                                :
                                                                item.nome
                                                        }
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                        {/* <Select.Option value="abatia">Abatia - PR</Select.Option>
                                        <Select.Option value="umuarama">Umuarama - PR</Select.Option> */}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
}

export default AddContratoInUser;
