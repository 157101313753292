export const Layout1_1 = {
    xs: { span: 16, offset: 6 }, //<576
    sm: { span: 14, offset: 6 }, //>576
    md: { span: 16, offset: 6 }, // >768
    lg: { span: 16, offset: 6 }, // > 992
    xl: { span: 16, offset: 6 }, // > 1200
    xxl: { span: 16, offset: 6 }, // > 1600
};

export const receberViaEmailAdd = {
    xs: { span: 24, offset: 0 },
    sm: { span: 8, offset: 0 },
    md: { span: 8, offset: 0 },
    lg: { span: 8, offset: 0 },
    xl: { span: 5, offset: 0 },
    xxl: { span: 5, offset: 0 },
};

export const receberViaCelularAdd = {
    xs: { span: 24, offset: 0 },
    sm: { span: 7, offset: 1 },
    md: { span: 7, offset: 1 },
    lg: { span: 7, offset: 1 },
    xl: { span: 5, offset: 0 },
    xxl: { span: 5, offset: 0 },
};

export const receberViaAppAdd = {
    xs: { span: 24, offset: 0},//<576
    sm: { span: 7, offset: 1 },//>576
    md: { span: 7, offset: 1 },// >768
    lg: { span: 7, offset: 1 }, // > 992
    xl: { span: 4, offset: 0 },// > 1200
    xxl: { span: 6, offset: 0 },// > 1600
};

export const intervaloNotificacoesUserAddnew = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 8, offset: 0 },
    xxl: { span: 8, offset: 0 },
};
export const horarioNotificacaoAddnew = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 11, offset: 1 },
    xl: { span: 8, offset: 0 },
    xxl: { span: 8, offset: 0 },
};
export const cidadeDosContratoSelect = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 24, offset: 0 },
    xl: { span: 24, offset: 0 },
    xxl: { span: 24, offset: 0 },
};
export const espacoModulosEBotoes = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 9, offset: 0 }, // > 1200
    xxl: { span: 9, offset: 0 }, // > 1600
};
export const modulosNotificacaoArvoreTree = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 24, offset: 0 },
    xl: { span: 24, offset: 0 },
    xxl: { span: 24, offset: 0 },
};

export const horarioNotificacaoAddAntigoposition = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 9, offset: 1 },
    xxl: { span: 9, offset: 0 },
};
export const tabelaListagemCidadesSalvas = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 11, offset: 1 }, // > 992
    xl: { span: 14, offset: 1 }, // > 1200
    xxl: { span: 14, offset: 1 }, // > 1600
};
export const botaoCancelarModulos = {
    xs: { span: 17, offset: 7 }, //<576
    sm: { span: 9, offset: 15 }, //>576
    md: { span: 11, offset: 13 }, // >768
    lg: { span: 13, offset: 11 }, // > 992
    xl: { span: 13, offset: 11 }, // > 1200
    xxl: { span: 13, offset: 11 }, // > 1600
};
export const botaoSalvarModulos = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 11, offset: 1 }, // > 992
    xl: { span: 3, offset: 1 }, // > 1200
    xxl: { span: 11, offset: 1 }, // > 1600
};

