import React, { useCallback, useState, useRef, useEffect } from "react";
import {
    Breadcrumb,
    Divider,
    Row,
    Col,
    Typography,
    Form,
    Input,
    Spin,
    Button,
    notification,
    Upload,
    message,
    Tabs,
    Table,
    Tag,
    Modal,
} from "antd";
import { useHistory, useParams } from "react-router-dom";
import {
    cardLayout,
    inputMensagem,
    inputFuncao,
    inputNome,
    inputPerfilInstagram,
    inputLogo,
    preVisualizacao,
} from "./GridStyle";
import {
    SolutionOutlined,
    LoadingOutlined,
    PlusOutlined,
    InfoCircleOutlined,
    SearchOutlined,
    EditOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import axios from "axios";
import {
    urlContrato,
    urlEntidadeLogo,
    urlContratosEntidade,
    urlMembroEntidadeFoto,
    urlNoticiaEntidadeImagem,
    urlContratoUsuarios,
} from "../../../../services/request/urls";
import { getToken } from "../../../../services/token";

//EDITOR DE HTML ESSES IMPORTS
// https://jpuri.github.io/react-draft-wysiwyg/#/docs
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
    convertDataUSParaBR,
    convertDataUSParaBrasilEHora,
} from "../../../../services/Conversores";
import moment from "moment";
import { useSelector } from "react-redux";
import { stateGlobal } from "../../../../interfaces/Global";
import { BiTrash, BiUnlink } from "react-icons/bi";
import { RcFile } from "antd/lib/upload";
import {
    interfaceContratoEntidadeMembro,
    interfaceContratoEntidadeNoticia,
    interfaceModuloContrato,
} from "./Interfaces";
import { ModalImagem } from "./modals/ModalImagem";
import { ModalFormNoticia } from "./modals/ModalFormNoticia";
import { ModalFormMembro } from "./modals/ModalFormMembro";
import { ModalFormUsuario } from "./modals/ModalFormUsuario";
import { mask } from "../../../../utils/MascaraDeCampos";
import { FaGlobe, FaInstagram } from "react-icons/fa";

const { Title, Paragraph, Text } = Typography;

const CreateModulo: React.FC = () => {
    const { idParam }: any = useParams();
    const refForm = useRef<any>(null);
    const propsState = useSelector((state: stateGlobal) => state.global);
    const refIdContrato = useRef<any>(null);
    const refDataContrato = useRef<null | any>(null);
    const refContratoInterface = useRef<null | interfaceModuloContrato>(null);
    const refidUsuarioLogado = useRef<any>(null);
    const refImagemCarregadaLight = useRef<any>(null);
    const refImagemCarregadaDark = useRef<any>(null);

    const history = useHistory();

    const signal = axios.CancelToken.source();
    const [fotoMembro, setFotoMembro] = useState<any>(null);
    //Lista de estados antes da cidades
    const [fileList, setFileList] = useState<any>([]);
    const [loadingPage, setLoadingPage] = useState(false);
    const [isSalvarOrEditar, setIsSalvarOrEditar] = useState(false);
    const [editorState, setEditorState] = useState<any>(
        EditorState.createEmpty()
    );
    const [entidade, setEntidade] = useState();
    const [defaultEditorState, setdefaultEditorState] =
        useState<any>(undefined);
    const [loadingCampoUpload, setLoadingCampoUpload] =
        useState<boolean>(false);
    const [imageUrlLight, setImageUrlLight] = useState<any>(null);
    const [imageUrlDark, setImageUrlDark] = useState<any>(null);
    const refContagemDoFiltroNoticias = React.useRef<Array<any>>([]);
    const refContagemDoFiltroMembros = React.useRef<Array<any>>([]);
    const refContagemDoFiltroUsuarios = React.useRef<Array<any>>([]);
    const [usuarios, setUsuarios] = useState<any>([]);
    const [usuariosVinculados, setUsuariosVinculados] = useState<any>([]);
    const [isModalNoticiaOpen, setIsModalNoticiaOpen] = useState(false);
    const [isModalMembroOpen, setIsModalMembroOpen] = useState(false);
    const [isModalUsuarioOpen, setIsModalUsuarioOpen] = useState(false);
    const [isModalImagemOpen, setIsModalImagemOpen] = useState(false);
    const [isEditarNoticia, setIsEditarNoticia] = useState(false);
    const [isEditarMembro, setIsEditarMembro] = useState(false);
    const [isEditarUsuario, setIsEditarUsuario] = useState(false);
    const [imagemModal, setImagemModal] = useState<any>(null);
    const [noticiaEditar, setNoticiaEditar] =
        useState<interfaceContratoEntidadeNoticia | null>(null);
    const [membroEditar, setMembroEditar] = useState<any | null>(null);
    const refFormNoticia = useRef<any>(null);
    const refFormMembro = useRef<any>(null);
    const refFormUsuario = useRef<any>(null);
    const [noticiaDescricao, setNoticiaDescricao] = useState<any>(null);
    const [ordensMembros, setOrdensMembros] = useState<any>([]);
    const [usuario, setUsuario] = useState<any>();
    const [loadingSaveNoticia, setLoadingSaveNoticia] = useState(false);
    const [loadingSaveMembro, setLoadingSaveMembro] = useState(false);
    const showModalNoticia = () => {
        formNoticia.setFieldsValue({
            contratoEntidadeId: entidade,
        });
        setIsModalNoticiaOpen(true);
    };

    const showModalUsuario = () => {
        formUsuario.setFieldsValue({
            contratoEntidadeId: entidade,
        });
        setIsModalUsuarioOpen(true);
    };

    const showModalMembro = async () => {
        let membroOrdens = membros.map((membro) => {
            //retorna ordem e id
            return { ordem: membro.ordem, id: membro.id };
        });

        setOrdensMembros(membroOrdens);
        formMembro.setFieldsValue({
            membroOrdens: membroOrdens,
        });

        setIsModalMembroOpen(true);
    };

    const handleNoticiaCancel = () => {
        setFileList([]);
        setIsModalNoticiaOpen(false);
    };

    const handleMembroCancel = () => {
        setIsModalMembroOpen(false);
    };

    const handleUsuarioCancel = () => {
        setIsModalUsuarioOpen(false);
    };

    const handleImagemCancel = () => {
        setIsModalImagemOpen(false);
    };

    const [noticias, setNoticias] = useState<
        interfaceContratoEntidadeNoticia[]
    >([]);

    const [membros, setMembros] = useState<interfaceContratoEntidadeMembro[]>(
        []
    );

    const handleEditorContentChange = (updatedHtml: any) => {
        formNoticia.setFieldsValue({
            descricaoNoticia: updatedHtml,
        });
    };

    const downloadImage = (url: string) => {
        // Faz uma solicitação para buscar os dados da imagem
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                // Cria um elemento 'a' para iniciar o download
                const element = document.createElement("a");
                element.href = URL.createObjectURL(blob);
                element.download = "logo.png";
                element.click();
            })
            .catch((error) => {
                window.open(url);
            });
    };

    const removerImagemNoticia = (file: any, index: number) => {
        Modal.confirm({
            title: "Remover imagem",
            icon: <InfoCircleOutlined />,
            content:
                "Deseja realmente remover a imagem? (Essa ação não pode ser desfeita)",
            okText: "Sim",
            cancelText: "Não",
            onOk() {
                if (!file.uid) {
                    let idNoticia =
                        refFormNoticia.current.getFieldValue("idNoticia");
                    axios
                        .delete(
                            urlContratosEntidade +
                            "/noticia/imagem/" +
                            idNoticia,
                            {
                                headers: {
                                    Authorization: "Bearer " + getToken(),
                                },
                                data: {
                                    imagem: file,
                                },
                            }
                        )
                        .then((result) => {
                            notification.success({
                                message: "Sucesso",
                                description: "Imagem excluida com sucesso!",
                            });
                            fileList.splice(index, 1);
                            setFileList(fileList);
                            getNoticias();
                        })
                        .catch((error) => {
                            notification.error({
                                message: "Erro",
                                description:
                                    error?.response?.data?.message ||
                                    "Não foi possivel excluir a imagem!",
                            });
                        });
                }
            },
            onCancel() { },
        });
    };

    //abrir navegar em add contrato
    const clickBotaoEditarContrato = useCallback((idContrato: number) => {
        const buscaDeDados = async () => {
            try {
                let resultContratos = await axios.get(
                    urlContrato + "/" + idContrato,
                    { headers: { Authorization: "Bearer " + getToken() } }
                );

                refDataContrato.current = resultContratos.data;
                refContratoInterface.current =
                    resultContratos.data?.contrato_entidade;

                return true;
            } catch (error) {
                let msgErro: any = error as Error;
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
                return false;
            }
        };

        return buscaDeDados();
    }, []);

    const [loadingTableNoticias, setLoadingTableNoticias] = useState(false);
    const [loadindTableMembros, setLoadindTableMembros] = useState(false);
    const [loadingTableUsuarios, setLoadingTableUsuarios] = useState(false);

    const getNoticias = useCallback(() => {
        setLoadingTableNoticias(true);
        const buscaDeDados = async () => {
            try {
                let resultNoticias = await axios.get(
                    urlContratosEntidade +
                    "/noticia/" +
                    refDataContrato.current?.contrato_entidade.id
                    + "/pagina/0"
                    ,
                    { headers: { Authorization: "Bearer " + getToken() } }
                );
                resultNoticias.data.sort((a: any, b: any) => {
                    return (
                        moment(b.created_at).unix() -
                        moment(a.created_at).unix()
                    );
                });

                setNoticias(resultNoticias.data);
            } catch (error) {
                let msgErro: any = error as Error;
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
            }
        };
        setLoadingTableNoticias(false);
        return buscaDeDados();
    }, []);

    const getMembros = useCallback(() => {
        setLoadindTableMembros(true);
        const buscaDeDados = async () => {
            try {
                let resultMembros = await axios.get(
                    urlContratosEntidade +
                    "/membro/" +
                    refDataContrato.current?.contrato_entidade.id,
                    { headers: { Authorization: "Bearer " + getToken() } }
                );

                resultMembros.data.sort((a: any, b: any) => {
                    return (
                        moment(b.created_at).unix() -
                        moment(a.created_at).unix()
                    );
                });
                setMembros(resultMembros.data);
            } catch (error) {
                let msgErro: any = error as Error;
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
            }
        };
        setLoadindTableMembros(false);
        return buscaDeDados();
    }, []);

    const getUsuariosVinculados = useCallback(() => {
        setLoadingTableUsuarios(true);
        const buscaDeDados = async () => {
            try {
                let resultUsuarios = await axios.get(
                    urlContratosEntidade + "/usuario_entidade/entidade"
                    + "/" + refDataContrato.current?.contrato_entidade.id,
                    { headers: { Authorization: "Bearer " + getToken() } }
                );
                resultUsuarios.data.sort((a: any, b: any) => {
                    return (
                        moment(b.created_at).unix() -
                        moment(a.created_at).unix()
                    );
                });
                setUsuariosVinculados(resultUsuarios.data);
            } catch (error) {
                let msgErro: any = error as Error;
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
            }
        };
        setLoadingTableUsuarios(false);
        return buscaDeDados();
    }, []);

    const getUsuarios = useCallback(() => {
        setLoadingTableUsuarios(true);
        const buscaDeDados = async () => {
            try {
                let resultUsuarios = await axios.get(
                    urlContratoUsuarios
                    + "/" + refDataContrato.current?.id,
                    { headers: { Authorization: "Bearer " + getToken() } }
                );

                resultUsuarios.data.sort((a: any, b: any) => {
                    return (
                        moment(b.created_at).unix() -
                        moment(a.created_at).unix()
                    );
                });
                setUsuarios(resultUsuarios.data);
            } catch (error) {
                let msgErro: any = error as Error;
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
            }
        };
        setLoadingTableUsuarios(false);
        return buscaDeDados();
    }, []);

    const excluirNoticia = useCallback((id: number) => {
        try {
            //Confirmação de exclusão
            Modal.confirm({
                title: "Excluir notícia",
                icon: <InfoCircleOutlined />,
                content:
                    "Deseja realmente excluir a notícia? (Essa ação não pode ser desfeita)",
                okText: "Sim",
                cancelText: "Não",
                onOk() {
                    axios
                        .delete(urlContratosEntidade + "/noticia/" + id, {
                            headers: {
                                Authorization: "Bearer " + getToken(),
                            },
                        })
                        .then((result) => {
                            notification.success({
                                message: "Sucesso",
                                description: "Notícia excluida com sucesso!",
                            });
                            getNoticias();
                        })
                        .catch((error) => {
                            notification.error({
                                message: "Erro",
                                description:
                                    "Não foi possivel excluir a notícia!",
                            });
                        });
                },
                onCancel() { },
            });
        } catch (error) {
            let msgErro: any = error as Error;
            notification.error({
                message: "Erro",
                description: msgErro?.response?.data?.message
                    ? msgErro?.response?.data?.message
                    : "Entre em contato com o suporte!",
            });
        }
    }, []);

    const excluirMembro = useCallback((id: number) => {
        try {
            //Confirmação de exclusão
            Modal.confirm({
                title: "Excluir membro",
                icon: <InfoCircleOutlined />,
                content:
                    "Deseja realmente excluir o membro? (Essa ação não pode ser desfeita)",
                okText: "Sim",
                cancelText: "Não",
                onOk() {
                    axios
                        .delete(urlContratosEntidade + "/membro/" + id, {
                            headers: {
                                Authorization: "Bearer " + getToken(),
                            },
                        })
                        .then((result) => {
                            notification.success({
                                message: "Sucesso",
                                description: "Membro excluido com sucesso!",
                            });
                            getMembros();
                        })
                        .catch((error) => {
                            notification.error({
                                message: "Erro",
                                description:
                                    "Não foi possivel excluir o membro!",
                            });
                        });
                },
                onCancel() { },
            });
        } catch (error) {
            let msgErro: any = error as Error;
            notification.error({
                message: "Erro",
                description: msgErro?.response?.data?.message
                    ? msgErro?.response?.data?.message
                    : "Entre em contato com o suporte!",
            });
        }
    }, []);

    const excluirUsuario = useCallback((id: number) => {
        try {
            //Confirmação de exclusão
            Modal.confirm({
                title: "Desassociar usuário",
                icon: <InfoCircleOutlined />,
                content:
                    "Deseja realmente desassociar o usuário? (Essa ação não pode ser desfeita)",
                okText: "Sim",
                cancelText: "Não",
                onOk() {
                    axios
                        .delete(urlContratosEntidade + "/usuario_entidade/" + id, {
                            headers: {
                                Authorization: "Bearer " + getToken(),
                            },
                        })
                        .then((result) => {
                            notification.success({
                                message: "Sucesso",
                                description: "Usuário desassociado!",
                            });
                            getUsuariosVinculados();
                        })
                        .catch((error) => {
                            notification.error({
                                message: "Erro",
                                description:
                                    "Não foi possivel desassociar o usuário!",
                            });
                        });
                },
                onCancel() { },
            });
        } catch (error) {
            let msgErro: any = error as Error;
            notification.error({
                message: "Erro",
                description: msgErro?.response?.data?.message
                    ? msgErro?.response?.data?.message
                    : "Entre em contato com o suporte!",
            });
        }
    }, []);

    const getColumnSearchProps = useCallback(
        (dataIndex: any) => ({
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={(node) => { }}
                        placeholder={`Buscar ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                            );
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => {
                            confirm();
                        }}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={clearFilters}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered: any) => (
                <SearchOutlined
                    style={{ color: filtered ? "#1890ff" : undefined }}
                />
            ),
            onFilter: (value: any, record: any) =>
                record[dataIndex]
                    ? record[dataIndex]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())
                    : "",
        }),
        []
    );

    const vinculaUsuario = useCallback((data: any) => {
        try {
            if (!data.usuarioId) {
                axios
                    .post(
                        urlContratosEntidade + "/usuario_entidade",
                        {
                            usuarioId: data.id,
                            perfilId: data.perfilId,
                            contratoEntidadeId: refDataContrato.current?.contrato_entidade.id,
                            permissoesIds: data.permissoes.map((permissao: any) => {
                                return permissao.uuid ? permissao.uuid : permissao;
                            })
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + getToken(),
                            },
                        }
                    )
                    .then((result) => {
                        notification.success({
                            message: "Sucesso",
                            description: "Usuário vinculado com sucesso!",
                        });
                        getUsuariosVinculados();
                        setIsModalUsuarioOpen(false);
                    })
                    .catch((error) => {
                        notification.error({
                            message: "Erro",
                            description: error?.response?.data?.message
                        });
                    });
            } else {
                let permissoes = data.permissoes.map((permissao: any) => {
                    //Se elemento contem nome é porque é um objeto e precisa pegar o id
                    if (permissao.nome) {
                        return permissao.uuid;
                    }
                    return permissao;
                }
                );
                const dados = {
                    perfilId: data.perfilId,
                    permissoesIds: permissoes
                };
                axios.put(
                    urlContratosEntidade + "/usuario_entidade/" + data.usuarioId,
                    dados,
                    {
                        headers: {
                            Authorization: "Bearer " + getToken(),
                        },
                    }
                ).then((result) => {

                    notification.success({
                        message: "Sucesso",
                        description: "Usuário vinculado com sucesso!",
                    });
                    getUsuariosVinculados();
                    setIsModalUsuarioOpen(false);
                }
                ).catch((error) => {
                    notification.error({
                        message: "Erro",
                        description: "Não foi possivel vincular o usuário!",
                    });
                });

            }
        } catch (error) {
            let msgErro: any = error as Error;
            notification.error({
                message: "Erro",
                description: msgErro?.response?.data?.message
                    ? msgErro?.response?.data?.message
                    : "Entre em contato com o suporte!",
            });
        }
    }, []);

    const [formNoticia] = Form.useForm();
    const [formMembro] = Form.useForm();
    const [formUsuario] = Form.useForm();
    const columnsNoticias = [
        {
            title: "Origem",
            dataIndex: "id_instagram",
            width: "3%",
            sorter: (a: any, b: any) => a.id_instagram - b.id_instagram,
            showSorterTooltip: false,
            render: (val: any) => {
                const icone = val ? (
                    <FaInstagram
                        style={{
                            color: "#C13584",
                            fontSize: 20,
                        }}
                    />
                ) : (
                    <FaGlobe
                        style={{
                            color: "#1890ff",
                            fontSize: 20,
                        }}
                    />
                );

                return (
                    <span>
                        {icone}
                    </span>
                );
            }
        },
        {
            title: "Titulo",
            dataIndex: "titulo",
            width: "20%",
            sorter: (a: any, b: any) => a.titulo.localeCompare(b.titulo),
            ...getColumnSearchProps("titulo"),
            showSorterTooltip: false,
            render: (val: any) => {
                let titulo = val.length > 45 ? val.substring(0, 45) + "..." : val;
                 return (
                      <span style={{ textTransform: "capitalize" }}>{titulo}</span>
                 );
            }
        },
        {
            title: "Categoria",
            dataIndex: "categoria",
            width: "15%",
            sorter: (a: any, b: any) => {
                return a.categoria?.localeCompare(b.categoria);
            },
            showSorterTooltip: false,
            render: (val: any) => {
                return (
                    <span style={{ textTransform: "capitalize" }}>{val}</span>
                );
            },
        },
        {
            title: "Data",
            dataIndex: "data",
            width: "10%",
            render: (val: any) => {
                return convertDataUSParaBrasilEHora(val);
            },
            showSorterTooltip: false,
        },
        {
            title: "Destaque",
            dataIndex: "destaque",
            width: "5%",
            render: (val: any) => {
                return (
                    <span>
                        <Tag color={val ? "green" : "red"} key={val}>
                            {val ? "Sim" : "Não"}
                        </Tag>
                    </span>
                );
            },
        },
        {
            title: "Criado em",
            dataIndex: "created_at",
            width: "10%",
            sorter: (a: any, b: any) => {
                let aMonMen: any = null;
                let bMonMen: any = null;
                if (a.created_at) {
                    aMonMen = moment(a.created_at).unix();
                } else {
                    aMonMen = 0;
                }
                if (b.created_at) {
                    bMonMen = moment(b.created_at).unix();
                } else {
                    bMonMen = 0;
                }

                return aMonMen - bMonMen;
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            render: (criado: any) => moment(criado).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Ações",
            dataIndex: "id",
            width: "7%",
            render: (id: any, record: any) => (
                <span>
                    <Button
                        style={{
                            borderRadius: 5,
                            margin: 1,
                        }}
                        type="primary"
                        size="small"
                        onClick={() => {
                            setIsEditarNoticia(true);
                            // Busca em noticias a noticia que vai ser editada
                            let noticia = noticias.find((noticia) => {
                                return noticia.id === Number(id);
                            });

                            // Carrega os dados da noticia com block
                            formNoticia.setFieldsValue({
                                idNoticia: noticia?.id,
                                tituloNoticia: noticia?.titulo,
                                linkNoticia: noticia?.link_noticia,
                                categoriaNoticia: noticia?.categoria,
                                destaqueNoticia: noticia?.destaque
                                    ? "Sim"
                                    : "Não",
                                dataNoticia: moment(noticia?.data).format(
                                    "YYYY-MM-DD HH:mm"
                                ),
                                descricaoNoticia: noticia?.descricao,
                                imagensNoticia: noticia?.imagens,
                                idInstagramNoticia: noticia?.id_instagram,
                            });
                            setNoticiaDescricao(noticia?.descricao);
                            // setFileList(noticia?.imagens?.split(","));
                            //Verifica se tem imagens
                            if (
                                noticia?.imagens !== " " ||
                                noticia?.imagens.length > 2
                            ) {
                                setFileList(noticia?.imagens?.split(","));
                            } else {
                                setFileList([]);
                            }

                            setIsModalNoticiaOpen(true);
                        }}
                    >
                        <EditOutlined />
                    </Button>
                    <Divider type="vertical" />
                    <Button
                        style={{
                            borderRadius: 5,
                            margin: 1,
                        }}
                        type="primary"
                        danger
                        size="small"
                        onClick={() => {
                            excluirNoticia(id);
                        }}
                    >
                        <BiTrash />
                    </Button>
                </span>
            ),
        },
    ];

    const columnsMembros = [
        {
            title: "Foto",
            dataIndex: "foto",
            width: "10%",
            render: (foto: any) => {
                return (
                    <img
                        src={
                            foto !== " "
                                ? urlMembroEntidadeFoto + "/" + foto
                                : "/default-avatar.png"
                        }
                        alt="Foto"
                        onClick={() => {
                            setImagemModal(urlMembroEntidadeFoto + "/" + foto);
                            setIsModalImagemOpen(true);
                        }}
                        style={{
                            width: 50,
                            height: 50,
                            borderRadius: 5,
                            cursor: "pointer",
                        }}
                    />
                );
            },
        },
        {
            title: "Nome",
            dataIndex: "nome",
            width: "20%",
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            ...getColumnSearchProps("nome"),
            showSorterTooltip: false,
        },
        {
            title: "Cargo",
            dataIndex: "cargo",
            width: "20%",
            sorter: (a: any, b: any) => a.cargo.localeCompare(b.cargo),
            ...getColumnSearchProps("cargo"),
            showSorterTooltip: false,
        },
        {
            title: "Mandato",
            dataIndex: "mandato",
            width: "20%",
            sorter: (a: any, b: any) => a.mandato.localeCompare(b.mandato),
            ...getColumnSearchProps("mandato"),
            showSorterTooltip: false,
        },
        {
            title: "Ordem",
            dataIndex: "ordem",
            width: "5%",
            sorter: (a: any, b: any) => a.ordem - b.ordem,
            showSorterTooltip: false,
            render: (val: any) => {
                return (
                    <span>
                        <Tag color={"green"} key={val}>
                            {val}
                        </Tag>
                    </span>
                );
            },
        },
        {
            title: "Criado em",
            dataIndex: "created_at",
            width: "15%",
            showSorterTooltip: false,
            sorter: (a: any, b: any) =>
                a.created_at.localeCompare(b.created_at),
            render: (criado: any) => moment(criado).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Ações",
            dataIndex: "id",
            width: "10%",
            render: (id: any, record: any) => (
                <span>
                    <Button
                        style={{
                            borderRadius: 5,
                            margin: 1,
                        }}
                        type="primary"
                        size="small"
                        onClick={() => {
                            setIsEditarMembro(true);

                            // Busca em noticias a noticia que vai ser editada
                            let membro = membros.find((membro) => {
                                return membro.id === Number(id);
                            });

                            let membroOrdens = membros.map((membro) => {
                                return membro.ordem;
                            });

                            setOrdensMembros(membroOrdens);
                            formMembro.setFieldsValue({
                                ordensMembros: membroOrdens,
                            });
                            setIsModalMembroOpen(true);

                            // Carrega os dados da noticia com block
                            formMembro.setFieldsValue({
                                idMembro: membro?.id,
                                nomeMembro: membro?.nome,
                                cargoMembro: membro?.cargo,
                                mandatoMembro: membro?.mandato,
                                fotoMembro: membro?.foto,
                                ordemMembro: membro?.ordem,
                                descricaoMembro: membro?.descricao,
                            });

                            setFotoMembro(membro?.foto);
                            setMembroEditar(membro?.id);
                            setIsModalMembroOpen(true);
                        }}
                    >
                        <EditOutlined />
                    </Button>
                    <Divider type="vertical" />
                    <Button
                        style={{
                            borderRadius: 5,
                            margin: 1,
                        }}
                        type="primary"
                        danger
                        size="small"
                        onClick={() => {
                            excluirMembro(id);
                        }}
                    >
                        <BiTrash />
                    </Button>
                </span>
            ),
        },
    ];

    const columnsUsuarios = [
        {
            title: 'Nome',
            dataIndex: 'usuario',
            key: 'name',
            render: (_: any, { usuario }: any) => usuario.nome,
            sorter: (a: any, b: any) => a.usuario.nome.localeCompare(b.usuario.nome),
        },
        {
            title: 'Email',
            dataIndex: 'usuario',
            key: 'email',
            width: '20%',
            render: (_: any, { usuario }: any) => usuario.email,
            sorter: (a: any, b: any) => a.usuario.email.localeCompare(b.usuario.email),
        },
        {
            title: 'Telefone',
            dataIndex: 'usuario',
            key: 'celular',
            width: '10%',
            render: (_: any, { usuario }: any) => usuario.celular.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3'),
            sorter: (a: any, b: any) => a.usuario.telefone.localeCompare(b.usuario.telefone),
        },
        {
            title: 'Perfil',
            dataIndex: 'perfil',
            key: 'perfil',
            render: (_: any, { perfil }: any) => perfil.nome
        },
        {
            title: "Criado",
            dataIndex: "created_at",
            width: "15%",
            sorter: (a: any, b: any) => {
                let aMonMen: any = null;
                let bMonMen: any = null;
                if (a.created_at) {
                    aMonMen = moment(a.created_at).unix();
                } else {
                    aMonMen = 0;
                }
                if (b.created_at) {
                    bMonMen = moment(b.created_at).unix();
                } else {
                    bMonMen = 0;
                }

                return aMonMen - bMonMen;
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            render: (_: any, { created_at }: any) => convertDataUSParaBrasilEHora(created_at)
        },
        {
            title: 'Ações',
            dataIndex: 'usuario',
            key: 'id',
            width: '10%',
            render: (usuario: any, record: any) => {
                return (
                    <div>
                        <Button
                            style={{
                                borderRadius: 5,
                                margin: 1,
                            }}
                            type="primary"
                            size="small"
                            onClick={() => {
                                setIsEditarUsuario(true);
                                setUsuario(null);
                                //busca o usuario na lista de usuários vinculados pelo id
                                let usuarioFind = usuariosVinculados.find((usuarioFind: any) => {
                                    return usuarioFind.usuarioId === Number(usuario.id);
                                });
                                formUsuario.setFieldsValue({
                                    perfilId: usuarioFind?.perfil?.uuid,
                                    permissoes: usuarioFind?.permissoes?.map((permissao: any) => {
                                        return permissao.uuid;
                                    })
                                });
                                setUsuario(usuarioFind);
                                setIsModalUsuarioOpen(true);

                            }}
                        >
                            <EditOutlined />
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            style={{
                                borderRadius: 5,
                                margin: 1,
                            }}
                            type="primary"
                            danger
                            size="small"
                            onClick={() => {
                                excluirUsuario(usuario.id);
                            }}
                        >
                            <BiUnlink />
                        </Button>
                    </div>)
            }


        }
    ];

    const comonentDidMount = useEffect((): void => {
        setIsSalvarOrEditar(true);

        const buscaDadosIniciais = async () => {
            try {
                let dadosLocalStorage: string | null =
                    localStorage.getItem("@GovFacil:token");
                let localUserData: any = null;
                if (typeof dadosLocalStorage == "string") {
                    localUserData = JSON.parse(dadosLocalStorage);
                    // return localUserData.user.nome
                }

                if (idParam) {
                    await clickBotaoEditarContrato(idParam);
                    refIdContrato.current = idParam;
                    // setId(idParam)
                }

                if (refContratoInterface.current) {
                    //caso for editar ativar o loading
                    setLoadingPage(true);

                    if (localUserData) {
                        refidUsuarioLogado.current = localUserData?.user?.id;
                    }

                    setIsSalvarOrEditar(false);

                    refForm.current.setFieldsValue({
                        id: refContratoInterface.current?.id
                            ? refContratoInterface.current.id
                            : undefined,
                        nome: refContratoInterface.current?.nome
                            ? refContratoInterface.current.nome
                            : undefined,
                        url: refContratoInterface.current?.url
                            ? refContratoInterface.current.url
                            : undefined,
                        perfil_instagram: refContratoInterface.current
                            ?.perfil_instagram
                            ? refContratoInterface.current.perfil_instagram
                            : undefined,
                    });

                    if (
                        refContratoInterface.current?.logo_light &&
                        refContratoInterface.current.logo_light !== null &&
                        refContratoInterface.current.logo_light !== ""
                    ) {
                        setImageUrlLight(
                            urlEntidadeLogo +
                            "/" +
                            refContratoInterface.current.logo_light
                        );
                    }

                    if (
                        refContratoInterface.current?.logo_dark &&
                        refContratoInterface.current.logo_dark !== null &&
                        refContratoInterface.current.logo_dark !== ""
                    ) {
                        setImageUrlDark(
                            urlEntidadeLogo +
                            "/" +
                            refContratoInterface.current.logo_dark
                        );
                    }

                    if (
                        refContratoInterface.current?.descricao &&
                        refContratoInterface.current.descricao !== null &&
                        refContratoInterface.current.descricao !== ""
                    ) {
                        // setEditorState(refContratoInterface.current.descricao)

                        const contentBlocks = htmlToDraft(
                            refContratoInterface.current.descricao
                        );

                        const sampleEditorContent =
                            ContentState.createFromBlockArray(
                                contentBlocks.contentBlocks,
                                contentBlocks.entityMap
                            );
                        const editorState =
                            EditorState.createWithContent(sampleEditorContent);

                        setEditorState(editorState);
                        // setdefaultEditorState(sampleEditorContent)
                    }
                }
                //Se tiver id
                if (refContratoInterface.current?.id) {
                    getNoticias();
                    getMembros();
                    getUsuariosVinculados();
                    getUsuarios();
                }
                setLoadingPage(false);
            } catch (error) {
                setLoadingPage(false);
                notification.error({
                    message: "Erro",
                    description: "Não foi possivel buscar dados!",
                });
            }
        };

        buscaDadosIniciais();
    }, [idParam]);

    const onClickVoltarConfigOfSystem = useCallback((): void => {
        history.push("/contrato");
    }, [history]);

    const getBase64 = useCallback((img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    }, []);

    const beforeUpload = useCallback((file) => {
        // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isJpgOrPng = file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("Somente arquivos png são suportados!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Image must smaller than 2MB!");
        }
        return isJpgOrPng && isLt2M;
    }, []);

    const beforeUploadMembro = useCallback(async (file): Promise<any> => {
        const isJpgOrPng =
            file.type === "image/png" ||
            file.type === "image/jpeg" ||
            file.type === "image/jpg";
        if (!isJpgOrPng) {
            message.error("Somente arquivos [png, jpg, jpeg] são suportados!");
            return;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Imagem não pode ser maior que 2MB!");
            return;
        }

        //Pega proporção da imagem
        const proporcaoImagem = await getProporcaoImagem(file);

        if (proporcaoImagem.width !== proporcaoImagem.height) {
            message.error("Imagem deve ser quadrada!");
            return;
        }

        formMembro.setFieldsValue({
            fotoMembro: file,
        });

        setFotoMembro(file);
    }, []);

    const getProporcaoImagem = useCallback((file): Promise<any> => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const { width, height } = img;
                resolve({ width, height });
            };
            img.onerror = reject;
            img.src = URL.createObjectURL(file);
        });
    }, []);

    const handleChangeImagensNoticia = (info: any) => {
        let newFileList = [...info.fileList];

        newFileList = newFileList.map((file) => {
            const isJpgOrPng =
                file.type === "image/jpeg" ||
                file.type === "image/png" ||
                file.type === "image/jpg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (file.type) {
                if (!isJpgOrPng) {
                    message.error("Somente arquivos JPG/PNG são suportados.");
                    // remove o arquivo inválido da lista
                    return false;
                }

                if (!isLt2M) {
                    message.error("A imagem deve ser menor que 2MB.");
                    // return; // Ignora o arquivo inválido
                    return false;
                }
                return file;
            }
            return file;
        });

        //remove os arquivos inválidos da lista
        newFileList = newFileList.filter((file) => {
            if (file !== false) {
                return file;
            }
        });

        formNoticia.setFieldsValue({
            imagensNoticia: newFileList,
        });

        setFileList(newFileList);
    };

    //Função para subir as imagens
    const uploadImagensNoticia = () => {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: "Bearer " + getToken(),
            },
        };

        // Pega o idNoticia no formulário
        let idNoticia = refFormNoticia.current.getFieldValue("idNoticia");
        const formData = new FormData();

        // Adicione cada arquivo da fileList ao formData
        fileList.forEach((file: any) => {
            formData.append(`imagens`, file.originFileObj);
        });

        axios
            .patch(
                urlContratosEntidade + "/noticia/" + idNoticia,
                formData,
                config
            )
            .then((response) => {
                //Atualiza o valor do campo imagensNoticia no form
                refFormNoticia.current.setFieldsValue({
                    imagensNoticia: response.data.imagens,
                });

                message.success(`Todas as imagens foram enviadas com sucesso.`);
                getNoticias();
            })
            .catch((error) => {
                // Lide com erros aqui
                message.error(`Falha ao enviar imagens.`);
            });
    };

    const onchangeEditor = useCallback((editorState): void => {
        setEditorState(editorState);
    }, []);

    const handleChangeLight = useCallback((info) => {
        refImagemCarregadaLight.current = info;

        getBase64(info.file.originFileObj, (imageUrl: any) => {
            setLoadingCampoUpload(false);
            setImageUrlLight(imageUrl);
        });
    }, []);

    const handleChangeDark = useCallback((info) => {
        refImagemCarregadaDark.current = info;

        getBase64(info.file.originFileObj, (imageUrl: any) => {
            setLoadingCampoUpload(false);
            setImageUrlDark(imageUrl);
        });
    }, []);

    useEffect(() => {
        if (entidade !== undefined) {
            setEntidade(entidade);
            // Faça qualquer coisa que você precisa com entidade aqui
        }
    }, [entidade]);

    const onClickBotaoEditar = useCallback((): void => {
        let dadosLocalStorage: string | null =
            localStorage.getItem("@GovFacil:Mensagem");
        let localUserData: any = null;
        if (typeof dadosLocalStorage == "string") {
            localUserData = JSON.parse(dadosLocalStorage);
        }
        const editarEntidadeContrato = async (values: any) => {
            try {
                setLoadingPage(true);

                const formData = new FormData();
                formData.append("nome", values.nome);
                formData.append("url", values.url);
                formData.append("perfil_instagram", values.perfil_instagram || '');
                formData.append("descricao", values.descricao);
                formData.append("contratoId", values.contratoId);

                let requisicaoFoto = false;
                if (
                    refImagemCarregadaLight.current !== null ||
                    refImagemCarregadaDark.current !== null
                ) {
                    requisicaoFoto = true;
                }

                if (refImagemCarregadaLight.current !== null) {
                    formData.append(
                        "logo_light",
                        refImagemCarregadaLight.current.file.originFileObj
                    );
                }
                if (refImagemCarregadaDark.current !== null) {
                    formData.append(
                        "logo_dark",
                        refImagemCarregadaDark.current.file.originFileObj
                    );
                }

                const idEntidade =
                    refDataContrato.current?.contrato_entidade.id;
                let resultEntidade = await axios.put(
                    urlContratosEntidade + "/" + idEntidade,
                    formData,
                    {
                        headers: {
                            Authorization: "Bearer " + getToken(),
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                notification.success({
                    message: "Sucesso",
                    description: "Entidade atualizada com sucesso!",
                });
                setLoadingPage(false);
                // history.push("/contrato");
            } catch (error) {
                let msgErro: any = error as Error;
                setLoadingPage(false);
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
            }
        };

        refForm.current
            .validateFields()
            .then((values: any) => {
                values.descricao = draftToHtml(
                    convertToRaw(editorState.getCurrentContent())
                );

                values.contratoId = refIdContrato.current;

                // editar
                editarEntidadeContrato(values);
            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: "Erro",
                    description: "Preencha os campos obrigatorios!",
                });
            });
    }, [editorState]);

    const onClickBotaoSalvar = useCallback((): void => {
        const salvarEntidadeContrato = async (values: any) => {
            setLoadingPage(true);
            try {
                const formData = new FormData();
                let requisicaoFoto = false;
                formData.append("nome", values.nome);
                formData.append("url", values.url);
                formData.append("perfil_instagram", values.perfil_instagram || '');
                formData.append("descricao", values.descricao);
                formData.append("contratoId", values.contratoId);

                if (refImagemCarregadaLight.current !== null || refImagemCarregadaDark.current !== null) {
                    requisicaoFoto = true;
                }

                if (refImagemCarregadaLight.current !== null) {
                    formData.append(
                        "logo_light",
                        refImagemCarregadaLight.current.file.originFileObj
                    );
                }
                if (refImagemCarregadaDark.current !== null) {
                    formData.append(
                        "logo_dark",
                        refImagemCarregadaDark.current.file.originFileObj
                    );
                }

                let resultEntidade = await axios.post(
                    urlContratosEntidade,
                    formData,
                    {
                        headers: {
                            Authorization: "Bearer " + getToken(),
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                setLoadingPage(false);
                notification.success({
                    message: "Sucesso",
                    description: "Entidade salva com sucesso!",
                });
                setIsSalvarOrEditar(false);
                return resultEntidade.data;
            } catch (error) {
                let msgErro: any = error as Error;
                setLoadingPage(false);
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
            }
        };

        refForm.current
            .validateFields()
            .then(async (values: any) => {
                values.descricao = draftToHtml(
                    convertToRaw(editorState.getCurrentContent())
                );

                values.contratoId = refIdContrato.current;

                // salvar
                const entidade = await salvarEntidadeContrato(values);
                await clickBotaoEditarContrato(values.contratoId);
                setEntidade(entidade.id);
            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: "Erro",
                    description: "Preencha os campos obrigatorios!",
                });
            });
    }, [editorState]);

    const uploadButton = useCallback(() => {
        return (
            <div>
                {loadingCampoUpload ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
    }, [loadingCampoUpload]);

    const salvarNoticia = useCallback((values: any, escondeModal: boolean) => {
        try {
            setLoadingSaveNoticia(true);
            const data = new FormData();
            data.append("titulo", values.tituloNoticia);
            data.append("link_noticia", values.linkNoticia);
            data.append("descricao", values.descricaoNoticia);
            data.append("categoria", values.categoriaNoticia);
            data.append("data", values.dataNoticia);
            data.append(
                "destaque",
                String(values.destaqueNoticia == 1 ? true : false)
            );
            data.append(
                "contratoEntidadeId",
                refDataContrato.current?.contrato_entidade.id
            );
            const imagens = formNoticia.getFieldValue("imagensNoticia");

            try {
                let dataImagens: any = [];
                //se imagens não for um array da um split
                if (!Array.isArray(imagens)) {
                    dataImagens = imagens.split(",");
                } else {
                    dataImagens = imagens;
                }
                if (imagens) {
                    for (let i = 0; i < dataImagens.length; i++) {
                        if (dataImagens[i].originFileObj) {
                            data.append(
                                "imagens",
                                dataImagens[i].originFileObj
                            );
                        } else {
                            data.append("imagens", dataImagens[i]);
                        }
                    }
                } else {
                    notification.error({
                        message: "Erro",
                        description: "Selecione uma imagem!",
                    });
                    return;
                }
            } catch (error) {

            }
            if (values.idNoticia) {
                axios
                    .put(
                        urlContratosEntidade + "/noticia/" + values.idNoticia,
                        data,
                        {
                            headers: {
                                Authorization: "Bearer " + getToken(),
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    )
                    .then((result) => {
                        notification.success({
                            message: "Sucesso",
                            description: "Notícia atualizada com sucesso!",
                        });
                        if (escondeModal) {
                            setIsModalNoticiaOpen(false);
                        }
                        setNoticiaDescricao("");
                        getNoticias();
                    })
                    .catch((error) => {
                        notification.error({
                            message: "Erro",
                            description: "Não foi possivel salvar a notícia!",
                        });
                    });
            } else {
                axios
                    .post(urlContratosEntidade + "/noticia", data, {
                        headers: {
                            Authorization: "Bearer " + getToken(),
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((result) => {
                        notification.success({
                            message: "Sucesso",
                            description: "Notícia salva com sucesso!",
                        });
                        setIsModalNoticiaOpen(false);
                        getNoticias();
                    })
                    .catch((error) => {
                        notification.error({
                            message: "Erro",
                            description: "Não foi possivel salvar a notícia!",
                        });
                    });
            }
            setLoadingSaveNoticia(false);
        } catch (error) {
            let msgErro: any = error as Error;
            notification.error({
                message: "Erro",
                description: msgErro?.response?.data?.message
                    ? msgErro?.response?.data?.message
                    : "Entre em contato com o suporte!",
            });
            setLoadingSaveNoticia(false);
            return false;
        }
    }, []);

    const salvarMembro = useCallback(async (values: any) => {
        const ordensMembros = formMembro.getFieldValue("membroOrdens");

        // Converter values.ordemMembro para o mesmo tipo que ordem.ordem para uma comparação mais precisa
        const ordemMembro = parseInt(values.ordemMembro);
        if (ordensMembros !== undefined) {
            // Busca se existe ordem igual com id diferente
            let ordemEncontrada = ordensMembros.find((ordem: any) => {
                // Converter ordem.ordem para o mesmo tipo que values.ordemMembro
                const ordemNoArray = parseInt(ordem.ordem);
                return (
                    ordemNoArray === ordemMembro && ordem.id !== values.idMembro
                );
            });
            if (ordemEncontrada) {
                notification.error({
                    message: "Erro",
                    description: "Ordem já cadastrada em outro membro!",
                });
                return;
            }
        }

        try {
            const data = new FormData();
            data.append("nome", values.nomeMembro);
            data.append("cargo", values.cargoMembro);
            data.append("mandato", values.mandatoMembro);
            data.append("ordem", values.ordemMembro);
            data.append(
                "descricao",
                values.descricaoMembro ? values.descricaoMembro : " "
            );
            data.append(
                "contratoEntidadeId",
                refDataContrato.current?.contrato_entidade.id
            );
            const foto = formMembro.getFieldValue("fotoMembro");

            if (foto.uid) {
                data.append("foto", foto);
            }

            if (values.idMembro) {
                axios
                    .put(
                        urlContratosEntidade + "/membro/" + values.idMembro,
                        data,
                        {
                            headers: {
                                Authorization: "Bearer " + getToken(),
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    )
                    .then((result) => {
                        notification.success({
                            message: "Sucesso",
                            description: "Membro atualizado com sucesso!",
                        });
                        getMembros();
                        setIsModalMembroOpen(false);
                    })
                    .catch((error) => {
                        notification.error({
                            message: "Erro",
                            description: "Não foi possivel salvar o membro!",
                        });
                    });
            } else {
                axios
                    .post(urlContratosEntidade + "/membro", data, {
                        headers: {
                            Authorization: "Bearer " + getToken(),
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((result) => {
                        notification.success({
                            message: "Sucesso",
                            description: "Membro salvo com sucesso!",
                        });
                        getMembros();
                        setIsModalMembroOpen(false);
                    })
                    .catch((error) => {
                        notification.error({
                            message: "Erro",
                            description: "Não foi possivel salvar o membro!",
                        });
                    });
            }
            setLoadingSaveMembro(false);
        } catch (error) {
            let msgErro: any = error as Error;
            notification.error({
                message: "Erro",
                description: msgErro?.response?.data?.message
                    ? msgErro?.response?.data?.message
                    : "Entre em contato com o suporte!",
            });
            return false;
        }
    }, []);

    const onClickBotaoSalvarNoticia = useCallback((): void => {
        setLoadingSaveNoticia(true);
        //espera 1 segundo
        setTimeout(() => {
            //Pega os campos do formulários
            refFormNoticia.current
                .validateFields()
                .then((values: any) => {
                    values.contratoEntidadeId = entidade
                        ? entidade
                        : refDataContrato.current?.contrato_entidade.id;
                    salvarNoticia(values, true);
                })
                .catch((errorInfo: any) => {
                    notification.error({
                        message: "Erro",
                        description: "Preencha os campos obrigatorios!",
                    });
                    setLoadingSaveNoticia(false);
                });
        }, 1000);
    }, [noticiaEditar]);

    const onClickBotaoSalvarMembro = useCallback((): void => {
        setLoadingSaveMembro(true);
        //Pega os campos do formulário
        setTimeout(() => {
            refFormMembro.current
                .validateFields()
                .then((values: any) => {
                    values.contratoEntidadeId = entidade
                        ? entidade
                        : refDataContrato.current?.contrato_entidade.id;
                    salvarMembro(values);
                })
                .catch((errorInfo: any) => {
                    notification.error({
                        message: "Erro",
                        description: "Preencha os campos obrigatorios!",
                    });
                });
        }, 1000);
    }, []);


    return (
        <>
            <Row>
                <Col {...cardLayout}>
                    <Title level={3}>
                        <SolutionOutlined
                            style={{ fontSize: 35, marginRight: 10 }}
                        />{" "}
                        Entidade Contrato
                    </Title>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <a
                                onClick={() =>
                                    history.push("/contrato/entidades")
                                }
                            >
                                Entidades
                            </a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item> Entidade Contrato</Breadcrumb.Item>
                    </Breadcrumb>
                    <Divider />
                </Col>
            </Row>
            {
                <Row>
                    <Col
                        span={24}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                            fontSize: 24,
                            marginTop: -5,
                            marginBottom: 12,
                        }}
                    >
                        <Text
                            style={{
                                margin: 0,
                                marginRight: 5,
                            }}
                        >
                            Contrato:
                        </Text>
                        <Title
                            level={3}
                            style={{
                                margin: 0,
                                fontSize: 24,
                            }}
                        >
                            {refDataContrato.current?.nome}
                        </Title>
                    </Col>
                </Row>
            }

            <Tabs
                defaultActiveKey="1"
                type="card"
                size={"large"}
                style={{ width: "100%", marginTop: 15 }}
                tabBarStyle={{ marginBottom: 0 }}
                key={"tabContratoEntidade"}
            >
                <Tabs.TabPane
                    tab="Institucional"
                    key="145645654"
                    style={{ padding: 30 }}
                >
                    <Spin spinning={loadingPage}>
                        <Form
                            ref={refForm}
                            name="formSendMensagens"
                            layout="vertical"
                            onFinishFailed={() => { }}
                        >
                            <Row>
                                <Col hidden {...inputNome}>
                                    <Form.Item
                                        name="id"
                                        label="id"
                                        rules={[
                                            {
                                                required: false,
                                                message:
                                                    "Por favor preencha o id!",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="id" disabled />
                                    </Form.Item>
                                </Col>
                                <Col {...inputNome}>
                                    <Form.Item
                                        name="nome"
                                        label="Nome"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Por favor preencha o nome!",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Nome" />
                                    </Form.Item>
                                </Col>
                                <Col {...inputFuncao}>
                                    <Form.Item
                                        name="url"
                                        label="Url"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Por favor preencha a url!",
                                            },
                                            {
                                                type: "url",
                                                message:
                                                    "Por favor preencha uma url válida!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            // addonBefore="https://"
                                            placeholder="ex: https://govfacil.com.br"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col {...inputPerfilInstagram}>
                                    <Form.Item
                                        name="perfil_instagram"
                                        label="Perfil Instagram"
                                    >
                                        <Input placeholder="Perfil do Instagram" />
                                    </Form.Item>
                                </Col>
                                <Col {...inputMensagem}>
                                    <Form.Item
                                        name="descricao"
                                        label="Descrição"
                                        rules={[
                                            {
                                                required: false,
                                                message:
                                                    "Por favor digite a descrição!",
                                            },
                                        ]}
                                    >
                                        {/* <Input.TextArea style={{ maxHeight: 250, height: 150 }} /> */}
                                        <Editor
                                            editorState={editorState}
                                            defaultEditorState={
                                                defaultEditorState
                                            }
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            editorStyle={{
                                                maxHeight: 250,
                                                border: "1px solid #e4e4e4",
                                                padding: 5,
                                                paddingBottom: 10,
                                                borderRadius: 2,
                                            }}
                                            onEditorStateChange={onchangeEditor}
                                            toolbar={{
                                                options: [
                                                    "inline",
                                                    "fontSize",
                                                    "fontFamily",
                                                    "list",
                                                    "textAlign",
                                                    "colorPicker",
                                                    "history",
                                                    "emoji",
                                                ],
                                                inline: {
                                                    options: ["bold", "italic"],
                                                },
                                                blockType: {
                                                    options: [
                                                        "Normal",
                                                        "H1",
                                                        "H2",
                                                        "H3",
                                                        "H4",
                                                        "H5",
                                                        "H6",
                                                        "Blockquote",
                                                        "Code",
                                                    ],
                                                },
                                                fontFamily: {
                                                    options: [
                                                        "Montserrat-Bold",
                                                        "Montserrat-SemiBold",
                                                        "Montserrat-Medium",
                                                        "Montserrat-Regular",
                                                    ],
                                                },
                                                colorPicker: {
                                                    colors: [
                                                        "rgb(97,189,109)",
                                                        "rgb(26,188,156)",
                                                        "rgb(84,172,210)",
                                                        "rgb(44,130,201)",
                                                        "rgb(147,101,184)",
                                                        "rgb(71,85,119)",
                                                        "rgb(204,204,204)",
                                                        "rgb(65,168,95)",
                                                        "rgb(0,168,133)",
                                                        "rgb(61,142,185)",
                                                        "rgb(41,105,176)",
                                                        "rgb(85,57,130)",
                                                        "rgb(40,50,78)",
                                                        "rgb(0,0,0)",
                                                        "rgb(247,218,100)",
                                                        "rgb(251,160,38)",
                                                        "rgb(235,107,86)",
                                                        "rgb(226,80,65)",
                                                        "rgb(163,143,132)",
                                                        "rgb(239,239,239)",
                                                        "rgb(255,255,255)",
                                                        "rgb(250,197,28)",
                                                        "rgb(243,121,52)",
                                                        "rgb(209,72,65)",
                                                        "rgb(184,49,47)",
                                                        "rgb(124,112,107)",
                                                        "rgb(209,213,216)",
                                                    ],
                                                },
                                                list: {
                                                    options: [
                                                        "unordered",
                                                        "ordered",
                                                    ],
                                                },
                                                textAlign: {
                                                    // options: ['justify'],
                                                    options: [
                                                        "left",
                                                        "center",
                                                        "right",
                                                        "justify",
                                                    ],
                                                    left: "left",
                                                    center: "center",
                                                    right: "right",
                                                    link: "justify",
                                                },
                                            }}
                                        // options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']
                                        />
                                    </Form.Item>
                                </Col>
                                <Col {...inputLogo}>
                                    <Paragraph>Imagem modo Light</Paragraph>
                                    <Upload
                                        // name="file"
                                        showUploadList={false}
                                        // customRequest={()}
                                        action="/upload.do"
                                        beforeUpload={beforeUpload}
                                        onChange={handleChangeLight}
                                    >
                                        <Button
                                            style={{
                                                width: "100%",
                                                borderRadius: "8px 8px 0px 0px",

                                            }}
                                            icon={<UploadOutlined />}
                                        >
                                            Clique para adicionar imagem
                                        </Button>

                                        {imageUrlLight ? (
                                            <div
                                                style={{
                                                    paddingBottom: 10,
                                                    paddingTop: 10,
                                                    backgroundColor: "#fff",
                                                    //centralizar imagem
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}>
                                                <img
                                                    src={imageUrlLight}
                                                    alt="imagem modo light"
                                                    style={{
                                                        width: "100%",
                                                        maxWidth: 150,
                                                        minWidth: 200,
                                                        //centralizar imagem


                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </Upload>
                                    {imageUrlLight ? (
                                        <>
                                            <Button
                                                style={{
                                                    width: "100%",
                                                    maxWidth: 240,
                                                    minWidth: 240,
                                                    borderRadius: "0px 0px 8px 8px",
                                                }}

                                                type="primary"
                                                onClick={() => {
                                                    downloadImage(imageUrlLight);
                                                }}
                                            >
                                                Baixar Imagem
                                            </Button>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </Col>
                                <Col span={1} />
                                <Col {...preVisualizacao}>
                                    <Paragraph>
                                        Pré-visualização modo light
                                    </Paragraph>
                                    <div
                                        style={{
                                            backgroundColor: "#fff",
                                            padding: 0,
                                            borderRadius: 8,
                                            width: 200,
                                            height: 100,
                                            // shadow
                                            boxShadow:
                                                "0px 0px 10px rgba(0, 0, 0, 0.3)",
                                        }}
                                    >
                                        <div
                                            style={{
                                                paddingTop: 5,
                                                flexDirection: "row",
                                                // justifyContent: 'space-between'
                                            }}
                                        >
                                            <img
                                                style={{
                                                    maxWidth: "80%", // Largura máxima
                                                    minWidth: "50px", // Largura mínima
                                                    maxHeight: "70px", // Altura máxima (ajuste ao seu tamanho desejado)
                                                    paddingLeft: 10,
                                                    paddingTop: 10,
                                                }}
                                                src={
                                                    imageUrlLight
                                                }
                                            />
                                        </div>
                                        <Text
                                            strong
                                            style={{
                                                fontSize: 13,
                                                color: "#555",
                                                alignItems: "center",
                                                paddingTop: 5,
                                                position: "absolute",
                                                bottom: 0,
                                                top: 105,
                                                paddingBottom: 5,
                                                paddingLeft: 10,
                                            }}
                                        >
                                            {
                                                refDataContrato.current
                                                    ?.contrato_entidade?.nome
                                            }
                                        </Text>
                                    </div>
                                </Col>
                                <Col {...inputLogo}>
                                    <Paragraph>Imagem modo Dark</Paragraph>
                                    <Upload
                                        // name="file"
                                        // className="avatar-uploader"
                                        showUploadList={false}
                                        // customRequest={()}
                                        action="/upload.do"
                                        beforeUpload={beforeUpload}
                                        onChange={handleChangeDark}
                                    >
                                        {" "}
                                        <Button
                                            style={{
                                                width: "100%",
                                                borderRadius: "8px 8px 0px 0px",
                                            }}
                                            icon={<UploadOutlined />}
                                        >
                                            Clique para adicionar imagem
                                        </Button>
                                        {imageUrlDark ? (
                                            <div
                                                style={{
                                                    paddingBottom: 10,
                                                    paddingTop: 10,
                                                    backgroundColor: "#1D1D1D",
                                                    //centralizar imagem
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}>
                                                <img
                                                    src={imageUrlDark}
                                                    alt="imagem modo dark"
                                                    style={{
                                                        width: "100%",
                                                        maxWidth: 150,
                                                        minWidth: 200,
                                                        //centralizar imagem


                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </Upload>
                                    {imageUrlDark ? (
                                        <>
                                            <Button
                                                style={{
                                                    width: "100%",
                                                    maxWidth: 240,
                                                    minWidth: 240,
                                                    borderRadius: "0px 0px 8px 8px",
                                                }}
                                                type="primary"
                                                onClick={() => {
                                                    downloadImage(imageUrlDark);
                                                }}
                                            >
                                                Baixar Imagem
                                            </Button>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </Col>
                                <Col span={1} />
                                <Col {...preVisualizacao}>
                                    <Paragraph>
                                        Pré-visualização modo dark
                                    </Paragraph>
                                    <div
                                        style={{
                                            backgroundColor: "#383838",
                                            padding: 0,
                                            borderRadius: 8,
                                            width: 200,
                                            height: 100,

                                            // shadow
                                            boxShadow:
                                                "0px 0px 10px rgba(0, 0, 0, 0.3)",
                                        }}
                                    >
                                        <div
                                            style={{
                                                paddingTop: 5,
                                                flexDirection: "row",
                                                //  justifyContent: 'space-between'
                                            }}
                                        >
                                            <img
                                                style={{
                                                    maxWidth: "80%", // Largura máxima
                                                    minWidth: "50px", // Largura mínima
                                                    maxHeight: "70px", // Altura máxima (ajuste ao seu tamanho desejado)
                                                    paddingLeft: 10,
                                                    paddingTop: 10,
                                                }}
                                                src={
                                                    imageUrlDark
                                                }
                                            />
                                        </div>
                                        <Text
                                            strong
                                            style={{
                                                fontSize: 13,
                                                color: "#fff",
                                                alignItems: "center",
                                                paddingTop: 5,
                                                position: "absolute",
                                                bottom: 0,
                                                top: 105,
                                                paddingBottom: 5,
                                                paddingLeft: 10,
                                            }}
                                        >
                                            {
                                                refDataContrato.current
                                                    ?.contrato_entidade?.nome
                                            }
                                        </Text>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={22}>
                                    {/* Botões de salvar  */}
                                    <Row style={{ marginTop: 10 }}>
                                        <Col
                                            span={24}
                                            style={{ textAlign: "end" }}
                                        >
                                            <Button
                                                style={{ marginRight: 10 }}
                                                loading={loadingPage}
                                                onClick={
                                                    onClickVoltarConfigOfSystem
                                                }
                                            >
                                                Cancelar
                                            </Button>
                                            {isSalvarOrEditar ? (
                                                <Button
                                                    type="primary"
                                                    loading={loadingPage}
                                                    onClick={onClickBotaoSalvar}
                                                >
                                                    Salvar
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="primary"
                                                    loading={loadingPage}
                                                    onClick={onClickBotaoEditar}
                                                >
                                                    Atualizar
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab="Notícias"
                    key="2456456"
                    style={{ padding: 30 }}
                >
                    {refDataContrato.current?.contrato_entidade || entidade ? (
                        <>
                            <Title level={3}>
                                Notícias da:{" "}
                                {
                                    refDataContrato.current?.contrato_entidade
                                        ?.nome
                                }
                            </Title>
                            <Row style={{ marginTop: 10 }}>
                                <Col
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setIsEditarNoticia(false);
                                            formNoticia.setFieldsValue({
                                                idNoticia: "",
                                                tituloNoticia: "",
                                                linkNoticia: "",
                                                categoriaNoticia: "",
                                                destaqueNoticia: "Não",
                                                descricaoNoticia: "",
                                                imagensNoticia: "",
                                                idInstagramNoticia: "",
                                                dataNoticia:
                                                    moment().format(
                                                        "YYYY-MM-DD"
                                                    ),
                                            });
                                            setFileList([]);
                                            setNoticiaDescricao("");
                                            showModalNoticia();
                                        }}
                                    >
                                        <PlusOutlined />
                                        Adicionar Notícia
                                    </Button>
                                </Col>
                            </Row>

                            <Table
                                style={{
                                    marginTop: 10,
                                }}
                                className="marginTopTables"
                                rowKey={(record) => Number(record?.id)}
                                loading={loadingTableNoticias}
                                // onRow={(record, rowIndex) => {
                                //     return {
                                //         onClick: event => { onclickTableNoticias(record, rowIndex) }, // click row
                                //     };
                                // }}
                                // rowSelection={rowSelection}
                                columns={columnsNoticias}
                                dataSource={noticias}
                                scroll={{ x: 1100 }}
                                size="small"
                                title={(registros) => {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor:
                                                    propsState.theme === "light"
                                                        ? "#FAFAFA"
                                                        : "#1D1D1D",
                                                padding: 10,
                                                margin: -8,
                                                marginBottom: 0.5,
                                            }}
                                        >
                                            <Text>
                                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                                {`Mostrando: ${registros.length
                                                    } de ${refContagemDoFiltroNoticias
                                                        .current.length === 0
                                                        ? noticias.length
                                                        : refContagemDoFiltroNoticias
                                                            .current.length
                                                    } num total de ${noticias.length
                                                    } `}
                                            </Text>
                                        </div>
                                    );
                                }}
                                footer={(registros) => {
                                    return (
                                        <div
                                            style={{
                                                textAlign: "end",
                                            }}
                                        >
                                            <Text>
                                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                                {`Mostrando: ${registros.length
                                                    } de ${refContagemDoFiltroNoticias
                                                        .current.length === 0
                                                        ? noticias.length
                                                        : refContagemDoFiltroNoticias
                                                            .current.length
                                                    } num total de ${noticias.length
                                                    } `}
                                            </Text>
                                        </div>
                                    );
                                }}
                                pagination={{
                                    locale: { items_per_page: "" },
                                    showSizeChanger: true,
                                    defaultPageSize: 100,
                                    position: ["topRight", "bottomRight"],
                                    pageSizeOptions: ["10", "20", "50", "100"],
                                }}
                                locale={{
                                    emptyText: (
                                        <div style={{ marginTop: 10 }}>
                                            <InfoCircleOutlined
                                                style={{
                                                    fontSize: 30,
                                                    marginBottom: 10,
                                                }}
                                            />
                                            <br />
                                            <Text style={{ fontSize: 15 }}>
                                                Nenhum registro encontrado!
                                            </Text>
                                        </div>
                                    ),
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <Title
                                level={4}
                                style={{
                                    marginTop: 5,
                                    color:
                                        propsState.theme === "light"
                                            ? "#444"
                                            : "#fff",
                                }}
                            >
                                <InfoCircleOutlined
                                    style={{
                                        fontSize: 20,
                                        marginRight: 5,
                                    }}
                                />{" "}
                                Para incluir as notícias cadastre primeiro os
                                dados do institucional
                            </Title>
                        </>
                    )}
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab="Membros"
                    key="329408"
                    style={{ padding: 30 }}
                >
                    {refDataContrato.current?.contrato_entidade || entidade ? (
                        <>
                            <Title level={3}>
                                Membros da:{" "}
                                {
                                    refDataContrato.current?.contrato_entidade
                                        ?.nome
                                }
                            </Title>
                            <Row style={{ marginTop: 10 }}>
                                <Col
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setIsEditarMembro(false);
                                            formMembro.setFieldsValue({
                                                idMembro: "",
                                                nomeMembro: "",
                                                descricaoMembro: "",
                                                cargoMembro: "",
                                                mandatoMembro: "",
                                                ordemMembro: "",
                                                fotoMembro: " ",
                                            });

                                            setFotoMembro(" ");
                                            showModalMembro();
                                        }}
                                    >
                                        <PlusOutlined />
                                        Adicionar Membro
                                    </Button>
                                </Col>
                            </Row>

                            <Table
                                style={{
                                    marginTop: 10,
                                }}
                                className="marginTopTables"
                                rowKey={(record) => Number(record?.id)}
                                loading={loadindTableMembros}
                                // onRow={(record, rowIndex) => {
                                //     return {
                                //         onClick: event => { onclickTableNoticias(record, rowIndex) }, // click row
                                //     };
                                // }}
                                // rowSelection={rowSelection}
                                columns={columnsMembros}
                                dataSource={membros}
                                scroll={{ x: 1100 }}
                                size="small"
                                title={(registros) => {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor:
                                                    propsState.theme === "light"
                                                        ? "#FAFAFA"
                                                        : "#1D1D1D",
                                                padding: 10,
                                                margin: -8,
                                                marginBottom: 0.5,
                                            }}
                                        >
                                            <Text>
                                                {`Mostrando: ${registros.length
                                                    } de ${refContagemDoFiltroMembros
                                                        .current.length === 0
                                                        ? membros.length
                                                        : refContagemDoFiltroMembros
                                                            .current.length
                                                    } num total de ${membros.length
                                                    } `}
                                            </Text>
                                        </div>
                                    );
                                }}
                                footer={(registros) => {
                                    return (
                                        <div
                                            style={{
                                                textAlign: "end",
                                            }}
                                        >
                                            <Text>
                                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                                {`Mostrando: ${registros.length
                                                    } de ${refContagemDoFiltroMembros
                                                        .current.length === 0
                                                        ? membros.length
                                                        : refContagemDoFiltroMembros
                                                            .current.length
                                                    } num total de ${membros.length
                                                    } `}
                                            </Text>
                                        </div>
                                    );
                                }}
                                pagination={{
                                    locale: { items_per_page: "" },
                                    showSizeChanger: true,
                                    defaultPageSize: 100,
                                    position: ["topRight", "bottomRight"],
                                    pageSizeOptions: ["10", "20", "50", "100"],
                                }}
                                locale={{
                                    emptyText: (
                                        <div style={{ marginTop: 10 }}>
                                            <InfoCircleOutlined
                                                style={{
                                                    fontSize: 30,
                                                    marginBottom: 10,
                                                }}
                                            />
                                            <br />
                                            <Text style={{ fontSize: 15 }}>
                                                Nenhum registro encontrado!
                                            </Text>
                                        </div>
                                    ),
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <Title
                                level={4}
                                style={{
                                    marginTop: 5,
                                    color:
                                        propsState.theme === "light"
                                            ? "#444"
                                            : "#fff",
                                }}
                            >
                                <InfoCircleOutlined
                                    style={{
                                        fontSize: 20,
                                        marginRight: 5,
                                    }}
                                />
                                Para incluir os membros cadastre primeiro os
                                dados do institucional
                            </Title>
                        </>
                    )}
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab="Usuários Painel Gestor"
                    key="434345"
                    style={{ padding: 30 }}
                >
                    {refDataContrato.current?.contrato_entidade || entidade ? (
                        <>
                            <Title level={3}>
                                Usuários da:{" "}
                                {
                                    refDataContrato.current?.contrato_entidade
                                        ?.nome
                                }
                            </Title>
                            <Row style={{ marginTop: 10 }}>
                                <Col
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            formUsuario.setFieldsValue({
                                                contratoEntidadePerfil: "",
                                                permissoes: [],
                                            });
                                            setUsuario(null);
                                            showModalUsuario();
                                        }}
                                    >
                                        <PlusOutlined />
                                        Vincular Usuário
                                    </Button>
                                </Col>
                            </Row>
                            <Table
                                className="marginTopTables"
                                rowKey={(record) => Number(record.usuario.id)}
                                loading={loadingTableUsuarios}
                                columns={columnsUsuarios}
                                dataSource={usuariosVinculados}
                                scroll={{ x: 1100 }}
                                size="small"
                                title={(registros) => {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor:
                                                    propsState.theme === "light"
                                                        ? "#FAFAFA"
                                                        : "#1D1D1D",
                                                padding: 10,
                                                margin: -8,
                                                marginBottom: 0.5,
                                            }}
                                        >
                                            <Text>
                                                {`Mostrando: ${registros.length
                                                    } de ${refContagemDoFiltroUsuarios
                                                        .current.length === 0
                                                        ? membros.length
                                                        : refContagemDoFiltroUsuarios
                                                            .current.length
                                                    } num total de ${membros.length
                                                    } `}
                                            </Text>
                                        </div>
                                    );
                                }}
                                footer={(registros) => {
                                    return (
                                        <div
                                            style={{
                                                textAlign: "end",
                                            }}
                                        >
                                            <Text>
                                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                                {`Mostrando: ${registros.length
                                                    } de ${refContagemDoFiltroMembros
                                                        .current.length === 0
                                                        ? membros.length
                                                        : refContagemDoFiltroMembros
                                                            .current.length
                                                    } num total de ${membros.length
                                                    } `}
                                            </Text>
                                        </div>
                                    );
                                }}
                                pagination={{
                                    locale: { items_per_page: "" },
                                    showSizeChanger: true,
                                    defaultPageSize: 100,
                                    position: ["topRight", "bottomRight"],
                                    pageSizeOptions: ["10", "20", "50", "100"],
                                }}
                                locale={{
                                    emptyText: (
                                        <div style={{ marginTop: 10 }}>
                                            <InfoCircleOutlined
                                                style={{
                                                    fontSize: 30,
                                                    marginBottom: 10,
                                                }}
                                            />
                                            <br />
                                            <Text style={{ fontSize: 15 }}>
                                                Nenhum registro encontrado!
                                            </Text>
                                        </div>
                                    ),
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <Title
                                level={4}
                                style={{
                                    marginTop: 5,
                                    color:
                                        propsState.theme === "light"
                                            ? "#444"
                                            : "#fff",
                                }}
                            >
                                <InfoCircleOutlined
                                    style={{
                                        fontSize: 20,
                                        marginRight: 5,
                                    }}
                                />
                                Para incluir os usuários cadastre primeiro os
                                dados do institucional
                            </Title>
                        </>
                    )}
                </Tabs.TabPane>
            </Tabs>

            <ModalImagem
                isOpen={isModalImagemOpen}
                handleCancel={handleImagemCancel}
                srcImage={imagemModal}
            />

            <ModalFormNoticia
                isEditarNoticia={isEditarNoticia}
                isModalNoticiaOpen={isModalNoticiaOpen}
                handleNoticiaCancel={handleNoticiaCancel}
                formNoticia={formNoticia}
                refFormNoticia={refFormNoticia}
                handleChangeImagensNoticia={handleChangeImagensNoticia}
                fileList={fileList}
                setImagemModal={setImagemModal}
                setIsModalImagemOpen={setIsModalImagemOpen}
                uploadImagensNoticia={uploadImagensNoticia}
                loadingCampoUpload={loadingCampoUpload}
                loadingPage={loadingSaveNoticia}
                onClickBotaoSalvarNoticia={onClickBotaoSalvarNoticia}
                noticiaDescricao={noticiaDescricao}
                onEditorContentChange={handleEditorContentChange}
                removerImagemNoticia={removerImagemNoticia}
                setFileList={setFileList}
            />

            <ModalFormMembro
                isModalMembroOpen={isModalMembroOpen}
                handleMembroCancel={handleMembroCancel}
                formMembro={formMembro}
                refFormMembro={refFormMembro}
                beforeUploadMembro={beforeUploadMembro}
                onClickBotaoSalvarMembro={onClickBotaoSalvarMembro}
                loadingPage={loadingSaveMembro}
                isEditarMembro={isEditarMembro}
                fotoMembro={fotoMembro}
            />

            <ModalFormUsuario
                isModalUsuarioOpen={isModalUsuarioOpen}
                handleUsuarioCancel={handleUsuarioCancel}
                usuarios={usuarios}
                usuariosVinculados={usuariosVinculados}
                loadingPage={loadingPage}
                entidade={refDataContrato.current?.contrato_entidade?.nome}
                vinculaUsuario={vinculaUsuario}
                usuario={usuario}
                setUsuario={setUsuario}
                formUsuario={formUsuario}
                refFormUsuario={refFormUsuario}
            />
        </>
    );
};

export default CreateModulo;
