import { getUsuario } from "../token/usuario";

export const validacaoPermissaoPorCargo = (permissao: Array<string>): boolean => {
    const token = getUsuario();

    if (token) {
        // sub é o uuid

        if (token?.cargo?.nome) {

            //se o usuario tem algumas dessas permissoes isso é o some
            return permissao.includes(token.cargo.nome);
            // const hasAllPermissions = permissao.some(permission => {
            // });

        }

        return false;
    }
    return true;
};
