export const cardLayout = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 23, offset: 0 }, // > 992
    xl: { span: 20, offset: 0 }, // > 1200
    xxl: { span: 20, offset: 0 }, // > 1600
};

export const inputCaptacaoNome = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 23, offset: 0 }, //>576
    md: { span: 23, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 8, offset: 0 }, // > 1200
    xxl: { span: 6, offset: 0 }, // > 1600
};
export const inputCaptacaoCelular = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 23, offset: 0 }, //>576
    md: { span: 23, offset: 0 }, // >768
    lg: { span: 11, offset: 1 }, // > 992
    xl: {span: 7, offset: 1 }, // > 1200
    xxl: { span: 5, offset: 1 }, // > 1600
};
export const inputCaptacaoCidade = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 23, offset: 0 }, //>576
    md: { span: 23, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: {span: 7, offset: 1 }, // > 1200
    xxl: { span: 5, offset: 1 }, // > 1600
};
export const inputCaptacaoMarca = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 23, offset: 0 }, //>576
    md: { span: 23, offset: 0 }, // >768
    lg: { span: 11, offset: 1 }, // > 992
    xl: { span: 8, offset: 0 }, // > 1200
    xxl: { span: 5, offset: 1 }, // > 1600
};
export const inputCaptacaoModelo = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 23, offset: 0 }, //>576
    md: { span: 23, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 7, offset: 1 }, // > 1200
    xxl: { span: 6, offset: 0 }, // > 1600
};
export const inputCaptacaoData = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 23, offset: 0 }, //>576
    md: { span: 23, offset: 0 }, // >768
    lg: { span: 11, offset: 1 }, // > 992
    xl: { span: 7, offset: 1 }, // > 1200
    xxl: { span: 5, offset: 1 }, // > 1600
};
export const inputCaptacaoContatado = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 23, offset: 0 }, //>576
    md: { span: 23, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 8, offset: 0 }, // > 1200
    xxl: { span: 5, offset: 1 }, // > 1600
};
export const inputCaptacaoDataContatado = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 23, offset: 0 }, //>576
    md: { span: 23, offset: 0 }, // >768
    lg: { span: 11, offset: 1 }, // > 992
    xl: { span: 7, offset: 1 }, // > 1200
    xxl: { span: 5, offset: 1 }, // > 1600
};
export const inputCaptacaoObservacao = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 23, offset: 0 }, //>576
    md: { span: 23, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 7, offset: 1 }, // > 1200
    xxl: { span: 6, offset: 0 }, // > 1600
};

export const botaoSalvar = {
    xs: { span: 17, offset: 6 }, //<576
    sm: { span: 12, offset: 12 }, //>576
    md: { span: 12, offset: 12 }, // >768
    lg: { span: 7, offset: 17 }, // > 992
    xl: { span: 7, offset: 17 }, // > 1200
    xxl: { span: 5, offset: 19 }, // > 1600
};
