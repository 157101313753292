import produce from 'immer';
import { UserInitialState } from './../../../interfaces/UserAdd';

export const INITIAL_STATE: UserInitialState = {
    nome: "app",
    formulario: null,
    current: 0,
    maxLenghtStep: 4,
    botaoAddContrato: false,
    spinnerSteps: false,
};

export default function login(state = INITIAL_STATE, action: ReturnType<any>) {
    return produce(state, draft => {
        switch (action.type) {
            case '@user/MENU_STEP': {
                draft.nome = action.payload.nome
                break;
            }
            case '@user/FORMULARIOREFUSERS': {

                const { validateFields, submit } = action.payload.formulario.current

                draft.formulario = { validateFields, submit }

                break;
            }
            case '@user/STEPPROXIMO': {
                draft.current = action.payload.current
                break;
            }
            case '@user/STEPANTERIOR': {
                draft.current = action.payload.current
                break;
            }
            case '@user/SETMAXLENGHTsTEP': {
                draft.maxLenghtStep = action.payload.maxLenghtStep
                break;
            }
            case '@user/SETBOTAOADDCONTRATO': {
                draft.botaoAddContrato = action.payload.botaoAddContrato
                break;
            }
            case '@user/SPINNERSTEPUSER': {
                draft.spinnerSteps = action.payload.spinnerSteps
                break;
            }
            default:
        }
    });
}
