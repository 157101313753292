interface User {
    ativo: boolean
    avatar: string
    cargo: { id: number, nome: string } //"Desenvolvedor(a)"
    cargoId: number
    celular: string
    created_at: string
    email: string
    id: number
    nome: string
    sistema: string
    updated_at: string
    uuid: string
    vendedor: boolean
}
export const getUsuario = (): User | undefined => {
    let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:token');
    let localUserData: any = null;
    if (typeof dadosLocalStorage == 'string') {
        localUserData = JSON.parse(dadosLocalStorage);
        return localUserData.user
    } else {
        return undefined
    }
}
