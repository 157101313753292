export const graficosDashboardLayout = {
  xs: { span: 24, offset: 0 }, //<576
  sm: { span: 12, offset: 0 }, //>576
  md: { span: 24, offset: 0 }, // >768
  lg: { span: 12, offset: 0 }, // > 992
  xl: { span: 12, offset: 0 }, // > 1200
  xxl: { span: 12, offset: 0 }, // > 1600
};

export const cardLayout = {
  xs: { span: 24, offset: 0 }, //<576
  sm: { span: 12, offset: 0 }, //>576
  md: { span: 24, offset: 0 }, // >768
  lg: { span: 12, offset: 0 }, // > 992
  xl: { span: 12, offset: 0 }, // > 1200
  xxl: { span: 12, offset: 0 }, // > 1600
};

export const cardImag = {
  xs: { span: 24, offset: 0 }, //<576
  sm: { span: 24, offset: 0 }, //>576
  md: { span: 24, offset: 0 }, // >768
  lg: { span: 12, offset: 6 }, // > 992
  xl: { span: 12, offset: 6 }, // > 1200
  xxl: { span: 12, offset: 7 }, // > 1600
};

export const LayoutLogoLogin = {
  xs: { span: 10, offset: 6 }, //<576
  sm: { span: 15, offset: 3 }, //>576
  md: { span: 16, offset: 4 }, // >768
  lg: { span: 16, offset: 5 }, // > 992
  xl: { span: 16, offset: 6 }, // > 1200
  xxl: { span: 16, offset: 6 }, // > 1600
};

export const esqueceuASenha = {
  xs: { offset: 11 },
  sm: { offset: 10 },
  md: { offset: 14 },
  lg: { offset: 15 },
  xl: { offset: 16 },
  xxl: { offset: 16 },
};

export const Layout1_4 = {
  xs: { span: 20, offset: 0 },
  sm: { span: 12, offset: 0 },
  md: { span: 10, offset: 0 },
  lg: { span: 10, offset: 0 },
  xl: { span: 6, offset: 0 },
  xxl: { span: 6, offset: 0 },
};

export const Layout2_5 = {
  xs: { span: 20, offset: 0 },
  sm: { span: 10, offset: 0 },
  md: { span: 8, offset: 0 },
  lg: { span: 8, offset: 0 },
  xl: { span: 8, offset: 0 },
  xxl: { span: 8, offset: 0 },
};

export const Layout3_5 = {
  xs: { span: 20, offset: 0 },
  sm: { span: 12, offset: 0 },
  md: { span: 10, offset: 0 },
  lg: { span: 10, offset: 0 },
  xl: { span: 10, offset: 0 },
  xxl: { span: 10, offset: 0 },
};

export const Layout1_9 = {
  xs: { span: 20, offset: 0 },
  sm: { span: 12, offset: 0 },
  md: { span: 12, offset: 0 },
  lg: { span: 21, offset: 0 },
  xl: { span: 16, offset: 0 },
  xxl: { span: 21, offset: 0 },
};

export const LayoutTextArea = {
  xs: { span: 14, offset: 0 },
  sm: { span: 14, offset: 0 },
  md: { span: 10, offset: 0 },
  lg: { span: 9, offset: 0 },
  xl: { span: 9, offset: 0 },
  xxl: { span: 8, offset: 0 },
}

export const formItemLayout = {
  labelCol: { span: 4 , offset: 4},
  wrapperCol: { span: 6 }
};
