import { takeLatest, put, all } from 'redux-saga/effects';

import { loadingLoginTrue, loadingLoginFalse } from './actions';

export function * signIn({payload}: ReturnType<any>) {

  yield put(loadingLoginTrue())

  yield put(loadingLoginFalse())

}

export default all([
  takeLatest('@login/LOGIN_IN_SISTEM-REQUEST', signIn),
]);
