import produce from 'immer';

const INITIAL_STATE = {
    positionMenu: '1',
    token: null,
    signed: false,
    loading: false,
    theme: 'light',
};

export default function global(state = INITIAL_STATE, action: ReturnType<any>) {
    return produce(state, draft => {
        switch (action.type) {
            case '@global/UPDATEPOSITIONMENU': {
                draft.positionMenu = action.payload;
                break;
            }
            case '@global/updateTheme': {
                draft.theme = action.payload;
                break;
            }
            default:
        }
    });
}

export function auth(state = INITIAL_STATE, action: ReturnType<any>) {
    return produce(state, draft => {
        switch (action.type) {
            case '@auth/SIGN_IN_REQUEST': {
                draft.loading = true;
                break;
            }
            case '@auth/SIGN_IN_SUCCESS': {
                draft.token = action.payload.token;
                draft.signed = true;
                draft.loading = false;
                break;
            }
            case '@auth/SIGN_FAILURE': {
                draft.loading = false;
                break;
            }
            case '@auth/SIGN_OUT': {
                draft.token = null;
                draft.signed = false;
                break;
            }
            default:
        }
    });
}
