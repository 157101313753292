export const cardLayout = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};

export const rowColNomeAndTipo = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};

export const inputNome = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 23, offset: 0 }, // > 992
    xl: { span: 10, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};

export const inputSelectTipoDaAssociacao = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 23, offset: 0 }, // > 992
    xl: { span: 10, offset: 1 }, // > 1200
    xxl: { span: 10, offset: 1 }, // > 1600
};

export const botaoSalvar = {
    xs: { span: 16, offset: 7 }, //<576
    sm: { span: 8, offset: 15 }, //>576
    md: { span: 10, offset: 13 }, // >768
    lg: { span: 7, offset: 16 }, // > 992
    xl: { span: 5, offset: 19 }, // > 1200
    xxl: { span: 5, offset: 19 }, // > 1600
};

export const inputSelectEstadosCity = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 23, offset: 0 }, // > 992
    xl: { span: 10, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};

export const inputSelectCidades = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 23, offset: 0 }, // > 992
    xl: { span: 10, offset: 1 }, // > 1200
    xxl: { span: 10, offset: 1 }, // > 1600
};


