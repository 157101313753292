import React, { } from 'react';
import { Tabs, Typography } from 'antd';

import { useTabAcessos } from './useTabAcessos';
import RelUltimoAcesso from './Tabs/UltimoAcesso';
import RelAcessosUsuario from './Tabs/AcessosUsuario';

const { Text } = Typography;

const RelAcessos: React.FC = () => {

    const {
        setActiveKeyTabs,
        activeKeyTabs,
        loading,
        clickNavigateTab,
        refFiltersGlobal
    } = useTabAcessos();


    return (
        <>
            <Tabs
                type="card"
                activeKey={activeKeyTabs}
                style={{
                    marginBottom: 20,
                }}
                onChange={(e) => {
                    setActiveKeyTabs(e)
                }}
                className='tab-municipios'
            >
                <Tabs.TabPane
                    tab="Últimos acessos"
                    key="RelUltimoAcesso"
                    style={{
                        borderRadius: 4
                    }}
                >
                    <RelUltimoAcesso
                        activeKeyTabs={activeKeyTabs}
                        clickNavigateTab={clickNavigateTab}
                        refFiltersGlobal={refFiltersGlobal}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab="Acessos usuário"
                    key="RelAcessosUsuario"
                    style={{
                        borderRadius: 4
                    }}
                >
                    <RelAcessosUsuario
                        activeKeyTabs={activeKeyTabs}
                        clickNavigateTab={clickNavigateTab}
                        refFiltersGlobal={refFiltersGlobal}
                    />
                </Tabs.TabPane>
            </Tabs>
        </>
    );
}

export default RelAcessos;
