import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Breadcrumb, Divider, Row, Col, Typography, Form, Input, Spin, Button, notification, Select, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import {
    cardLayout,
    rowColNomeAndTipo,
    inputNome,
    inputSelectTipo,
} from './GridStyle';
import Icon, { CloseSquareOutlined } from '@ant-design/icons';
import { FaSitemap, FaTrash } from 'react-icons/fa';
import { HiPencilSquare } from 'react-icons/hi2';
import axios from 'axios';
import {
    urlBuscaEstados, urlAssocCreate, urlAssocUpdate, urlUsersStatusAtivos, urlContratoUsuarios, urlContratoAtivos, urlCargo, urlPesquisa
} from '../../../../../services/request/urls';
import './style.css';

import { getToken } from '../../../../../services/token';
import { removeAcento } from '../../../../../utils/RemoveAcentos';

import { ReactComponent as FaPenSquare } from '../../../../../assets/imagsIconesModulos/pen-to-square.svg';

const { Title, Text } = Typography;

const IconHand = (props: any) => <Icon {...props} component={FaSitemap} ></Icon>
const IconLixo = (props: any) => <Icon {...props} component={FaTrash} ></Icon>
const IconFaPenSquare = (props: any) => <Icon {...props} component={FaPenSquare} ></Icon>

interface interfaceUsuarios {
    id: number,
    nome: string,
    cargo: {
        id: number,
        nome: string
    }
};

interface interfaceDadosCidades {
    id: number,
    ibge_id_antigo: number,
    nome: string,
    uuid: string,
    microrregiao: {
        id: number,
        uuid: string,
        mesorregiao: {
            id: number,
            uuid: string,
            estado: {
                id: number,
                sigla: string,
                regiao_id: number,
                nome: string,
                populacao: number,
                uuid: string
            },
            estado_id: number,
            nome: string
        },
        mesorregiao_id: number,
        nome: string
    },
    microrregiao_id: number,
    latitude: string,
    longitude: string,
    populacao: number
};


const PesquisaCreateOrUpdate: React.FC = () => {

    const refForm = useRef<any>(null);

    const refBotaoSeleContrato = useRef<any>(null);
    const refBotaoSeleUsuario = useRef<any>(null);
    const refBotaoSeleCargos = useRef<any>(null);
    const refBotaoSeleEstados = useRef<any>(null);

    const refFormSelectUsuario = useRef<any>(null);
    const refFormSelectContrato = useRef<any>(null);
    const refFormSelectCargos = useRef<any>(null);
    const refFormSelectEstados = useRef<any>(null);

    const history = useHistory();

    const refAllUsersAtivos = useRef<any>(null);

    const [dadosTabelaState, setDadosTabelaState] = useState<Array<interfaceDadosCidades>>([]);
    const [valuSelecionadoTipo, setValuSelecionadoTipo] = useState<string>('');

    const [dadosUsuariosList, setDadosUsuriosList] = useState<Array<interfaceUsuarios>>([]);
    const [dadosContratosList, setDadosContratosList] = useState<any>([]);

    const [dadosCargosList, setDadosCargosList] = useState<any>([]);
    const [dadosEstadosList, setDadosEstadosList] = useState<any>([]);

    const [isSalvarOrEditar, setIsSalvarOrEditar] = useState(true);
    const [loadingFormAdd, setLoadingFormAdd] = useState(false);
    const [formValues, setFormValues] = useState<Array<any>>([
        { name: '' },
    ]);

    //para poder cancelar a requisição
    const signal = useRef(axios.CancelToken.source());

    //executa apenas quando desmonta o component
    useEffect(() => () => {
        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:PesquisaSatisfacao');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@GovFacil:PesquisaSatisfacao');
        }

        //remover as requisiçoes quando não terminou
        signal.current.cancel("Requisicao cancelada!")
    }, []);

    //setando dados das cidades no selected
    const buscaAllUsuarioForSelected = useCallback(() => {

        const buscaEstados = async () => {
            try {
                let result = await axios.get(urlUsersStatusAtivos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.current.token
                    });

                refAllUsersAtivos.current = result.data
                setDadosUsuriosList(result.data);

                return true;
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar usuários!',
                    });
                }

                return false;
            }
        }

        return buscaEstados();

    }, []);


    //setando dados das cidades no selected
    const buscaUsuariosDoContrato = useCallback((id) => {

        const buscaEstados = async () => {
            try {

                let result = await axios.get(urlContratoUsuarios + '/' + id,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.current.token
                    });


                setDadosUsuriosList(result.data);

                // return true;
            } catch (error) {

                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Usuario do contrato!',
                    });
                }
                // return false;
            }
        }

        buscaEstados();

    }, []);

    //setando dados das cidades no selected
    const buscaContratosESetSelected = useCallback(() => {
        // setLoadingUsuariosSelected(true);
        const buscaEstados = async () => {
            try {
                let result = await axios.get(urlContratoAtivos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.current.token
                    });
                setDadosContratosList(result.data);

                return true;

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Contratos!',
                    });
                }
                return false
            }
        }

        return buscaEstados();

    }, []);

    //setando dados das cidades no selected
    const buscaCargosESetSelected = useCallback(() => {
        // setLoadingUsuariosSelected(true);
        const requisicao = async () => {
            try {
                let result = await axios.get(urlCargo,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.current.token
                    });
                setDadosCargosList(result.data);

                return true;

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Cargos!',
                    });
                }
                return false
            }
        }

        return requisicao();

    }, []);

    //setando dados das cidades no selected
    const buscaEstadosESetSelected = useCallback(() => {
        setLoadingFormAdd(true);
        const requisicao = async () => {
            try {
                let resultCidade = await axios.get(urlBuscaEstados, {
                    headers: { 'Authorization': 'Bearer ' + getToken() }
                });
                setDadosEstadosList(resultCidade.data);
                setLoadingFormAdd(false);
            } catch (error) {
                setLoadingFormAdd(false);

                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Estados!',
                    });
                }
            }
        }

        requisicao();

    }, []);

    useEffect((): void => {
        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:PesquisaSatisfacao');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        buscaAllUsuarioForSelected();
        buscaContratosESetSelected();
        buscaCargosESetSelected();
        buscaEstadosESetSelected();
        if (localUserData) {
            // buscaEstadosAndCityESetSelected();

            // formRef.current.setFieldsValue(
            //     {
            //         nome: localUserData?.nome ? localUserData.nome : undefined,
            //         tipo: localUserData?.tipo ? localUserData.tipo : undefined,
            //         observacao: localUserData?.observacao ? localUserData.observacao : undefined,
            //         modalidade: localUserData?.modalidade?.id ? localUserData.modalidade.id : undefined,
            //     }
            // );
            // setIsSalvarOrEditar(false);

        }
    }, []);

    const onClickVoltarConfigOfSystem = useCallback((): void => {
        history.push("/pesquisa-satisfacao");
    }, [history]);

    const onSelectTipo = useCallback((val): void => {

        setValuSelecionadoTipo(val)
        refForm.current.setFieldsValue(
            {
                usuarios: undefined,
                contratos: undefined,
                cargos: undefined,
                estados: undefined,
            })

    }, []);

    const onClickAddTodosSeleContrato = useCallback(() => {

        let arrayIds: any = [];
        dadosContratosList.forEach((val: any) => {
            arrayIds.push(val.id);
        });
        refForm.current.setFieldsValue(
            { contratos: arrayIds }
        );

        refBotaoSeleContrato.current?.blur();
        refFormSelectContrato.current?.blur();

    }, [dadosContratosList]);

    const onChangeContratoDoUsuario = useCallback((val: number | string): void => {

        refForm.current.setFieldsValue(
            {
                usuarios: undefined,
            })


        if (val === 'Todos') {
            setDadosUsuriosList(refAllUsersAtivos.current)
        } else {
            buscaUsuariosDoContrato(val)
        }

    }, []);

    const onClickAddTodosSeleUsuario = useCallback(() => {

        let arrayIds: any = [];
        dadosUsuariosList.forEach((val: any) => {
            arrayIds.push(val.id);
        });
        refForm.current.setFieldsValue(
            { usuarios: arrayIds }
        );

        refBotaoSeleUsuario.current?.blur();
        refFormSelectUsuario.current?.blur();

    }, [dadosUsuariosList]);

    const onClickAddTodosSeleCargos = useCallback(() => {

        let arrayIds: any = [];
        dadosCargosList.forEach((val: any) => {
            arrayIds.push(val.id);
        });
        refForm.current.setFieldsValue(
            { cargos: arrayIds }
        );

        refBotaoSeleCargos.current?.blur();
        refFormSelectCargos.current?.blur();

    }, [dadosCargosList]);

    const onClickAddTodosSeleEstados = useCallback(() => {

        let arrayIds: any = [];
        dadosEstadosList.forEach((val: any) => {
            arrayIds.push(val.id);
        });
        refForm.current.setFieldsValue(
            { estados: arrayIds }
        );

        refBotaoSeleEstados.current?.blur();
        refFormSelectEstados.current?.blur();

    }, [dadosEstadosList]);

    //inputs Dynamics -----------------------------------------------

    const handleChange = useCallback((i, tipo, value) => {
        let newFormValues = [...formValues];

        if (tipo === 'input') {
            newFormValues[i]['pergunta'] = value;
        }

        if (tipo === 'select') {
            newFormValues[i]['tipo'] = value;
        }

        setFormValues(newFormValues);
    }, [formValues]);

    const addFormFields = useCallback(() => {
        setFormValues([...formValues, { name: `perguntadd` }])
    }, [formValues]);

    const removeFormFields = useCallback((i: number) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }, [formValues]);

    const onClickBotaoSalvar = useCallback((): void => {

        const salvarDados = async (values: any) => {
            try {

                let tipo = false
                let pergunta = false
                formValues.forEach(element => {
                    delete element.name
                    if (!element?.pergunta || element.pergunta.trim() === '') {
                        pergunta = true
                    }
                    if (!element?.tipo || element.tipo.trim() === '') {
                        tipo = true
                    }
                });
                if (pergunta) {
                    notification.error({
                        message: 'Por favor',
                        description:
                            'Preencha todas as perguntas!',
                    });
                    return false
                }
                if (tipo) {
                    notification.error({
                        message: 'Por favor',
                        description:
                            'Selecione todos os tipos!',
                    });
                    return false
                }

                values.perguntas = formValues

                setLoadingFormAdd(true);
                await axios.post(urlPesquisa, values,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                notification.success({
                    message: 'Sucesso',
                    description:
                        'Sua pesquisa foi criada com sucesso!',
                });

                setLoadingFormAdd(false);
                onClickVoltarConfigOfSystem();
            } catch (error) {

                let msgErro: any = (error as Error);
                setLoadingFormAdd(false);
                notification.error({
                    message: 'Erro',
                    description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',

                });
            }
        }

        refForm.current.validateFields()
            .then((values: any) => {

                if (values.selecioneTipo === 'usuario') {

                    let obri: any = []

                    values.usuarios.forEach((element: number) => {
                        obri.push({ id: element })
                    });

                    values.usuarios = obri;

                } else if (values.selecioneTipo === 'contrato') {
                    let obri: any = []
                    values.contratos.forEach((element: number) => {
                        obri.push({ id: element })
                    });

                    values.contratos = obri;
                } else if (values.selecioneTipo === 'cargos') {
                    let obri: any = []
                    values.cargos.forEach((element: number) => {
                        obri.push({ id: element })
                    });

                    values.cargos = obri;
                } else if (values.selecioneTipo === 'estados') {
                    let obri: any = []
                    values.estados.forEach((element: number) => {
                        obri.push({ id: element })
                    });

                    values.estados = obri;
                }

                // salvar
                salvarDados(values);
            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });

    }, [formValues]);

    const onClickBotaoEditar = useCallback((): void => {

        const EditarDados = async (valores: any) => {
            setLoadingFormAdd(true);
            let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:associacao');
            let localUserData: any = null;
            if (typeof dadosLocalStorage == 'string') {
                localUserData = JSON.parse(dadosLocalStorage);
            }
            try {

                valores.id = localUserData.id

                let resultCidade = await axios.put(urlAssocUpdate, valores,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram atualizados com sucesso!',
                });
                setLoadingFormAdd(false);
                history.push("/associacao");
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingFormAdd(false);
                notification.error({
                    message: 'Erro',
                    description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                });
            }
        }
        // EditarDados(values);

    }, [dadosTabelaState]);

    return (
        <>
            <Spin spinning={loadingFormAdd}>
                <Row>
                    <Col {...cardLayout}>
                        <Title level={3}><IconFaPenSquare style={{ fontSize: 35, marginRight: 10 }} />  {isSalvarOrEditar ? "Criar" : "Editar"} Pesquisa</Title>
                        <Breadcrumb>
                            <Breadcrumb.Item><a onClick={onClickVoltarConfigOfSystem}>Listar Pesquisas</a></Breadcrumb.Item>
                            <Breadcrumb.Item> {isSalvarOrEditar ? "Criar" : "Editar"} Pesquisa</Breadcrumb.Item>
                        </Breadcrumb>
                        <Divider />
                    </Col>
                </Row>
                <Form
                    ref={refForm}
                    name="formCreateOrUpdatePesquisa"
                    layout="vertical"
                    onFinishFailed={() => { }}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="nome"
                                label="Nome da pesquisa"
                                style={{
                                    margin: 5,
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor digite o nome da pesquisa!",
                                    },
                                ]}
                            >
                                <Input
                                    style={{
                                        // width: '100%',
                                        // margin: 5
                                    }}
                                    placeholder="Digite a pesquisa"
                                />
                            </Form.Item>
                        </Col>
                        <Col {...inputNome}>
                            <Form.Item
                                name="selecioneTipo"
                                label="Selecione o tipo"
                                style={{
                                    margin: 5,
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor selecione o tipo!",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Selecione..."
                                    onChange={onSelectTipo}
                                    // disabled={disabledSelectedEstado}
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                >
                                    <Select.Option
                                        value={'todos'}
                                        key={'Todos'}
                                    >
                                        Todos
                                    </Select.Option>
                                    <Select.Option
                                        value={'contrato'}
                                        key={'Contrato'}
                                    >
                                        Contrato
                                    </Select.Option>
                                    <Select.Option
                                        value={'usuario'}
                                        key={'Usuario'}
                                    >
                                        Usuário
                                    </Select.Option>
                                    <Select.Option
                                        value={'cargos'}
                                        key={'Cargos'}
                                    >
                                        Cargos
                                    </Select.Option>
                                    <Select.Option
                                        value={'estados'}
                                        key={'Estados'}
                                    >
                                        Estados
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        {
                            valuSelecionadoTipo == 'todos' ? <></> :
                                valuSelecionadoTipo == 'contrato' ?
                                    <>
                                        <Col {...inputNome}>
                                            <Form.Item
                                                name="contratos"
                                                label="Selecione um contrato"
                                                style={{
                                                    margin: 5,
                                                }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Por favor selecione um contrato!",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    ref={refFormSelectContrato}
                                                    // loading={loadingUsuariosSelected}
                                                    // notFoundContent={loadingUsuariosSelected ? <Spin size="small" /> : null}
                                                    optionFilterProp="children"
                                                    placeholder="Selecione..."
                                                    mode="multiple"
                                                    filterOption={(input: any, option: any) => {
                                                        let textDigit = removeAcento(input)
                                                        let listCidade = removeAcento(option?.children);
                                                        return listCidade.indexOf(textDigit) >= 0
                                                    }}
                                                    dropdownRender={menu => (
                                                        <div>

                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                <Button
                                                                    ref={refBotaoSeleContrato}
                                                                    block
                                                                    onClick={onClickAddTodosSeleContrato}
                                                                    type="dashed"
                                                                >Todos</Button>
                                                            </div>
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            {menu}
                                                        </div>
                                                    )}
                                                >
                                                    {
                                                        dadosContratosList.map((item: any) => {
                                                            return (
                                                                <Select.Option
                                                                    value={item.id}
                                                                    key={item.id}
                                                                >
                                                                    {
                                                                        item?.poder?.nome ?
                                                                            item.nome + ' - ' + item?.poder?.nome
                                                                            :
                                                                            item.nome
                                                                    }
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </>
                                    :
                                    valuSelecionadoTipo == 'usuario' ?
                                        <>
                                            <Col {...inputNome}>
                                                <Form.Item
                                                    name="contratoDoUsuario"
                                                    label="Selecione um contrato"
                                                    style={{
                                                        margin: 5,
                                                    }}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "Por favor selecione um contrato!",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        // ref={refFormSelectContrato}
                                                        // loading={loadingUsuariosSelected}
                                                        // notFoundContent={loadingUsuariosSelected ? <Spin size="small" /> : null}
                                                        optionFilterProp="children"
                                                        placeholder="Selecione..."
                                                        // mode="multiple"
                                                        onChange={onChangeContratoDoUsuario}
                                                        filterOption={(input: any, option: any) => {
                                                            let textDigit = removeAcento(input)
                                                            let listCidade = removeAcento(option?.children);
                                                            return listCidade.indexOf(textDigit) >= 0
                                                        }}
                                                    >
                                                        <Select.Option
                                                            value={'Todos'}
                                                            key={'toodos'}
                                                        >
                                                            {'Todos'}
                                                        </Select.Option>
                                                        {
                                                            dadosContratosList.map((item: any) => {
                                                                return (
                                                                    <Select.Option
                                                                        value={item.id}
                                                                        key={item.id}
                                                                    >
                                                                        {
                                                                            item?.poder?.nome ?
                                                                                item.nome + ' - ' + item?.poder?.nome
                                                                                :
                                                                                item.nome
                                                                        }
                                                                    </Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col {...inputNome}>
                                                <Form.Item
                                                    name="usuarios"
                                                    label="Selecione um usuário"
                                                    style={{
                                                        margin: 5,
                                                    }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Por favor selecione um usuário!",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        ref={refFormSelectUsuario}
                                                        // loading={loadingUsuariosSelected}
                                                        // notFoundContent={loadingUsuariosSelected ? <Spin size="small" /> : null}
                                                        optionFilterProp="children"
                                                        placeholder="Selecione..."
                                                        mode="multiple"
                                                        filterOption={(input: any, option: any) => {
                                                            let textDigit = removeAcento(input)
                                                            let listCidade = removeAcento(option?.children);
                                                            return listCidade.indexOf(textDigit) >= 0
                                                        }}
                                                        dropdownRender={menu => (
                                                            <div>

                                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                    <Button
                                                                        ref={refBotaoSeleUsuario}
                                                                        block
                                                                        onClick={onClickAddTodosSeleUsuario}
                                                                        type="dashed"
                                                                    >Todos</Button>
                                                                </div>
                                                                <Divider style={{ margin: '4px 0' }} />
                                                                {menu}
                                                            </div>
                                                        )}
                                                    >
                                                        {
                                                            dadosUsuariosList.map((item) => {
                                                                return (
                                                                    <Select.Option
                                                                        value={item.id}
                                                                        key={item.id}
                                                                    >
                                                                        {item.nome + ' - ' + item?.cargo?.nome}
                                                                    </Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </>
                                        : valuSelecionadoTipo == 'cargos' ?
                                            <>
                                                <Col {...inputNome}>
                                                    <Form.Item
                                                        name="cargos"
                                                        label="Selecione um cargo"
                                                        style={{
                                                            margin: 5,
                                                        }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Por favor selecione um cargo!",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            ref={refFormSelectCargos}
                                                            // loading={loadingUsuariosSelected}
                                                            // notFoundContent={loadingUsuariosSelected ? <Spin size="small" /> : null}
                                                            optionFilterProp="children"
                                                            placeholder="Selecione..."
                                                            mode="multiple"
                                                            filterOption={(input: any, option: any) => {
                                                                let textDigit = removeAcento(input)
                                                                let listCidade = removeAcento(option?.children);
                                                                return listCidade.indexOf(textDigit) >= 0
                                                            }}
                                                            dropdownRender={menu => (
                                                                <div>

                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <Button
                                                                            ref={refBotaoSeleCargos}
                                                                            block
                                                                            onClick={onClickAddTodosSeleCargos}
                                                                            type="dashed"
                                                                        >Todos</Button>
                                                                    </div>
                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                    {menu}
                                                                </div>
                                                            )}
                                                        >
                                                            {
                                                                dadosCargosList.map((item: any) => {
                                                                    return (
                                                                        <Select.Option
                                                                            value={item.id}
                                                                            key={item.id}
                                                                        >
                                                                            {item.nome}
                                                                        </Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </>
                                            : valuSelecionadoTipo == 'estados' ?
                                                <>
                                                    <Col {...inputNome}>
                                                        <Form.Item
                                                            name="estados"
                                                            label="Selecione um estado"
                                                            style={{
                                                                margin: 5,
                                                            }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Por favor selecione um estado!",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                ref={refFormSelectEstados}
                                                                // loading={loadingUsuariosSelected}
                                                                // notFoundContent={loadingUsuariosSelected ? <Spin size="small" /> : null}
                                                                optionFilterProp="children"
                                                                placeholder="Selecione..."
                                                                mode="multiple"
                                                                filterOption={(input: any, option: any) => {
                                                                    let textDigit = removeAcento(input)
                                                                    let listCidade = removeAcento(option?.children);
                                                                    return listCidade.indexOf(textDigit) >= 0
                                                                }}
                                                                dropdownRender={menu => (
                                                                    <div>

                                                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                            <Button
                                                                                ref={refBotaoSeleEstados}
                                                                                block
                                                                                onClick={onClickAddTodosSeleEstados}
                                                                                type="dashed"
                                                                            >Todos</Button>
                                                                        </div>
                                                                        <Divider style={{ margin: '4px 0' }} />
                                                                        {menu}
                                                                    </div>
                                                                )}
                                                            >
                                                                {
                                                                    dadosEstadosList.map((item: any) => {
                                                                        return (
                                                                            <Select.Option
                                                                                value={item.id}
                                                                                key={item.id}
                                                                            >
                                                                                {item.nome}
                                                                            </Select.Option>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                                : <></>
                        }
                    </Row>

                    <Divider className="dividerPermisaoDoApp" />
                    <Row
                        style={{
                            // marginTop: 20,
                            marginBottom: 20,
                            marginLeft: 5
                        }}
                    >
                        <Col span={24}>
                            <Button type="primary" className='botaoAdicionarPesquisa_ehvfwqeuygfvq' onClick={addFormFields} >
                                Adicionar Pergunta
                            </Button>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginBottom: 24,
                        }}
                    >
                        {
                            formValues.map((element, index) => {
                                return (
                                    <React.Fragment
                                        key={index}
                                    >
                                        <Col {...inputNome}>
                                            <div
                                                style={{
                                                    margin: 5,
                                                }}
                                            >
                                                <div
                                                    style={{ marginBottom: 8 }}
                                                >
                                                    <Text>{'Pergunta' + (index + 1)}</Text>
                                                </div>
                                                <Input
                                                    style={{
                                                        width: '100%',
                                                        // margin: 5
                                                    }}
                                                    value={element?.pergunta}
                                                    placeholder="Digite a Pergunta"
                                                    onChange={(e) => { handleChange(index, 'input', e.target.value) }}
                                                />
                                            </div>
                                        </Col>
                                        <Col {...inputNome}>
                                            <div
                                                style={{
                                                    margin: 5,
                                                }}>
                                                <div
                                                    style={{ marginBottom: 8 }}
                                                >
                                                    <Text>{'Tipo'}</Text>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                    }}
                                                >
                                                    <Select
                                                        // showSearch
                                                        placeholder="Selecione..."
                                                        style={{
                                                            width: '100%',
                                                            // margin: 5
                                                        }}
                                                        value={element?.tipo}
                                                        onSelect={(val: any) => {
                                                            handleChange(index, 'select', val)
                                                        }}
                                                        optionFilterProp="children"
                                                        filterOption={(input: any, option: any) => {
                                                            let textDigit = removeAcento(input)
                                                            let listCidade = removeAcento(option?.children);
                                                            return listCidade.indexOf(textDigit) >= 0
                                                        }}
                                                    >
                                                        <Select.Option
                                                            value={'input'}
                                                        >
                                                            {'Input'}
                                                        </Select.Option>
                                                        <Select.Option
                                                            value={'star'}
                                                        >
                                                            {'Star'}
                                                        </Select.Option>
                                                    </Select>

                                                    <Tooltip title="Remover">
                                                        <Button
                                                            style={{
                                                                // marginRight: 5
                                                                marginLeft: 5
                                                            }} type="primary" danger shape="circle"
                                                            onClick={() => { removeFormFields(index) }}
                                                            icon={<IconLixo />}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </Col>
                                    </React.Fragment>
                                )
                            })
                        }
                    </Row>
                    <Divider className="dividerPermisaoDoApp" />
                    <Row
                        style={{
                            // marginTop: -24
                        }}
                    >
                        <Col span={24}>
                            <Form.Item
                                name="observacao"
                                label="Observações"
                                style={{
                                    margin: 5,
                                }}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row
                    style={{
                        marginTop: 10
                    }}
                >
                    <Col span={24}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                // justifyContent: 'flex-end',
                                width: '100%',
                                padding: 5
                            }}
                        >
                            <Button style={{ marginRight: 10 }} onClick={onClickVoltarConfigOfSystem} >
                                Cancelar
                            </Button>
                            {isSalvarOrEditar ?
                                <Button disabled={formValues.length === 0} type="primary" onClick={onClickBotaoSalvar} >
                                    Salvar
                                </Button>
                                :
                                <Button type="primary" onClick={onClickBotaoEditar} >
                                    Atualizar
                                </Button>
                            }
                        </div>
                    </Col>
                </Row>
            </Spin>

        </>
    );
}


export default PesquisaCreateOrUpdate;
