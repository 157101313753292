import React, { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';
import ListUserPorContrato from './components/ListUserPorContrato';
import ListUsuarios from './components/ListUsuario';
import './styles.css'

// const useQuery = () => {
//     return new URLSearchParams(useLocation().search);
// }


const Usuarios: React.FC = () => {

    // const queryParans = useQuery();

    const [valorLsStorage, setValorLsStorage] = useState<null | { nome: string, id: number }>(null);

    //executa apenas ao iniciar o component
    useEffect(() => {

        // let idParam = queryParans.get("id")

        // if (idParam && parseInt(idParam)) {

        // } else {
        // }

        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:ListUsersContrato');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }


        if (localUserData) {
            setValorLsStorage(localUserData)
        }


    }, []);

    return (
        <>
            {!valorLsStorage ?
                <ListUsuarios />
                :
                <ListUserPorContrato />
            }

        </>
    );
}

export default Usuarios;
