import React, { useEffect } from 'react';
import { Card, Row, Col } from 'antd';
import { Chart, Util } from '@antv/g2';
import './index.css';

import { graficosDashboardLayout, cardLayout, cardImag } from './GridStyle';

// @ts-ignore
import ImagemLogo from '../../assets/logo.png';

const data = [
    { genre: 'Janeiro', valor: 63.883 },
    { genre: 'Fevereiro', valor: 94.405 },
    { genre: 'Março', valor: 77.523 },
    { genre: 'Abril', valor: 54.811 },
    { genre: 'Maio', valor: 51.988 },
    { genre: 'Junho', valor: 40.074 },
    { genre: 'Julho', valor: 37.423 },
    { genre: 'Agosto', valor: 38.067 },
    { genre: 'Setembro', valor: 34.232 },
];

const dataPie = [
    { type: 'Índices Constitucionais', value: 8892, percent: 20.46 },
    { type: 'CAUC', value: 8193, percent: 18.85 },
    { type: 'Receitas Constitucionais', value: 5618, percent: 12.93 },
    { type: 'Mensagens', value: 4572, percent: 10.52 },
    { type: 'Certidões', value: 2888, percent: 5.89 },
];

const largura = window.innerWidth;
// const altura = window.innerHeight;

const Dashboard: React.FC = () => {

    useEffect(() => {
        let larguraDashBoard = undefined;
        let autoFitDashBoard = true;
        switch (true) {
            case (largura < 768 && largura >= 700):
                larguraDashBoard = 300;
                autoFitDashBoard = false;
                break;
            case (largura < 700 && largura >= 576):
                larguraDashBoard = 250;
                autoFitDashBoard = false;
                break;
            case (largura < 576 && largura >= 435):
                larguraDashBoard = 350;
                autoFitDashBoard = false;
                break;
            case (largura < 435 && largura >= 345):
                larguraDashBoard = 270;
                autoFitDashBoard = false;
                break;
            case (largura < 345 && largura >= 0):
                larguraDashBoard = 230;
                autoFitDashBoard = false;
                break;
            default:
                larguraDashBoard = undefined;
                autoFitDashBoard = true;
        }

        //grafico de barras
        // const chart = new Chart({
        //     container: 'charts', // ID
        //     autoFit: autoFitDashBoard,
        //     width: larguraDashBoard,
        //     height: 300,
        // });

        // chart.data(data);

        // chart.interval().position('genre*valor');

        // chart.render();
        //fin de grafico de barras

        //grafico Pie
        // const chartPie = new Chart({
        //     container: 'chartPie',
        //     autoFit: autoFitDashBoard,
        //     width: larguraDashBoard,
        //     height: 295,
        // });
        // chartPie.data(dataPie);

        // chartPie.coordinate('theta', {
        //     radius: 0.75
        // });
        // chartPie.tooltip({
        //     showMarkers: false,
        //     showTitle: false
        // });

        // const interval = chartPie
        //     .interval()
        //     .adjust('stack')
        //     .position('value') //valor aparece no tooltip
        //     .color('type', ['#063d8a', '#1770d6', '#47abfc', '#38c060', '#077027']) // cor do pie aleatoria ou ,[array de cores]
        //     .style({ opacity: 0.4 })
        //     .state({
        //         active: {
        //             style: (element) => {
        //                 const shape = element.shape;
        //                 return {
        //                     matrix: Util.zoom(shape, 1.1),
        //                 }
        //             }
        //         },
        //     })
        //     .label('percent', (val) => {
        //         return {
        //             offset: -30,
        //             style: {
        //                 fill: 'white',
        //                 fontSize: 14,
        //                 shadowBlur: 1,
        //                 shadowColor: '#FFF',
        //             },
        //             content: (obj) => {
        //                 return obj.percent + '%';
        //             },
        //         };
        //     });

        // chartPie.interaction('element-single-selected');

        // chartPie.render();
        //fim grafico de pie
    }, []);

    const getNome = (): string => {
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:token');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
            return localUserData.user.nome
        } else {
            return ''
        }
    }

    return (
        <>
            <Row>
                <Col {...cardLayout}>
                    <Card title={`Bem Vindo ${getNome()}!`} bordered={false}>

                    </Card>
                </Col>
            </Row>
            <Row>
                <Col {...cardImag}>
                    <img className="imgdashboardGovfacil" src={ImagemLogo}></img>
                </Col>
            </Row>
            {/* <Row gutter={[12, 24]}>
                <Col {...graficosDashboardLayout}>
                    <Card title="Acessos ao aplicativo no ano de 2020" bordered={false}>
                        <div className="charts" id="charts">
                        </div>
                    </Card>
                </Col>
                <Col {...graficosDashboardLayout}>
                    <Card title="Modulos mais acessados no ano de 2020" bordered={false}>
                        <div className="chartPie" id="chartPie">
                        </div>
                    </Card>
                </Col>
            </Row> */}
        </>

    );
}

export default Dashboard;
