export const cadastroModalCampoNome = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 24, offset: 0 }, // > 992
    xl: { span: 24, offset: 0 }, // > 1200
    xxl: { span: 24, offset: 0 }, // > 1600
};

export const radioOptionSystemAddUser = {
    xs: { span: 23, offset: 1 },
    sm: { span: 19, offset: 0 },
    md: { span: 18, offset: 0 },
    lg: { span: 13, offset: 0 },
    xl: { span: 9, offset: 0 },
    xxl: { span: 6, offset: 0 },
};

export const radioAtivoserAdd = {
    xs: { span: 23, offset: 1 },
    sm: { span: 6, offset: 0 },
    md: { span: 7, offset: 0 },
    lg: { span: 5, offset: 0 },
    xl: { span: 4, offset: 0 },
    xxl: { span: 3, offset: 0 },
};

export const nameUserAdd = {
    xs: { span: 23, offset: 1 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 11, offset: 0 },
    xxl: { span: 7, offset: 0 },
};

export const celularUserAdd = {
    xs: { span: 23, offset: 1 },
    sm: { span: 10, offset: 0 },
    md: { span: 10, offset: 0 },
    lg: { span: 11, offset: 1 },
    xl: { span: 4, offset: 0 },
    xxl: { span: 7, offset: 1 },
};

export const selectedCargo = {
    xs: { span: 23, offset: 1 },
    sm: { span: 13, offset: 1 },
    md: { span: 13, offset: 1 },
    lg: { span: 12, offset: 0 },
    xl: { span: 7, offset: 1 },
    xxl: { span: 5, offset: 0 },
};

export const emailUserAdd = {
    xs: { span: 23, offset: 1 },
    sm: { span: 12, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 11, offset: 1 },
    xl: { span: 9, offset: 1 },
    xxl: { span: 8, offset: 1 },
};

export const passUserAdd = {
    xs: { span: 23, offset: 1 },
    sm: { span: 11, offset: 1 },
    md: { span: 24, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 8, offset: 0 },
    xxl: { span: 9, offset: 1 },
};

export const confirmPassUserAdd = {
    xs: { span: 23, offset: 1 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 11, offset: 1 },
    xl: { span: 8, offset: 1 },
    xxl: { span: 9, offset: 0 },
};

export const radioButtomVendedorUserAdd = {
    xs: { span: 23, offset: 1 },
    sm: { span: 12, offset: 0 },
    md: { span: 12, offset: 0 },
    lg: { span: 5, offset: 0 },
    xl: { span: 4, offset: 0 },
    xxl: { span: 3, offset: 0 },
};
export const radioButtomObservacaoUserAdd = {
    xs: { span: 23, offset: 1 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 18, offset: 0 },
    xl: { span: 19, offset: 0 },
    xxl: { span: 20, offset: 0 },
};

export const DiasDeTesteUserAdd = {
    xs: { span: 23, offset: 1 },
    sm: { span: 8, offset: 1 },
    md: { span: 7, offset: 0 },
    lg: { span: 5, offset: 1 },
    xl: { span: 4, offset: 0 },
    xxl: { span: 5, offset: 0 },
};

export const validarDispositivoUserAdd = {
    xs: { span: 23, offset: 1 },
    sm: { span: 6, offset: 2 },
    md: { span: 7, offset: 1 },
    lg: { span: 5, offset: 1 },
    xl: { span: 4, offset: 1 },
    xxl: { span: 3, offset: 1 },
};
//(Vendedor) foi alterado de 1acesso para vendedor
export const primeiroAcessoLiberadoUserAdd = {
    xs: { span: 23, offset: 1 },
    sm: { span: 7, offset: 0 },
    md: { span: 9, offset: 0 },
    lg: { span: 5, offset: 1 },
    xl: { span: 4, offset: 0 },
    xxl: { span: 3, offset: 1 },
};
export const NotificacaoAppUserAdd = {
    xs: { span: 23, offset: 1 },
    sm: { span: 9, offset: 1 },
    md: { span: 9, offset: 0 },
    lg: { span: 6, offset: 0 },
    xl: { span: 6, offset: 1 },
    xxl: { span: 4, offset: 0 },
};
//fim aqui

export const selectedGrupoUserAdd = {
    xs: { span: 23, offset: 1 },
    sm: { span: 17, offset: 1 },
    md: { span: 16, offset: 1 },
    lg: { span: 7, offset: 0 },
    xl: { span: 10, offset: 0 },
    xxl: { span: 10, offset: 0 },
};

export const selectedOrgaoUserAdd = {
    xs: { span: 23, offset: 1 },
    sm: { span: 9, offset: 0 },
    md: { span: 9, offset: 1 },
    lg: { span: 10, offset: 1 },
    xl: { span: 10, offset: 1 },
    xxl: { span: 10, offset: 1 },
};

export const relatorioDoPainelUserAdd = {
    xs: { span: 23, offset: 1 },
    sm: { span: 6, offset: 1 },
    md: { span: 8, offset: 6 },
    lg: { span: 5, offset: 0 },
    xl: { span: 4, offset: 0 },
    xxl: { span: 5, offset: 0 },
};
export const AcessoMsgAppPainelUserAdd = {
    xs: { span: 23, offset: 1 },
    sm: { span: 24, offset: 0 },
    md: { span: 20, offset: 0 },
    lg: { span: 11, offset: 0 },
    xl: { span: 5, offset: 1 },
    xxl: { span: 10, offset: 1 },
};
export const AcessoNotificacaoSMSEmailUserAdd = {
    xs: { span: 23, offset: 1 },
    sm: { span: 16, offset: 0 },
    md: { span: 20, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 5, offset: 0 },
    xxl: { span: 10, offset: 1 },
};

export const receberNotificacaoUserAdd = {
    xs: { span: 16, offset: 1 },
    sm: { span: 16, offset: 1 },
    md: { span: 16, offset: 1 },
    lg: { span: 16, offset: 0 },
    xl: { span: 15, offset: 9 },
    xxl: { span: 16, offset: 0 },
};
