export const colCollapseContrato = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 11, offset: 0 }, // > 992
    xl: { span: 7, offset: 0 }, // > 700
    xxl: { span: 7, offset: 0 }, // > 1600
  };
  export const colCollapseContrato1 = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 11, offset: 1 }, // > 992
    xl: { span: 7, offset: 1 }, // > 700
    xxl: { span: 7, offset: 1 }, // > 1600
  };
  export const colCollapseContrato2 = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 11, offset: 0 }, // > 992
    xl: { span: 7, offset: 1 }, // > 700
    xxl: { span: 7, offset: 1 }, // > 1600
  };

  export const cardLayout = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
  };

  export const modaisDeConfig = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 24, offset: 0 }, // > 992
    xl: { span: 24, offset: 0 }, // > 1200
    xxl: { span: 24, offset: 0 }, // > 1600
  };

  export const colLisTabelaConfigs = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 24, offset: 0 }, // > 992
    xl: { span: 24, offset: 0 }, // > 1200
    xxl: { span: 24, offset: 0 }, // > 1600
  };

  export const modalAddModuloInputs = {
      xs: { span: 24, offset: 0 }, //<576
      sm: { span: 24, offset: 0 }, //>576
      md: { span: 12, offset: 0 }, // >768
      lg: { span: 12, offset: 0 }, // > 992
      xl: { span: 12, offset: 0 }, // > 1200
      xxl: { span: 12, offset: 0 }, // > 1600
    };
