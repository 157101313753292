export function loginAlterSingned() {
  return {
    type: '@login/loginAlterSingned',
  };
}
export function loginAlterSingOut() {
  return {
    type: '@login/loginAlterSingOut',
  };
}
export function loginInSistem(email: string, senha: string) {
  return {
    type: '@login/LOGIN_IN_SISTEM',
    payload: { email, senha },
  };
}

export function loginInSistemRequest(email: string, senha: string) {
  return {
    type: '@login/LOGIN_IN_SISTEM-REQUEST',
    payload: { email, senha },
  };
}

export function loadingLoginTrue() {
  return {
    type: '@login/LOADING_TRUE',
  };
}
export function loadingLoginFalse() {
  return {
    type: '@login/LOADING_FALSE',
  };
}
