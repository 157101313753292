import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
    Table, Input, Button, Space, notification, Typography, Row, Col,
    Select, Tooltip, Modal, Divider
} from 'antd';
import Highlighter from 'react-highlight-words';
import locale from 'antd/es/locale/pt_BR';
import {
    SearchOutlined,
    FilePdfOutlined,
    FileTextOutlined
} from '@ant-design/icons';
import axios from 'axios';
import {
    urlBuscaEstados,
    urlRelatorioPendenciasMunicipiosEstado
} from '../../../services/request/urls';

import { useSelector } from 'react-redux';
import { stateGlobal } from '../../../interfaces/Global';

import { getToken } from './../../../services/token';
import Icon from '@ant-design/icons';
import {
    cardLayout,
    colBotaoSelecionar,
} from './GridStyle';

import {
    removeAcento
} from './../../../utils/RemoveAcentos';

import { FaSearch } from 'react-icons/fa';
import { useGetColumnSearchProps } from '../../../hooks/FilterTable/stringCLearFiltros';

const { Text, Title } = Typography;

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>

interface interfDadosTabela {
    "id": number,
    "uuid": string,
    "nome": string,
    "uf": string,
    "edtadoId": number,
    "pendencias_cauc": Array<
        {
            "titulo_abreviado": string,
            "titulo": string
        }
    >,
    "pendencias_certidoes": Array<
        {
            "descricao": string
        }
    >
}

interface IDadosEstados {
    id: number,
    sigla: string,
    regiao: {
        id: number,
        sigla: string,
        nome: string
    },
    regiao_id: number,
    nome: string,
    populacao: number
};

const PendenciasMunicipiosPorEstado: React.FC = () => {

    const refContagemDoFiltro = React.useRef<Array<any>>([]);
    const refFuncaoLimparFiltrosString = useRef<any>(null);

    const propsState = useSelector((state: stateGlobal) => state.global);
    // const queryParans = useQuery();

    // const refContratoSituacao = useRef<{ nome: string, id: number } | null>(null);

    const refAllDadosEstados = useRef<Array<IDadosEstados>>([]);

    const refIDEstado = useRef<any>(null);
    const refNomeEstado = useRef<any>(null);

    // const history = useHistory();
    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();
    const signal1 = axios.CancelToken.source();

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string>>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dadosTabelaState, setDadosTabelaState] = useState<Array<interfDadosTabela>>([]);

    const [hasSelectedAdd, setHasSelectedAdd] = useState(true);
    const [hasSelectedView, setHasSelectedView] = useState(false);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);
    const [hasSelectedDel, setHasSelectedDel] = useState(false);

    const [loadingTable, setLoadingTable] = useState(false);
    const [listEstados, setListEstados] = useState<Array<IDadosEstados>>([]);

    const [dadosTabelaModalViewArrayDados, setDadosTabelaModalViewArrayDados] = useState<{
        visible: boolean,
        dataTable: Array<any>,
        tipo: string,
        titulo: string,
    }>({
        visible: false,
        dataTable: [],
        tipo: '',
        titulo: '',
    });

    const buscaDadosEstados = useCallback((): void => {

        const buscaDeDadosContratos = async () => {
            try {

                setLoadingTable(true)
                let result = await axios.get(urlBuscaEstados,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                refAllDadosEstados.current = result.data

                setListEstados(result.data);
                setLoadingTable(false)


            } catch (error) {

                setLoadingTable(false)

                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosContratos();

    }, []);

    const buscaDadosPendenciasMunicipiosPorEstadoID = useCallback((id: string | number): void => {

        const buscaDeDados = async () => {
            try {

                setLoadingTable(true)

                let result = await axios.get(urlRelatorioPendenciasMunicipiosEstado + '/' + id,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });


                setDadosTabelaState(result.data);


                setLoadingTable(false)


            } catch (error) {

                setLoadingTable(false)

                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDados();

    }, []);


    //executa apenas ao iniciar o component
    useEffect(() => {

        buscaDadosEstados();

    }, []);

    //executa apenas quando desmonta o component
    const comonentExecutaAoDestroy = useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
        signal1.cancel("Requisicao cancelada!")
    }, []);

    const encontrarContratoPorId = useCallback((id): void => {

        const recebeFilter = refAllDadosEstados.current.filter((element, index) => (
            element.id === id
        ))

        // setStateContratoSituacao(recebeFilter[0]?.id)

    }, []);

    //Search da tabela de listagem
    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {

                let trecord = removeAcento(record[dataIndex].toString());

                return record[dataIndex] ? trecord.includes(removeAcento(value)) : false

            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, [searchText]);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);
    // fim do search da tabela de listagem

    const onChangeSelectEstados = useCallback((value, obj): void => {

        // encontrarContratoPorId(value)

        refIDEstado.current = value;
        refNomeEstado.current = obj?.children;

        buscaDadosPendenciasMunicipiosPorEstadoID(value);

    }, []);

    const columnsModalPendenciasCAUC = [
        {
            title: 'Título Abreviado',
            dataIndex: 'titulo_abreviado',
            ...useGetColumnSearchProps('titulo_abreviado', refFuncaoLimparFiltrosString),
            width: '10%',
        },
        {
            title: 'Título',
            dataIndex: 'titulo',
            ...useGetColumnSearchProps('titulo', refFuncaoLimparFiltrosString),
            width: '10%',
        },
    ];

    const columnsModalPendenciasCertidoes = [
        {
            title: 'Descrição',
            dataIndex: 'descricao',
            ...useGetColumnSearchProps('descricao', refFuncaoLimparFiltrosString),
            width: '10%',
        },
    ];

    // click na linha da tabela de listagem de usuario
    const clickCancelModalArrayDados = useCallback((): void => {
        setDadosTabelaModalViewArrayDados({
            dataTable: [],
            visible: false,
            tipo: '',
            titulo: '',
        })
    }, []);

    const clickAbrirModalViewArrayDados = useCallback((
        record: Array<any>,
        tipo: string,
        titulo: string,
    ): void => {

        setDadosTabelaModalViewArrayDados({
            dataTable: record,
            visible: true,
            tipo: tipo,
            titulo: titulo,
        })

    }, []);

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeys.includes(record.uuid)) {
            let filter = selectedRowKeys.filter((val) => {
                return val != record.uuid
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(true);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length == 0) {
                setSelectedRowKeys([record.uuid]);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else {
                let teste = selectedRowKeys.concat(record.uuid);
                setSelectedRowKeys(teste);
                setHasSelectedAdd(false);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
                setHasSelectedDel(true);
            }
        }
    }, [selectedRowKeys]);

    // //checkbox da tabela de listagem
    // const onSelectChange = useCallback((selectedRowKeys): void => {
    //     if (selectedRowKeys.length > 1) {
    //         setSelectedRowKeys(selectedRowKeys);
    //         setHasSelectedAdd(false);
    //         setHasSelectedView(false);
    //         setHasSelectedEdit(false);
    //         setHasSelectedDel(true);
    //     } else if (selectedRowKeys.length === 0) {
    //         setSelectedRowKeys(selectedRowKeys);
    //         setHasSelectedAdd(true);
    //         setHasSelectedView(false);
    //         setHasSelectedEdit(false);
    //         setHasSelectedDel(false);
    //     } else {
    //         setSelectedRowKeys(selectedRowKeys);
    //         setHasSelectedAdd(false);
    //         setHasSelectedView(true);
    //         setHasSelectedEdit(true);
    //         setHasSelectedDel(true);
    //     }
    // }, [selectedRowKeys]);

    // const rowSelection = {
    //     selectedRowKeys,
    //     onChange: onSelectChange,
    //     columnWidth: '1%'
    // };

    const columns = [
        {
            title: 'Cidade',
            dataIndex: 'nome',
            ...getColumnSearchProps('nome'),
            width: '20%',
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
            render: (nome: string, record: any) => {
                return (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            <Text
                                style={{
                                    marginLeft: 10
                                }}
                            >
                                {nome + ' - ' + record?.uf}
                            </Text>

                        </div>
                    </>
                )
            }
        },
        {
            title: 'Total Pendências',
            dataIndex: 'uuid',
            // ...getColumnSearchProps('usuarios'),
            width: '6%',
            sorter: (a: any, b: any) => {
                let somaA = (Number(b.pendencias_cauc.length) + Number(b?.pendencias_certidoes.length))
                let somaB = (Number(a.pendencias_cauc.length) + Number(a?.pendencias_certidoes.length))
                return somaA - somaB
            },
            showSorterTooltip: false,
            render: (pendencias: Array<any>, record: any) => {
                return (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            <Text
                                style={{
                                    marginLeft: 10
                                }}
                            >
                                {(record.pendencias_cauc.length + record.pendencias_certidoes.length)}
                            </Text>

                        </div>
                    </>
                )
            }
        },
        // {
        //     title: 'Sigla',
        //     dataIndex: 'estadoSigla',
        //     ...getColumnSearchProps('estadoSigla'),
        //     width: '10%',
        //     sorter: (a: any, b: any) => a.estadoSigla.localeCompare(b.estadoSigla),
        //     showSorterTooltip: false,
        // },
        {
            title: 'Pendências CAUC',
            dataIndex: 'pendencias_cauc',
            // ...getColumnSearchProps('usuarios'),
            width: '10%',
            sorter: (a: any, b: any) => Number(b.pendencias_cauc.length) - Number(a.pendencias_cauc.length),
            showSorterTooltip: false,
            render: (pendencias: Array<any>, record: any) => {
                return (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            <Tooltip title="CAUC" color="green" key="adicionargreen">
                                <Button
                                    type="primary"
                                    className="botaoVincularUsuarioInAtendimentos"
                                    onClick={() => clickAbrirModalViewArrayDados(
                                        record.pendencias_cauc,
                                        'pendencias_cauc',
                                        'Pendências CAUC',
                                    )}
                                >
                                    <FileTextOutlined />
                                </Button>
                            </Tooltip>
                            <Text
                                style={{
                                    marginLeft: 10
                                }}
                            >
                                {pendencias.length}
                            </Text>

                        </div>
                    </>
                )
            }
        },
        {
            title: 'Pendências Certidões',
            dataIndex: 'pendencias_certidoes',
            // ...getColumnSearchProps('usuarios'),
            width: '10%',
            sorter: (a: any, b: any) => Number(b.pendencias_certidoes.length) - Number(a.pendencias_certidoes.length),
            showSorterTooltip: false,
            render: (pendencias: Array<any>, record: any) => {
                return (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            <Tooltip title="Certidões" color="red" key="editargold">
                                <Button
                                    type="primary"
                                    style={{ marginLeft: 5 }}
                                    danger
                                    onClick={() => clickAbrirModalViewArrayDados(
                                        record.pendencias_certidoes,
                                        'pendencias_certidoes',
                                        'Pendências Certidões',
                                    )}
                                >
                                    <FilePdfOutlined />
                                </Button>
                            </Tooltip>
                            <Text
                                style={{
                                    marginLeft: 10
                                }}
                            >
                                {pendencias.length}
                            </Text>
                        </div>
                    </>
                )
            }
        },
        // {
        //     title: 'Ações',
        //     dataIndex: 'id',
        //     // ...getColumnSearchProps('created_at'),
        //     width: '8%',
        //     // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
        //     render: (idModal: any, record: any) => {

        //         return (
        //             <>
        //                 <Row>
        //                     <Tooltip title="CAUC" color="green" key="adicionargreen">
        //                         <Button
        //                             type="primary"
        //                             className="botaoVincularUsuarioInAtendimentos"
        //                             onClick={() => clickAbrirModalViewArrayDados(
        //                                 record.pendencias_cauc,
        //                                 'pendencias_cauc',
        //                                 'Pendências CAUC',
        //                             )}
        //                         >
        //                             <FileTextOutlined />
        //                         </Button>
        //                     </Tooltip>
        //                     <Tooltip title="Certidões" color="red" key="editargold">
        //                         <Button
        //                             type="primary"
        //                             style={{ marginLeft: 5 }}
        //                             danger
        //                             // className="EditarCidadesContratoSalvas"
        //                             onClick={() => clickAbrirModalViewArrayDados(
        //                                 record.pendencias_certidoes,
        //                                 'pendencias_certidoes',
        //                                 'Pendências Certidões',
        //                             )}
        //                         >
        //                             <FilePdfOutlined />
        //                         </Button>
        //                     </Tooltip>
        //                 </Row>
        //             </>
        //         )
        //     },
        // },
    ];

    return (
        <>
            <Row>
                <Col {...cardLayout}>
                    <Title level={3}><FileTextOutlined style={{ fontSize: 25 }} />{' Relatório de pendências dos municípios por estado'}</Title>
                    <Divider />
                </Col>
            </Row>
            <div
                style={{
                    position: 'fixed',
                    zIndex: 2,
                    backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                    //
                    width: '100%',
                    // backgroundColor: 'blue',
                    paddingTop: 14,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24
                }}
            >
                <Row
                // style={{ marginBottom: 16 }}
                >
                    <Col
                        {...colBotaoSelecionar}
                        style={{
                            // backgroundColor: 'red'
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Select
                            showSearch
                            placeholder="Selecione um Estado"
                            optionFilterProp="children"
                            onChange={onChangeSelectEstados}
                            style={{
                                width: '100%',
                                marginBottom: 5,
                                marginRight: 5,
                            }}
                            filterOption={(input: any, option: any) => {
                                let textDigit = removeAcento(input)
                                let listCidade = removeAcento(option?.children);
                                return listCidade.indexOf(textDigit) >= 0
                            }}
                        >
                            {
                                listEstados.map((item) => {
                                    return (
                                        <Select.Option
                                            value={item.id}
                                            key={item.id}
                                        >
                                            {
                                                item?.sigla ?
                                                    item.nome + ' - ' + item?.sigla
                                                    :
                                                    item.nome
                                            }
                                        </Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Col>
                </Row>

            </div>
            <div
                style={{
                    marginTop: 50
                }}
                className='marginTopTables'
            >

                <Table
                    rowKey={(record) => record.uuid}
                    loading={loadingTable}
                    onRow={(record, rowIndex) => {
                        return {
                            // onClick: event => { onclickTable(record, rowIndex) }, // click row
                            // onDoubleClick: event => { }, // double click row
                            // onContextMenu: event => { }, // right button click row
                            // onMouseEnter: event => { }, // mouse enter row
                            // onMouseLeave: event => { }, // mouse leave row
                        };
                    }}
                    // rowSelection={rowSelection}
                    columns={columns}
                    dataSource={dadosTabelaState}
                    scroll={{ x: 1000 }}
                    onChange={
                        (pagination, filters, sorter, extra) => {
                            refContagemDoFiltro.current = extra.currentDataSource
                        }
                    }
                    title={(registros) => {
                        return (
                            <div
                                style={{
                                    backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                    padding: 10,
                                    margin: -8,
                                    marginBottom: 0.5
                                }}
                            >
                                <Text>
                                    {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                        dadosTabelaState.length :
                                        refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                                </Text>
                            </div>
                        )
                    }}
                    footer={(registros) => {
                        return (
                            <div
                                style={{
                                    textAlign: 'end'
                                }}
                            >
                                <Text>
                                    {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                        dadosTabelaState.length :
                                        refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                                </Text>
                            </div>
                        )
                    }}
                    size="small"
                    pagination={{
                        locale: { items_per_page: '' },
                        showSizeChanger: true,
                        defaultPageSize: 100,
                        position: ['topRight', 'bottomRight'],
                        pageSizeOptions: ['10', '20', '50', '100']
                    }}
                    locale={locale.Table}
                />
            </div>
            <Modal
                title={dadosTabelaModalViewArrayDados.titulo}
                visible={dadosTabelaModalViewArrayDados.visible}
                className="modalVincularUsuario"
                onOk={clickCancelModalArrayDados}
                onCancel={clickCancelModalArrayDados}
                footer={[
                    <Button key="back"
                        onClick={clickCancelModalArrayDados}>
                        Fechar
                    </Button>
                ]}
            >
                {dadosTabelaModalViewArrayDados.dataTable.length > 0 ?
                    <Table
                        rowKey={(record, index) => {
                            if (index) {
                                return index.toString()
                            } else {
                                return record.titulo
                            }
                        }}
                        columns={
                            dadosTabelaModalViewArrayDados.tipo === 'pendencias_cauc' ?
                                columnsModalPendenciasCAUC
                                :
                                dadosTabelaModalViewArrayDados.tipo === 'pendencias_certidoes' ?
                                    columnsModalPendenciasCertidoes
                                    :
                                    [{}]
                        }
                        title={(registrosTabela) => {

                            return (
                                <div
                                    style={{
                                        backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                        padding: 10,
                                        margin: -8,
                                        marginBottom: 0.5
                                    }}
                                >
                                    <Text>
                                        {`Mostrando: ${registrosTabela.length} num total de ${dadosTabelaModalViewArrayDados.dataTable.length} `}
                                    </Text>
                                </div>
                            )
                        }}
                        dataSource={dadosTabelaModalViewArrayDados.dataTable}
                        scroll={{ y: 200, x: 680 }}
                        size="small"
                        pagination={{ locale: { items_per_page: '' } }}
                        locale={locale.Table}
                    />
                    :
                    <Text>Não há pendências.</Text>
                }
            </Modal>
        </>
    );
}

export default PendenciasMunicipiosPorEstado;
