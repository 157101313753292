import produce from 'immer';
import { LoginInitialState } from './../../../interfaces';

export const INITIAL_STATE: LoginInitialState = {
    token: null,
    signed: false,
    loading: false,
    nome: "CHINA",
};

export default function login(state = INITIAL_STATE, action: ReturnType<any>) {
    return produce(state, draft => {
        switch (action.type) {
            case '@login/loginAlterSingned': {
                draft.signed = true;
                break;
            }
            case '@login/loginAlterSingOut': {
                draft.signed = false;
                break;
            }
            case '@login/LOGIN_IN_SISTEM': {
                draft.signed = true;
                draft.nome = action.payload.email
                break;
            }
            case '@login/LOADING_TRUE': {
                draft.loading = true;
                break;
            }
            case '@login/LOADING_FALSE': {
                draft.loading = false;
                break;
            }
            default:
        }
    });
}
