import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
    Table, Button, notification, Typography, Row, Col,
    Select, Tooltip, Modal, Divider, Breadcrumb, Spin, Form
} from 'antd';
import locale from 'antd/es/locale/pt_BR';
import {
    FileTextOutlined,
    ExclamationCircleOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import axios from 'axios';
import {
    urlDadosAppIntegracaoSiconOrgaos
} from '../../../../../services/request/urls';

import { useSelector } from 'react-redux';
import { stateGlobal } from '../../../../../interfaces/Global';

import { getToken } from './../../../../../services/token';

import {
    removeAcento
} from './../../../../../utils/RemoveAcentos';

import { useGetColumnSearchProps } from '../../../../../hooks/FilterTable/stringCLearFiltros';
import { useHistory, useParams } from 'react-router';
import './styles.css'

const { Text, Title } = Typography;

interface interfDadosTabelaOrgaos {
    "orgao_id": number,
    "orgao": string,
    "servicos": Array<string>,
    "_id": string
}

interface IReqDadosOrgaosByMunicipio {
    "_id": string,
    "id_ibge": number,
    "nome": string,
    "orgaos": Array<interfDadosTabelaOrgaos>,
    "__v": number
}

interface IReqDadosAllOrgaos {
    "servidores": {
        "efetivos": number | null,
        "aposentados_pensionistas": number | null,
        "outros": number | null
    },
    "_id": string,
    "orgao_id": number | null,
    "__v": number | null,
    "atualizado_em": string,
    "bancos": Array<
        {
            "id": number,
            "banco": string,
            "_id": string
        }
    >,
    "cnpj": string,
    "orgao": string,
    "servicos": Array<
        {
            "nome_servico": string,
            "percentual_margem": number | null,
            "_id": string
        }
    >,
    "uf": string
}

const SiconPersonalizacao: React.FC = () => {

    const { idCidade }: any = useParams();

    const refContagemDoFiltro = React.useRef<Array<any>>([]);
    const refFuncaoLimparFiltrosString = useRef<any>(null);

    const propsState = useSelector((state: stateGlobal) => state.global);

    const history = useHistory();

    const refAllDadosOrgaosByMunicipio = useRef<IReqDadosOrgaosByMunicipio | null>();

    const refAllDadosOrgaos = useRef<Array<IReqDadosAllOrgaos>>([]);

    const refIdCidade = useRef<any>(null);

    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();
    const signal1 = axios.CancelToken.source();

    const [loadingTable, setLoadingTable] = useState(false);
    const [listOrgaosByMunicipio, setListOrgaosByMunicipio] = useState<IReqDadosOrgaosByMunicipio | null>();
    const [listAllOrgaosFilter, setListAllOrgaosFilter] = useState<Array<IReqDadosAllOrgaos>>([]);

    const refFormModalAddOrgaos = useRef<any>(null);
    const [modalAddOrgaos, setModalAddOrgaos] = useState(false);
    const [loadingFormAddOrgaos, setLoadingFormAddOrgaos] = useState(false);

    const [valorLsStorage, setValorLsStorage] = useState<null | { nome: string, id: number }>(null);

    const buscaDadosOrgaosByMunicipio = useCallback((id: any): void => {

        const buscaDeDadosContratos = async () => {
            try {

                setLoadingTable(true)
                let result = await axios.get(urlDadosAppIntegracaoSiconOrgaos + '/' + id,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });


                refAllDadosOrgaosByMunicipio.current = result.data

                setListOrgaosByMunicipio(result.data);
                setLoadingTable(false)


            } catch (error) {

                setLoadingTable(false)

                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Atenção',
                        description:
                            msgErro?.response?.data?.message ? msgErro?.response?.data?.message :
                                'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosContratos();

    }, []);

    // Integracoes
    const buscaDadosAllOrgaos = useCallback((): void => {

        const buscaDeDadosContratos = async () => {
            try {

                setLoadingFormAddOrgaos(true)
                let result = await axios.get(urlDadosAppIntegracaoSiconOrgaos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                refAllDadosOrgaos.current = result.data
                setListAllOrgaosFilter(result.data)

                setLoadingFormAddOrgaos(false)


            } catch (error) {

                setLoadingFormAddOrgaos(false)

                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Erro',
                        description:
                            msgErro?.response?.data?.message ? msgErro?.response?.data?.message :
                                'Não foi possivel buscar os dados!',
                    });

                }
            }
        }

        buscaDeDadosContratos();

    }, []);

    //executa apenas ao iniciar o component
    useEffect(() => {

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:ListInteracoesByMunicipio');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }


        if (localUserData) {
            setValorLsStorage(localUserData)
        }


        if (idCidade) {
            refIdCidade.current = idCidade
            buscaDadosOrgaosByMunicipio(idCidade);
        }
        buscaDadosAllOrgaos();

    }, [idCidade]);

    //executa apenas quando desmonta o component
    useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
        signal1.cancel("Requisicao cancelada!")
    }, []);

    const encontrarOrgaoPorId = useCallback((id): IReqDadosAllOrgaos => {

        const recebeFilter = refAllDadosOrgaos.current.filter((element, index) => (
            element._id === id
        ))

        return recebeFilter[0]

    }, []);

    const clickRemoveOrgaos = useCallback((orgao: interfDadosTabelaOrgaos): void => {

        const requisicaoRemoverOrgao = async () => {
            try {
                setLoadingFormAddOrgaos(true);

                if (
                    refAllDadosOrgaosByMunicipio.current?.orgaos
                    && refAllDadosOrgaosByMunicipio.current?.orgaos.length === 1
                ) {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Os Órgãos não podem ficar vazios!',
                    });
                    return
                }


                const integracoesParaSalvar = refAllDadosOrgaosByMunicipio.current?.orgaos.filter((element, index) => (
                    element.orgao_id !== orgao.orgao_id
                ))


                let obj = {
                    orgaos: integracoesParaSalvar
                }

                await axios.put(urlDadosAppIntegracaoSiconOrgaos + '/' + refIdCidade.current, obj,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Órgãos salvos com sucesso!',
                });

                setLoadingFormAddOrgaos(false);

                buscaDadosOrgaosByMunicipio(refIdCidade.current);
                // buscaDadosAllIntegracoes();

                clickCancelModalAddOrgaos();
            } catch (error) {


                let msgErro: any = (error as Error);
                setLoadingFormAddOrgaos(false);
                notification.error({
                    message: 'Erro',
                    description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel salvar, entre em contato com suporte!',
                });
            }
        }

        requisicaoRemoverOrgao();


    }, []);

    const onClickRemoverOrgaosMunicipio = useCallback((integracao: interfDadosTabelaOrgaos): void => {
        Modal.confirm({
            title: 'Cuidado',
            icon: <ExclamationCircleOutlined />,
            content: integracao.orgao + ' - Tem certeza que você deseja remover este órgão?',
            okText: 'Sim',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                clickRemoveOrgaos(integracao);
            },
            onCancel() {
            },
        });
    }, []);

    const columns = [
        {
            title: 'Órgãos',
            dataIndex: 'orgao',
            ...useGetColumnSearchProps('orgao', refFuncaoLimparFiltrosString),
            width: '20%',
            sorter: (a: any, b: any) => a.orgao.localeCompare(b.orgao),
            showSorterTooltip: false,
            render: (orgao: string, record: any) => {
                return (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            <Text
                                style={{
                                    marginLeft: 10
                                }}
                            >
                                {orgao}
                            </Text>

                        </div>
                    </>
                )
            }
        },
        {
            title: 'Ações',
            dataIndex: 'orgao_id',
            width: '5%',
            showSorterTooltip: false,
            render: (orgao_id: string | null, record: interfDadosTabelaOrgaos) => {
                return (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            {
                                <Tooltip title="Excluir" color="red" key="removerIntegracao">
                                    <Button
                                        type="primary"
                                        danger
                                        onClick={() => {
                                            onClickRemoverOrgaosMunicipio(record)
                                        }}
                                    >
                                        <DeleteOutlined />
                                    </Button>
                                </Tooltip>
                            }

                        </div>
                    </>
                )
            }
        },
    ];

    const onClickVoltarConfigOfSystem = useCallback((): void => {
        history.push("/integracoes");
    }, [history]);

    const onClickVoltarIntegracaoMunicipio = useCallback((): void => {
        history.push("/integracoes/" + idCidade);
    }, [history, idCidade]);

    const clickCancelModalAddOrgaos = useCallback(() => {
        setModalAddOrgaos(false);
        refFormModalAddOrgaos.current?.resetFields();
    }, []);

    const clickSubmitModalAddOrgaos = useCallback((): void => {

        const reqSalvarOrgao = async (values: any, isEdit = false) => {
            try {
                setLoadingFormAddOrgaos(true);

                if (isEdit) {
                    await axios.put(urlDadosAppIntegracaoSiconOrgaos + '/' + idCidade, values,
                        { headers: { 'Authorization': 'Bearer ' + getToken() } });
                } else {
                    await axios.post(urlDadosAppIntegracaoSiconOrgaos, values,
                        { headers: { 'Authorization': 'Bearer ' + getToken() } });
                }

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Órgãos salvos com sucesso!',
                });

                setLoadingFormAddOrgaos(false);

                buscaDadosOrgaosByMunicipio(refIdCidade.current);
                buscaDadosAllOrgaos();

                clickCancelModalAddOrgaos();
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingFormAddOrgaos(false);
                notification.error({
                    message: 'Erro',
                    description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel salvar, entre em contato com suporte!',
                });
            }
        }

        refFormModalAddOrgaos.current?.validateFields()
            .then((values: any) => {

                let arrOrgaos: any = [];

                //para funcionar pela nova rota de enviar os que ja existem e acrescentar os que não tem.
                if (refAllDadosOrgaosByMunicipio.current?.orgaos) {
                    arrOrgaos = [...refAllDadosOrgaosByMunicipio.current?.orgaos]
                }

                for (let index = 0; index < values?.orgaos.length; index++) {
                    const orgaoID = values?.orgaos[index];

                    let orgaoSelecionado = encontrarOrgaoPorId(orgaoID)

                    let orgaoServicos: Array<string> = []
                    orgaoSelecionado.servicos.forEach((servico) => {
                        orgaoServicos.push(servico.nome_servico)
                    })

                    let newOrgaoSelecionado = {
                        orgao_id: orgaoSelecionado.orgao_id,
                        orgao: orgaoSelecionado.orgao,
                        servicos: orgaoServicos,
                    }
                    arrOrgaos.push(newOrgaoSelecionado)
                }

                let obj = {
                    id_ibge: idCidade,
                    orgaos: arrOrgaos
                }

                if (refAllDadosOrgaosByMunicipio.current?.orgaos &&
                    refAllDadosOrgaosByMunicipio.current?.orgaos.length > 0) {
                    // Editar
                    reqSalvarOrgao(obj, true);
                } else {
                    reqSalvarOrgao(obj);
                }

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [idCidade]);

    return (
        <>
            <Row>
                <Col span={24}>
                    <Title level={3}><FileTextOutlined style={{ fontSize: 25 }} />{' Listagem de Órgãos - ' + valorLsStorage?.nome}</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item><a href='#' onClick={onClickVoltarConfigOfSystem}>Municípios</a></Breadcrumb.Item>
                        <Breadcrumb.Item><a href='#' onClick={onClickVoltarIntegracaoMunicipio}>Integrações</a></Breadcrumb.Item>
                        <Breadcrumb.Item>Personalização</Breadcrumb.Item>
                    </Breadcrumb>
                    <Divider />
                </Col>
            </Row>
            <div
                style={{
                    position: 'fixed',
                    zIndex: 2,
                    backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                    width: '100%',
                    // backgroundColor: 'blue',
                    paddingTop: 14,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24
                }}
            >
                <Row>
                    <Col>
                        <Button
                            type="primary"
                            className="botaoAdicionarOrgaos_fioljbweofgbvo"
                            onClick={() => {
                                setModalAddOrgaos(true);
                            }}

                            style={{ marginRight: 5 }}
                        >
                            Adicionar
                        </Button>
                    </Col>

                </Row>

            </div>
            <div
                style={{
                    marginTop: 50
                }}
                className='marginTopTables'
            >

                <Table
                    rowKey={(record) => record.orgao_id}
                    loading={loadingTable}
                    columns={columns}
                    dataSource={
                        (listOrgaosByMunicipio && listOrgaosByMunicipio.orgaos.length > 0) ?
                            listOrgaosByMunicipio.orgaos :
                            []
                    }
                    scroll={{ x: 350 }}
                    onChange={
                        (pagination, filters, sorter, extra) => {
                            refContagemDoFiltro.current = extra.currentDataSource
                        }
                    }
                    title={(registros) => {
                        return (
                            <div
                                style={{
                                    backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                    padding: 10,
                                    margin: -8,
                                    marginBottom: 0.5
                                }}
                            >
                                <Text>
                                    {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                        (listOrgaosByMunicipio && listOrgaosByMunicipio.orgaos.length > 0)
                                            ?
                                            listOrgaosByMunicipio?.orgaos.length : '0' :
                                        refContagemDoFiltro.current.length} num total de ${(listOrgaosByMunicipio && listOrgaosByMunicipio.orgaos.length > 0)
                                            ? listOrgaosByMunicipio?.orgaos.length : '0'} `}
                                </Text>
                            </div>
                        )
                    }}
                    footer={(registros) => {
                        return (
                            <div
                                style={{
                                    textAlign: 'end'
                                }}
                            >
                                <Text>
                                    {/* {`Total: ${listIntegracoes.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                        (listOrgaosByMunicipio && listOrgaosByMunicipio.orgaos.length > 0)
                                            ?
                                            listOrgaosByMunicipio?.orgaos.length : '0' :
                                        refContagemDoFiltro.current.length} num total de ${(listOrgaosByMunicipio && listOrgaosByMunicipio.orgaos.length > 0)
                                            ?
                                            listOrgaosByMunicipio?.orgaos.length : '0'} `}
                                </Text>
                            </div>
                        )
                    }}
                    size="small"
                    pagination={{
                        locale: { items_per_page: '' },
                        showSizeChanger: true,
                        defaultPageSize: 100,
                        position: ['topRight', 'bottomRight'],
                        pageSizeOptions: ['10', '20', '50', '100']
                    }}
                    locale={locale.Table}
                />
            </div>
            <Modal
                title="Adicionar Órgãos"
                visible={modalAddOrgaos}
                className="modalAddOrgaos"
                onOk={clickSubmitModalAddOrgaos}
                onCancel={clickCancelModalAddOrgaos}
                footer={[
                    <Button key="back" disabled={loadingFormAddOrgaos} onClick={clickCancelModalAddOrgaos}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary"
                        loading={loadingFormAddOrgaos}
                        onClick={clickSubmitModalAddOrgaos}
                    >
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingFormAddOrgaos}>
                    <Form
                        ref={refFormModalAddOrgaos}
                        name="formModalAddAddIntegracoes"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                        onFinishFailed={() => { }}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="orgaos"
                                    label="Órgãos"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor selecione um órgão!',
                                        },
                                    ]}
                                    style={{ margin: 0, marginBottom: 15 }}
                                >
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Selecione..."
                                        mode="multiple"
                                        loading={loadingFormAddOrgaos}
                                        notFoundContent={loadingFormAddOrgaos ? <Spin size="small" /> : null}
                                        filterOption={(input: any, option: any) => {
                                            let textDigit = removeAcento(input)
                                            let listCargo = removeAcento(option?.children);
                                            return listCargo.indexOf(textDigit) >= 0
                                        }}
                                    >
                                        {
                                            listAllOrgaosFilter.map((item) => {
                                                return (
                                                    <Select.Option
                                                        value={item._id}
                                                        key={item._id}
                                                    >
                                                        {item.orgao}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
}

export default SiconPersonalizacao;
