export const Layout1_1 = {
    xs: { span: 16, offset: 6 }, //<576
    sm: { span: 14, offset: 6 }, //>576
    md: { span: 16, offset: 6 }, // >768
    lg: { span: 16, offset: 6 }, // > 992
    xl: { span: 16, offset: 6 }, // > 1200
    xxl: { span: 16, offset: 6 }, // > 1600
};
export const modulosCidadesInPermApp = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 8, offset: 0 },
    xl: { span: 8, offset: 0 },
    xxl: { span: 8, offset: 0 },
};

export const modulosIndicesGerenciaisInPemApp = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 7, offset: 1 },
    xl: { span: 7, offset: 1 },
    xxl: { span: 7, offset: 1 },
};

export const modulosProcessosJuridicosInPemApp = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 7, offset: 1 },
    xl: { span: 7, offset: 1 },
    xxl: { span: 7, offset: 1 },
};

export const modulosSINCOVCidadesPermInApp = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 8, offset: 0 },
    xl: { span: 8, offset: 0 },
    xxl: { span: 8, offset: 0 },
};

export const selectedComparacoesAdd = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 7, offset: 1 },
    xl: { span: 7, offset: 1 },
    xxl: { span: 7, offset: 1 },
};

export const complementoFimComparacoesCidades = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 7, offset: 1 },
    xl: { span: 7, offset: 1 },
    xxl: { span: 7, offset: 1 },
};

//   export const moduloEstadosInPemApp = {

export const moduloEstadosInPemApp = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 8, offset: 0 },
    xl: { span: 8, offset: 0 },
    xxl: { span: 8, offset: 0 },
};

export const modulosIndicadoresGerenciaisEstadosInPermApp = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 7, offset: 1 },
    xl: { span: 7, offset: 1 },
    xxl: { span: 7, offset: 1 },
};
export const modulosProcessosJuridicosEstadosInPermApp = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 7, offset: 1 },
    xl: { span: 7, offset: 1 },
    xxl: { span: 7, offset: 1 },
};

export const modulosSINCOVEstadoInPermApp = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 8, offset: 0 },
    xl: { span: 8, offset: 0 },
    xxl: { span: 8, offset: 0 },
};

export const selectedOrgaoUserAdd = {
    xs: { span: 23, offset: 1 },
    sm: { span: 9, offset: 0 },
    md: { span: 9, offset: 0 },
    lg: { span: 8, offset: 0 },
    xl: { span: 8, offset: 0 },
    xxl: { span: 8, offset: 0 },
};

export const selectedPoderOrgaoAdd = {
    xs: { span: 23, offset: 1 },
    sm: { span: 17, offset: 1 },
    md: { span: 16, offset: 1 },
    lg: { span: 7, offset: 0 },
    xl: { span: 7, offset: 1 },
    xxl: { span: 8, offset: 0 },
};

//modo cidade estados
export const rowColNomeAndTipo = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};

export const inputSelectTipoDaAssociacao = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 23, offset: 0 }, // > 992
    xl: { span: 23, offset: 0 }, // > 1200
    xxl: { span: 23, offset: 0 }, // > 1600
};

export const inputSelectEstadosCity = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 23, offset: 0 }, // > 992
    xl: { span: 11, offset: 0 }, // > 1200
    xxl: { span: 11, offset: 0 }, // > 1600
};

export const inputSelectCidades = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 23, offset: 0 }, // > 992
    xl: { span: 11, offset: 1 }, // > 1200
    xxl: { span: 11, offset: 1 }, // > 1600
};

export const cardLayout = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};

export const modalListDeEstados = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 12, offset: 0 }, //>576
    md: { span: 8, offset: 0 }, // >768
    lg: { span: 8, offset: 0 }, // > 992
    xl: { span: 8, offset: 0 }, // > 1200
    xxl: { span: 8, offset: 0 }, // > 1600
};
