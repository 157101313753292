import React from 'react';
import { Route, Switch } from 'react-router-dom';

// import AuthLayout from '~/pages/_layouts/auth'
// import DefaultLayout from '~/pages/_layouts/default'

// import { store } from '~/store'
import Login from '../../pages/Login';

const RouteAuth: React.FC = () => {

    return (
        <>
            <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/recoverpass" component={Login} />
                <Route path="*" exact component={Login} />
            </Switch>
        </>
    )
}

export default RouteAuth;
