import { Button, Col, Layout, Row, Spin, Typography } from "antd";
import { useAcessos } from "./useAcessos";
import { useSelector } from "react-redux";
import { stateGlobal } from "../../../../../interfaces/Global";
import { FilePdfOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import FiltrosAndTable from "./components/FiltrosAndTable";
import './style.css'

const { Text, Title } = Typography

interface IProps {
    activeKeyTabs: string
    clickNavigateTab: (aba: string) => void
    refFiltersGlobal: any
}

const RelUltimoAcesso = (
    {
        activeKeyTabs,
        clickNavigateTab,
        refFiltersGlobal
    }: IProps
) => {

    const {
        loadingScreen,
        setLoadingScreen,
        refComponentToPrint
    } = useAcessos()

    const handlePrint = useReactToPrint({
        content: () => refComponentToPrint.current,
        onAfterPrint: () => { setLoadingScreen(false) },
        onBeforePrint: () => { setLoadingScreen(true) },
        documentTitle: 'Relatório de acessos'
    });

    return (
        <Spin spinning={loadingScreen}>
            <Layout
                style={{
                    borderRadius: 8
                }}
                className="layoutVGrey_UltimoAcesso_lvbweqrafw"
            >
                <Row>
                    <Col span={24}>
                        <Layout
                            style={{
                                borderRadius: 8,
                                margin: 5,
                            }}
                            className="layout_UltimoAcesso_cvrehjvfqw"
                        >
                            <Row
                                style={{
                                    alignItems: 'center'
                                }}
                            >
                                <Col
                                    style={{
                                        margin: 10
                                    }}
                                >
                                    <Title
                                        style={{
                                            margin: 0
                                        }}
                                        level={5}
                                        type="secondary"
                                    >
                                        {"Gerar Relatório: "}
                                    </Title>
                                </Col>
                                <Col
                                    style={{
                                        margin: 10
                                    }}
                                >
                                    <Button
                                        key="submit"
                                        type="primary"
                                        danger
                                        onClick={handlePrint}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginRight: 5
                                        }}
                                    >
                                        {'Imprimir PDF '}
                                        <FilePdfOutlined style={{ fontSize: 22 }} />
                                    </Button>
                                </Col>
                            </Row>
                        </Layout>
                    </Col>
                </Row>


                <FiltrosAndTable
                    ref={refComponentToPrint}
                    activeKeyTabs={activeKeyTabs}
                    clickNavigateTab={clickNavigateTab}
                    refFiltersGlobal={refFiltersGlobal}
                    setLoadingScreen={setLoadingScreen}
                    loadingScreen={loadingScreen}
                />
            </Layout>
        </Spin>
    )
}

export default RelUltimoAcesso;
