import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
    Table, Input, Button, Space, notification, Typography, Row, Col,
    Select, Tooltip, Modal, Divider, Breadcrumb
} from 'antd';
import Highlighter from 'react-highlight-words';
import locale from 'antd/es/locale/pt_BR';
import {
    SearchOutlined,
    FilePdfOutlined,
    FileTextOutlined
} from '@ant-design/icons';
import axios from 'axios';
import {
    urlBuscaCidades,
    urlBuscaEstados,
    urlRelatorioPendenciasMunicipiosEstado
} from '../../services/request/urls';

import { useSelector } from 'react-redux';
import { stateGlobal } from '../../interfaces/Global';

import { getToken } from './../../services/token';
import Icon from '@ant-design/icons';
import {
    cardLayout,
    colBotaoSelecionar,
} from './GridStyle';

import {
    removeAcento
} from './../../utils/RemoveAcentos';

import { FaSearch } from 'react-icons/fa';
import { useGetColumnSearchProps } from '../../hooks/FilterTable/stringCLearFiltros';
import { useHistory } from 'react-router';

const { Text, Title } = Typography;

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>

interface interfDadosTabela {
    "id": number,
    "uuid": string,
    "nome": string,
    "uf": string,
    "edtadoId": number,
    "pendencias_cauc": Array<
        {
            "titulo_abreviado": string,
            "titulo": string
        }
    >,
    "pendencias_certidoes": Array<
        {
            "descricao": string
        }
    >
}

interface IDadosEstados {
    id: number,
    sigla: string,
    regiao: {
        id: number,
        sigla: string,
        nome: string
    },
    regiao_id: number,
    nome: string,
    populacao: number
};

interface DadosCidadesAll {
    "id": number,
    "uuid": string,
    "nome": string,
    "microrregiao": {
        "mesorregiao": {
            "estado": {
                "id": number,
                "uuid": string,
                "sigla": string,
                "nome": string
            }
        }
    }
    "estadoId": number,
    "populacao": number,
    "uf": string,
}

const Integracoes: React.FC = () => {

    const refContagemDoFiltro = React.useRef<Array<any>>([]);
    const refFuncaoLimparFiltrosString = useRef<any>(null);

    const propsState = useSelector((state: stateGlobal) => state.global);
    // const queryParans = useQuery();

    const history = useHistory();
    // const refContratoSituacao = useRef<{ nome: string, id: number } | null>(null);

    const refAllDadosCidades = useRef<Array<DadosCidadesAll>>([]);

    // const history = useHistory();
    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();
    const signal1 = axios.CancelToken.source();

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string>>([]);

    const [hasSelectedAdd, setHasSelectedAdd] = useState(true);
    const [hasSelectedView, setHasSelectedView] = useState(false);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);
    const [hasSelectedDel, setHasSelectedDel] = useState(false);

    const [loadingTable, setLoadingTable] = useState(false);
    const [listCidades, setListCidades] = useState<Array<DadosCidadesAll>>([]);


    const buscaDadosCidadesDoBrasil = useCallback((): void => {

        const buscaDeDadosContratos = async () => {
            try {

                setLoadingTable(true)
                let result = await axios.get(urlBuscaCidades,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                refAllDadosCidades.current = result.data

                setListCidades(result.data);
                setLoadingTable(false)


            } catch (error) {

                setLoadingTable(false)

                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Atenção',
                        description:
                            msgErro?.response?.data?.message ? msgErro?.response?.data?.message :
                                'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosContratos();

    }, []);

    //executa apenas ao iniciar o component
    useEffect(() => {

        buscaDadosCidadesDoBrasil();

    }, []);

    //executa apenas quando desmonta o component
    const comonentExecutaAoDestroy = useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
        signal1.cancel("Requisicao cancelada!")
    }, []);

    //abrir navegar editar
    const clickBotaoIntegracoes = useCallback((): void => {

        const buscaDeDados = async () => {

            if (selectedRowKeys.length == 1) {

                let objSelecionado: any = null

                refAllDadosCidades.current.forEach((dados): void => {
                    let convertId: string = dados.uuid.toString();
                    if (convertId == selectedRowKeys[0]) {
                        objSelecionado = dados
                    }
                })

                try {

                    if (objSelecionado) {
                        localStorage.setItem('@GovFacil:ListInteracoesByMunicipio', JSON.stringify(objSelecionado));

                        history.push("/integracoes/" + objSelecionado.id);
                    }
                } catch (error) {
                    let msgErro: any = (error as Error);
                    notification.error({
                        message: 'Erro',
                        description:
                            msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Preencha os campos obrigatorios!',
                    });
                }

            }
        }

        buscaDeDados();

    }, [selectedRowKeys]);

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeys.includes(record.uuid)) {
            let filter = selectedRowKeys.filter((val) => {
                return val != record.uuid
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(true);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length == 0) {
                setSelectedRowKeys([record.uuid]);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else {
                let teste = selectedRowKeys.concat(record.uuid);
                setSelectedRowKeys(teste);
                setHasSelectedAdd(false);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
                setHasSelectedDel(true);
            }
        }
    }, [selectedRowKeys]);

    // //checkbox da tabela de listagem
    const onSelectChange = useCallback((selectedRowKeys): void => {
        if (selectedRowKeys.length > 1) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
            setHasSelectedDel(true);
        } else if (selectedRowKeys.length === 0) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(true);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
            setHasSelectedDel(false);
        } else {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(true);
            setHasSelectedEdit(true);
            setHasSelectedDel(true);
        }
    }, [selectedRowKeys]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '1%'
    };

    const columns = [
        {
            title: 'Cidade',
            dataIndex: 'nome',
            ...useGetColumnSearchProps('nome', refFuncaoLimparFiltrosString),
            width: '20%',
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
            render: (nome: string, record: any) => {
                return (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            <Text
                                style={{
                                    marginLeft: 10
                                }}
                            >
                                {nome + ' - ' + record?.uf}
                            </Text>

                        </div>
                    </>
                )
            }
        },
    ];

    const onClickVoltarConfigOfSystem = useCallback((): void => {
        history.push("/configuracoesdosistema");
    }, [history]);

    return (
        <>
            <Row>
                <Col {...cardLayout}>
                    <Title level={3}><FileTextOutlined style={{ fontSize: 25 }} />{' Integrações'}</Title>
                    <Breadcrumb>
                        {/* <Breadcrumb.Item><a href='#' onClick={onClickVoltarConfigOfSystem}>Configurações do Sistema</a></Breadcrumb.Item> */}
                        <Breadcrumb.Item>Municípios</Breadcrumb.Item>
                    </Breadcrumb>
                    <Divider />
                </Col>
            </Row>
            <div
                style={{
                    position: 'fixed',
                    zIndex: 2,
                    backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                    //
                    width: '100%',
                    // backgroundColor: 'blue',
                    paddingTop: 14,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24
                }}
            >
                <Row
                // style={{ marginBottom: 16 }}
                >
                    <Col>
                        <Button
                            type="primary"
                            // className="botaoAdicionarUsuarioUser"
                            disabled={!hasSelectedEdit}
                            // disabled={!hasSelectedAdd} //ADICIONAR
                            onClick={clickBotaoIntegracoes}
                        >
                            Integrações
                        </Button>
                        {/* <Button
                            type="primary"
                            className="botaoEditarUsuarioUser"
                            // onClick={clickBotaoEditarUsuario}
                            // disabled={!hasSelectedEdit}
                        >
                            Editar
                        </Button> */}
                    </Col>

                </Row>

            </div>
            <div
                style={{
                    marginTop: 50
                }}
                className='marginTopTables'
            >

                <Table
                    rowKey={(record) => record.uuid}
                    loading={loadingTable}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => { onclickTable(record, rowIndex) }, // click row
                            // onDoubleClick: event => { }, // double click row
                            // onContextMenu: event => { }, // right button click row
                            // onMouseEnter: event => { }, // mouse enter row
                            // onMouseLeave: event => { }, // mouse leave row
                        };
                    }}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={listCidades}
                    scroll={{ x: 300 }}
                    onChange={
                        (pagination, filters, sorter, extra) => {
                            refContagemDoFiltro.current = extra.currentDataSource
                        }
                    }
                    title={(registros) => {
                        return (
                            <div
                                style={{
                                    backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                    padding: 10,
                                    margin: -8,
                                    marginBottom: 0.5
                                }}
                            >
                                <Text>
                                    {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                        listCidades.length :
                                        refContagemDoFiltro.current.length} num total de ${listCidades.length} `}
                                </Text>
                            </div>
                        )
                    }}
                    footer={(registros) => {
                        return (
                            <div
                                style={{
                                    textAlign: 'end'
                                }}
                            >
                                <Text>
                                    {/* {`Total: ${listCidades.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                        listCidades.length :
                                        refContagemDoFiltro.current.length} num total de ${listCidades.length} `}
                                </Text>
                            </div>
                        )
                    }}
                    size="small"
                    pagination={{
                        locale: { items_per_page: '' },
                        showSizeChanger: true,
                        defaultPageSize: 100,
                        position: ['topRight', 'bottomRight'],
                        pageSizeOptions: ['10', '20', '50', '100']
                    }}
                    locale={locale.Table}
                />
            </div>
        </>
    );
}

export default Integracoes;
