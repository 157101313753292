import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
    Button, Col, Row, Breadcrumb, Input, Space, Table, Modal, Spin, Form,
    Typography, Tag, notification, Popconfirm, Radio, Divider, Select
} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import {
    cardLayout,
    modaisDeConfig,
    colLisTabelaConfigs,
    modalAddModuloInputs
} from './GridStyle';

import { FaCogs, FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router';
import axios from 'axios';
import { urlContratosPlano, urlContratosPlanoModulo, urlContratosPlanoRecurso } from '../../../../../services/request/urls';

import { getToken } from '../../../../../services/token';
import { convertMoedaBRParaUS, convertMoedaUSParaBR } from '../../../../../services/Conversores';
import { removeAcento } from '../../../../../utils/RemoveAcentos';

const IconCogs = (props: any) => <Icon {...props} component={FaCogs} ></Icon>

const { Title } = Typography;

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>

// {
//     "nome": "Licença Diamond",
//     "descricao": "Descricao do plano",
//     "modulosIds":["e0f768d1-d7ae-49bb-9617-88ad40b66ca5"],
//     "recursosIds":["2f9d8968-65e5-452b-9a0b-002d48d3d6e9"]
//     }
interface interfaceConfigs {
    nome: string,
    descricao: string,
    uuid: number
}


interface IDadosPlanoSelecionadoModal {
    "uuid": string,
    "nome": string,
    // "descricao": string,
    "valor": string,
    "modulos": Array<
        {
            "uuid": string,
            "nome": string,
            "descricao": string,
            "path": string,
            "modoEstado": boolean,
            "modoMunicipio": boolean,
            "createdAt": string,
            "updatedAt": string
        }
    >,
    "recursos": Array<
        {
            "uuid": string,
            "nome": string,
            "descricao": string,
            "modoEstado": boolean,
            "modoMunicipio": boolean,
            "createdAt": string,
            "updatedAt": string
        }
    >,
    "createdAt": string,
    "updatedAt": string
}

const ListGerenciarPlanos: React.FC = () => {

    const formRef = useRef<any>(null);

    const history = useHistory();

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<any>>([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dadosTabelaState, setDadosTabelaState] = useState([]);
    const [hasSelectedAdd, setHasSelectedAdd] = useState(true);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);
    const [hasSelectedDel, setHasSelectedDel] = useState(false);
    const [loadingFormAdd, setLoadingFormAdd] = useState(false);
    const [modalAddConfigVisible, setModalAddConfigVisible] = useState(false);

    const [ativarOuInativarModulo, setAtivarOuInativarModulo] = useState(false);


    //State de editar usuario
    const [initialValueEditId, setInitialValueEditId] = useState<undefined | number>(undefined);


    const refBotaoRemoveModulosPlanoList = useRef<any>(null);
    const refBotaoModulosPlanoList = useRef<any>(null);
    const refDadosModulosPlanoList = useRef<Array<interfaceConfigs>>([]);
    const [dadosModulosPlanoList, setDadosModulosPlanoList] = useState<Array<interfaceConfigs>>([]);
    const [loadingModulosPlanoList, setLoadingModulosPlanoList] = useState(false);

    const refBotaoRemoveRecusosPlanoList = useRef<any>(null);
    const refBotaoRecusosPlanoList = useRef<any>(null);
    const refDadosRecusosPlanoList = useRef<Array<interfaceConfigs>>([]);
    const [dadosRecusosPlanoList, setDadosRecusosPlanoList] = useState<Array<interfaceConfigs>>([]);
    const [loadingRecusosPlanoList, setLoadingRecusosPlanoList] = useState(false);

    const refVIEWDadosPlanoSelect = useRef<IDadosPlanoSelecionadoModal>();
    const [isModalDadosDoPlanoOpen, setIsModalDadosDoPlanoOpen] = useState(false);

    const signal = useRef(axios.CancelToken.source());

    const onClickLimparTodosModulosPlanoList = useCallback(() => {

        if (formRef.current.getFieldValue('modulosIds')?.length >= 1) {

            formRef.current.setFieldsValue(
                { modulosIds: [] }
            );

            refBotaoRemoveModulosPlanoList.current?.blur();
            refBotaoModulosPlanoList.current?.blur();

        }
    }, []);

    const onClickAddTodosModulosPlanoList = useCallback(() => {
        const setAllValuesInputs = async () => {
            try {

                //se nao foi preenchido
                let arrayIds: any = [];
                refDadosModulosPlanoList.current.forEach(async (estadosFiltro) => {

                    arrayIds.push(estadosFiltro.uuid);

                });

                formRef.current.setFieldsValue(
                    { modulosIds: arrayIds }
                );

                refBotaoModulosPlanoList.current?.blur();

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    notification.error({
                        message: 'Erro',
                        description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }
            }
        }

        setAllValuesInputs();
    }, []);

    const buscarDadosModulosPlanoList = useCallback(() => {

        setLoadingModulosPlanoList(true);
        const buscaDados = async () => {
            try {
                let result = await axios.get(urlContratosPlanoModulo,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.current.token
                    });

                refDadosModulosPlanoList.current = result.data;
                setDadosModulosPlanoList(result.data)


                setLoadingModulosPlanoList(false);
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message !== 'Requisicao cancelada!') {
                    setLoadingModulosPlanoList(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar dados!',
                    });
                }
            }
        }

        buscaDados();

    }, []);

    const onClickLimparTodosRecusosPlanoList = useCallback(() => {

        if (formRef.current.getFieldValue('recursosIds')?.length >= 1) {

            formRef.current.setFieldsValue(
                { recursosIds: [] }
            );

            refBotaoRemoveRecusosPlanoList.current?.blur();
            refBotaoRecusosPlanoList.current?.blur();

        }
    }, []);

    const onClickAddTodosRecusosPlanoList = useCallback(() => {
        const setAllValuesInputs = async () => {
            try {

                //se nao foi preenchido
                let arrayIds: any = [];
                refDadosRecusosPlanoList.current.forEach(async (estadosFiltro) => {

                    arrayIds.push(estadosFiltro.uuid);

                });

                formRef.current.setFieldsValue(
                    { recursosIds: arrayIds }
                );

                refBotaoRecusosPlanoList.current?.blur();

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    notification.error({
                        message: 'Erro',
                        description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }
            }
        }

        setAllValuesInputs();
    }, []);

    const buscarDadosRecusosPlanoList = useCallback(() => {

        setLoadingRecusosPlanoList(true);
        const buscaDados = async () => {
            try {
                let result = await axios.get(urlContratosPlanoRecurso,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.current.token
                    });

                refDadosRecusosPlanoList.current = result.data;
                setDadosRecusosPlanoList(result.data)

                setLoadingRecusosPlanoList(false);
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message !== 'Requisicao cancelada!') {
                    setLoadingRecusosPlanoList(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar dados!',
                    });
                }
            }
        }

        buscaDados();

    }, []);

    //executa apenas ao iniciar o component
    useEffect(() => {
        setLoadingTable(true);
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlContratosPlano,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                setDadosTabelaState(result.data);
                setLoadingTable(false);

            } catch (error) {
                setLoadingTable(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        buscaDeDados();
        buscarDadosModulosPlanoList();
        buscarDadosRecusosPlanoList();

    }, []);

    //executa apenas quando salvar
    const salvarModalRecarregaTable = useCallback(() => {
        setLoadingTable(true);
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlContratosPlano,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                setDadosTabelaState(result.data);
                setLoadingTable(false);

            } catch (error) {
                setLoadingTable(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        buscaDeDados();

    }, []);

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, []);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);

    //Search da tabela de listagem
    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) =>
                record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn, handleReset, handleSearch]);

    const getColumnSearchPropsBoolean = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let nome = '';
                if (record[dataIndex]) {
                    nome = 'sim'
                } else {
                    nome = 'não'
                }
                return (
                    nome.toString().toLowerCase().includes(value.toLowerCase())
                )
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn, handleReset, handleSearch]);

    const getColumnSearchPropsBooleanAtivo = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let nome = '';
                if (record[dataIndex]) {
                    nome = 'ativo'
                } else {
                    nome = 'inativo'
                }
                return (
                    nome.toString().toLowerCase().includes(value.toLowerCase())
                )
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn, handleReset, handleSearch]);

    // fim do search da tabela de listagem

    const clickBotaoDeletarDados = useCallback((): void => {
        const buscaDeDados = () => {
            // if (selectedRowKeys.length == 1 || selectedRowKeys.length > 1) {
            if (selectedRowKeys.length == 1) {
                let obj: Array<any> = [];
                let resultforeach = dadosTabelaState.forEach(({ uuid, id }): void => {

                    selectedRowKeys.forEach((val, i) => {
                        if (uuid == val) {
                            obj.push({ uuid })
                        }
                    });

                })

                const excluirDadosModal = async () => {
                    try {
                        let resultCidade = await axios.delete(urlContratosPlano + '/' + obj[0].uuid, {
                            //EXEMPLO APENAS PARA EXCLUIR UM [0] ATE CORRIGIR O BACK
                            data: obj[0],
                            headers: { 'Authorization': 'Bearer ' + getToken() }
                        })
                        notification.success({
                            message: 'Sucesso',
                            description:
                                'Seus dados foram excluídos com sucesso!',
                        });
                        setLoadingFormAdd(false);
                        salvarModalRecarregaTable();
                        setInitialValueEditId(undefined);
                    } catch (error) {

                        let msgErro: any = (error as Error);

                        setLoadingFormAdd(false);
                        notification.error({
                            message: 'Erro',
                            description:
                                msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel deletar, entre em contato com suporte!',
                        });
                    }
                }
                excluirDadosModal();
                setHasSelectedAdd(true);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
                setSelectedRowKeys([]);

            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState, formRef]);

    // const onChangeModalInputOrdem = useCallback((e: any) => {
    //     const originalValue = e.target.value;//+''
    //     const regex = /^[0-9]*$/

    //     if (regex.test(originalValue)) {
    //         setValorCampoModalInputOrdem(originalValue)
    //         formRef.current.setFieldsValue(
    //             { ordem: originalValue }
    //         );
    //     } else {
    //         formRef.current.setFieldsValue(
    //             { ordem: valorCampoModalInputOrdem }
    //         );
    //     }
    // }, [valorCampoModalInputOrdem]);

    const clickCancelModalAddConfig = useCallback(() => {
        setModalAddConfigVisible(false);
        formRef.current.resetFields();
    }, []);

    const clickSubmitModalAddConfig = useCallback((): void => {
        formRef.current?.validateFields()
            .then((values: any) => {
                setLoadingFormAdd(true);

                values.valor = convertMoedaBRParaUS(values?.valor)

                //verifica se ele esta editando ou é um novo
                if (initialValueEditId) {
                    values.uuid = initialValueEditId;

                    const editarDadosModal = async () => {
                        try {
                            await axios.put(urlContratosPlano + '/' + initialValueEditId, values,
                                { headers: { 'Authorization': 'Bearer ' + getToken() } });
                            notification.success({
                                message: 'Sucesso',
                                description:
                                    'Seus dados foram editados com sucesso!',
                            });
                            setLoadingFormAdd(false);
                            salvarModalRecarregaTable();
                            clickCancelModalAddConfig();
                            setInitialValueEditId(undefined);
                        } catch (error) {
                            setLoadingFormAdd(false);
                            notification.error({
                                message: 'Erro',
                                description:
                                    'Não foi possivel salvar, entre em contato com suporte!',
                            });
                        }
                    }
                    editarDadosModal();
                } else {
                    const salvarDadosModal = async () => {
                        try {
                            await axios.post(urlContratosPlano, values,
                                { headers: { 'Authorization': 'Bearer ' + getToken() } });
                            notification.success({
                                message: 'Sucesso',
                                description:
                                    'Seus dados foram criados com sucesso!',
                            });
                            setLoadingFormAdd(false);
                            salvarModalRecarregaTable();
                            clickCancelModalAddConfig();
                        } catch (error) {
                            setLoadingFormAdd(false);
                            notification.error({
                                message: 'Erro',
                                description:
                                    'Não foi possivel salvar, entre em contato com suporte!',
                            });
                        }
                    }
                    salvarDadosModal();
                }
                setHasSelectedAdd(true);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
                setSelectedRowKeys([])

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [initialValueEditId, clickCancelModalAddConfig, salvarModalRecarregaTable]);

    //abrir modal
    const clickBotaoAddEsfera = useCallback((): void => {
        setInitialValueEditId(undefined);
        setModalAddConfigVisible(true);
    }, []);

    //Editar dados
    const clickBotaoEditarEsfera = useCallback((): void => {

        const buscaDeDados = async () => {

            if (selectedRowKeys.length === 1) {
                let obj: Array<any> = [];
                dadosTabelaState.forEach((objEditar: any): void => {
                    if (objEditar.uuid === selectedRowKeys[0]) {
                        obj.push(objEditar)
                    }
                })

                const { data } = await axios.get(urlContratosPlano + '/' + obj[0].uuid,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    }
                );

                let arrModulo: Array<any> = [];
                data.modulos.forEach((modul: any): void => {
                    arrModulo.push(modul.uuid)
                })

                let arrRecursos: Array<any> = [];
                data.recursos.forEach((recurs: any): void => {
                    arrRecursos.push(recurs.uuid)
                })



                if (formRef.current) {
                    formRef.current.setFieldsValue(
                        {
                            nome: obj[0].nome,
                            // descricao: obj[0].descricao,
                            // path: obj[0].path,
                            // status: obj[0].status,
                            valor: convertMoedaUSParaBR(obj[0].valor),
                            modulosIds: arrModulo,
                            recursosIds: arrRecursos,

                        }
                    );
                } else {
                    setTimeout(() => {
                        formRef.current?.setFieldsValue(
                            {
                                nome: obj[0].nome,
                                // descricao: obj[0].descricao,
                                // path: obj[0].path,
                                // status: obj[0].status,
                                valor: convertMoedaUSParaBR(obj[0].valor),
                                modulosIds: arrModulo,
                                recursosIds: arrRecursos,
                            }
                        );
                    }, 100);
                }

                setInitialValueEditId(obj[0].uuid)
                setModalAddConfigVisible(true);

            }
        }

        buscaDeDados();

        setModalAddConfigVisible(true);
    }, [selectedRowKeys, dadosTabelaState, formRef]);

    const clickBotaoVisualizar = useCallback((): void => {

        const buscaDeDados = async () => {

            try {
                if (selectedRowKeys.length === 1) {
                    let obj: Array<any> = [];
                    dadosTabelaState.forEach((objEditar: any): void => {
                        if (objEditar.uuid === selectedRowKeys[0]) {
                            obj.push(objEditar)
                        }
                    })
                    setLoadingFormAdd(true)

                    const { data } = await axios.get(urlContratosPlano + '/' + obj[0].uuid,
                        {
                            headers: { 'Authorization': 'Bearer ' + getToken() }
                        }
                    );

                    refVIEWDadosPlanoSelect.current = data

                    setTimeout(() => {
                        setIsModalDadosDoPlanoOpen(true)
                        setLoadingFormAdd(false)
                    }, 200);

                }
            } catch (error) {
                setLoadingFormAdd(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel salvar, entre em contato com suporte!',
                });
            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState, formRef]);

    //executa ao iniciar e did update para atualizar o botao de ativo ou inativo
    useEffect(() => {
        const buscaDeDados = async () => {
            try {
                if (selectedRowKeys.length === 1) {
                    let obj: Array<any> = [];
                    dadosTabelaState.forEach((objEditar: any): void => {
                        if (objEditar.uuid === selectedRowKeys[0]) {
                            obj.push(objEditar)
                        }
                    })

                    setAtivarOuInativarModulo(obj[0].status)

                }


            } catch (error) {

            }
        }

        buscaDeDados();

    }, [selectedRowKeys]);

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        //verifica se nome esta no array
        if (selectedRowKeys.includes(record.uuid)) {
            let filter = selectedRowKeys.filter((val) => {
                return val !== record.uuid
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(false);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(true);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length === 0) {
                setSelectedRowKeys([record.uuid]);
                setHasSelectedAdd(false);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else {
                let teste = selectedRowKeys.concat(record.uuid);
                setSelectedRowKeys(teste);
                setHasSelectedAdd(false);
                setHasSelectedEdit(false);
                setHasSelectedDel(true);
            }
        }
    }, [selectedRowKeys]);

    //checkbox da tabela de listagem
    const onSelectChange = useCallback((rowKeys): void => {
        if (rowKeys.length > 1) {
            setSelectedRowKeys(rowKeys);
            setHasSelectedAdd(false);
            setHasSelectedEdit(false);
            setHasSelectedDel(false);
        } else if (rowKeys.length === 0) {
            setSelectedRowKeys(rowKeys);
            setHasSelectedAdd(true);
            setHasSelectedEdit(false);
            setHasSelectedDel(false);
        } else {
            setSelectedRowKeys(rowKeys);
            setHasSelectedAdd(false);
            setHasSelectedEdit(true);
            setHasSelectedDel(true);
        }
    }, [selectedRowKeys, setSelectedRowKeys]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '5%'
    };

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...getColumnSearchProps('nome'),
            width: '20%',
        },
        // {
        //     title: 'Descrição',
        //     dataIndex: 'descricao',
        //     ...getColumnSearchProps('descricao'),
        //     width: '20%',
        // },
        {
            title: 'Valor',
            dataIndex: 'valor',
            ...getColumnSearchProps('valor'),
            width: '20%',
            render: (val: any) => {
                return (
                    convertMoedaUSParaBR(val)
                )
            },
        }
    ];

    const onClickVoltarConfigOfSystem = useCallback((): void => {
        history.push("/configuracoesdosistema");
    }, [history]);

    const onChangeMoney = useCallback((e: any) => {

        formRef.current.setFieldsValue(
            { valor: convertMoedaUSParaBR(e.target.value) }
        );

    }, []);

    return (
        <>
            <Row>
                <Col {...cardLayout}>
                    <Title level={3}><IconCogs style={{ fontSize: 35 }} /> Configurações do Sistema</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item><a href='#' onClick={onClickVoltarConfigOfSystem}>Configurações do Sistema</a></Breadcrumb.Item>
                        <Breadcrumb.Item>Gerenciar Planos</Breadcrumb.Item>
                    </Breadcrumb>
                    <Divider />
                </Col>
            </Row>
            <Row style={{ marginBottom: 16 }}>
                <Col>
                    <Button type="primary" className="botaoAdicionarAssociacao" disabled={!hasSelectedAdd} onClick={clickBotaoAddEsfera} >
                        Adicionar
                    </Button>
                    <Button
                        className="botaoViewUsuarioUser"
                        type="primary"
                        onClick={clickBotaoVisualizar}
                        disabled={!hasSelectedEdit}
                    >
                        Visualizar
                    </Button>
                </Col>
                <Col>
                    <Button type="primary" className="botaoEditarAssociacao" onClick={clickBotaoEditarEsfera} disabled={!hasSelectedEdit} >
                        Editar
                    </Button>
                    <Popconfirm
                        title="Deseja excluir?"
                        onConfirm={clickBotaoDeletarDados}
                        onCancel={() => { }}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button className="botaoDeleteAssociacao" type="primary" danger
                            // disabled={!hasSelectedDel}
                            disabled={!hasSelectedEdit}
                        >
                            <DeleteOutlined />
                        </Button>
                    </Popconfirm>
                </Col>
            </Row>
            <Row>
                <Col {...colLisTabelaConfigs}>
                    <Table
                        loading={loadingTable}
                        rowKey={(record) => record.uuid}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => { onclickTable(record, rowIndex) }, // click row
                            };
                        }}
                        rowSelection={rowSelection}
                        columns={columns} dataSource={dadosTabelaState}
                        size="small"
                        pagination={{ locale: { items_per_page: '' } }}
                    />
                </Col>
            </Row>
            <Modal
                title="Adicionar Plano"
                visible={modalAddConfigVisible}
                className="modalAddAssociacao"
                onOk={clickSubmitModalAddConfig}
                onCancel={clickCancelModalAddConfig}
                footer={[
                    <Button key="back" disabled={loadingFormAdd} onClick={clickCancelModalAddConfig}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loadingFormAdd} onClick={clickSubmitModalAddConfig}>
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingFormAdd}>
                    <Form
                        ref={formRef}
                        name="formModalAddAssociacao"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                        onFinishFailed={() => { }}
                    >
                        <Row>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Preencha o nome do módulo!",
                                        }
                                    ]}
                                    name="nome"
                                    label="Nome"
                                    style={{ margin: 5 }}
                                >
                                    <Input placeholder="Nome" />
                                </Form.Item>
                            </Col>
                            {/* <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Preencha a descrição!",
                                        }
                                    ]}
                                    name="descricao"
                                    label="Descrição"
                                    style={{ margin: 5 }}
                                >
                                    <Input placeholder="Descrição" />
                                </Form.Item>
                            </Col> */}

                            {/* <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Preencha a Path!",
                                        }
                                    ]}
                                    name="path"
                                    label="Path"
                                    style={{ margin: 5 }}
                                >
                                    <Input placeholder="Path" />
                                </Form.Item>
                            </Col> */}

                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    name="valor"
                                    label="Valor (R$)"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Por favor digite a valor",
                                        },
                                    ]}
                                    style={{ margin: 5 }}
                                >
                                    <Input
                                        onChange={(e) => onChangeMoney(e)}
                                        placeholder="0,00"
                                        maxLength={21}
                                    />
                                </Form.Item>
                            </Col>

                            {/* <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    initialValue={true}
                                    name="status"
                                    label="Status"
                                    rules={[
                                    ]}
                                    style={{ margin: 5 }}
                                >
                                    <Radio.Group>
                                        <Radio.Button value={true}>Ativo</Radio.Button>
                                        <Radio.Button value={false}>Inativo</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col> */}

                            <Col span={24}>
                                <Form.Item
                                    name="modulosIds"
                                    label="Selecione Modulo"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Obrigatório selecionar!",
                                        }
                                    ]}
                                    style={{ margin: 5 }}
                                >
                                    <Select
                                        showSearch
                                        loading={loadingModulosPlanoList}
                                        notFoundContent={loadingModulosPlanoList ? <Spin size="small" /> : null}
                                        optionFilterProp="children"
                                        placeholder="Selecione..."
                                        mode="multiple"
                                        filterOption={(input: any, option: any) => {
                                            let textDigit = removeAcento(input)
                                            let listCidade = removeAcento(option?.children);
                                            return listCidade.indexOf(textDigit) >= 0
                                        }}
                                        dropdownRender={menu => (
                                            <div>

                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Button
                                                        ref={refBotaoRemoveModulosPlanoList}
                                                        block
                                                        danger
                                                        style={{
                                                            width: 80,
                                                            marginRight: 10
                                                        }}
                                                        onClick={onClickLimparTodosModulosPlanoList}
                                                        type="primary"
                                                    ><CloseCircleOutlined /></Button>
                                                    <Button
                                                        ref={refBotaoModulosPlanoList}
                                                        block onClick={onClickAddTodosModulosPlanoList}
                                                        type="dashed"
                                                    >Todos</Button>
                                                </div>
                                                <Divider style={{ margin: '4px 0' }} />
                                                {menu}
                                            </div>
                                        )}
                                    >
                                        {
                                            dadosModulosPlanoList.map((item) => {
                                                return (
                                                    <Select.Option
                                                        value={item.uuid}
                                                        key={item.uuid}
                                                    >
                                                        {item.descricao}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="recursosIds"
                                    label="Selecione Recurso"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Obrigatório selecionar!",
                                        }
                                    ]}
                                    style={{ margin: 5 }}
                                >
                                    <Select
                                        showSearch
                                        loading={loadingRecusosPlanoList}
                                        notFoundContent={loadingRecusosPlanoList ? <Spin size="small" /> : null}
                                        optionFilterProp="children"
                                        placeholder="Selecione..."
                                        mode="multiple"
                                        filterOption={(input: any, option: any) => {
                                            let textDigit = removeAcento(input)
                                            let listCidade = removeAcento(option?.children);
                                            return listCidade.indexOf(textDigit) >= 0
                                        }}
                                        dropdownRender={menu => (
                                            <div>

                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Button
                                                        ref={refBotaoRemoveRecusosPlanoList}
                                                        block
                                                        danger
                                                        style={{
                                                            width: 80,
                                                            marginRight: 10
                                                        }}
                                                        onClick={onClickLimparTodosRecusosPlanoList}
                                                        type="primary"
                                                    ><CloseCircleOutlined /></Button>
                                                    <Button
                                                        ref={refBotaoRecusosPlanoList}
                                                        block onClick={onClickAddTodosRecusosPlanoList}
                                                        type="dashed"
                                                    >Todos</Button>
                                                </div>
                                                <Divider style={{ margin: '4px 0' }} />
                                                {menu}
                                            </div>
                                        )}
                                    >
                                        {
                                            dadosRecusosPlanoList.map((item) => {
                                                return (
                                                    <Select.Option
                                                        value={item.uuid}
                                                        key={item.uuid}
                                                    >
                                                        {item.descricao}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
            <Modal
                title="Dados do Plano"
                visible={isModalDadosDoPlanoOpen}
                onOk={() => { setIsModalDadosDoPlanoOpen(false) }}
                footer={[
                    <></>
                    // <Button key="back" loading={loadingSpinModal} onClick={clickCancelModalFiltros}>
                    //     Cancelar
                    // </Button>
                    ,
                    <Button
                        type="primary"
                        onClick={() => { setIsModalDadosDoPlanoOpen(false) }}
                    >
                        OK
                    </Button>,
                ]}
            // onCancel={() => { setIsModalDadosDoPlanoOpen(false) }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <p style={{ marginRight: 5 }}>Nome:</p>
                    <p>{refVIEWDadosPlanoSelect.current?.nome}</p>
                </div>
                {/* <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <p style={{ marginRight: 5 }}>Descrição:</p>
                    <p>{refVIEWDadosPlanoSelect.current?.descricao}</p>
                </div> */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <p style={{ marginRight: 5 }}>Valor:</p>
                    <p>{convertMoedaUSParaBR(refVIEWDadosPlanoSelect.current?.valor)}</p>
                </div>

                <Divider className="dividerPermisaoDoApp" style={{ margin: '5px 0px' }} />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <p style={{ marginRight: 5 }}>Modulos:</p>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap'
                        }}
                    >
                        {refVIEWDadosPlanoSelect.current?.modulos.map((value) => {
                            return (
                                <span key={value.uuid}>
                                    <Tag >
                                        {value.descricao}
                                    </Tag>
                                </span>
                            )
                        })}
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 10
                    }}
                >
                    <p style={{ marginRight: 5 }}>Recursos:</p>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap'
                        }}
                    >
                        {refVIEWDadosPlanoSelect.current?.recursos.map((value) => {
                            return (
                                <span key={value.uuid}>
                                    <Tag >
                                        {value.descricao}
                                    </Tag>
                                </span>
                            )
                        })}
                    </div>
                </div>

            </Modal>
        </>
    );
}

export default ListGerenciarPlanos;
