import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const useTabAcessos = () => {

    const refFiltersGlobal = useRef<any>();

    const queryParans = useQuery();

    const [activeKeyTabs, setActiveKeyTabs] = useState<string>('RelUltimoAcesso');
    const [loading, setLoading] = useState(false);

    const clickNavigateTab = useCallback((aba: string) => {
        setActiveKeyTabs(aba)
    }, [])

    useEffect(() => {

        const abaFilter = queryParans.get("aba");

        if (typeof abaFilter === 'string') {
            setActiveKeyTabs(abaFilter)
        }

    }, []);

    return {
        setActiveKeyTabs,
        activeKeyTabs,
        loading,
        setLoading,
        clickNavigateTab,
        refFiltersGlobal,
    }
}
