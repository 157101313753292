import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
    Button, Col, Row, Breadcrumb, Input, Space, Table, Modal, Spin, Form,
    Typography, Tag, notification, Popconfirm, Radio, Divider
} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import {
    cardLayout,
    modaisDeConfig,
    colLisTabelaConfigs,
    modalAddModuloInputs
} from './GridStyle';

import { FaCogs, FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router';
import axios from 'axios';
import { urlModulo } from '../../../../../services/request/urls';

import { getToken } from '../../../../../services/token';

const IconCogs = (props: any) => <Icon {...props} component={FaCogs} ></Icon>

const { Title } = Typography;

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>

const ListModulo: React.FC = () => {

    const formRef = useRef<any>(null);

    const history = useHistory();

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<any>>([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dadosTabelaState, setDadosTabelaState] = useState([]);
    const [hasSelectedAdd, setHasSelectedAdd] = useState(true);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);
    const [hasSelectedDel, setHasSelectedDel] = useState(false);
    const [loadingFormAdd, setLoadingFormAdd] = useState(false);
    const [modalAddConfigVisible, setModalAddConfigVisible] = useState(false);

    const [ativarOuInativarModulo, setAtivarOuInativarModulo] = useState(false);

    const [valorCampoModalInputOrdem, setValorCampoModalInputOrdem] = useState('');

    //State de editar usuario
    const [initialValueEditId, setInitialValueEditId] = useState<undefined | number>(undefined);

    //executa apenas ao iniciar o component
    useEffect(() => {
        setLoadingTable(true);
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlModulo,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                setDadosTabelaState(result.data);
                setLoadingTable(false);

            } catch (error) {
                setLoadingTable(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        buscaDeDados();

    }, []);

    //executa apenas quando salvar
    const salvarModalRecarregaTable = useCallback(() => {
        setLoadingTable(true);
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlModulo,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                setDadosTabelaState(result.data);
                setLoadingTable(false);

            } catch (error) {
                setLoadingTable(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        buscaDeDados();

    }, []);

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, []);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);

    //Search da tabela de listagem
    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) =>
                record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn, handleReset, handleSearch]);

    const getColumnSearchPropsBoolean = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let nome = '';
                if (record[dataIndex]) {
                    nome = 'sim'
                } else {
                    nome = 'não'
                }
                return (
                    nome.toString().toLowerCase().includes(value.toLowerCase())
                )
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn, handleReset, handleSearch]);

    const getColumnSearchPropsBooleanAtivo = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let nome = '';
                if (record[dataIndex]) {
                    nome = 'ativo'
                } else {
                    nome = 'inativo'
                }
                return (
                    nome.toString().toLowerCase().includes(value.toLowerCase())
                )
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn, handleReset, handleSearch]);

    // fim do search da tabela de listagem

    const clickBotaoDeletarDados = useCallback((ativo: boolean): void => {
        const buscaDeDados = () => {
            // if (selectedRowKeys.length == 1 || selectedRowKeys.length > 1) {
            if (selectedRowKeys.length === 1) {
                let obj: Array<object> = [];
                dadosTabelaState.forEach(({ nome, id, uuid }): void => {

                    selectedRowKeys.forEach((val, i) => {
                        if (uuid === val) {
                            obj.push({
                                id: id,
                                status: ativo
                            })
                        }
                    });

                })

                const excluirDadosModal = async () => {
                    try {

                        await axios.patch(urlModulo, obj[0], {
                            //EXEMPLO APENAS PARA EXCLUIR UM [0] ATE CORRIGIR O BACK
                            headers: { 'Authorization': 'Bearer ' + getToken() }
                        })

                        notification.success({
                            message: 'Sucesso',
                            description:
                                `Módulo ${ativo ? 'ativado' : 'inativado'} com sucesso!`,
                        });
                        setLoadingFormAdd(false);
                        salvarModalRecarregaTable();
                        setInitialValueEditId(undefined);
                    } catch (error) {
                        setLoadingFormAdd(false);
                        notification.error({
                            message: 'Erro',
                            description:
                                'Não foi possivel Inativar, entre em contato com suporte!',
                        });
                    }
                }
                excluirDadosModal();
                setHasSelectedAdd(true);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
                setSelectedRowKeys([]);

            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState, salvarModalRecarregaTable]);

    // const onChangeModalInputOrdem = useCallback((e: any) => {
    //     const originalValue = e.target.value;//+''
    //     const regex = /^[0-9]*$/

    //     if (regex.test(originalValue)) {
    //         setValorCampoModalInputOrdem(originalValue)
    //         formRef.current.setFieldsValue(
    //             { ordem: originalValue }
    //         );
    //     } else {
    //         formRef.current.setFieldsValue(
    //             { ordem: valorCampoModalInputOrdem }
    //         );
    //     }
    // }, [valorCampoModalInputOrdem]);

    const clickCancelModalAddConfig = useCallback(() => {
        setModalAddConfigVisible(false);
        formRef.current.resetFields();
    }, []);

    const clickSubmitModalAddConfig = useCallback((): void => {
        formRef.current?.validateFields()
            .then((values: any) => {
                setLoadingFormAdd(true);

                //verifica se ele esta editando ou é um novo
                if (initialValueEditId) {
                    values.id = initialValueEditId;

                    const editarDadosModal = async () => {
                        try {
                            await axios.put(urlModulo, values,
                                { headers: { 'Authorization': 'Bearer ' + getToken() } });
                            notification.success({
                                message: 'Sucesso',
                                description:
                                    'Seus dados foram editados com sucesso!',
                            });
                            setLoadingFormAdd(false);
                            salvarModalRecarregaTable();
                            clickCancelModalAddConfig();
                            setInitialValueEditId(undefined);
                        } catch (error) {
                            setLoadingFormAdd(false);
                            notification.error({
                                message: 'Erro',
                                description:
                                    'Não foi possivel salvar, entre em contato com suporte!',
                            });
                        }
                    }
                    editarDadosModal();
                } else {
                    const salvarDadosModal = async () => {
                        try {
                            await axios.post(urlModulo, values,
                                { headers: { 'Authorization': 'Bearer ' + getToken() } });
                            notification.success({
                                message: 'Sucesso',
                                description:
                                    'Seus dados foram criados com sucesso!',
                            });
                            setLoadingFormAdd(false);
                            salvarModalRecarregaTable();
                            clickCancelModalAddConfig();
                        } catch (error) {
                            setLoadingFormAdd(false);
                            notification.error({
                                message: 'Erro',
                                description:
                                    'Não foi possivel salvar, entre em contato com suporte!',
                            });
                        }
                    }
                    salvarDadosModal();
                }
                setHasSelectedAdd(true);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
                setSelectedRowKeys([])

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [initialValueEditId, clickCancelModalAddConfig, salvarModalRecarregaTable]);

    //abrir modal
    const clickBotaoAddEsfera = useCallback((): void => {
        setInitialValueEditId(undefined);
        setModalAddConfigVisible(true);
    }, []);

    //Editar dados
    const clickBotaoEditarEsfera = useCallback((): void => {

        const buscaDeDados = () => {

            if (selectedRowKeys.length === 1) {
                let obj: Array<any> = [];
                dadosTabelaState.forEach((objEditar: any): void => {
                    if (objEditar.uuid === selectedRowKeys[0]) {
                        obj.push(objEditar)
                    }
                })

                if (formRef.current) {
                    formRef.current.setFieldsValue(
                        {
                            nome: obj[0].nome,
                            descricao: obj[0].descricao,
                            path: obj[0].path,
                            status: obj[0].status,
                            modo_estado: obj[0].modo_estado,

                        }
                    );
                } else {
                    setTimeout(() => {
                        formRef.current?.setFieldsValue(
                            {
                                nome: obj[0].nome,
                                descricao: obj[0].descricao,
                                path: obj[0].path,
                                status: obj[0].status,
                                modo_estado: obj[0].modo_estado,
                            }
                        );
                    }, 100);
                }

                setInitialValueEditId(obj[0].id)
                setModalAddConfigVisible(true);

            }
        }

        buscaDeDados();

        setModalAddConfigVisible(true);
    }, [selectedRowKeys, dadosTabelaState, formRef]);

    //executa ao iniciar e did update para atualizar o botao de ativo ou inativo
    useEffect(() => {
        const buscaDeDados = async () => {
            try {
                if (selectedRowKeys.length === 1) {
                    let obj: Array<any> = [];
                    dadosTabelaState.forEach((objEditar: any): void => {
                        if (objEditar.uuid === selectedRowKeys[0]) {
                            obj.push(objEditar)
                        }
                    })

                    setAtivarOuInativarModulo(obj[0].status)

                }


            } catch (error) {

            }
        }

        buscaDeDados();

    }, [selectedRowKeys]);

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        //verifica se nome esta no array
        if (selectedRowKeys.includes(record.uuid)) {
            let filter = selectedRowKeys.filter((val) => {
                return val !== record.uuid
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(false);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(true);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length === 0) {
                setSelectedRowKeys([record.uuid]);
                setHasSelectedAdd(false);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else {
                let teste = selectedRowKeys.concat(record.uuid);
                setSelectedRowKeys(teste);
                setHasSelectedAdd(false);
                setHasSelectedEdit(false);
                setHasSelectedDel(true);
            }
        }
    }, [selectedRowKeys]);

    //checkbox da tabela de listagem
    const onSelectChange = useCallback((rowKeys): void => {
        if (rowKeys.length > 1) {
            setSelectedRowKeys(rowKeys);
            setHasSelectedAdd(false);
            setHasSelectedEdit(false);
            setHasSelectedDel(false);
        } else if (rowKeys.length === 0) {
            setSelectedRowKeys(rowKeys);
            setHasSelectedAdd(true);
            setHasSelectedEdit(false);
            setHasSelectedDel(false);
        } else {
            setSelectedRowKeys(rowKeys);
            setHasSelectedAdd(false);
            setHasSelectedEdit(true);
            setHasSelectedDel(true);
        }
    }, [selectedRowKeys, setSelectedRowKeys]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '5%'
    };

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...getColumnSearchProps('nome'),
            width: '20%',
        },
        {
            title: 'Modo Estado',
            dataIndex: 'modo_estado',
            ...getColumnSearchPropsBoolean('modo_estado'),
            width: '10%',
            render: (val: any) => {
                let cor = '';
                let nome = '';
                switch (val) {
                    case true:
                        cor = 'green';
                        nome = 'SIM';
                        break;
                    case false:
                        cor = 'blue';
                        nome = 'NÃO';
                        break;

                    default:
                        cor = ''
                        break;
                }
                return (
                    <span>
                        <Tag color={cor} key={val?.id}>
                            {nome}
                        </Tag>
                    </span>
                )
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            ...getColumnSearchPropsBooleanAtivo('status'),
            width: '5%',
            render: (val: any) => {
                let cor = '';
                let nome = '';
                switch (val) {
                    case true:
                        cor = 'green';
                        nome = 'ATIVO';
                        break;
                    case false:
                        cor = 'red';
                        nome = 'INATIVO';
                        break;

                    default:
                        cor = ''
                        break;
                }
                return (
                    <span>
                        <Tag color={cor} key={val?.id}>
                            {nome}
                        </Tag>
                    </span>
                )
            },
        },
    ];

    const onClickVoltarConfigOfSystem = useCallback((): void => {
        history.push("/configuracoesdosistema");
    }, [history]);

    return (
        <>
            <Row>
                <Col {...cardLayout}>
                    <Title level={3}><IconCogs style={{ fontSize: 35 }} /> Configurações do Sistema</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item><a href='#' onClick={onClickVoltarConfigOfSystem}>Configurações do Sistema</a></Breadcrumb.Item>
                        <Breadcrumb.Item>Módulo</Breadcrumb.Item>
                    </Breadcrumb>
                    <Divider />
                </Col>
            </Row>
            <Row style={{ marginBottom: 16 }}>
                <Col>
                    <Button type="primary" className="botaoAdicionarAssociacao" disabled={!hasSelectedAdd} onClick={clickBotaoAddEsfera} >
                        Adicionar
                    </Button>

                </Col>
                <Col>
                    <Button type="primary" className="botaoEditarAssociacao" onClick={clickBotaoEditarEsfera} disabled={!hasSelectedEdit} >
                        Editar
                    </Button>
                    {selectedRowKeys.length === 1 ?
                        ativarOuInativarModulo ?
                            <>
                                <Popconfirm
                                    title="Deseja realmente inativar o modulo?"
                                    onConfirm={() => clickBotaoDeletarDados(false)}
                                    onCancel={() => { }}
                                    okText="Sim"
                                    cancelText="Não"
                                >
                                    <Button className="botaoDeleteAssociacao" type="primary" danger
                                        // disabled={!hasSelectedDel}
                                        disabled={!hasSelectedEdit}
                                    >
                                        Inativar
                                    </Button>
                                </Popconfirm>
                            </>
                            :
                            <>
                                <Popconfirm
                                    title="Deseja realmente ativar modulo?"
                                    onConfirm={() => clickBotaoDeletarDados(true)}
                                    onCancel={() => { }}
                                    okText="Sim"
                                    cancelText="Não"
                                >
                                    <Button className="botaoAtivarUsuario" type="primary"
                                        // disabled={!hasSelectedDel}
                                        disabled={!hasSelectedEdit}
                                    >
                                        Ativar
                                    </Button>
                                </Popconfirm>
                            </>
                        :
                        <></>
                    }
                </Col>
            </Row>
            <Row>
                <Col {...colLisTabelaConfigs}>
                    <Table
                        loading={loadingTable}
                        rowKey={(record) => record.uuid}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => { onclickTable(record, rowIndex) }, // click row
                            };
                        }}
                        rowSelection={rowSelection}
                        columns={columns} dataSource={dadosTabelaState}
                        size="small"
                        pagination={{ locale: { items_per_page: '' } }}
                    />
                </Col>
            </Row>
            <Modal
                title="Adicionar Módulo"
                visible={modalAddConfigVisible}
                className="modalAddAssociacao"
                onOk={clickSubmitModalAddConfig}
                onCancel={clickCancelModalAddConfig}
                footer={[
                    <Button key="back" disabled={loadingFormAdd} onClick={clickCancelModalAddConfig}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loadingFormAdd} onClick={clickSubmitModalAddConfig}>
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingFormAdd}>
                    <Form
                        ref={formRef}
                        name="formModalAddAssociacao"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                        onFinishFailed={() => { }}
                    >
                        <Row>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Preencha o nome do módulo!",
                                        }
                                    ]}
                                    name="nome"
                                    label="Nome"
                                    style={{ margin: 5 }}
                                >
                                    <Input placeholder="Nome" />
                                </Form.Item>
                            </Col>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Preencha a descrição!",
                                        }
                                    ]}
                                    name="descricao"
                                    label="Descrição"
                                    style={{ margin: 5 }}
                                >
                                    <Input placeholder="Descrição" />
                                </Form.Item>
                            </Col>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Preencha a Path!",
                                        }
                                    ]}
                                    name="path"
                                    label="Path"
                                    style={{ margin: 5 }}
                                >
                                    <Input placeholder="Path" />
                                </Form.Item>
                            </Col>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    initialValue={true}
                                    name="status"
                                    label="Status"
                                    rules={[
                                    ]}
                                    style={{ margin: 5 }}
                                >
                                    <Radio.Group>
                                        <Radio.Button value={true}>Ativo</Radio.Button>
                                        <Radio.Button value={false}>Inativo</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    initialValue={false}
                                    name="modo_estado"
                                    label="Modo Estado"
                                    rules={[
                                    ]}
                                    style={{ margin: 5 }}
                                >
                                    <Radio.Group>
                                        <Radio.Button value={true}>Sim</Radio.Button>
                                        <Radio.Button value={false}>Não</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
}

export default ListModulo;
