export const Layout1_1 = {
    xs: { span: 16, offset: 6 }, //<576
    sm: { span: 14, offset: 6 }, //>576
    md: { span: 16, offset: 6 }, // >768
    lg: { span: 16, offset: 6 }, // > 992
    xl: { span: 16, offset: 6 }, // > 1200
    xxl: { span: 16, offset: 6 }, // > 1600
};

export const selectedContratoUserAdd = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 12, offset: 0 },
    xxl: { span: 12, offset: 0 },
};

export const selectedEsferaOrgaoAdd = {
    xs: { span: 24, offset: 0 },
    sm: { span: 12, offset: 0 },
    md: { span: 12, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 6, offset: 0 },
    xxl: { span: 6, offset: 0 },
};

export const selectedPoderOrgaoAdd = {
    xs: { span: 24, offset: 0 },
    sm: { span: 11, offset: 1 },
    md: { span: 11, offset: 1 },
    lg: { span: 11, offset: 1 },
    xl: { span: 5, offset: 1 },
    xxl: { span: 5, offset: 1 },
};

export const selectedNomeOrgaoAdd = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 5, offset: 1 },
    xxl: { span: 5, offset: 1 },
};
export const selectedVendedorAddContrato = {
    xs: { span: 24, offset: 0 },
    sm: { span: 11, offset: 0 },
    md: { span: 11, offset: 0 },
    lg: { span: 11, offset: 1 },
    xl: { span: 5, offset: 1 },
    xxl: { span: 5, offset: 1 },
};

export const selectedStatusOrgaoAdd = {
    xs: { span: 24, offset: 0 },
    sm: { span: 12, offset: 1 },
    md: { span: 12, offset: 1 },
    lg: { span: 12, offset: 0 },
    xl: { span: 6, offset: 0 },
    xxl: { span: 6, offset: 0 },
};
export const selectedTipoContrato = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 11, offset: 1 },
    xl: { span: 5, offset: 1 },
    xxl: { span: 5, offset: 1 },
};
export const selectedSituacaoAddContrato = {
    xs: { span: 24, offset: 0 },
    sm: { span: 11, offset: 0 },
    md: { span: 11, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 5, offset: 1 },
    xxl: { span: 5, offset: 1 },
};
export const selectedModalidadeAddContrato = {
    xs: { span: 24, offset: 0 },
    sm: { span: 12, offset: 1 },
    md: { span: 12, offset: 1 },
    lg: { span: 11, offset: 1 },
    xl: { span: 5, offset: 1 },
    xxl: { span: 5, offset: 1 },
};

export const selectedDataEnvioDaProposta = {
    xs: { span: 24, offset: 0 },
    sm: { span: 11, offset: 0 },
    md: { span: 11, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 6, offset: 0 },
    xxl: { span: 6, offset: 0 },
};
export const selectedVigenciaAddContrato = {
    xs: { span: 24, offset: 0 },
    sm: { span: 12, offset: 1 },
    md: { span: 12, offset: 1 },
    lg: { span: 11, offset: 1 },
    xl: { span: 5, offset: 1 },
    xxl: { span: 5, offset: 1 },
};
export const inputValorContratado = {
    xs: { span: 24, offset: 0 },
    sm: { span: 11, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 5, offset: 1 },
    xxl: { span: 5, offset: 1 },
};
export const selectedCadastroProcessosJuridicos = {
    xs: { span: 24, offset: 0 },
    sm: { span: 12, offset: 1 },
    md: { span: 24, offset: 0 },
    lg: { span: 11, offset: 1 },
    xl: { span: 5, offset: 1 },
    xxl: { span: 5, offset: 1 },
};
export const inputObservaçoesInAddContrato = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 8, offset: 0 },
    xxl: { span: 8, offset: 0 },
};

export const cardLayout = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};

export const botaoSalvar = {
    xs: { span: 16, offset: 8 }, //<576
    sm: { span: 8, offset: 16 }, //>576
    md: { span: 10, offset: 14 }, // >768
    lg: { span: 7, offset: 18 }, // > 992
    xl: { span: 6, offset: 18 }, // > 1200
    xxl: { span: 6, offset: 18 }, // > 1600
};

export const inputSelectEstadosCity = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 23, offset: 0 }, // > 992
    xl: { span: 11, offset: 0 }, // > 1200
    xxl: { span: 11, offset: 0 }, // > 1600
};

export const inputSelectCidades = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 23, offset: 0 }, // > 992
    xl: { span: 11, offset: 1 }, // > 1200
    xxl: { span: 11, offset: 1 }, // > 1600
};

export const inputSelectEstadosAssociacao = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 23, offset: 0 }, // > 992
    xl: { span: 11, offset: 0 }, // > 1200
    xxl: { span: 11, offset: 0 }, // > 1600
};

export const inputSelectAssociacao = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 23, offset: 0 }, // > 992
    xl: { span: 11, offset: 1 }, // > 1200
    xxl: { span: 11, offset: 1 }, // > 1600
};

export const rowColNomeAndTipo = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};

export const inputSelectTipoDaAssociacao = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 23, offset: 0 }, // > 992
    xl: { span: 23, offset: 0 }, // > 1200
    xxl: { span: 23, offset: 0 }, // > 1600
};
