import jwt_decode from 'jwt-decode';

export const getToken = (): string | undefined => {
    let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:token');
    let localUserData: any = null;
    if (typeof dadosLocalStorage == 'string') {
        localUserData = JSON.parse(dadosLocalStorage);
        return localUserData.token
    } else {
        return undefined
    }
}

/**
 * Verifica se o token não esta expirado
 */
export const checkExpiredToken = (token: string|undefined): boolean => {
    if (token) {
        let decodedToken: any = jwt_decode(token);

        if (decodedToken != null) {
            if (decodedToken.exp < new Date().getTime() / 1000) {
                return true;
            }
        }
    } else if (token === undefined) {
        return true
    }
    return false;
};
