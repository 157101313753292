import React from 'react';
import Route from './Route';
import { Switch } from 'react-router-dom';

// import AuthLayout from '~/pages/_layouts/auth'
// import DefaultLayout from '~/pages/_layouts/default'

// import { store } from '~/store'
import Login from '../../pages/Login';

import Dashboard from '../../pages/Dashboard';

import Usuarios from '../../pages/Usuarios';
import NovosUsuarios from '../../pages/NovosUsuarios';

import ListagemContrato from '../../pages/ListagemContrato';
import CreateContrato from '../../pages/ListagemContrato/components/CreateContrato';

import ListagemAssociacoes from '../../pages/ListagemAssociacoes';
import AdicionarAssociacao from '../../pages/ListagemAssociacoes/components/AdicionarAssociacao';

import ListagemCodigosSms from '../../pages/ListagemCodigosSms';
import ListCaptacaoDeUsuario from '../../pages/ListCaptacaoDeUsuario';
import AddContatctadoObrigacaoViewDados from '../../pages/ListCaptacaoDeUsuario/components/AddObrigacaoViewDados';

import ConfiguracoesDoSistema from '../../pages/ConfiguracoesDoSistema';
import ListEsfera from '../../pages/ConfiguracoesDoSistema/components/Contrato/ListEsfera';
import ListPoder from '../../pages/ConfiguracoesDoSistema/components/Contrato/ListPoder';
import ListSituacaoDeContrato from '../../pages/ConfiguracoesDoSistema/components/Contrato/ListSituacaoDeContrato';
import ListTipoDeContrato from '../../pages/ConfiguracoesDoSistema/components/Contrato/ListTipoDeContrato';
import ListModalidade from '../../pages/ConfiguracoesDoSistema/components/Contrato/ListModalidade';
import ListTipoAditivo from '../../pages/ConfiguracoesDoSistema/components/Contrato/TipoDeAditivo';

import ListCargo from '../../pages/ConfiguracoesDoSistema/components/DadosUsuario/ListCargo';

import ListModulo from '../../pages/ConfiguracoesDoSistema/components/ModulosApp/Modulo';
import ListModuloItem from '../../pages/ConfiguracoesDoSistema/components/ModulosApp/ModuloItem';
import Ordenacao from '../../pages/ConfiguracoesDoSistema/components/Aplicativo/Ordenacao';

import ListTipo from '../../pages/ConfiguracoesDoSistema/components/ProcessosJuridicos/Tipo';
// import ListIndicesConstitucionais from '../../pages/ConfiguracoesDoSistema/components/ModuloNotificacao/ListIndicesConstitucionais';
// import AddIndicesConstitucionais from '../../pages/ConfiguracoesDoSistema/components/ModuloNotificacao/ListIndicesConstitucionais/components/CreateIndicesConstitucioneis';
// import ListCertidao from '../../pages/ConfiguracoesDoSistema/components/ModuloNotificacao/ListCertidao';
// import AddCertidao from '../../pages/ConfiguracoesDoSistema/components/ModuloNotificacao/ListCertidao/components/CreateCertidao';
// import ListCauc from '../../pages/ConfiguracoesDoSistema/components/ModuloNotificacao/ListCauc';
// import AddCauc from '../../pages/ConfiguracoesDoSistema/components/ModuloNotificacao/ListCauc/components/CreateCauc';
// import ListObrigacoes from '../../pages/ConfiguracoesDoSistema/components/ModuloNotificacao/ListObrigacoes';
// import AddProcessoJuridicoNotificacao from '../../pages/ConfiguracoesDoSistema/components/ModuloNotificacao/ListProcessoJuridico';
// import ListEntidade from '../../pages/ConfiguracoesDoSistema/components/ModuloNotificacao/ListEntidade';
// import ListJornais from '../../pages/ConfiguracoesDoSistema/components/ModuloNotificacao/ListJornais';
import ListRotasPermissaoPainel from '../../pages/ConfiguracoesDoSistema/components/PermissaoPainel/ListRotas';
import ListVersaoApp from '../../pages/ConfiguracoesDoSistema/components/Aplicativo/ListVersaoApp';

import ListAtendimentos from '../../pages/ListAtendimentos';
import AddOrEditAtendimentos from '../../pages/ListAtendimentos/components/AddOrEditAtendimentos';

import ListagemProcessosJuridicos from '../../pages/ListagemProcessosJuridicos';
import AdicionarUserPassInCidades from '../../pages/ListagemProcessosJuridicos/components/AdicionarUserPassInCidades';
import ListMensagens from '../../pages/ListMensagens';
import AdicionarMensagens from '../../pages/ListMensagens/components/AdicionarMensagens';

import ListMunicipiosPorContrato from '../../pages/MunicipiosPorContrato';

import ListDispositivosAtivos from '../../pages/Relatorios/ListDispositivosAtivos';
import PesquisaDeSatisfacao from '../../pages/PesquisaDeSatisfacao';
import PesquisaCreateORUpdate from '../../pages/PesquisaDeSatisfacao/components/Pesquisas/CreateOrUpdate';
import CreateModulo from '../../pages/ListagemContrato/components/CreateModulo';
import ListagemEntidade from '../../pages/Entidade';
import PainelGestorPerfil from '../../pages/Entidade/PainelGestor/Perfil';
import PainelGestorPermissoes from '../../pages/Entidade/PainelGestor/Permissao';
import PendenciasMunicipiosPorEstado from '../../pages/Relatorios/PendenciasMunicipiosPorEstado';
import Integracoes from '../../pages/Integracoes';
import ListIntegracoesPorMunicipio from '../../pages/Integracoes/components/ListIntegracoesPorMunicipio';
import SiconPersonalizacao from '../../pages/Integracoes/components/Sicon/Personalizacao';
import ListModulosPlano from '../../pages/ConfiguracoesDoSistema/components/ContratoPlanos/ModulosPlano';
import ListRecursosPlano from '../../pages/ConfiguracoesDoSistema/components/ContratoPlanos/RecursosPlano';
import ListGerenciarPlanos from '../../pages/ConfiguracoesDoSistema/components/ContratoPlanos/GerenciarPlanos';
import ListOutrosAtendimentos from '../../pages/ListOutrosAtendimentos';
import AddOrEditOutrosAtendimentos from '../../pages/ListOutrosAtendimentos/components/AddOrEditOutrosAtendimentos';
import ListProdutos from '../../pages/ConfiguracoesDoSistema/components/Atendimento/ListProdutos';
import RelAcessos from '../../pages/Relatorios/Acessos';


const RouteDashboard: React.FC = () => {

    return (
        <>
            <Switch>
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/" exact component={Login} isAuth={true} />
                <Route path="/recoverpass" component={Login} isAuth={true} />

                <Route path="/perfil" component={Login} />
                <Route path="/usuarios" exact component={Usuarios} />
                <Route path="/usuarios/adicionar" component={NovosUsuarios} />
                <Route path="/contrato" exact component={ListagemContrato} />
                <Route path="/contrato/adicionar" component={CreateContrato} />
                <Route path="/associacao" exact component={ListagemAssociacoes} />
                <Route path="/associacao/adicionar" component={AdicionarAssociacao} />
                <Route path="/configuracoesdosistema" exact component={ConfiguracoesDoSistema} />
                <Route path="/configuracoesdosistema/listaresfera" component={ListEsfera} />
                <Route path="/configuracoesdosistema/listarpoder" component={ListPoder} />
                <Route path="/configuracoesdosistema/listarsituacaocontrato" component={ListSituacaoDeContrato} />
                <Route path="/configuracoesdosistema/listartipocontrato" component={ListTipoDeContrato} />
                <Route path="/configuracoesdosistema/listarmodalidade" component={ListModalidade} />
                <Route path="/configuracoesdosistema/listartipoaditivo" component={ListTipoAditivo} />
                <Route path="/configuracoesdosistema/listarcargo" component={ListCargo} />
                <Route path="/configuracoesdosistema/listarmodulo" component={ListModulo} />
                <Route path="/configuracoesdosistema/listarmoduloitem" component={ListModuloItem} />
                <Route path="/configuracoesdosistema/ordenacao" component={Ordenacao} />
                <Route path="/configuracoesdosistema/listartipoprocessosjuridicos" component={ListTipo} />
                <Route path="/configuracoesdosistema/modulos-plano" component={ListModulosPlano} />
                <Route path="/configuracoesdosistema/recursos-plano" component={ListRecursosPlano} />
                <Route path="/configuracoesdosistema/gerenciar-plano" component={ListGerenciarPlanos} />
                <Route path="/configuracoesdosistema/gerenciar-produto" component={ListProdutos} />
                {/* <Route path="/configuracoesdosistema/listarindicesconstitucionais" component={ListIndicesConstitucionais} />
                <Route path="/configuracoesdosistema/addindicesconstitucionais/:pastPage" component={AddIndicesConstitucionais} />
                <Route path="/configuracoesdosistema/listarcertidao" component={ListCertidao} />
                <Route path="/configuracoesdosistema/addcertidao/:pastPage" component={AddCertidao} />
                <Route path="/configuracoesdosistema/listarcauc" component={ListCauc} />
                <Route path="/configuracoesdosistema/addcauc/:pastPage" component={AddCauc} />
                <Route path="/configuracoesdosistema/listarobrigacoes" component={ListObrigacoes} />
                <Route path="/configuracoesdosistema/listarprocjurid" component={AddProcessoJuridicoNotificacao} />
                <Route path="/configuracoesdosistema/listarentidade" component={ListEntidade} />
                <Route path="/configuracoesdosistema/listarjornais" component={ListJornais} /> */}
                <Route path="/configuracoesdosistema/listarotaspainel" component={ListRotasPermissaoPainel} />
                <Route path="/liscodigosms" component={ListagemCodigosSms} />
                <Route path="/listcaptacaousuario" exact component={ListCaptacaoDeUsuario} />
                <Route path="/listcaptacaousuario/addobrigacao" component={AddContatctadoObrigacaoViewDados} />
                <Route path="/mensagens" exact component={ListMensagens} />
                <Route path="/mensagens/add" component={AdicionarMensagens} />
                <Route path="/configuracoesdosistema/listarversaoapp" component={ListVersaoApp} />
                <Route path="/atendimentos" exact component={ListAtendimentos} />
                <Route exact path={["/atendimentos/editar/:idParam", '/atendimentos/adicionar']} component={AddOrEditAtendimentos} />
                <Route path="/outros-atendimentos" exact component={ListOutrosAtendimentos} />
                <Route exact path={["/outros-atendimentos/editar/:idParam", '/outros-atendimentos/adicionar']} component={AddOrEditOutrosAtendimentos} />
                <Route path="/relatorios/dispositivosativos" component={ListDispositivosAtivos} />
                <Route path="/relatorios/pendencias-municipios-por-estado" component={PendenciasMunicipiosPorEstado} />
                <Route path="/relatorios/acessos" component={RelAcessos} />

                <Route exact path="/pesquisa-satisfacao" component={PesquisaDeSatisfacao} />
                <Route path="/pesquisa-satisfacao/:id" component={PesquisaCreateORUpdate} />

                <Route path="/cidadesUser" exact component={ListagemProcessosJuridicos} />
                <Route path="/cidadesUser/adicionar" component={AdicionarUserPassInCidades} />

                <Route exact path="/contrato/municipios" component={ListMunicipiosPorContrato} />
                <Route exact path="/contrato/modulo/:idParam" component={CreateModulo} />

                <Route exact path="/contrato/entidades" component={ListagemEntidade} />
                <Route exact path="/contrato/entidades/gestor/perfil" component={PainelGestorPerfil} />
                <Route exact path="/contrato/entidades/gestor/permissao" component={PainelGestorPermissoes} />
                <Route exact path="/integracoes" component={Integracoes} />
                <Route exact path="/integracoes/:idCidade" component={ListIntegracoesPorMunicipio} />
                <Route exact path="/integracoes/sicon-personalizacao/:idCidade" component={SiconPersonalizacao} />
            </Switch>
        </>
    )
}

export default RouteDashboard;
