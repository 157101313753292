import { EditOutlined, InfoCircleOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Modal, Row, Select, Table, Typography, notification } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { BiTrash } from "react-icons/bi";
import { urlContratosEntidade } from "../../../../services/request/urls";
import axios from "axios";
import { getToken } from "../../../../services/token";
import { useSelector } from "react-redux";
import { stateGlobal } from "../../../../interfaces/Global";
import { useHistory } from "react-router";

const PainelGestorPerfil: React.FC = () => {
    const { Title, Text } = Typography;
    const [loadingTablePerfis, setLoadingTablePerfis] = useState(false);
    const [perfis, setPerfis] = useState([]);
    const [permissoes, setPermissoes] = useState([]);
    const propsState = useSelector((state: stateGlobal) => state.global);
    const refContagemDoFiltro = useRef<Array<any>>([]);
    const history = useHistory();
    const [modalPerfilVisible, setModalPerfilVisible] = useState(false);
    const [isEditarPerfil, setIsEditarPerfil] = useState(false);
    const [loadingButtonSalvar, setLoadingButtonSalvar] = useState(false);
    const [formPerfil] = Form.useForm();
    const refFormPerfil = useRef<any>(null);

    const getPerfis = useCallback(() => {
        setLoadingTablePerfis(true);
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlContratosEntidade + "/perfil", {
                    headers: { Authorization: "Bearer " + getToken() },
                });

                setPerfis(result.data);
            } catch (error) {
                let msgErro: any = error as Error;
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
            }
        };
        setLoadingTablePerfis(false);
        return buscaDeDados();
    }, []);

    const getPermissoes = useCallback(() => {
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlContratosEntidade + "/permissao", {
                    headers: { Authorization: "Bearer " + getToken() },
                });

                setPermissoes(result.data);
            } catch (error) {
                let msgErro: any = error as Error;
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
            }
        };
        return buscaDeDados();
    }, []);

    const comonentDidMount = useEffect((): void => {
        const buscaDadosIniciais = async () => {
            getPerfis();
            getPermissoes();
        };

        buscaDadosIniciais();
    }, []);

    const getColumnSearchProps = useCallback(
        (dataIndex: any) => ({
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={(node) => { }}
                        placeholder={`Buscar ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                            );
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => {
                            confirm();
                        }}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={clearFilters}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered: any) => (
                <SearchOutlined
                    style={{ color: filtered ? "#1890ff" : undefined }}
                />
            ),
            onFilter: (value: any, record: any) =>
                record[dataIndex]
                    ? record[dataIndex]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())
                    : "",
        }),
        []
    );
    const columnsperfis = [
        {
            title: "Perfil",
            dataIndex: "nome",
            width: "30%",
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            ...getColumnSearchProps("nome"),
            showSorterTooltip: false,
        },
        {
            title: "Ações",
            dataIndex: "id",
            width: "7%",
            render: (id: any, record: any) => (
                <span>
                    <Button
                        style={{
                            borderRadius: 5,
                            margin: 1,
                        }}
                        type="primary"
                        size="small"
                        onClick={() => {
                            setIsEditarPerfil(true);

                            formPerfil.setFieldsValue({
                                id: record.id,
                                nome: record.nome,
                                descricao: record.descricao,
                                permissoes: record.permissoes.map((item: any) => item.uuid),
                            });

                            setModalPerfilVisible(true);
                        }}
                    >
                        <EditOutlined />
                    </Button>
                    <Divider type="vertical" />
                    <Button
                        style={{
                            borderRadius: 5,
                            margin: 1,
                        }}
                        type="primary"
                        danger
                        size="small"
                        onClick={() => {
                            excluirPerfil(record.id);
                        }}
                    >
                        <BiTrash />
                    </Button>
                </span>
            ),
        },
    ];

    const excluirPerfil = useCallback((id: number) => {
        try {
            //Confirmação de exclusão
            Modal.confirm({
                title: "Excluir perfil",
                icon: <InfoCircleOutlined />,
                content:
                    "Deseja realmente excluir o Perfil? (Essa ação não pode ser desfeita)",
                okText: "Sim",
                cancelText: "Não",
                onOk() {
                    axios
                        .delete(urlContratosEntidade + "/perfil/" + id, {
                            headers: {
                                Authorization: "Bearer " + getToken(),
                            },
                        })
                        .then((result) => {
                            notification.success({
                                message: "Sucesso",
                                description: "Perfil excluido com sucesso!",
                            });
                            getPerfis();
                        })
                        .catch((error) => {
                            notification.error({
                                message: "Erro",
                                description:
                                    "Não foi possivel excluir o perfil!, pode estar sendo usado em algum usuário.",
                            });
                        });
                },
                onCancel() { },
            });
        } catch (error) {
            let msgErro: any = error as Error;
            notification.error({
                message: "Erro",
                description: msgErro?.response?.data?.message
                    ? msgErro?.response?.data?.message
                    : "Entre em contato com o suporte!",
            });
        }
    }, []);

    const salvarPerfil = useCallback(async (values: any) => {
        try {
            // setLoadingButtonSalvar(true);
            const data = {
                nome: values.nome,
                descricao: values.descricao,
                permissoesIds: values.permissoes.map((item: any) => {
                    return item
                }),
            };
            if (values.id) {
                axios
                    .put(urlContratosEntidade + "/perfil/" + values.id, data, {
                        headers: {
                            Authorization: "Bearer " + getToken(),
                        },
                    })
                    .then((result) => {
                        notification.success({
                            message: "Sucesso",
                            description: "Perfil atualizado com sucesso!",
                        });
                        getPerfis();
                        setModalPerfilVisible(false);
                    })
                    .catch((error) => {
                        notification.error({
                            message: "Erro",
                            description: "Não foi possivel salvar o perfil!",
                        });
                    });
            } else {
                axios
                    .post(urlContratosEntidade + "/perfil", data, {
                        headers: {
                            Authorization: "Bearer " + getToken(),
                        },
                    })
                    .then((result) => {
                        notification.success({
                            message: "Sucesso",
                            description: "Perfil salvo com sucesso!",
                        });
                        getPerfis();
                        setModalPerfilVisible(false);
                    })
                    .catch((error) => {
                        notification.error({
                            message: "Erro",
                            description: "Não foi possivel salvar o perfil!",
                        });
                    });
            }
            setLoadingButtonSalvar(false);
        } catch (error) {
            let msgErro: any = error as Error;
            notification.error({
                message: "Erro",
                description: msgErro?.response?.data?.message
                    ? msgErro?.response?.data?.message
                    : "Entre em contato com o suporte!",
            });
            return false;
        }
    }, []);

    return (
        <>
            <Title level={2}>Painel Gestor Perfil de Usuário</Title>
            <Row style={{ marginTop: 10 }}>
                <Col
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Button
                        type="primary"
                        onClick={() => {
                            setIsEditarPerfil(false);

                            formPerfil.setFieldsValue({
                                id: null,
                                nome: null,
                                descricao: null,
                                permissoes: [],
                            });

                            setModalPerfilVisible(true);
                        }}
                    >
                        <PlusOutlined />
                        Novo Perfil
                    </Button>
                </Col>
            </Row>

            <Table
                style={{
                    marginTop: 10,
                }}
                className="marginTopTables"
                rowKey={(record) => Number(record?.id)}
                loading={loadingTablePerfis}
                columns={columnsperfis}
                dataSource={perfis}
                scroll={{ x: 1100 }}
                size="small"
                title={(registros) => {
                    return (
                        <div
                            style={{
                                backgroundColor:
                                    propsState.theme === "light"
                                        ? "#FAFAFA"
                                        : "#1D1D1D",
                                padding: 10,
                                margin: -8,
                                marginBottom: 0.5,
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0
                                    ? perfis.length
                                    : refContagemDoFiltro.current.length
                                    } num total de ${perfis.length} `}
                            </Text>
                        </div>
                    );
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: "end",
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0
                                    ? perfis.length
                                    : refContagemDoFiltro.current.length
                                    } num total de ${perfis.length} `}
                            </Text>
                        </div>
                    );
                }}
                pagination={{
                    locale: { items_per_page: "" },
                    showSizeChanger: true,
                    defaultPageSize: 100,
                    position: ["topRight", "bottomRight"],
                    pageSizeOptions: ["10", "20", "50", "100"],
                }}
                locale={{
                    emptyText: (
                        <div style={{ marginTop: 10 }}>
                            <InfoCircleOutlined
                                style={{
                                    fontSize: 30,
                                    marginBottom: 10,
                                }}
                            />
                            <br />
                            <Text style={{ fontSize: 15 }}>
                                Nenhum registro encontrado!
                            </Text>
                        </div>
                    ),
                }}
            />
            <Modal
                title={isEditarPerfil ? "Editar Perfil" : "Novo Perfil"}
                visible={modalPerfilVisible}
                onCancel={() => {
                    setModalPerfilVisible(false);
                }}
                footer={null}
                width={800}
            >
                <Form
                    form={formPerfil}
                    ref={refFormPerfil}
                    onFinish={(values) => {
                        salvarPerfil(values);
                    }}
                    style={{ marginTop: 10 }}
                    layout="vertical"
                >
                    <Form.Item name="id" hidden>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="nome"
                        label="Nome"
                        rules={[
                            {
                                required: true,
                                message: "Campo obrigatório!",
                            },
                        ]}
                    >
                        <Input placeholder="Informe o nome"/>
                    </Form.Item>

                    <Form.Item
                        name="descricao"
                        label="Descrição"
                        rules={[
                            {
                                required: true,
                                message: "Campo obrigatório!",
                            },
                        ]}
                    >
                        <Input placeholder="Informe a descrição"/>
                    </Form.Item>

                    <Form.Item
                        name="permissoes"
                        label="Permissões"
                        rules={[
                            {
                                required: true,
                                message: "Campo obrigatório!",
                            },
                        ]}
                    >
                        {/* Select Multiple */}
                        <Select
                            mode="multiple"
                            style={{ width: "100%" }}
                            placeholder="Selecione"
                        >
                            {permissoes.map((item: any) => (
                                <Select.Option key={item.uuid} value={item.uuid} >
                                    {item.nome}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Row>
                        <Col span={24} style={{ textAlign: "end" }}>
                            <Button
                                style={{ marginRight: 10 }}
                                onClick={() => {
                                    setModalPerfilVisible(false);
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loadingButtonSalvar}
                            >
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default PainelGestorPerfil;
