import React, { useState, useCallback, useEffect } from 'react';
import {
    Table, Input, Button, Space, notification, Typography, Row, Col,
    Select, Modal, Tag, Spin, Alert,
    Tooltip
} from 'antd';
import Highlighter from 'react-highlight-words';
import locale from 'antd/es/locale/pt_BR';
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
// import { format, addDays } from 'date-fns'
import axios from 'axios';
import {
    urlUsers,
    urlContratoVendedores,
    urlUsersStatusAtivos,
    urlUsersStatusInativos,
    urlUsersStatusTodos,
} from '../../../../services/request/urls';

import { useSelector } from 'react-redux';
import { stateGlobal } from '../../../../interfaces/Global';

import { getToken } from './../../../../services/token';
import Icon from '@ant-design/icons';
import { mask, unMask } from '../../../../utils/MascaraDeCampos';
import { IoArrowUndoSharp } from 'react-icons/io5';
// import { useForm } from 'antd/lib/form/Form';
// import {
//     cardLayout,
//     modalAddModuloInputs,
// } from './GridStyle';
// import {
//     convertDataBrParaUS
// } from './../../../../services/Conversores';

import ModalUsuarioRapido from './../ModalUsuarioRapido'

import {
    removeAcento
} from './../../../../utils/RemoveAcentos';

import { FaSearch, FaWhatsapp } from 'react-icons/fa';
import ModalUsuarioEvento from './../ModalUsuarioEvento';
import { verifyIsSmartphome } from '../../../../utils/VerifyIsSmartphone';

const { Text } = Typography;

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>
const IconWhatsApp = (props: any) => <Icon {...props} component={FaWhatsapp} ></Icon>

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

interface tabelaUsuarios {
    id: number,
    uuid: string,
    sistema: string,
    ativo: boolean,
    nome: string,
    celular: string,
    email: string,
    testador: boolean,
    dias_de_teste: string | number | null,
    vendedor: boolean,
    avatar: string | null,
    created_at: string,
    updated_at: string,
    cargo: { id: number, nome: string }
}

const ListUsuarios: React.FC = () => {

    // const formRef = useRef<any>(null);
    // formRef = React.createRef();

    const refContagemDoFiltro = React.useRef<Array<any>>([]);

    const propsState = useSelector((state: stateGlobal) => state.global);
    const queryParans = useQuery();

    const history = useHistory();
    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();
    const signal1 = axios.CancelToken.source();

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string>>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dadosTabelaState, setDadosTabelaState] = useState<Array<tabelaUsuarios>>([]);
    const [hasSelectedAdd, setHasSelectedAdd] = useState(true);
    const [hasSelectedView, setHasSelectedView] = useState(false);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);
    const [hasSelectedDel, setHasSelectedDel] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [valorSelecione, setValorSelecione] = useState('Usuários');

    const [abrirUsuarioRapido, setAbrirUsuarioRapido] = useState(false);
    const [abrirUsuarioEvento, setAbrirUsuarioEvento] = useState(false);

    const [visibleAlertDeAtencaoCache, setVisibleAlertDeAtencaoCache] = useState(false);
    const [spinAlertDeAtencaoCache, setSpinAlertDeAtencaoCache] = useState(false);
    const [ativarOuInativarModulo, setAtivarOuInativarModulo] = useState(false);

    const buscaDadosTodosusuariosAndSetTable = useCallback((): void => {

        setLoadingTable(true)
        const buscaDeDadosVerndedores = async () => {
            try {
                let result = await axios.get(urlUsersStatusTodos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setDadosTabelaState(result.data);
                setLoadingTable(false);

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosVerndedores();

    }, []);

    const buscaDadosUsuariosAtivosAndSetTable = useCallback((): void => {

        setLoadingTable(true)
        const buscaDeDadosUsuarios = async () => {
            try {
                let result = await axios.get(urlUsersStatusAtivos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setDadosTabelaState(result.data);
                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosUsuarios();

    }, []);

    const mensagemDeAtencaoNaTelaPeloCache = useCallback((semParam?: boolean): void => {

        let atencaoParam = queryParans.get("at");
        // comenntado pois não esta utilizando a atualizacao do cache
        // if (
        //     atencaoParam &&
        //     parseInt(atencaoParam)
        //     && parseInt(atencaoParam) === 1
        // ) {
        //     setVisibleAlertDeAtencaoCache(true);
        //     setSpinAlertDeAtencaoCache(true);

        //     setTimeout(() => {
        //         setSpinAlertDeAtencaoCache(false);

        //         // 22 segundos
        //     }, 22000);
        // }

        // if (semParam) {
        //     setVisibleAlertDeAtencaoCache(true);
        //     setSpinAlertDeAtencaoCache(true);

        //     setTimeout(() => {
        //         setSpinAlertDeAtencaoCache(false);

        //         // 15 segundos
        //     }, 15000);
        // }

    }, []);

    //executa apenas ao iniciar o component
    useEffect(() => {

        buscaDadosUsuariosAtivosAndSetTable();

        // mensagemDeAtencaoNaTelaPeloCache();

    }, []);

    //executa apenas quando desmonta o component
    const comonentExecutaAoDestroy = useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
        signal1.cancel("Requisicao cancelada!")
    }, []);

    const buscaDadosVendedoresAndSetTable = useCallback((): void => {

        setLoadingTable(true)
        const buscaDeDadosVerndedores = async () => {
            try {
                let result = await axios.get(urlContratoVendedores,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setDadosTabelaState(result.data);
                setLoadingTable(false);

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosVerndedores();

    }, []);

    const buscaDadosUsuariosInativosAndSetTable = useCallback((): void => {

        setLoadingTable(true)
        const buscaDeDadosUsuarios = async () => {
            try {
                let result = await axios.get(urlUsersStatusInativos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setDadosTabelaState(result.data);
                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosUsuarios();

    }, []);

    //Search da tabela de listagem
    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {

                let trecord = removeAcento(record[dataIndex]?.toString());

                return record[dataIndex] ? trecord.includes(removeAcento(value)) : false

            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) => {

                return (
                    <>
                        {searchedColumn === dataIndex ? (
                            <Highlighter
                                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                searchWords={[searchText]}
                                autoEscape
                                textToHighlight={text ? text.toString() : ''}
                            />
                        ) : (
                            text
                        )}
                    </>
                )
            }
        })
    }, [searchText, searchedColumn]);

    const getColumnSearchPropsData = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let texto: string | undefined = ''
                if (record[dataIndex]) texto = moment(record[dataIndex]).format('DD/MM/YYYY HH:mm')

                return (
                    texto ? texto.toString().toLowerCase().includes(value.toLowerCase()) : false
                )
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    const getColumnSearchPropsObjNome = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let trecord = removeAcento(record[dataIndex]?.nome.toString());

                return record[dataIndex]?.nome ? trecord.includes(removeAcento(value)) : false
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    //Search da tabela de listagem
    // const getColumnSearchPropsBolean = useCallback((dataIndex) => {
    //     let searchInput: any = '';
    //     return ({
    //         filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
    //             <div style={{ padding: 8 }}>
    //                 <Input
    //                     ref={node => {
    //                         searchInput = node;
    //                     }}
    //                     placeholder={`Digite...`}
    //                     value={selectedKeys[0]}
    //                     onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //                     onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
    //                     style={{ width: 188, marginBottom: 8, display: 'block' }}
    //                 />
    //                 <Space>
    //                     <Button
    //                         type="primary"
    //                         onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
    //                         icon={<SearchOutlined />}
    //                         size="small"
    //                         style={{ width: 90 }}
    //                     >
    //                         Pesquisar
    //                     </Button>
    //                     <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
    //                         Limpar
    //                     </Button>
    //                 </Space>
    //             </div>
    //         ),
    //         filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
    //         onFilter: (value: any, record: any) => {
    //             let valor: string = record[dataIndex] ? 'ativo' : 'inativo'

    //             return valor ? valor.includes(value.toLowerCase()) : ''
    //         },
    //         onFilterDropdownVisibleChange: (visible: any) => {
    //             if (visible) {
    //                 setTimeout(() => searchInput.select());
    //             }
    //         },
    //         render: (text: any) =>
    //             searchedColumn === dataIndex ? (
    //                 <Highlighter
    //                     highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //                     searchWords={[searchText]}
    //                     autoEscape
    //                     textToHighlight={text ? text.toString() : ''}
    //                 />
    //             ) : (
    //                 text
    //             ),
    //     })
    // }, [searchText, searchedColumn]);

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, [searchText]);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);
    // fim do search da tabela de listagem

    //abrir navegar em add contrato
    const clickBotaoEditarUsuario = useCallback((): void => {

        // const buscaDeDados = async () => {
        const buscaDeDados = () => {

            if (selectedRowKeys.length == 1) {

                let objSelecionado: any = {}
                dadosTabelaState.forEach((dados): void => {
                    if (dados.uuid == selectedRowKeys[0]) {
                        objSelecionado.step1 = dados
                        objSelecionado.idUser = dados.id;
                        objSelecionado.step1.cargo_id = dados.cargo.id;
                    }
                })

                try {
                    // let resultVendedores = await axios.get(urlContratoVendedores + '/' + objSelecionado?.id,
                    //     { headers: { 'Authorization': 'Bearer ' + getToken() } });

                    //     objSelecionado.vendedores = resultVendedores.data;

                    //     let resultEstados = await axios.get(urlContratoEstados + '/' + objSelecionado?.id,
                    //         { headers: { 'Authorization': 'Bearer ' + getToken() } });

                    //     objSelecionado.modo_estado = resultEstados.data;


                    //     objSelecionado.cidades = resultCidades.data;

                    objSelecionado.isEdit = true;

                    localStorage.setItem('@GovFacil:userDataAtualizarESair', JSON.stringify(objSelecionado));
                    localStorage.setItem('@GovFacil:userData', JSON.stringify(objSelecionado));

                    history.push("/usuarios/adicionar");
                } catch (error) {
                    let msgErro: any = (error as Error);
                    notification.error({
                        message: 'Erro',
                        description:
                            msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Erro entre em contato com o suporte!',
                    });
                }

            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState]);

    const requestAtivarOuInativarUser = useCallback((obj): void => {
        setLoadingTable(true);
        const buscaDeDados = async () => {
            // if (selectedRowKeys.length == 1 || selectedRowKeys.length > 1) {
            if (selectedRowKeys.length == 1) {
                let linha: any = [];
                dadosTabelaState.forEach(({ nome, uuid, id }): void => {

                    selectedRowKeys.forEach((val, i) => {
                        if (uuid == val) {
                            linha.push({ id })
                        }
                    });

                })

                try {
                    await axios.patch(urlUsers + "/" + linha[0].id, obj, {
                        //EXEMPLO APENAS PARA EXCLUIR UM [0] ATE CORRIGIR O BACK
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    })

                    setLoadingTable(false);
                    if (obj.ativo) {
                        buscaDadosUsuariosInativosAndSetTable();
                    } else {
                        buscaDadosUsuariosAtivosAndSetTable();
                    }

                    mensagemDeAtencaoNaTelaPeloCache(true);

                    notification.success({
                        message: 'Sucesso',
                        description:
                            `O usuario foi ${obj.ativo ? 'ativado' : 'inativado'} com sucesso!`,
                    });
                } catch (error) {
                    setLoadingTable(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            `Não foi possivel
                        ${obj.ativo ? 'ativar' : 'inativar'}
                         o usuario, entre em contato com suporte!`
                    });
                }
                setHasSelectedAdd(true);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
                setHasSelectedView(false);
                setSelectedRowKeys([]);

            }
        }

        buscaDeDados();
    }, [selectedRowKeys, dadosTabelaState, buscaDadosUsuariosAtivosAndSetTable, buscaDadosUsuariosInativosAndSetTable]);

    const TextAtivarInativar = (props: any) => <Text>{props.text} <br />Tem certeza que deseja continuar?</Text>;

    const onClickInativarUser = useCallback((): void => {
        Modal.confirm({
            title: 'Cuidado',
            icon: <ExclamationCircleOutlined />,
            content: <TextAtivarInativar text='O usuário perderá todo acesso ao sistema!' />,
            okText: 'Sim',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                requestAtivarOuInativarUser({ ativo: false });
            },
            onCancel() {
            },
        });
    }, [requestAtivarOuInativarUser]);

    const onClickAtivarUser = useCallback((): void => {
        Modal.confirm({
            title: 'Cuidado',
            icon: <ExclamationCircleOutlined />,
            content: <TextAtivarInativar text='O usuário terá acesso ao sistema novamente!' />,
            okText: 'Sim',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                requestAtivarOuInativarUser({ ativo: true });
            },
            onCancel() {
            },
        });
    }, [requestAtivarOuInativarUser]);

    //click add usuario
    const navigateAddUser = useCallback((): void => {
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@GovFacil:userData');
        }

        history.push("/usuarios/adicionar");
    }, []);

    const onChangeSelectFiltroBuscaTabela = useCallback((e): void => {

        //setando valor do selecione em stado para caso for inativo renderizar outro botao
        setValorSelecione(e);
        switch (e) {
            case 'Inativos':
                buscaDadosUsuariosInativosAndSetTable();
                break;
            case 'Usuários':
                buscaDadosUsuariosAtivosAndSetTable();
                break;
            case 'Vendedores':
                buscaDadosVendedoresAndSetTable();
                break;
            case 'Todos':
                buscaDadosTodosusuariosAndSetTable();
                break;

            default:
                buscaDadosUsuariosAtivosAndSetTable();
                break;
        }
    }, []);

    //executa ao iniciar e did update para atualizar o botao de ativo ou inativo
    useEffect(() => {
        const buscaDeDados = async () => {
            try {
                if (selectedRowKeys.length === 1) {
                    dadosTabelaState.forEach((objEditar: any): void => {
                        if (objEditar.uuid === selectedRowKeys[0]) {
                            // obj.push(objEditar)
                            setAtivarOuInativarModulo(objEditar.ativo)
                        }
                    })


                }


            } catch (error) {

            }
        }

        buscaDeDados();

    }, [selectedRowKeys]);

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeys.includes(record.uuid)) {
            let filter = selectedRowKeys.filter((val) => {
                return val != record.uuid
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(true);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length == 0) {
                setSelectedRowKeys([record.uuid]);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else {
                let teste = selectedRowKeys.concat(record.uuid);
                setSelectedRowKeys(teste);
                setHasSelectedAdd(false);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
                setHasSelectedDel(true);
            }
        }
    }, [selectedRowKeys]);

    //checkbox da tabela de listagem
    const onSelectChange = useCallback((selectedRowKeys): void => {
        if (selectedRowKeys.length > 1) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
            setHasSelectedDel(true);
        } else if (selectedRowKeys.length === 0) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(true);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
            setHasSelectedDel(false);
        } else {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(true);
            setHasSelectedEdit(true);
            setHasSelectedDel(true);
        }
    }, [selectedRowKeys]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '1%'
    };

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...getColumnSearchProps('nome'),
            width: '10%',
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
            render: (nome: any, record: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        {record.ativo ?
                            <Text>{nome}</Text>
                            :
                            <Text strong style={{ color: '#EB3C54' }}>{nome}</Text>
                        }
                        {
                            nome ?
                                <Tooltip title="Ir para os acessos" color="blue" key="bluedesvincular">
                                    <Button
                                        type="primary"
                                        size='small'
                                        style={{ margin: 2 }}
                                        onClick={() => {
                                            history.push(`/relatorios/acessos?idUsuario=${record?.id}&aba=RelAcessosUsuario`)
                                        }}
                                    >
                                        <IoArrowUndoSharp />
                                    </Button>
                                </Tooltip>
                                :
                                <></>
                        }
                    </div>
                )
            },
        },
        {
            title: 'Contratos',
            dataIndex: 'contratos',
            ...getColumnSearchProps('contratos'),
            width: '1.5%',
            render: (contratos: any) => (
                <span>
                    {
                        contratos.map((element: string, index: number) => (
                            // element+' '
                            <span key={'span' + index}>
                                <Tag color={''} key={index}>
                                    {element}
                                </Tag>
                            </span>
                        ))
                    }
                </span>
            ),
        },
        {
            title: 'Cargo',
            dataIndex: 'cargo',
            ...getColumnSearchPropsObjNome('cargo'),
            width: '4%',
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            render: (cargo: any) => (
                cargo?.nome
            ),
        },
        {
            title: 'Celular',
            dataIndex: 'celular',
            ...getColumnSearchProps('celular'),
            width: '9%',
            render: (celular: any) => {
                let formatedCell: string | null = null
                if (celular) {

                    const originalValue = unMask(celular);
                    const maskedValue = mask(originalValue, [
                        "(99) 9999-9999",
                        "(99) 9 9999-9999"
                    ]);
                    formatedCell = maskedValue;
                }
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        // justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        zIndex: 5
                    }}>
                        <Text>{formatedCell}</Text>
                        {
                            celular ?
                                <Button
                                    className='botaoWhatsApp_fwebfhewvouyfgvwa'
                                    type="primary"
                                    size='small'
                                    style={{ marginLeft: 5 }}
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        const link = document.createElement('a');
                                        link.href = `https://api.whatsapp.com/send/?phone=55${celular.trim()}&text&type=phone_number&app_absent=1`
                                        // if (verifyIsSmartphome()) {
                                        //     link.href = `https://api.whatsapp.com/send/?phone=+55${celular}&text&type=phone_number&app_absent=0`
                                        // } else {
                                        //     link.href = `https://web.whatsapp.com/send/?phone=+55${celular}&text&type=phone_number&app_absent=0`
                                        // }
                                        link.target = "_blank";

                                        document.body.appendChild(link);

                                        link.click();

                                        return
                                    }}
                                >
                                    <IconWhatsApp />
                                </Button>
                                :
                                <></>
                        }
                    </div >

                )
            }
            // sorter: (a, b) => a.age - b.age //para quando é numero
        },
        {
            title: 'Sistema',
            dataIndex: 'sistema',
            ...getColumnSearchProps('sistema'),
            width: '2.5%',
            render: (status: any) => (
                <span>
                    <Tag color={'blue'} key={status}>
                        {status?.toUpperCase()}
                    </Tag>
                </span>
            ),
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'ativo',
        //     ...getColumnSearchPropsBolean('ativo'),
        //     width: '2%',
        //     render: (status: any) => (
        //         <span>
        //             <Tag color={status ? 'green' : 'red'} key={status}>
        //                 {status ? 'ATIVO' : 'INATIVO'}
        //             </Tag>
        //         </span>
        //     ),
        // },
        // {
        //     title: 'Status cadastro',
        //     dataIndex: 'statusCadastro',
        //     ...getColumnSearchProps('statusCadastro'),
        //     width: '6%',
        //     render: (status: any) => (
        //         <span>
        //             <Tag color={status == "efetivado" ? 'blue' : 'orange'} key={status}>
        //                 {status?.toUpperCase()}
        //             </Tag>
        //         </span>
        //     ),
        // },
        // {
        //     title: 'Período de Teste',
        //     dataIndex: 'validade_teste',
        //     ...getColumnSearchPropsData('validade_teste'),
        //     width: '2%',
        //     sorter: (a: any, b: any) => {

        //         let aMonMen: any = null
        //         let bMonMen: any = null
        //         if (a.validade_teste) {
        //             aMonMen = moment(a.validade_teste).unix()
        //         } else {
        //             aMonMen = 0
        //         }
        //         if (b.validade_teste) {
        //             bMonMen = moment(b.validade_teste).unix()
        //         } else {
        //             bMonMen = 0
        //         }

        //         return aMonMen - bMonMen
        //     },
        //     // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
        //     render: (validade_teste: any) => {
        //         let data: any = null;
        //         if (validade_teste) {
        //             data = moment(validade_teste).format('DD/MM/YYYY')
        //         }
        //         return (
        //             data
        //         )
        //     },
        // },
        {
            title: 'Criado',
            dataIndex: 'created_at',
            ...getColumnSearchPropsData('created_at'),
            width: '2%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.created_at) {
                    aMonMen = moment(a.created_at).unix()
                } else {
                    aMonMen = 0
                }
                if (b.created_at) {
                    bMonMen = moment(b.created_at).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            render: (criado: any) => (
                moment(criado).format('DD/MM/YYYY HH:mm')
            ),
        },
    ];

    //funcoes user rapido

    const abrirComponentModal = useCallback(() => {
        setAbrirUsuarioRapido(true)
    }, []);

    const fecharComponentModal = useCallback(() => {
        setAbrirUsuarioRapido(false);

        buscaDadosUsuariosAtivosAndSetTable();
    }, []);

    const abrirComponentModalEvento = useCallback(() => {
        setAbrirUsuarioEvento(true)
    }, []);

    const fecharComponentModalEvento = useCallback(() => {
        setAbrirUsuarioEvento(false);

        buscaDadosUsuariosAtivosAndSetTable();
    }, []);

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    zIndex: 2,
                    // background: propsState.theme === "light" ? '#fff' : '#141414',
                    //
                    // width: '80%',
                    paddingTop: 24,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24
                }}
            >
                <Row
                // style={{ marginBottom: 16 }}
                >
                    <Col>
                        <Button type="primary" className="botaoAdicionarUsuarioUser" disabled={!hasSelectedAdd} onClick={navigateAddUser} >
                            Adicionar
                        </Button>
                        {/* <Button className="botaoViewUsuarioUser" type="primary" onClick={start} disabled={!hasSelectedView} >
                        Visualizar
                    </Button> */}
                        <Button type="primary" className="botaoEditarUsuarioUser" onClick={clickBotaoEditarUsuario} disabled={!hasSelectedEdit} >
                            Editar
                        </Button>
                    </Col>

                    <Col>
                        <Select
                            showSearch
                            placeholder="Selecione..."
                            optionFilterProp="children"
                            className="botaoListVendedorUser"
                            onChange={onChangeSelectFiltroBuscaTabela}
                            disabled={!hasSelectedAdd}
                            defaultValue='Usuários'
                            filterOption={(input: any, option: any) => {
                                let textDigit = removeAcento(input)
                                let listCidade = removeAcento(option?.children);
                                return listCidade.indexOf(textDigit) >= 0
                            }}
                        >
                            <Select.Option value="Usuários">Ativos</Select.Option>
                            <Select.Option value="Vendedores">Vendedores</Select.Option>
                            <Select.Option value="Inativos">Inativos</Select.Option>
                            <Select.Option value="Todos">Todos</Select.Option>
                        </Select>

                        {selectedRowKeys.length === 1 ?
                            ativarOuInativarModulo ?
                                <Button className="botaoDeleteUsuarioUser" type="primary" danger onClick={onClickInativarUser}
                                    // disabled={!hasSelectedDel}
                                    disabled={!hasSelectedEdit}
                                >
                                    Inativar
                                </Button>
                                :
                                <Button className="botaoAtivarUsuarioUser" type="primary" onClick={onClickAtivarUser}
                                    // disabled={!hasSelectedDel}
                                    disabled={!hasSelectedEdit}
                                >
                                    Ativar
                                </Button>

                            : <Button className="botaoDeleteUsuarioUser" type="primary" danger onClick={onClickInativarUser}
                                // disabled={!hasSelectedDel}
                                disabled
                            >
                                Inativar
                            </Button>}
                    </Col>

                    <Button type="primary" className="botaoUserRapidoUser" onClick={() => {
                        abrirComponentModal();
                    }}>
                        Usuário Rápido
                    </Button>

                    <Button type="primary" className="botaoUserRapidoUser" onClick={() => {
                        abrirComponentModalEvento();
                    }}>
                        Usuário Evento
                    </Button>
                </Row>

            </div>
            {
                visibleAlertDeAtencaoCache &&
                <Alert
                    message="Atenção"
                    description="Atualizando cache de usuários."
                    type="info"
                    className='marginTopAlertEnsimaTable'
                    style={{
                        marginTop: 50,
                        marginBottom: -40
                    }}
                    showIcon
                    action={
                        <>
                            {
                                spinAlertDeAtencaoCache ?

                                    <Spin spinning={true} />
                                    :
                                    <Button size="small" onClick={() => {

                                        setVisibleAlertDeAtencaoCache(false);
                                        setSpinAlertDeAtencaoCache(true);

                                        if (valorSelecione === 'Usuários') {

                                            buscaDadosUsuariosAtivosAndSetTable();

                                        } else if (valorSelecione === 'Vendedores') {

                                            buscaDadosVendedoresAndSetTable();
                                        } else if (valorSelecione === 'Inativos') {

                                            buscaDadosUsuariosInativosAndSetTable();
                                        } else if (valorSelecione === 'Todos') {

                                            buscaDadosTodosusuariosAndSetTable();
                                        }
                                    }
                                    } type="primary">
                                        Atualizar
                                    </Button>
                            }
                        </>
                    }
                />
            }
            <Table
                style={{
                    marginTop: 50
                }}
                className='marginTopTables'
                rowClassName={(record, index) => (record.ativo ? "ativoList" : "inativoList")}
                rowKey={(record) => record.uuid}
                loading={loadingTable}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onclickTable(record, rowIndex) }, // click row
                        onDoubleClick: event => { }, // double click row
                        onContextMenu: event => { }, // right button click row
                        onMouseEnter: event => { }, // mouse enter row
                        onMouseLeave: event => { }, // mouse leave row
                    };
                }}
                rowSelection={rowSelection}
                columns={columns} dataSource={dadosTabelaState}
                scroll={{ x: 1000 }}
                onChange={
                    (pagination, filters, sorter, extra) => {
                        refContagemDoFiltro.current = extra.currentDataSource
                    }
                }
                title={(registros) => {

                    return (
                        <div
                            style={{
                                backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                padding: 10,
                                margin: -8,
                                marginBottom: 0.5
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: 'end'
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                size="small"
                pagination={{
                    locale: { items_per_page: '' },
                    showSizeChanger: true,
                    defaultPageSize: 100,
                    // showTotal: (total, range) => {
                    //     return (`Mostrando: ${range[0]} ao ${range[1]} num total de ${total} itens`)
                    // },
                    position: ['topRight', 'bottomRight'],
                    pageSizeOptions: ['10', '20', '50', '100']
                }}
                locale={locale.Table}
            />
            <ModalUsuarioRapido visible={abrirUsuarioRapido} setFecharModal={fecharComponentModal} />
            <ModalUsuarioEvento visible={abrirUsuarioEvento} setFecharModal={fecharComponentModalEvento} />

        </>
    );
}

export default ListUsuarios;
