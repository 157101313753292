import { Layout, Row, Table, Typography } from "antd";
import locale from 'antd/es/locale/pt_BR';
import { forwardRef } from "react";
import { IDataReqAcessosPorUsuario } from "../FiltrosAndTable/useFiltrosAndTable";

// @ts-ignore
import ImagemLogo from './../../../../../../../assets/logo.png';

const { Text } = Typography

interface IProps {
    loadingTableModulos: boolean,
    columnsModalModulosPorUsuario: any,
    dadosTabelaModalModulosPorUsuario?: IDataReqAcessosPorUsuario
}

const ContainerModalPorUsuario = forwardRef((
    {
        loadingTableModulos,
        columnsModalModulosPorUsuario,
        dadosTabelaModalModulosPorUsuario,
    }: IProps,
    ref?: React.ForwardedRef<any>
) => {

    return (
        <div
            ref={ref}
            style={{
                margin: 0,
                padding: 0
            }}
            className="layout_UltimoAcesso_cvrehjvfqw"
        >
            <Row>
                <Layout
                    className="layout_UltimoAcesso_cvrehjvfqw"
                >
                    <div
                        className="divImgGovFacilRel_UltimoAcesso_fewkhgfvqof"
                    >
                        <img
                            className="imgRelUltimoAcesso_fewhljbv"
                            style={{
                                width: '200px'
                            }}
                            src={ImagemLogo}
                        />
                    </div>
                </Layout>
            </Row>

            <div
                className='layoutVGrey_UltimoAcesso_lvbweqrafw'
                style={{
                    borderRadius: 8,
                    padding: 5
                }}
            >
                <div
                    className='layout_UltimoAcesso_cvrehjvfqw'
                    style={{
                        borderRadius: 8,
                        padding: 5,
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between'
                    }}
                >
                    {/* <Text
                        style={{
                            marginRight: 10
                        }}
                    >
                        {'Contrato: '}
                        <Text strong>{
                            dadosTabelaModalModulosPorUsuario && dadosTabelaModalModulosPorUsuario?.contrato ?
                                dadosTabelaModalModulosPorUsuario.contrato
                                :
                                ""
                        }</Text>
                    </Text> */}
                    <Text
                        style={{
                            marginRight: 10
                        }}
                    >
                        {'Usuário: '}
                        <Text strong>{
                            dadosTabelaModalModulosPorUsuario && dadosTabelaModalModulosPorUsuario?.usuario ?
                                dadosTabelaModalModulosPorUsuario.usuario + ' - ' + dadosTabelaModalModulosPorUsuario?.cargo
                                :
                                ""
                        }</Text>
                    </Text>
                    <Text
                        style={{
                            marginRight: 10
                        }}
                    >
                        {'Versão do app: '}
                        <Text strong>{
                            dadosTabelaModalModulosPorUsuario && dadosTabelaModalModulosPorUsuario?.versao ?
                                dadosTabelaModalModulosPorUsuario.versao
                                :
                                ""
                        }</Text>
                    </Text>
                </div>
            </div>
            <Table
                className="tableNotPrint_acessos"
                rowKey={(record) => record.dataEHora}
                loading={loadingTableModulos}
                columns={columnsModalModulosPorUsuario}
                dataSource={dadosTabelaModalModulosPorUsuario && dadosTabelaModalModulosPorUsuario.acessos_modulos ? dadosTabelaModalModulosPorUsuario.acessos_modulos : []}
                scroll={{ y: 400, x: 550 }}
                size="small"
                title={(registros) => {
                    return (
                        <div
                            style={{
                                padding: 10,
                                margin: -8,
                                marginBottom: 0.5
                            }}
                            className="divTable_UltimoAcesso_awywfgbowreag"
                        >
                            <Text>
                                {`Mostrando: ${registros.length} de ${dadosTabelaModalModulosPorUsuario?.acessos_modulos.length} `}
                            </Text>
                        </div>
                    )
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: 'end'
                            }}
                        >
                            <Text>
                                {`Mostrando: ${registros.length} de ${dadosTabelaModalModulosPorUsuario?.acessos_modulos.length} `}
                            </Text>
                        </div>
                    )
                }}
                pagination={{
                    locale: { items_per_page: '' },
                    showSizeChanger: true,
                    pageSize: dadosTabelaModalModulosPorUsuario && dadosTabelaModalModulosPorUsuario?.acessos_modulos.length > 0 && dadosTabelaModalModulosPorUsuario?.acessos_modulos ?
                        (dadosTabelaModalModulosPorUsuario?.acessos_modulos.length + 10) : (10),
                    position: [],
                    // pageSizeOptions: ['10', '20', '50', '100']
                }}
                locale={locale.Table}
            />
            <Table
                className="tableToPrint_acessos"
                rowKey={(record) => record.dataEHora}
                loading={loadingTableModulos}
                columns={columnsModalModulosPorUsuario}
                dataSource={dadosTabelaModalModulosPorUsuario && dadosTabelaModalModulosPorUsuario.acessos_modulos ? dadosTabelaModalModulosPorUsuario.acessos_modulos : []}
                size="small"
                title={(registros) => {
                    return (
                        <div
                            style={{
                                padding: 10,
                                margin: -8,
                                marginBottom: 0.5
                            }}
                            className="divTable_UltimoAcesso_awywfgbowreag"
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${dadosTabelaModalModulosPorUsuario?.acessos_modulos.length} `}
                            </Text>
                        </div>
                    )
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: 'end'
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${dadosTabelaModalModulosPorUsuario?.acessos_modulos.length} `}
                            </Text>
                        </div>
                    )
                }}
                pagination={{
                    locale: { items_per_page: '' },
                    showSizeChanger: true,
                    pageSize: dadosTabelaModalModulosPorUsuario && dadosTabelaModalModulosPorUsuario?.acessos_modulos.length > 0 && dadosTabelaModalModulosPorUsuario?.acessos_modulos ?
                        (dadosTabelaModalModulosPorUsuario?.acessos_modulos.length + 10) : (10),
                    position: [],
                    // pageSizeOptions: ['10', '20', '50', '100']
                }}
                locale={locale.Table}
            />
        </div>
    )
})

export default ContainerModalPorUsuario;
