import React, { useState, useCallback, useEffect } from 'react';
import { Button, Steps, Divider, Row, Col, notification, Spin, Form } from 'antd';

import {
    boatoCancelarStep,
    botaoProximoStep,
    botaoCancelarESalvarEmAddContrato
} from './GridStyle';
import BotaoSelectAddUser from "./components/BotaoSelectAddUser";
import AddContratoInUser from "./components/AddContratoInUser";
import PermissoesApp from "./components/PermissoesApp";
import NotificacaoPage from "./components/Notificacao";
import PermissoesPainel from "./components/PermissoesPainel";
import { useSelector, useDispatch } from 'react-redux';
import { UserState } from './../../interfaces/UserAdd';
import * as UsuariosActions from '../../store/modules/usuario/actions';


const stepsContent = [
    {
        key: 1,
        title: 'Usuário',
        content: <BotaoSelectAddUser />,
        description: "Dados do usuário",
    },
    {
        key: 2,
        title: 'Contrato',
        content: <AddContratoInUser />,
        description: "Dados do contrato",
    },
    {
        key: 3,
        title: 'Permissões',
        content: <PermissoesApp />,
        description: "Permissões do app",
    },
    {
        key: 4,
        title: 'Notificações',
        content: <NotificacaoPage />,
        description: "Notificações do app",
    },
    {
        key: 5,
        title: 'Permissões',
        content: <PermissoesPainel />,
        description: "Permissões do painel",
    }
];

const NovosUsuarios: React.FC = () => {

    const dispatch = useDispatch();
    // formRef = React.createRef();

    const propsState = useSelector((state: UserState) => state.usuario);

    const [current, setCurrent] = useState(0);
    // const [loadingFormAdd, setLoadingFormAdd] = useState(false);
    const [stepsState, setStepsState] = useState(stepsContent);
    const [estaEditando, setEstaEditando] = useState(false);

    // start = () => {

    // };

    const executaAoIsiciar = useEffect((): void => {

        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData?.isEdit) {
            setEstaEditando(localUserData?.isEdit);
        }
    }, []);

    const selecionaModal = useEffect((): void => {
        switch (propsState?.nome) {
            case "app":
                const stepsApp = [
                    {
                        key: 1,
                        title: 'Usuário',
                        content: <BotaoSelectAddUser />,
                        description: "Dados do usuário",
                    },
                    {
                        key: 2,
                        title: 'Contrato',
                        content: <AddContratoInUser />,
                        description: "Dados do contrato",
                    },
                    {
                        key: 3,
                        title: 'Permissões',
                        content: <PermissoesApp />,
                        description: "Permissões do app",
                    },
                    {
                        key: 4,
                        title: 'Notificações',
                        content: <NotificacaoPage />,
                        description: "Notificações do app",
                    }
                ];
                setStepsState(stepsApp);
                dispatch(UsuariosActions.setMaxLenghtStep(stepsApp.length));
                break;
            case "painel":
                const stepsPainel = [
                    {
                        key: 1,
                        title: 'Usuário',
                        content: <BotaoSelectAddUser />,
                        description: "Dados do usuário",
                    },
                    {
                        key: 5,
                        title: 'Permissões',
                        content: <PermissoesPainel />,
                        description: "Permissões do painel",
                    }
                ];
                setStepsState(stepsPainel);
                dispatch(UsuariosActions.setMaxLenghtStep(stepsPainel.length));
                break;
            case "notificacoes":
                const stepsNotificacao = [
                    {
                        key: 1,
                        title: 'Usuário',
                        content: <BotaoSelectAddUser />,
                        description: "Dados do usuário",
                    },
                    {
                        key: 2,
                        title: 'Contrato',
                        content: <AddContratoInUser />,
                        description: "Dados do contrato",
                    },
                    {
                        key: 4,
                        title: 'Notificações',
                        content: <NotificacaoPage />,
                        description: "Notificações do app",
                    }
                ];
                setStepsState(stepsNotificacao);
                dispatch(UsuariosActions.setMaxLenghtStep(stepsNotificacao.length));
                break;
            case "todos":
                const stepsTodos = [
                    {
                        key: 1,
                        title: 'Usuário',
                        content: <BotaoSelectAddUser />,
                        description: "Dados do usuário",
                    },
                    {
                        key: 2,
                        title: 'Contrato',
                        content: <AddContratoInUser />,
                        description: "Dados do contrato",
                    },
                    {
                        key: 3,
                        title: 'Permissões',
                        content: <PermissoesApp />,
                        description: "Permissões do app",
                    },
                    {
                        key: 4,
                        title: 'Notificações',
                        content: <NotificacaoPage />,
                        description: "Notificações do app",
                    },
                    {
                        key: 5,
                        title: 'Permissões',
                        content: <PermissoesPainel />,
                        description: "Permissões do painel",
                    }
                ];
                setStepsState(stepsTodos);
                dispatch(UsuariosActions.setMaxLenghtStep(stepsTodos.length));
                break;

            default:
                const stepsPadrao = [
                    {
                        key: 1,
                        title: 'Usuário',
                        content: <BotaoSelectAddUser />,
                        description: "Dados do usuário",
                    },
                    {
                        key: 2,
                        title: 'Contrato',
                        content: <AddContratoInUser />,
                        description: "Dados do contrato",
                    },
                    {
                        key: 3,
                        title: 'Permissões',
                        content: <PermissoesApp />,
                        description: "Permissões do app",
                    },
                    {
                        key: 4,
                        title: 'Notificações',
                        content: <NotificacaoPage />,
                        description: "Notificações do app",
                    },
                    {
                        key: 5,
                        title: 'Permissões',
                        content: <PermissoesPainel />,
                        description: "Permissões do painel",
                    }
                ];
                setStepsState(stepsPadrao);
                dispatch(UsuariosActions.setMaxLenghtStep(stepsPadrao.length));
                break;
        }
    }, [propsState?.nome]);

    //enviar formulario
    const submitBotaoSalvar = useCallback((): void => {
        propsState.formulario?.validateFields()
            .then((values: any) => {
                propsState.formulario?.submit();
            })
            .catch((errorInfo: any) => {

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [current, propsState.formulario]);

    const submitFormUserEsalvarESair = useCallback((): void => {

        propsState.formulario?.validateFields()
            .then((values: any) => {
                propsState.formulario?.submit();
            })
            .catch((errorInfo: any) => {

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });

    }, [current, propsState.formulario]);

    //enviar formulario
    const submitBotaoProximo = useCallback((): void => {

        propsState.formulario?.validateFields()
            .then((values: any) => {
                if (current == stepsState.length - 1) {
                } else {
                    setCurrent(current + 1);
                }
            })
            .catch((errorInfo: any) => {

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });

    }, [current, propsState.formulario]);

    //salvar form de contrato
    const submitSalvarContrato = useCallback((): void => {
        propsState.formulario?.validateFields()
            .then((values: any) => {
                if (values) {
                    propsState.formulario?.submit();
                }
            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });

    }, [current, propsState.formulario]);

    //salvar form de contrato
    const submitCancelarTelaAddContrato = useCallback((): void => {
        dispatch(UsuariosActions.setBotaoAddContrato(false));
    }, []);

    const cancelarModalFormUser = useCallback((): void => {
        propsState.formulario?.validateFields()
            .then((values: any) => {
                setCurrent(current - 1);
            })
            .catch((errorInfo: any) => {

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });

    }, [current, propsState.formulario]);

    const saveLocalStorage = useCallback((valoresForm, step): void => {

    }, []);

    //quando o usuario clica na barra de progresso na tela de usuario, verifica se ele pode navegar ou não
    const onChangeStep = useCallback((posicaoatual): void => {
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }
        switch (propsState.nome) {
            case 'app':
                if (posicaoatual == 0) {
                    propsState.formulario?.validateFields()
                        .then((values: any) => {
                            setCurrent(posicaoatual);
                        })
                        .catch((errorInfo: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });
                } else if (posicaoatual == 1) {
                    propsState.formulario?.validateFields()
                        .then((values: any) => {
                            if (localUserData?.step1) {
                                setCurrent(posicaoatual);
                            }
                        })
                        .catch((errorInfo: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });
                } else if (posicaoatual == 2) {
                    propsState.formulario?.validateFields()
                        .then((values: any) => {
                            if (localUserData?.step1 && localUserData?.step2) {
                                setCurrent(posicaoatual);
                            }
                        })
                        .catch((errorInfo: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });
                } else if (posicaoatual == 3) {
                    propsState.formulario?.validateFields()
                        .then((values: any) => {
                            if (localUserData?.step1 && localUserData?.step2 && localUserData?.step3) {
                                setCurrent(posicaoatual);
                            }
                        })
                        .catch((errorInfo: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });
                }
                break;
            case 'painel':
                if (posicaoatual == 0) {
                    propsState.formulario?.validateFields()
                        .then((values: any) => {
                            setCurrent(posicaoatual);
                        })
                        .catch((errorInfo: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });

                } else if (posicaoatual == 1) {
                    propsState.formulario?.validateFields()
                        .then((values: any) => {
                            if (localUserData?.step1) {
                                setCurrent(posicaoatual);
                            }
                        })
                        .catch((errorInfo: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });
                }
                break;
            case 'notificacoes':
                if (posicaoatual == 0) {
                    propsState.formulario?.validateFields()
                        .then((values: any) => {
                            setCurrent(posicaoatual);
                        })
                        .catch((errorInfo: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });
                } else if (posicaoatual == 1) {
                    propsState.formulario?.validateFields()
                        .then((values: any) => {
                            if (localUserData?.step1) {
                                setCurrent(posicaoatual);
                            }
                        })
                        .catch((errorInfo: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });

                } else if (posicaoatual == 2) {
                    propsState.formulario?.validateFields()
                        .then((values: any) => {
                            if (localUserData?.step1 && localUserData?.step2) {
                                setCurrent(posicaoatual);
                            }
                        })
                        .catch((errorInfo: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });
                }
                break;
            case 'todos':
                if (posicaoatual == 0) {
                    propsState.formulario?.validateFields()
                        .then((values: any) => {
                            setCurrent(posicaoatual);
                        })
                        .catch((errorInfo: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });
                } else if (posicaoatual == 1) {
                    propsState.formulario?.validateFields()
                        .then((values: any) => {
                            if (localUserData?.step1) {
                                setCurrent(posicaoatual);
                            }
                        })
                        .catch((errorInfo: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });

                } else if (posicaoatual == 2) {
                    propsState.formulario?.validateFields()
                        .then((values: any) => {
                            if (localUserData?.step1 && localUserData?.step2) {
                                setCurrent(posicaoatual);
                            }
                        })
                        .catch((errorInfo: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });
                } else if (posicaoatual == 3) {
                    propsState.formulario?.validateFields()
                        .then((values: any) => {
                            if (localUserData?.step1 && localUserData?.step2 && localUserData?.step3) {
                                setCurrent(posicaoatual);
                            }
                        })
                        .catch((errorInfo: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });
                } else if (posicaoatual == 4) {
                    propsState.formulario?.validateFields()
                        .then((values: any) => {
                            if (localUserData?.step1 && localUserData?.step2 && localUserData?.step3 && localUserData?.step4) {
                                setCurrent(posicaoatual);
                            }
                        })
                        .catch((errorInfo: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });
                }
                break;
            default:
                if (posicaoatual == 0) {
                    propsState.formulario?.validateFields()
                        .then((values: any) => {
                            setCurrent(posicaoatual);
                        })
                        .catch((errorInfo: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });
                } else if (posicaoatual == 1) {
                    propsState.formulario?.validateFields()
                        .then((values: any) => {
                            if (localUserData?.step1) {
                                setCurrent(posicaoatual);
                            }
                        })
                        .catch((errorInfo: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });

                } else if (posicaoatual == 2) {
                    propsState.formulario?.validateFields()
                        .then((values: any) => {
                            if (localUserData?.step1 && localUserData?.step2) {
                                setCurrent(posicaoatual);
                            }
                        })
                        .catch((errorInfo: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });
                } else if (posicaoatual == 3) {
                    propsState.formulario?.validateFields()
                        .then((values: any) => {
                            if (localUserData?.step1 && localUserData?.step2 && localUserData?.step3) {
                                setCurrent(posicaoatual);
                            }
                        })
                        .catch((errorInfo: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });
                } else if (posicaoatual == 4) {
                    propsState.formulario?.validateFields()
                        .then((values: any) => {
                            if (localUserData?.step1 && localUserData?.step2 && localUserData?.step3 && localUserData?.step4) {
                                setCurrent(posicaoatual);
                            }
                        })
                        .catch((errorInfo: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });
                }
                break;
        }
    }, [current, propsState.formulario, propsState.nome]);

    const comonentExecutaAoDestroy = useEffect(() => () => {
        dispatch(UsuariosActions.setBotaoAddContrato(false));
        localStorage.removeItem('@GovFacil:userData');
    }, []);

    return (
        <>
            <Spin spinning={propsState.spinnerSteps}>
                <Steps current={current} onChange={onChangeStep}>
                    {
                        stepsState.map((item) => {
                            return (
                                <Steps.Step key={item.key} title={item.title} description={item.description} />
                            )
                        })
                    }
                </Steps>
                <div style={{ marginTop: 30 }}>
                    {stepsState[current].content}
                </div>
                <Divider style={{ marginTop: 0, marginBottom: 20 }} />
                <Row>
                    <>
                        {propsState.botaoAddContrato ?
                            <>
                                <Col {...boatoCancelarStep}>
                                </Col>
                                <Col {...botaoCancelarESalvarEmAddContrato}>
                                    <div className="botaoSalvarStep">
                                        <Button onClick={submitCancelarTelaAddContrato}>
                                            {"Cancelar"}
                                        </Button>
                                        <Button style={{ marginLeft: 19 }} type="primary" onClick={submitSalvarContrato}>
                                            {"Salvar"}
                                        </Button>
                                    </div>
                                </Col>
                            </>
                            :
                            <>
                                <Col {...boatoCancelarStep}>
                                    {current == 0 ?
                                        ""
                                        :
                                        <Button key="back" disabled={current == 0} onClick={cancelarModalFormUser}>
                                            Anterior
                                        </Button>
                                    }
                                </Col>
                                <Col {...botaoProximoStep}>
                                    <div className="botaoSalvarStep" style={{
                                        flexDirection: 'row',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        // background: 'red'
                                    }}>
                                        {current == 0 && estaEditando ?
                                            <Button
                                                key="salvarESair"
                                                onClick={submitFormUserEsalvarESair}
                                                type="primary"
                                                style={{
                                                    marginRight: 5
                                                }}
                                            >
                                                Atualizar e sair
                                            </Button>
                                            :
                                            ""
                                        }
                                        {current == stepsState.length - 1 ?
                                            <Button key="submit" type="primary" onClick={submitBotaoSalvar}>
                                                {"Salvar"}
                                            </Button>
                                            :
                                            <Button
                                                key="submit"
                                                type="primary" onClick={submitBotaoProximo}
                                            >
                                                {"Próximo"}
                                            </Button>
                                        }
                                    </div>
                                </Col>
                            </>
                        }
                    </>

                </Row>
            </Spin>
        </>
    );
}

export default NovosUsuarios;
