export const cardLayout = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};

export const rowColNomeAndTipo = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};

export const inputNome = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 12, offset: 0 }, //>576
    md: { span: 12, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};
export const inputSelectTipo = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 11, offset: 0 }, //>576
    md: { span: 11, offset: 0 }, // >768
    lg: { span: 11, offset: 0 }, // > 992
    xl: { span: 6, offset: 0 }, // > 1200
    xxl: { span: 6, offset: 0 }, // > 1600
};
