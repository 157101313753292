export const cardLayout = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 24, offset: 0 }, // > 992
    xl: { span: 24, offset: 0 }, // > 1200
    xxl: { span: 24, offset: 0 }, // > 1600
};

export const botaoSalvar = {
    xs: { span: 16, offset: 8 }, //<576
    sm: { span: 8, offset: 16 }, //>576
    md: { span: 10, offset: 14 }, // >768
    lg: { span: 7, offset: 17 }, // > 992
    xl: { span: 6, offset: 18 }, // > 1200
    xxl: { span: 6, offset: 18 }, // > 1600
};

export const selectedVinteQuatro = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 24, offset: 0 },
    xl: { span: 24, offset: 0 },
    xxl: { span: 24, offset: 0 },
}
export const selectedPoder = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 8, offset: 0 }, // > 1600
}
export const selectedCidade = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 11, offset: 1 }, // > 992
    xl: { span: 11, offset: 1 }, // > 1200
    xxl: { span: 7, offset: 1 }, // > 1600
}

export const modalAddModuloInputs = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 8, offset: 0 }, // >768
    lg: { span: 8, offset: 0 }, // > 992
    xl: { span: 8, offset: 0 }, // > 1200
    xxl: { span: 8, offset: 0 }, // > 1600
};

export const modaisDeConfig = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 24, offset: 0 }, // > 992
    xl: { span: 24, offset: 0 }, // > 1200
    xxl: { span: 24, offset: 0 }, // > 1600
};

export const colMeioIni = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 24, offset: 0 }, // > 992
    xl: { span: 24, offset: 0 }, // > 1200
    xxl: { span: 24, offset: 0 }, // > 1600
};

export const colMeioMeio = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 24, offset: 0 }, // > 992
    xl: { span: 24, offset: 0 }, // > 1200
    xxl: { span: 24, offset: 0 }, // > 1600
};

export const inputAtendViewMeioIni = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
}
export const botaoSelecionarContrato = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 11, offset: 1 }, //>576
    md: { span: 11, offset: 1 }, // >768
    lg: { span: 11, offset: 1 }, // > 992
    xl: { span: 5, offset: 1 }, // > 1200
    xxl: { span: 11, offset: 1 }, // > 1600
}

export const campoValidadeDoTesteCol = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 11, offset: 1 }, // > 992
    xl: { span: 11, offset: 1 }, // > 1200
    xxl: { span: 11, offset: 1 }, // > 1600
}

export const botoesVincularUsuarioAndListar = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 11, offset: 1 }, //>576
    md: { span: 11, offset: 1 }, // >768
    lg: { span: 11, offset: 1 }, // > 992
    xl: { span: 5, offset: 1 }, // > 1200
    xxl: { span: 11, offset: 1 }, // > 1600
}

export const colMeioFim = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 12, offset: 0 }, // >768
    lg: { span: 7, offset: 1 }, // > 992
    xl: { span: 8, offset: 0 }, // > 1200
    xxl: { span: 7, offset: 1 }, // > 1600
};

export const colModalVincularUsuarioInputs = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 12, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};
export const colModalVincularUsuarioInputs2 = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 11, offset: 1 }, // >768
    lg: { span: 11, offset: 1 }, // > 992
    xl: { span: 11, offset: 1 }, // > 1200
    xxl: { span: 11, offset: 1 }, // > 1600
};
