import { useRef, useState } from "react";

export const useAcessos = () => {

    const refComponentToPrint = useRef<any>(null);

    const [loadingScreen, setLoadingScreen] = useState(false);

    return {
        loadingScreen,
        setLoadingScreen,
        refComponentToPrint,
    }
}
