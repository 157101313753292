import { takeLatest, all } from 'redux-saga/effects';

export function* signIn({ payload }: ReturnType<any>) {

}

export function* signUp({ payload }: ReturnType<any>) {

}

export function setToken({ payload }: ReturnType<any>) {

}

export function updateTheme({ payload }: ReturnType<any>) {
    localStorage.setItem("@GovFacil:theme", payload);
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@global/updateTheme', updateTheme),
]);
