import { combineReducers } from 'redux';

import global from './global/reducer';
import login from './login/reducer';
import usuario from './usuario/reducer';

export default combineReducers({
  global,
  login,
  usuario
});
