import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
    Button, Col, Row, Breadcrumb, Input, Space, Table, Modal, Spin, Form,
    Typography, Divider, notification, Popconfirm
} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import {
    cardLayout,
    modaisDeConfig,
    colLisTabelaConfigs
} from './GridStyle';

import { FaCogs, FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router';
import axios from 'axios';
import { urlTipoProcessoJuridico } from '../../../../../services/request/urls';
import { getToken } from '../../../../../services/token';

const IconCogs = (props: any) => <Icon {...props} component={FaCogs} ></Icon>

const { Title } = Typography;

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>

const ListTipo: React.FC = () => {
    const formRef = useRef<any>(null);

    const history = useHistory();

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<number>>([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dadosTabelaState, setDadosTabelaState] = useState([]);
    const [hasSelectedAdd, setHasSelectedAdd] = useState(true);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);
    const [hasSelectedDel, setHasSelectedDel] = useState(false);
    const [loadingFormAdd, setLoadingFormAdd] = useState(false);
    const [modalAddConfigVisible, setModalAddConfigVisible] = useState(false);

    //State de editar usuario
    const [initialValueEditId, setInitialValueEditId] = useState<undefined | number>(undefined);

    //executa apenas ao iniciar o component
    useEffect(() => {
        setLoadingTable(true)
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlTipoProcessoJuridico,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                setDadosTabelaState(result.data);
                setLoadingTable(false)

            } catch (error) {
                setLoadingTable(false)
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        buscaDeDados();

    }, []);

    //executa apenas quando salvar
    const salvarModalRecarregaTable = useCallback(() => {
        setLoadingTable(true)
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlTipoProcessoJuridico,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                setDadosTabelaState(result.data);
                setLoadingTable(false)
            } catch (error) {
                setLoadingTable(false)
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        buscaDeDados();

    }, []);

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, [searchText]);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);

    //Search da tabela de listagem
    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) =>
                record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                        text
                    ),
        })
    }, [searchText, searchedColumn, handleSearch, handleReset]);

    // fim do search da tabela de listagem


    const clickBotaoDeletarDados = useCallback((): void => {
        const buscaDeDados = () => {
            // if (selectedRowKeys.length == 1 || selectedRowKeys.length > 1) {
            if (selectedRowKeys.length == 1) {
                let obj: Array<object> = [];
                let resultforeach = dadosTabelaState.forEach(({ nome, id }): void => {

                    selectedRowKeys.forEach((val, i) => {
                        if (nome == val) {
                            obj.push({ id })
                        }
                    });

                })

                const excluirDadosModal = async () => {
                    try {
                        let resultCidade = await axios.delete(urlTipoProcessoJuridico, {
                            //EXEMPLO APENAS PARA EXCLUIR UM [0] ATE CORRIGIR O BACK
                            data: obj[0],
                            headers: { 'Authorization': 'Bearer ' + getToken() }
                        })
                        notification.success({
                            message: 'Sucesso',
                            description:
                                'Seus dados foram excluídos com sucesso!',
                        });
                        setLoadingFormAdd(false);
                        salvarModalRecarregaTable();
                        setInitialValueEditId(undefined);
                    } catch (error) {
                        setLoadingFormAdd(false);
                        notification.error({
                            message: 'Erro',
                            description:
                                'Não foi possivel deletar, entre em contato com suporte!',
                        });
                    }
                }
                excluirDadosModal();
                setHasSelectedAdd(true);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
                setSelectedRowKeys([]);

            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState, formRef]);

    const clickSubmitModalAddConfig = useCallback((): void => {

        formRef.current?.validateFields()
            .then((values: any) => {
                setLoadingFormAdd(true);

                if (initialValueEditId) {
                    values.id = initialValueEditId;

                    const editarDadosModal = async () => {
                        try {
                            let resultCidade = await axios.put(urlTipoProcessoJuridico, values,
                                { headers: { 'Authorization': 'Bearer ' + getToken() } });
                            notification.success({
                                message: 'Sucesso',
                                description:
                                    'Seus dados foram editados com sucesso!',
                            });
                            setLoadingFormAdd(false);
                            salvarModalRecarregaTable();
                            clickCancelModalAddConfig();
                            setInitialValueEditId(undefined);
                        } catch (error) {
                            setLoadingFormAdd(false);
                            notification.error({
                                message: 'Erro',
                                description:
                                    'Não foi possivel salvar, entre em contato com suporte!',
                            });
                        }
                    }
                    editarDadosModal();
                } else {
                    const salvarDadosModal = async () => {
                        try {
                            let resultCidade = await axios.post(urlTipoProcessoJuridico, values,
                                { headers: { 'Authorization': 'Bearer ' + getToken() } });
                            notification.success({
                                message: 'Sucesso',
                                description:
                                    'Seus dados foram criados com sucesso!',
                            });
                            setLoadingFormAdd(false);
                            salvarModalRecarregaTable();
                            clickCancelModalAddConfig();
                        } catch (error) {
                            setLoadingFormAdd(false);
                            notification.error({
                                message: 'Erro',
                                description:
                                    'Não foi possivel salvar, entre em contato com suporte!',
                            });
                        }
                    }
                    salvarDadosModal();
                }
                setHasSelectedAdd(true);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
                setSelectedRowKeys([])

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [initialValueEditId]);

    //Editar dados
    const clickBotaoEditarDados = useCallback((): void => {

        const buscaDeDados = async () => {
            if (selectedRowKeys.length == 1) {
                let obj: Array<any> = [];
                let resultforeach = await dadosTabelaState.forEach(({ nome, id }): void => {
                    if (nome == selectedRowKeys[0]) {
                        obj.push({ id, nome })
                    }
                })

                if (formRef.current) {
                    formRef.current.setFieldsValue(
                        { nome: obj[0].nome }
                    );
                } else {
                    setTimeout(() => {
                        formRef.current?.setFieldsValue(
                            { nome: obj[0].nome }
                        );
                    }, 100);
                }

                setInitialValueEditId(obj[0].id)
                setModalAddConfigVisible(true);

            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState, formRef]);

    const clickCancelModalAddConfig = useCallback(() => {
        setModalAddConfigVisible(false);
        formRef.current.resetFields();
    }, []);

    //abrir modal
    const clickBotaoAddDados = useCallback((): void => {
        setInitialValueEditId(undefined);
        setModalAddConfigVisible(true)
    }, []);

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeys.includes(record.nome)) {
            let filter = selectedRowKeys.filter((val) => {
                return val != record.nome
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(false);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(true);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length == 0) {
                setSelectedRowKeys([record.nome]);
                setHasSelectedAdd(false);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else {
                let teste = selectedRowKeys.concat(record.nome);
                setSelectedRowKeys(teste);
                setHasSelectedAdd(false);
                setHasSelectedEdit(false);
                setHasSelectedDel(true);
            }
        }
    }, [selectedRowKeys]);

    //checkbox da tabela de listagem
    const onSelectChange = useCallback((selectedRowKeys): void => {
        if (selectedRowKeys.length > 1) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedEdit(false);
            setHasSelectedDel(true);
        } else if (selectedRowKeys.length === 0) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(true);
            setHasSelectedEdit(false);
            setHasSelectedDel(false);
        } else {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedEdit(true);
            setHasSelectedDel(true);
        }
    }, [selectedRowKeys]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '1%'
    };

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...getColumnSearchProps('nome'),
            width: '15%',
        }
    ];

    const onClickVoltarConfigOfSystem = useCallback((): void => {
        history.push("/configuracoesdosistema");
    }, []);

    return (
        <>
            <Row>
                <Col {...cardLayout}>
                    <Title level={3}><IconCogs style={{ fontSize: 35 }} /> Configurações do Sistema</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item><a onClick={onClickVoltarConfigOfSystem}>Configurações do Sistema</a></Breadcrumb.Item>
                        <Breadcrumb.Item>Tipo</Breadcrumb.Item>
                    </Breadcrumb>
                    <Divider />
                </Col>
            </Row>
            <Row style={{ marginBottom: 16 }}>
                <Col>
                    <Button type="primary" className="botaoAdicionarAssociacao" disabled={!hasSelectedAdd} onClick={clickBotaoAddDados} >
                        Adicionar
                    </Button>

                </Col>
                <Col>
                    <Button type="primary" className="botaoEditarAssociacao" onClick={clickBotaoEditarDados} disabled={!hasSelectedEdit} >
                        Editar
                    </Button>
                    <Popconfirm
                        title="Deseja excluir?"
                        onConfirm={clickBotaoDeletarDados}
                        onCancel={() => { }}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button className="botaoDeleteAssociacao" type="primary" danger
                            // disabled={!hasSelectedDel}
                            disabled={!hasSelectedEdit}
                        >
                            <DeleteOutlined />
                        </Button>
                    </Popconfirm>
                </Col>
            </Row>
            <Row>
                <Col {...colLisTabelaConfigs}>
                    <Table
                        loading={loadingTable}
                        rowKey={(record) => record.nome}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => { onclickTable(record, rowIndex) }, // click row
                            };
                        }}
                        rowSelection={rowSelection}
                        columns={columns} dataSource={dadosTabelaState}
                        size="small"
                        pagination={{ locale: { items_per_page: '' } }}
                    />
                </Col>
            </Row>
            <Modal
                title="Adicionar Tipo Processo Juridico"
                visible={modalAddConfigVisible}
                className="modalAddAssociacao"
                onOk={clickSubmitModalAddConfig}
                onCancel={clickCancelModalAddConfig}
                footer={[
                    <Button key="back" disabled={loadingFormAdd} onClick={clickCancelModalAddConfig}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loadingFormAdd} onClick={clickSubmitModalAddConfig}>
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingFormAdd}>
                    <Form
                        ref={formRef}
                        name="formModalAddAssociacao"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                        onFinishFailed={() => { }}
                    >
                        <Row>
                            <Col {...modaisDeConfig}>
                                <Form.Item
                                    name="nome"
                                    label="Nome"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor digite o nome do tipo!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Nome" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
}

export default ListTipo;
