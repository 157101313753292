import React, { useCallback, useState, useRef } from 'react';
import {
    Button, Col, Collapse, List, Row, Radio, Select,
    Typography, Divider, Modal, Form, Input, notification, Spin
} from 'antd';
import Icon from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
    cardLayout,
    colCollapseContrato,
    colCollapseContrato1,
    colCollapseContrato2,
    modaisDeConfig,
    modalAddModuloInputs
} from './GridStyle';
import axios from 'axios';
import {
    urlCargo, urlEsfera, urlPoder, urlSituacaoContrato, urlTiposContrato,
    urlModalidade, urlTiposAditivos, urlModulo, urlModuloItem, urlTipoProcessoJuridico,
    urlConfigModulosNotificaProcessosJuridicos, urlConfigModulosNotificaOrbigacoes, urlBuscaCidadesPorIdEstado,
    urlBuscaEstados, urlConfigModulosNotificaEntidade, urlConfigModulosNotificaJornais,
    urlConfigRotasPainelPermissao,
    urlConfigVersaoApp
} from '../../services/request/urls';

import { getToken } from '../../services/token';

import { FaCogs } from 'react-icons/fa';

import './index.css';

const IconCogs = (props: any) => <Icon {...props} component={FaCogs} ></Icon>

const { Panel } = Collapse;
const { Title } = Typography;

interface interfaceModuloList {
    id: number,
    nome: string,
    descricao: null | string,
    path: string,
    status: boolean,
    ordem: number,
    modo_estado: boolean
}

interface interfaceObrigacoes {
    id: number,
    nome: string,
    sigla: string,
}

interface interfaceDadosEntiProcsJurid {
    id: number,
    nome: string,
}

interface interfaceDadosEstados {
    id: number,
    sigla: string,
    regiao: {
        id: number,
        sigla: string,
        nome: string
    },
    regiao_id: number,
    nome: string,
    populacao: number
};

interface interfaceDadosCidades {
    id: number,
    nome: string,
    uuid: string,
    microrregiao: {
        id: number,
        uuid: string,
        mesorregiao: {
            id: number,
            uuid: string,
            estado: {
                id: number,
                sigla: string,
                nome: string,
                uuid: string
            },
            estado_id: number,
            nome: string
        },
        nome: string
    },
};

// import { Container } from './styles';

const ConfiguracoesDoSistema: React.FC = () => {

    const refFormModalAddConfig = useRef<any>(null);
    const refBotaoAddTodosObrigacoes = useRef<any>(null);
    const refBotaoAddTodosEstadosJornais = useRef<any>(null);
    //ModdulosApp
    const refFormModalAddModulos = useRef<any>(null);
    const refFormModalAddModuloItem = useRef<any>(null);

    //modulo notificacao obrigacoes
    const refFormModalAddNotificacoesObrigacoes = useRef<any>(null);
    const refFormModalAddNotificacoesEntidade = useRef<any>(null);
    const refFormModalAddNotificacoesJornais = useRef<any>(null);
    const refFormModalAddRotasPermissaoPainel = useRef<any>(null);
    const refFormModalAddVersaoApp = useRef<any>(null);

    const history = useHistory();

    const [modalAddConfigVisible, setModalAddConfigVisible] = useState(false);
    const [messageObrigatorioFormModal, setMessageObrigatorioFormModal] = useState('');
    const [titleFormModal, setTitleFormModal] = useState('');
    const [urlSalvarModal, setUrlSalvarModal] = useState('');
    const [loadingFormAdd, setLoadingFormAdd] = useState(false);

    //Modulos App Modal
    const [valorCampoModalInputOrdem, setValorCampoModalInputOrdem] = useState('');
    const [modalConfigModulos, setModalConfigModulos] = useState(false);

    //modutlo notificacoes modal obrigacoes visible
    const [modalConfigNotficacoesObrigacoes, setModalConfigNotficacoesObrigacoes] = useState(false);
    const [modalConfigNotficacoesEntidade, setModalConfigNotficacoesEntidade] = useState(false);
    const [modalConfigNotficacoesJornais, setModalConfigNotficacoesJornais] = useState(false);

    const [modalRotalPermissaoPainelVisible, setModalRotalPermissaoPainelVisible] = useState(false);
    const [modalRotalVersaoAppVisible, setModalRotalVersaoAppVisible] = useState(false);
    const [valorDiasParaAtualizar, setValorDiasParaAtualizar] = useState('');
    const [valorBuildAndroid, setValorBuildAndroid] = useState('');
    const [valorBuildIos, setValorBuildIos] = useState('');
    const [valorNumero, setValorNumero] = useState('');

    const [listModulo, setListModulo] = useState<Array<interfaceModuloList>>([]);
    const [listObrigacoes, setListObrigacoes] = useState<Array<interfaceObrigacoes>>([]);
    const [listCidades, setListCidades] = useState<Array<interfaceDadosCidades>>([]);
    const [listEstados, setListEstados] = useState<Array<interfaceDadosEstados>>([]);
    const [listEntidadeProcsJurid, setListEntidadeProcsJurid] = useState<Array<interfaceDadosEntiProcsJurid>>([]);

    const [loadingEstadosSelected, setLoadingEstadosSelected] = useState(false);
    const [loadingCidadesSelected, setLoadingCidadesSelected] = useState(false);

    const [loadingEntidadeProcJuridSelected, setLoadingEntidadeProcJuridSelected] = useState(false);
    const [valorCampoInputCodigo, setValorCampoInputCodigo] = useState('');

    //Contrato
    const clickListarContratoEsfera = useCallback((): void => {
        history.push("/configuracoesdosistema/listaresfera");
    }, []);
    const clickListarContratoPoder = useCallback((): void => {
        history.push("/configuracoesdosistema/listarpoder");
    }, []);
    const clickListarContratoTipoDeContrato = useCallback((): void => {
        history.push("/configuracoesdosistema/listartipocontrato");
    }, []);
    const clickListarContratoSituacaoDeContrato = useCallback((): void => {
        history.push("/configuracoesdosistema/listarsituacaocontrato");
    }, []);
    const clickListarModalidade = useCallback((): void => {
        history.push("/configuracoesdosistema/listarmodalidade");
    }, []);
    const clickListarTipoDeAditivo = useCallback((): void => {
        history.push("/configuracoesdosistema/listartipoaditivo");
    }, []);

    //Dados Usuario
    const clickListarDadosUsuarioCargo = useCallback((): void => {
        history.push("/configuracoesdosistema/listarcargo");
    }, []);

    //modulo app
    const clickListarModulo = useCallback((): void => {
        history.push("/configuracoesdosistema/listarmodulo");
    }, []);
    const clickListarModuloItem = useCallback((): void => {
        history.push("/configuracoesdosistema/listarmoduloitem");
    }, []);
    const clickListarOrdenacao = useCallback((): void => {
        history.push("/configuracoesdosistema/ordenacao");
    }, []);

    //Planos
    const clickListarModulosPlano = useCallback((): void => {
        history.push("/configuracoesdosistema/modulos-plano");
    }, []);
    const clickListarRecursosPlano = useCallback((): void => {
        history.push("/configuracoesdosistema/recursos-plano");
    }, []);
    const clickListarGerenciarPlano = useCallback((): void => {
        history.push("/configuracoesdosistema/gerenciar-plano");
    }, []);

    const clickListarAtendimentoProduto = useCallback((): void => {
        history.push("/configuracoesdosistema/gerenciar-produto");
    }, []);

    //Processos juridicos
    const clickListarTipoProcessosJuridicos = useCallback((): void => {
        history.push("/configuracoesdosistema/listartipoprocessosjuridicos");
    }, []);

    // de notificações
    const clickListMNProcessosJuridicos = useCallback((): void => {
        history.push("/configuracoesdosistema/listarprocjurid");
    }, []);

    const clickListMNObrigacoes = useCallback((): void => {
        history.push("/configuracoesdosistema/listarobrigacoes");
    }, []);

    const clickListMNEntidade = useCallback((): void => {
        history.push("/configuracoesdosistema/listarentidade");
    }, []);

    const clickListMNJornais = useCallback((): void => {
        history.push("/configuracoesdosistema/listarjornais");
    }, []);

    const clickListarRotasPermissaoPainel = useCallback((): void => {
        history.push("/configuracoesdosistema/listarotaspainel");
    }, []);
    const clickListarVersaoApp = useCallback((): void => {
        history.push("/configuracoesdosistema/listarversaoapp");
    }, []);

    //Modulos Notificacoes, so tem link de outra lista

    const clickListMNIndicesConstitucionais = useCallback((): void => {
        history.push("/configuracoesdosistema/listarindicesconstitucionais");
    }, []);
    const onClickAddMNIndicesConstitucionais = useCallback((): void => {
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:moduloNotifi');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@GovFacil:moduloNotifi');
        }
        history.push("/configuracoesdosistema/addindicesconstitucionais/add");
    }, []);

    const clickListMNCertidao = useCallback((): void => {
        history.push("/configuracoesdosistema/listarcertidao");
    }, []);

    const onClickAddMNCertidao = useCallback((): void => {
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:moduloNotifi');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@GovFacil:moduloNotifi');
        }
        history.push("/configuracoesdosistema/addcertidao/add");
    }, []);
    const clickListMNCauc = useCallback((): void => {
        history.push("/configuracoesdosistema/listarcauc");
    }, []);

    const onClickAddMNCauc = useCallback((): void => {
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:moduloNotifi');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@GovFacil:moduloNotifi');
        }
        history.push("/configuracoesdosistema/addcauc/add");
    }, []);

    // </>FIM Modulos Notificacoes, so tem link de outra lista

    //Abrir modal de cadastro Contrato
    const onClickCadastrarContratoEsfera = useCallback((): void => {
        setTitleFormModal("Adicionar Esfera");
        setMessageObrigatorioFormModal('Por favor digite o nome da esfera!');
        setModalAddConfigVisible(true);
        setUrlSalvarModal(urlEsfera);
    }, []);

    const onClickCadastrarContratoPoder = useCallback((): void => {
        setTitleFormModal("Adicionar Poder");
        setMessageObrigatorioFormModal('Por favor digite o nome do poder!');
        setModalAddConfigVisible(true);
        setUrlSalvarModal(urlPoder);
    }, []);

    const onClickCadastrarContratoTipoDeContrato = useCallback((): void => {
        setTitleFormModal("Adicionar Tipo do Contrato");
        setMessageObrigatorioFormModal('Por favor digite o nome do tipo de contrato!');
        setModalAddConfigVisible(true);
        setUrlSalvarModal(urlTiposContrato);
    }, []);

    const onClickCadastrarContratoSituacaoDeContrato = useCallback((): void => {
        setTitleFormModal("Adicionar situação do Contrato");
        setMessageObrigatorioFormModal('Por favor digite o nome da situação do contrato!');
        setModalAddConfigVisible(true);
        setUrlSalvarModal(urlSituacaoContrato);
    }, []);
    const onClickCadastrarModalidade = useCallback((): void => {
        setTitleFormModal("Adicionar Modalidade");
        setMessageObrigatorioFormModal('Por favor digite o nome da modalidade!');
        setModalAddConfigVisible(true);
        setUrlSalvarModal(urlModalidade);
    }, []);

    const onClickCadastrarTipoDeAditivo = useCallback((): void => {
        setTitleFormModal("Adicionar Tipo de Aditivo");
        setMessageObrigatorioFormModal('Por favor digite o nome do aditivo!');
        setModalAddConfigVisible(true);
        setUrlSalvarModal(urlTiposAditivos);
    }, []);

    //Dados Usuario
    const onClickCadastrarDadosUsuarioCargo = useCallback((): void => {
        setTitleFormModal("Adicionar Cargo");
        setMessageObrigatorioFormModal('Por favor digite o nome do cargo!');
        setModalAddConfigVisible(true);
        setUrlSalvarModal(urlCargo);
    }, []);

    //modulos App
    const onClickCadastrarModulo = useCallback((): void => {
        setModalConfigModulos(true);
    }, []);

    //modulo Processo Juridico
    const onClickCadastrarTipoProcessJuridico = useCallback((): void => {
        setTitleFormModal("Adicionar Tipo");
        setMessageObrigatorioFormModal('Por favor digite o nome do tipo!');
        setModalAddConfigVisible(true);
        setUrlSalvarModal(urlTipoProcessoJuridico);
    }, []);

    //modulo notificacao
    const onClickAddMNProcessosJuridicos = useCallback((): void => {
        setTitleFormModal("Adicionar Entidade Processo Juridico");
        setMessageObrigatorioFormModal('Por favor digite o nome da entidade processo juridico!');
        setModalAddConfigVisible(true);
        setUrlSalvarModal(urlConfigModulosNotificaProcessosJuridicos);
    }, []);

    //adicionar obrigacoes abrir modal
    const onClickAddMNObrigacoes = useCallback((): void => {
        setModalConfigNotficacoesObrigacoes(true);
    }, []);

    //adicionar obrigacoes abrir modal
    const onClickAddMNEntidade = useCallback((): void => {
        const buscarModulos = async () => {
            try {
                setLoadingEstadosSelected(true);
                let resultEstados = await axios.get(urlBuscaEstados,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });

                setLoadingEstadosSelected(false);

                setListEstados(resultEstados.data)

                let result = await axios.get(urlConfigModulosNotificaOrbigacoes,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                setListObrigacoes(result.data)

                setModalConfigNotficacoesEntidade(true);
            } catch (error) {
                setLoadingFormAdd(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar modulos, entre em contato com suporte!',
                });
            }
        }
        buscarModulos();
    }, []);

    //adicionar obrigacoes abrir modal
    const onClickAddMNJornais = useCallback((): void => {
        const buscarModulos = async () => {
            try {
                setLoadingEstadosSelected(true);
                let resultEstados = await axios.get(urlBuscaEstados,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });

                setLoadingEstadosSelected(false);

                setListEstados(resultEstados.data)

                setLoadingEntidadeProcJuridSelected(true)
                let result = await axios.get(urlConfigModulosNotificaProcessosJuridicos,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });

                setListEntidadeProcsJurid(result.data)

                setLoadingEntidadeProcJuridSelected(false)

                setModalConfigNotficacoesJornais(true);
            } catch (error) {
                setLoadingFormAdd(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar modulos, entre em contato com suporte!',
                });
            }
        }
        buscarModulos();
    }, []);

    //adicionar rotas de permissar painel
    const onClickCadastrarRotasPermissaoPainel = useCallback((): void => {
        setModalRotalPermissaoPainelVisible(true);
    }, []);
    //adicionar rotas de permissar painel
    const onClickCadastrarRotasVersaoApp = useCallback((): void => {
        setModalRotalVersaoAppVisible(true);
    }, []);

    //Modal e click botoes de Esfera
    const clickSubmitModalAddConfig = useCallback((): void => {
        refFormModalAddConfig.current?.validateFields()
            .then((values: any) => {

                setLoadingFormAdd(true);
                const salvarDadosModal = async () => {
                    try {
                        let resultCidade = await axios.post(urlSalvarModal, values,
                            { headers: { 'Authorization': 'Bearer ' + getToken() } });
                        setLoadingFormAdd(false);
                        notification.success({
                            message: 'Sucesso',
                            description:
                                'Seus dados foram criados com sucesso!',
                        });
                        clickCancelModalAddConfig();
                    } catch (error) {
                        setLoadingFormAdd(false);
                        notification.error({
                            message: 'Erro',
                            description:
                                'Não foi possivel salvar, entre em contato com suporte!',
                        });
                    }
                }
                salvarDadosModal();
            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [urlSalvarModal]);
    const clickCancelModalAddConfig = useCallback((): void => {
        setModalAddConfigVisible(false);
        refFormModalAddConfig.current.resetFields();
        setUrlSalvarModal('');
    }, []);
    // Fim de botoes modal esfera

    //Modal modulos App

    // const onChangeModalInputOrdem = useCallback((e: any) => {
    //     const originalValue = e.target.value;//+''
    //     const regex = /^[0-9]*$/

    //     if (regex.test(originalValue)) {
    //         setValorCampoModalInputOrdem(originalValue)
    //         refFormModalAddModulos.current.setFieldsValue(
    //             { ordem: originalValue }
    //         );
    //     } else {
    //         refFormModalAddModulos.current.setFieldsValue(
    //             { ordem: valorCampoModalInputOrdem }
    //         );
    //     }
    // }, [valorCampoModalInputOrdem]);

    const clickCancelModalAddModulos = useCallback((): void => {
        setModalConfigModulos(false);
        refFormModalAddModulos.current.resetFields();
    }, []);

    const clickSubmitModalAddModulos = useCallback((): void => {
        refFormModalAddModulos.current?.validateFields()
            .then((values: any) => {

                setLoadingFormAdd(true);
                const salvarDadosModal = async () => {
                    try {
                        let resultCidade = await axios.post(urlModulo, values,
                            { headers: { 'Authorization': 'Bearer ' + getToken() } });
                        setLoadingFormAdd(false);
                        notification.success({
                            message: 'Sucesso',
                            description:
                                'Seus dados foram criados com sucesso!',
                        });
                        clickCancelModalAddModulos();
                    } catch (error) {
                        setLoadingFormAdd(false);
                        notification.error({
                            message: 'Erro',
                            description:
                                'Não foi possivel salvar, entre em contato com suporte!',
                        });
                    }
                }
                salvarDadosModal();
            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description: errorInfo?.response?.data?.message ? errorInfo?.response?.data?.message : 'Preencha os campos obrigatorios!'
                });
            });
    }, [clickCancelModalAddModulos]);

    // Fim Modal modulos App

    //Inicio modulo notificacoes app modal obrigacoes

    const clickCancelModalAddNotificacoesObrigacoes = useCallback((): void => {
        setModalConfigNotficacoesObrigacoes(false);
        refFormModalAddNotificacoesObrigacoes.current.resetFields();
    }, []);

    const clickSubmitModalAddNotificacoesObrigacoes = useCallback((): void => {
        refFormModalAddNotificacoesObrigacoes.current?.validateFields()
            .then((values: any) => {

                setLoadingFormAdd(true);
                const salvarDadosModal = async () => {
                    try {
                        let resultCidade = await axios.post(urlConfigModulosNotificaOrbigacoes, values,
                            { headers: { 'Authorization': 'Bearer ' + getToken() } });
                        setLoadingFormAdd(false);
                        notification.success({
                            message: 'Sucesso',
                            description:
                                'Seus dados foram criados com sucesso!',
                        });
                        clickCancelModalAddNotificacoesObrigacoes();
                    } catch (error) {
                        let msgErro: any = (error as Error);

                        setLoadingFormAdd(false);
                        notification.error({
                            message: 'Erro',
                            description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Preencha os campos obrigatorios!'
                        });
                    }
                }
                salvarDadosModal();
            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [clickCancelModalAddNotificacoesObrigacoes]);

    const clickCancelModalAddNotificacoesEntidade = useCallback((): void => {
        setModalConfigNotficacoesEntidade(false);
        refFormModalAddNotificacoesEntidade.current.resetFields();
    }, []);

    const clickSubmitModalAddNotificacoesEntidade = useCallback((): void => {
        refFormModalAddNotificacoesEntidade.current?.validateFields()
            .then((values: any) => {

                let obri: any = []
                values.obrigacoes.forEach((element: number) => {
                    obri.push({ id: element })
                });

                values.obrigacoes = obri;

                setLoadingFormAdd(true);
                const salvarDadosModal = async () => {
                    try {
                        let resultCidade = await axios.post(urlConfigModulosNotificaEntidade, values,
                            { headers: { 'Authorization': 'Bearer ' + getToken() } });
                        setLoadingFormAdd(false);
                        notification.success({
                            message: 'Sucesso',
                            description:
                                'Seus dados foram criados com sucesso!',
                        });
                        clickCancelModalAddNotificacoesEntidade();
                    } catch (error) {
                        let msgErro: any = (error as Error);

                        setLoadingFormAdd(false);
                        notification.error({
                            message: 'Erro',
                            description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Preencha os campos obrigatorios!'
                        });
                    }
                }
                salvarDadosModal();
            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [clickCancelModalAddNotificacoesEntidade]);

    const clickCancelModalAddNotificacoesJornais = useCallback((): void => {
        setModalConfigNotficacoesJornais(false);
        refFormModalAddNotificacoesJornais.current.resetFields();
    }, []);

    const clickSubmitModalAddNotificacoesJornais = useCallback((): void => {
        refFormModalAddNotificacoesJornais.current?.validateFields()
            .then((values: any) => {

                let obri: any = []
                values.estados.forEach((element: number) => {
                    obri.push({ id: element })
                });

                values.estados = obri;

                let entidadepjObj: any = []
                values.entidadeProcessoJuridico.forEach((element: number) => {
                    entidadepjObj.push({ id: element })
                });

                values.entidadeProcessoJuridico = entidadepjObj;

                setLoadingFormAdd(true);
                const salvarDadosModal = async () => {
                    try {
                        let resultCidade = await axios.post(urlConfigModulosNotificaJornais, values,
                            { headers: { 'Authorization': 'Bearer ' + getToken() } });
                        setLoadingFormAdd(false);
                        notification.success({
                            message: 'Sucesso',
                            description:
                                'Seus dados foram criados com sucesso!',
                        });
                        clickCancelModalAddNotificacoesJornais();
                    } catch (error) {
                        let msgErro: any = (error as Error);

                        setLoadingFormAdd(false);
                        notification.error({
                            message: 'Erro',
                            description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Preencha os campos obrigatorios!'
                        });
                    }
                }
                salvarDadosModal();
            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [clickCancelModalAddNotificacoesJornais]);

    //setando dados das cidades no selected
    const buscaCityAndSetSelected = useCallback((idEstado: number) => {
        setLoadingCidadesSelected(true);
        const buscaCiaddes = async () => {
            try {
                let resultCidade = await axios.get(urlBuscaCidadesPorIdEstado + idEstado,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setListCidades(resultCidade.data);

                setLoadingCidadesSelected(false);

            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingCidadesSelected(false);
                    notification.error({
                        message: 'Erro',
                        description: msgErro?.response?.data?.message,
                    });
                }
            }
        }

        buscaCiaddes();

    }, []);

    const onSelectEstados = useCallback((e: number) => {
        buscaCityAndSetSelected(e);
        refFormModalAddNotificacoesEntidade.current.setFieldsValue(
            {
                cidade: undefined,
            }
        );
        refFormModalAddNotificacoesEntidade.current.validateFields(['cidade']);
    }, []);

    //FIM modulo notificacoes app modal obrigacoes

    //Inicio rotas permissao painel
    const clickCancelModalAddRotasPermissaoPainel = useCallback((): void => {
        setModalRotalPermissaoPainelVisible(false);
        refFormModalAddRotasPermissaoPainel.current.resetFields();
    }, []);

    const clickSubmitModalAddRotasPermissaoPainel = useCallback((): void => {
        refFormModalAddRotasPermissaoPainel.current?.validateFields()
            .then((values: any) => {

                setLoadingFormAdd(true);
                const salvarDadosModal = async () => {
                    try {
                        await axios.post(urlConfigRotasPainelPermissao, values,
                            { headers: { 'Authorization': 'Bearer ' + getToken() } });
                        setLoadingFormAdd(false);
                        notification.success({
                            message: 'Sucesso',
                            description:
                                'Seus dados foram criados com sucesso!',
                        });
                        clickCancelModalAddRotasPermissaoPainel();
                    } catch (error) {
                        let msgErro: any = (error as Error);

                        setLoadingFormAdd(false);
                        notification.error({
                            message: 'Erro',
                            description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Preencha os campos obrigatorios!'
                        });
                    }
                }
                salvarDadosModal();
            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [clickCancelModalAddRotasPermissaoPainel]);
    //Fim rotas permissao painel

    //Inicio rotas Versao app
    const clickCancelModalVersaoApp = useCallback((): void => {
        setModalRotalVersaoAppVisible(false);
        refFormModalAddVersaoApp.current.resetFields();
    }, []);

    const clickSubmitModalAddVersaoApp = useCallback((): void => {
        refFormModalAddVersaoApp.current?.validateFields()
            .then((values: any) => {

                values.build_ios = parseFloat(values.build_ios)
                values.build_android = parseFloat(values.build_android)
                values.dias_para_atualizar = parseFloat(values.dias_para_atualizar)
                values.numero = parseFloat(values.numero)

                setLoadingFormAdd(true);
                const salvarDadosModal = async () => {
                    try {
                        await axios.post(urlConfigVersaoApp, values,
                            { headers: { 'Authorization': 'Bearer ' + getToken() } });
                        setLoadingFormAdd(false);
                        notification.success({
                            message: 'Sucesso',
                            description:
                                'Seus dados foram criados com sucesso!',
                        });
                        clickCancelModalVersaoApp();
                    } catch (error) {
                        let msgErro: any = (error as Error);

                        setLoadingFormAdd(false);
                        notification.error({
                            message: 'Erro',
                            description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Preencha os campos obrigatorios!'
                        });
                    }
                }
                salvarDadosModal();
            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [clickCancelModalVersaoApp]);

    //Onchange inputs number
    const onChangeInputNumberDiasParaAtualizar = useCallback((e: any) => {
        const originalValue = e.target.value;//+''
        const regex = /^[0-9]*$/

        if (regex.test(originalValue)) {
            setValorDiasParaAtualizar(originalValue)
            refFormModalAddVersaoApp.current.setFieldsValue(
                { dias_para_atualizar: originalValue }
            );
        }
        else {
            refFormModalAddVersaoApp.current.setFieldsValue(
                { dias_para_atualizar: valorDiasParaAtualizar }
            );
        }
    }, [valorDiasParaAtualizar]);

    const onChangeInputNumberBuildAndroid = useCallback((e: any) => {
        const originalValue = e.target.value;//+''
        const regex = /^[0-9]*$/

        if (regex.test(originalValue)) {
            setValorBuildAndroid(originalValue)
            refFormModalAddVersaoApp.current.setFieldsValue(
                { build_android: originalValue }
            );
        }
        else {
            refFormModalAddVersaoApp.current.setFieldsValue(
                { build_android: valorBuildAndroid }
            );
        }
    }, [valorBuildAndroid]);

    const onChangeInputNumberBuildIos = useCallback((e: any) => {
        const originalValue = e.target.value;//+''
        const regex = /^[0-9]*$/

        if (regex.test(originalValue)) {
            setValorBuildIos(originalValue)
            refFormModalAddVersaoApp.current.setFieldsValue(
                { build_ios: originalValue }
            );
        }
        else {
            refFormModalAddVersaoApp.current.setFieldsValue(
                { build_ios: valorBuildIos }
            );
        }
    }, [valorBuildIos]);

    const onChangeInputNumberNumero = useCallback((e: any) => {
        const originalValue = e.target.value;//+''
        const regex = /^[0-9\.]*$/

        if (regex.test(originalValue)) {
            setValorNumero(originalValue)
            refFormModalAddVersaoApp.current.setFieldsValue(
                { numero: originalValue }
            );
        }
        else {
            refFormModalAddVersaoApp.current.setFieldsValue(
                { numero: valorNumero }
            );
        }
    }, [valorNumero]);

    //Fim rotas versao app

    //Onchange inputs
    const onChangeInputDiasDeTeste = useCallback((e: any) => {
        const originalValue = e.target.value;//+''
        const regex = /^[0-9]*$/

        if (regex.test(originalValue)) {
            setValorCampoInputCodigo(originalValue)
            refFormModalAddNotificacoesJornais.current.setFieldsValue(
                { codigo: originalValue }
            );
        } else {
            refFormModalAddNotificacoesJornais.current.setFieldsValue(
                { codigo: valorCampoInputCodigo }
            );
        }
    }, [valorCampoInputCodigo]);

    const onClickAddTodosObrigacoes = useCallback(() => {

        if (refFormModalAddNotificacoesEntidade.current.getFieldValue('obrigacoes')?.length != listObrigacoes.length) {
            let arrayIds: any = [];
            listObrigacoes.forEach((val: any) => {
                arrayIds.push(val.id);
            });
            refFormModalAddNotificacoesEntidade.current.setFieldsValue(
                { obrigacoes: arrayIds }
            );

            refBotaoAddTodosObrigacoes.current?.blur();
            // refFormModoEstadoSelect.current?.blur();
        }
    }, [listObrigacoes]);

    const onClickAddTodosEstadosJornais = useCallback(() => {

        if (refFormModalAddNotificacoesJornais.current.getFieldValue('estados')?.length != listEstados.length) {
            let arrayIds: any = [];
            listEstados.forEach((val: any) => {
                arrayIds.push(val.id);
            });
            refFormModalAddNotificacoesJornais.current.setFieldsValue(
                { estados: arrayIds }
            );

            refBotaoAddTodosEstadosJornais.current?.blur();
            // refFormModoEstadoSelect.current?.blur();
        }
    }, [listEstados]);
    //Fim Onchange inputs

    return (
        <>
            <Row>
                <Col {...cardLayout}>
                    <Title level={3}><IconCogs style={{ fontSize: 35 }} /> Configurações do Sistema</Title>
                    <Divider />
                </Col>
            </Row>
            <Row>
                <Col {...colCollapseContrato}>
                    <Collapse className="divCollapses" accordion>
                        <Panel header="Contrato" key="1">
                            <List>
                                <List.Item
                                    key={1}
                                    actions={[<a key="list-loadmore-edit" onClick={clickListarContratoEsfera}>Listar</a>, <a onClick={onClickCadastrarContratoEsfera} key="list-loadmore-more">Cadastrar</a>]}
                                >
                                    <div className="divNameCongSistemaList">Esfera</div>
                                </List.Item>
                                <List.Item
                                    key={2}
                                    actions={[<a key="list-loadmore-edit" onClick={clickListarContratoPoder}>Listar</a>, <a onClick={onClickCadastrarContratoPoder} key="list-loadmore-more">Cadastrar</a>]}
                                >
                                    <div className="divNameCongSistemaList">Poder</div>
                                </List.Item>
                                <List.Item
                                    key={3}
                                    actions={[<a onClick={clickListarContratoTipoDeContrato}>Listar</a>, <a onClick={onClickCadastrarContratoTipoDeContrato} key="list-loadmore-more">Cadastrar</a>]}
                                >
                                    <div className="divNameCongSistemaList">Tipo de Contrato</div>
                                </List.Item>
                                <List.Item
                                    key={4}
                                    actions={[<a key="list-loadmore-edit" onClick={clickListarContratoSituacaoDeContrato}>Listar</a>, <a onClick={onClickCadastrarContratoSituacaoDeContrato} key="list-loadmore-more">Cadastrar</a>]}
                                >
                                    <div className="divNameCongSistemaList">Situação do Contrato</div>
                                </List.Item>
                                <List.Item
                                    key={5}
                                    actions={[<a key="list-loadmore-edit" onClick={clickListarModalidade}>Listar</a>, <a onClick={onClickCadastrarModalidade} key="list-loadmore-more">Cadastrar</a>]}
                                >
                                    <div className="divNameCongSistemaList">Modalidade</div>
                                </List.Item>
                                <List.Item
                                    key={6}
                                    actions={[<a key="list-loadmore-edit"
                                        onClick={clickListarTipoDeAditivo}>Listar</a>,
                                    <a onClick={onClickCadastrarTipoDeAditivo} key="list-loadmore-more">Cadastrar</a>]}
                                >
                                    <div className="divNameCongSistemaList">Tipo de Aditivo</div>
                                </List.Item>
                            </List>
                        </Panel>
                        <Panel header="Dados Usuário" key="2">
                            <List.Item
                                key={1}
                                actions={[<a key="list-loadmore-edit" onClick={clickListarDadosUsuarioCargo}>Listar</a>, <a onClick={onClickCadastrarDadosUsuarioCargo} key="list-loadmore-more">Cadastrar</a>]}
                            >
                                <div className="divNameCongSistemaList">Cargo</div>
                            </List.Item>
                        </Panel>
                        <Panel header="Módulos App" key="3">
                            <List.Item
                                key="1"
                                actions={[
                                    <a
                                        key="list-loadmore-edit"
                                        onClick={clickListarModulo}
                                    >Listar</a>,
                                    <a onClick={onClickCadastrarModulo} key="list-loadmore-more">Cadastrar</a>]}
                            >
                                <div className="divNameCongSistemaList">Módulo</div>
                            </List.Item>
                        </Panel>
                        <Panel header="Processos Jurídicos (Senha)" key="4">
                            <List.Item
                                key="1"
                                actions={[
                                    <a key="list-loadmore-edit"
                                        onClick={clickListarTipoProcessosJuridicos}
                                    >Listar</a>,
                                    <a onClick={onClickCadastrarTipoProcessJuridico} key="list-loadmore-more">Cadastrar</a>]}
                            >
                                <div className="divNameCongSistemaList">Tipo</div>
                            </List.Item>
                        </Panel>
                        {/* <Panel header="Módulos Notificação" key="5">
                            <List.Item
                                key="1"
                                actions={[
                                    <a key="list-loadmore-edit"
                                        onClick={clickListMNIndicesConstitucionais}
                                    >Listar</a>,
                                    <a onClick={onClickAddMNIndicesConstitucionais} key="list-loadmore-more">Cadastrar</a>]}
                            >
                                <div className="divNameCongSistemaList">Indices Constitucionais</div>
                            </List.Item>
                            <List.Item
                                key="2"
                                actions={[
                                    <a key="list-loadmore-edit"
                                        onClick={clickListMNCertidao}
                                    >Listar</a>,
                                    <a onClick={onClickAddMNCertidao} key="list-loadmore-more">Cadastrar</a>]}
                            >
                                <div className="divNameCongSistemaList">Certidões</div>
                            </List.Item>
                            <List.Item
                                key="3"
                                actions={[
                                    <a key="list-loadmore-edit"
                                        onClick={clickListMNCauc}
                                    >Listar</a>,
                                    <a onClick={onClickAddMNCauc} key="list-loadmore-more">Cadastrar</a>]}
                            >
                                <div className="divNameCongSistemaList">Cauc</div>
                            </List.Item>
                            <List.Item
                                key="4"
                                actions={[
                                    <a key="list-loadmore-edit"
                                        onClick={clickListMNProcessosJuridicos}
                                    >Listar</a>,
                                    <a onClick={onClickAddMNProcessosJuridicos} key="list-loadmore-more">Cadastrar</a>]}
                            >
                                <div className="divNameCongSistemaList">Entidade Processos Juridicos</div>
                            </List.Item>
                            <List.Item
                                key="5"
                                actions={[
                                    <a key="list-loadmore-edit"
                                        onClick={clickListMNObrigacoes}
                                    >Listar</a>,
                                    <a onClick={onClickAddMNObrigacoes} key="list-loadmore-more">Cadastrar</a>]}
                            >
                                <div className="divNameCongSistemaList">Obrigações</div>
                            </List.Item>
                            <List.Item
                                key="6"
                                actions={[
                                    <a key="list-loadmore-edit"
                                        onClick={clickListMNEntidade}
                                    >Listar</a>,
                                    <a onClick={onClickAddMNEntidade} key="list-loadmore-more">Cadastrar</a>]}
                            >
                                <div className="divNameCongSistemaList">Entidade</div>
                            </List.Item>
                            <List.Item
                                key="7"
                                actions={[
                                    <a key="list-loadmore-edit"
                                        onClick={clickListMNJornais}
                                    >Listar</a>,
                                    <a onClick={onClickAddMNJornais} key="list-loadmore-more">Cadastrar</a>]}
                            >
                                <div className="divNameCongSistemaList">Jornais</div>
                            </List.Item>
                        </Panel> */}
                        <Panel header="Permissões Painel" key="6">
                            <List.Item
                                key="1"
                                actions={[
                                    <a key="list-loadmore-edit"
                                        onClick={clickListarRotasPermissaoPainel}
                                    >Listar</a>,
                                    <a onClick={onClickCadastrarRotasPermissaoPainel} key="list-loadmore-more">Cadastrar</a>]}
                            >
                                <div className="divNameCongSistemaList">Rotas</div>
                            </List.Item>
                        </Panel>
                        <Panel header="Aplicativo" key="7">
                            <List.Item
                                key="1"
                                actions={[
                                    <a key="list-loadmore-edit"
                                        onClick={clickListarVersaoApp}
                                    >Listar</a>,
                                    <a onClick={onClickCadastrarRotasVersaoApp} key="list-loadmore-more">Cadastrar</a>]}
                            >
                                <div className="divNameCongSistemaList">Versão App</div>
                            </List.Item>
                            <List.Item
                                key="2"
                                actions={[
                                    <a key="list-loadmore-edit"
                                        onClick={clickListarOrdenacao}
                                    >Ordenar</a>
                                ]}
                            >
                                <div className="divNameCongSistemaList">Módulo Ordenação</div>
                            </List.Item>
                        </Panel>
                        <Panel header="Planos" key="8planos">
                            <List.Item
                                key="1"
                                actions={[
                                    <a key="list-modulos-planio"
                                        onClick={clickListarModulosPlano}
                                    >Listar</a>
                                ]}
                            >
                                <div className="divNameCongSistemaList">Módulos</div>
                            </List.Item>
                            <List.Item
                                key="2"
                                actions={[
                                    <a key="list-recursos-plano"
                                        onClick={clickListarRecursosPlano}
                                    >Listar</a>
                                ]}
                            >
                                <div className="divNameCongSistemaList">Recursos</div>
                            </List.Item>
                            <List.Item
                                key="3"
                                actions={[
                                    <a key="list-gerenciar-planos"
                                        onClick={clickListarGerenciarPlano}
                                    >Listar</a>
                                ]}
                            >
                                <div className="divNameCongSistemaList">Gerenciar Planos</div>
                            </List.Item>
                        </Panel>
                        <Panel header="Atendimento" key="Atendimento_chkjvweDAC">
                            <List>
                                <List.Item
                                    key={1}
                                    actions={
                                        [
                                            <a key="list-gerenciar-produtos"
                                                onClick={clickListarAtendimentoProduto}>Listar</a>
                                        ]
                                    }
                                >
                                    <div className="divNameCongSistemaList">Produto</div>
                                </List.Item>
                            </List>
                        </Panel>
                    </Collapse>
                </Col>
                <Col {...colCollapseContrato1}>
                    {/* <Collapse className="divCollapses" defaultActiveKey={['1']} accordion onChange={onChangeCollapse}>
                        <Panel header="This is panel header 1" key="1">
                            <p>chines</p>
                        </Panel>
                        <Panel header="This is panel header 2" key="2">
                            <p>chines</p>
                        </Panel>
                        <Panel header="This is panel header 3" key="3">
                            <p>chines</p>
                        </Panel>
                    </Collapse> */}
                </Col>
                <Col {...colCollapseContrato2}>
                    {/* <Collapse className="divCollapses" accordion onChange={onChangeCollapse}>
                        <Panel header="This is panel header 1" key="1">
                            <p>chines</p>
                        </Panel>
                        <Panel header="This is panel header 2" key="2">
                            <p>chines</p>
                        </Panel>
                        <Panel header="This is panel header 3" key="3">
                            <p>chines</p>
                        </Panel>
                    </Collapse> */}
                </Col>
            </Row>
            <Modal
                title={titleFormModal}
                className="modalAddCargo"
                visible={modalAddConfigVisible}
                onOk={clickSubmitModalAddConfig}
                onCancel={clickCancelModalAddConfig}
                footer={[
                    <Button key="back" disabled={loadingFormAdd} onClick={clickCancelModalAddConfig}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loadingFormAdd} onClick={clickSubmitModalAddConfig}>
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingFormAdd}>
                    <Form
                        ref={refFormModalAddConfig}
                        name="formModalAddConfig"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                    >
                        <Row>
                            <Col {...modaisDeConfig}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: messageObrigatorioFormModal,
                                        }
                                    ]}
                                    name="nome"
                                    label="Nome"
                                >
                                    <Input placeholder="Nome" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>

            {/* Modulos APP */}
            <Modal
                title="Adicionar Módulo"
                visible={modalConfigModulos}
                onOk={clickSubmitModalAddModulos}
                onCancel={clickCancelModalAddModulos}
                footer={[
                    <Button key="back" disabled={loadingFormAdd} onClick={clickCancelModalAddModulos}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loadingFormAdd} onClick={clickSubmitModalAddModulos}>
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingFormAdd}>
                    <Form
                        ref={refFormModalAddModulos}
                        name="formModalAddModulos"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                    >
                        <Row>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Preencha o nome do módulo!",
                                        }
                                    ]}
                                    name="nome"
                                    label="Nome"
                                    style={{ margin: 5 }}
                                >
                                    <Input placeholder="Nome" />
                                </Form.Item>
                            </Col>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Preencha a descrição!",
                                        }
                                    ]}
                                    name="descricao"
                                    label="Descrição"
                                    style={{ margin: 5 }}
                                >
                                    <Input placeholder="Descrição" />
                                </Form.Item>
                            </Col>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Preencha a Path!",
                                        }
                                    ]}
                                    name="path"
                                    label="Path"
                                    style={{ margin: 5 }}
                                >
                                    <Input placeholder="Path" />
                                </Form.Item>
                            </Col>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    initialValue={true}
                                    name="status"
                                    label="Status"
                                    rules={[
                                    ]}
                                    style={{ margin: 5 }}
                                >
                                    <Radio.Group>
                                        <Radio.Button value={true}>Ativo</Radio.Button>
                                        <Radio.Button value={false}>Inativo</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    initialValue={false}
                                    name="modo_estado"
                                    label="Modo Estado"
                                    rules={[
                                    ]}
                                    style={{ margin: 5 }}
                                >
                                    <Radio.Group>
                                        <Radio.Button value={true}>Sim</Radio.Button>
                                        <Radio.Button value={false}>Não</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>

            {/* fim Modulos APP */}
            {/* inicio modulo notificacao APP */}
            <Modal
                title="Adicionar Obrigações"
                className="modalAddCargo"
                visible={modalConfigNotficacoesObrigacoes}
                onOk={clickSubmitModalAddNotificacoesObrigacoes}
                onCancel={clickCancelModalAddNotificacoesObrigacoes}
                footer={[
                    <Button key="back" disabled={loadingFormAdd} onClick={clickCancelModalAddNotificacoesObrigacoes}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loadingFormAdd} onClick={clickSubmitModalAddNotificacoesObrigacoes}>
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingFormAdd}>
                    <Form
                        ref={refFormModalAddNotificacoesObrigacoes}
                        name="formModalAddNotifiObrigacoes"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                    >
                        <Row>
                            <Col {...modaisDeConfig}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Por favor digite o nome!",
                                        }
                                    ]}
                                    name="nome"
                                    label="Nome"
                                >
                                    <Input placeholder="Nome" />
                                </Form.Item>
                            </Col>
                            <Col {...modaisDeConfig}>
                                <Form.Item
                                    name="sigla"
                                    label="Sigla"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor digite a sigla!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Sigla" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
            <Modal
                title="Adicionar Entidade"
                className="modalNotificacaoAppEntidade"
                visible={modalConfigNotficacoesEntidade}
                onOk={clickSubmitModalAddNotificacoesEntidade}
                onCancel={clickCancelModalAddNotificacoesEntidade}
                footer={[
                    <Button key="back" disabled={loadingFormAdd} onClick={clickCancelModalAddNotificacoesEntidade}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loadingFormAdd} onClick={clickSubmitModalAddNotificacoesEntidade}>
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingFormAdd}>
                    <Form
                        ref={refFormModalAddNotificacoesEntidade}
                        name="formModalAddNotifiObrigacoes"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                    >
                        <Row>
                            <Col {...modaisDeConfig}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Por favor digite o nome!",
                                        }
                                    ]}
                                    name="nome"
                                    label="Nome"
                                >
                                    <Input placeholder="Nome" />
                                </Form.Item>
                            </Col>
                            <Col {...modaisDeConfig}>
                                <Form.Item
                                    name="estado"
                                    label="Estado"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Por favor selecione um estado!",
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        loading={loadingEstadosSelected}
                                        notFoundContent={loadingEstadosSelected ? <Spin size="small" /> : null}
                                        placeholder="Selecione..."
                                        onSelect={onSelectEstados}
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            listEstados.map((item: any) => {
                                                return (
                                                    <Select.Option
                                                        value={item.id}
                                                        key={item.id}
                                                    >
                                                        {item.nome}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...modaisDeConfig}>
                                <Form.Item
                                    name="cidade"
                                    label="Selecione a Cidade"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor selecione a cidade!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        loading={loadingCidadesSelected}
                                        notFoundContent={loadingCidadesSelected ? <Spin size="small" /> : null}
                                        placeholder="Selecione..."
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            listCidades.map((item: any) => {
                                                return (
                                                    <Select.Option
                                                        value={item.id}
                                                        key={item.id}
                                                    >
                                                        {item.nome + " - " + item.microrregiao.mesorregiao.estado.sigla}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...modaisDeConfig}>
                                <Form.Item
                                    name="obrigacoes"
                                    label="Selecione as Obrigações"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor selecione as obrigações!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        mode="multiple"
                                        placeholder="Selecione..."
                                        filterOption={(input: any, option: any) =>
                                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        dropdownRender={(menu: any) => (
                                            <div>

                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Button
                                                        ref={refBotaoAddTodosObrigacoes}
                                                        block
                                                        onClick={onClickAddTodosObrigacoes}
                                                        type="dashed"
                                                    >Todos</Button>
                                                </div>
                                                <Divider style={{ margin: '4px 0' }} />
                                                {menu}
                                            </div>
                                        )}
                                    >
                                        {
                                            listObrigacoes.map((item: any) => {
                                                return (
                                                    <Select.Option
                                                        value={item.id}
                                                        key={item.id}
                                                    >
                                                        {`
                                                        ${item.nome}
                                                        `}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
            <Modal
                title="Adicionar Jornais"
                className="modalNotificacaoAppEntidade"
                visible={modalConfigNotficacoesJornais}
                onOk={clickSubmitModalAddNotificacoesJornais}
                onCancel={clickCancelModalAddNotificacoesJornais}
                footer={[
                    <Button key="back" disabled={loadingFormAdd} onClick={clickCancelModalAddNotificacoesJornais}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loadingFormAdd} onClick={clickSubmitModalAddNotificacoesJornais}>
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingFormAdd}>
                    <Form
                        ref={refFormModalAddNotificacoesJornais}
                        name="formModalAddNotifiJornais"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                    >
                        <Row>
                            <Col {...modaisDeConfig}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Por favor digite o nome!",
                                        }
                                    ]}
                                    name="nome"
                                    label="Nome"
                                >
                                    <Input placeholder="Nome" />
                                </Form.Item>
                            </Col>
                            <Col {...modaisDeConfig}>
                                <Form.Item
                                    name="entidadeProcessoJuridico"
                                    label="Entidade Processo Júridicos"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Por favor selecione uma entidade!",
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        loading={loadingEntidadeProcJuridSelected}
                                        notFoundContent={loadingEntidadeProcJuridSelected ? <Spin size="small" /> : null}
                                        placeholder="Selecione..."
                                        mode="multiple"
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            listEntidadeProcsJurid.map((item: any) => {
                                                return (
                                                    <Select.Option
                                                        value={item.id}
                                                        key={item.id}
                                                    >
                                                        {item.nome}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...modaisDeConfig}>
                                <Form.Item
                                    name="codigo"
                                    label="Codigo"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor digite o codigo!',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="0"
                                        onChange={onChangeInputDiasDeTeste}
                                        autoComplete="off"
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...modaisDeConfig}>
                                <Form.Item
                                    name="estados"
                                    label="Selecione os Estados"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor selecione os estados!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        mode="multiple"
                                        placeholder="Selecione..."
                                        filterOption={(input: any, option: any) =>
                                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        dropdownRender={(menu: any) => (
                                            <div>

                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Button
                                                        ref={refBotaoAddTodosEstadosJornais}
                                                        block
                                                        onClick={onClickAddTodosEstadosJornais}
                                                        type="dashed"
                                                    >Todos</Button>
                                                </div>
                                                <Divider style={{ margin: '4px 0' }} />
                                                {menu}
                                            </div>
                                        )}
                                    >
                                        {
                                            listEstados.map((item: any) => {
                                                return (
                                                    <Select.Option
                                                        value={item.id}
                                                        key={item.id}
                                                    >
                                                        {`
                                                        ${item.nome}
                                                        `}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>

            {/* fim Modulo notificacao APP */}
            {/* inicio rotas permissao painel */}
            <Modal
                title="Adicionar Rotas"
                className="modalRotasPermissaoPainel"
                visible={modalRotalPermissaoPainelVisible}
                onOk={clickSubmitModalAddRotasPermissaoPainel}
                onCancel={clickCancelModalAddRotasPermissaoPainel}
                footer={[
                    <Button key="back" disabled={loadingFormAdd} onClick={clickCancelModalAddRotasPermissaoPainel}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loadingFormAdd} onClick={clickSubmitModalAddRotasPermissaoPainel}>
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingFormAdd}>
                    <Form
                        ref={refFormModalAddRotasPermissaoPainel}
                        name="formModalAddRotasPermissaoPainel"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                    >
                        <Row>
                            <Col {...modaisDeConfig}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Por favor digite o nome!",
                                        }
                                    ]}
                                    name="nome"
                                    label="Nome"
                                >
                                    <Input placeholder="Nome" />
                                </Form.Item>
                            </Col>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Preencha a Path!",
                                        }
                                    ]}
                                    name="path"
                                    label="Path"
                                    style={{ margin: 5 }}
                                >
                                    <Input placeholder="Path" />
                                </Form.Item>
                            </Col>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    initialValue={true}
                                    name="status"
                                    label="Status"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Selecione o status!",
                                        }
                                    ]}
                                    style={{ margin: 5 }}
                                >
                                    <Radio.Group>
                                        <Radio.Button value={true}>Ativo</Radio.Button>
                                        <Radio.Button value={false}>Inativo</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
            {/* fim rotas permissao painel */}

            {/* inicio rotas permissao painel */}
            <Modal
                title="Adicionar Versão App"
                className="modalVersaoApp"
                visible={modalRotalVersaoAppVisible}
                onOk={clickSubmitModalAddVersaoApp}
                onCancel={clickCancelModalVersaoApp}
                footer={[
                    <Button key="back" disabled={loadingFormAdd} onClick={clickCancelModalVersaoApp}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loadingFormAdd} onClick={clickSubmitModalAddVersaoApp}>
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingFormAdd}>
                    <Form
                        ref={refFormModalAddVersaoApp}
                        name="formModalAddVersaoApp"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                    >
                        <Row>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Preencha a Numero!",
                                        }
                                    ]}
                                    name="numero"
                                    label="Numero"
                                    style={{ margin: 5 }}
                                >
                                    <Input
                                        onChange={onChangeInputNumberNumero}
                                        placeholder="Ex: 1.1" />
                                </Form.Item>
                            </Col>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Preencha a campo!",
                                        }
                                    ]}
                                    name="dias_para_atualizar"
                                    label="Dias para atualizar"
                                    style={{ margin: 5 }}
                                >
                                    <Input
                                        onChange={onChangeInputNumberDiasParaAtualizar}
                                        placeholder="120"
                                        maxLength={3}
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: false,
                                            message: "Preencha o build ios!",
                                        }
                                    ]}
                                    name="build_ios"
                                    label="Build IOS"
                                    style={{ margin: 5 }}
                                >
                                    <Input
                                        onChange={onChangeInputNumberBuildIos}
                                        placeholder="Ex: 1.1" />
                                </Form.Item>
                            </Col>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: false,
                                            message: "Preencha o build android!",
                                        }
                                    ]}
                                    name="build_android"
                                    label="Build Android"
                                    style={{ margin: 5 }}
                                >
                                    <Input
                                        onChange={onChangeInputNumberBuildAndroid}
                                        placeholder="Ex: 1.1"
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    initialValue={true}
                                    name="force"
                                    label="Forçar atualizar o app"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Selecione o forçar!",
                                        }
                                    ]}
                                    style={{ marginTop: 5, marginBottom: 8 }}
                                >
                                    <Radio.Group>
                                        <Radio.Button value={true}>Sim</Radio.Button>
                                        <Radio.Button value={false}>Não</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col {...modaisDeConfig}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: false,
                                            message: "Por favor comente as alterações!",
                                        }
                                    ]}
                                    name="alteracoes"
                                    label="Alterações"
                                >
                                    <Input.TextArea style={{ maxHeight: 200, height: 100 }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
            {/* fim rotas permissao painel */}
        </>
    );
}

export default ConfiguracoesDoSistema;
