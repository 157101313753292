import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
    Button, Col, Row, Breadcrumb, Layout, Space, Table, Modal, Spin, Form,
    Typography, Divider, notification, Popconfirm, Select, Radio, Tag
} from 'antd';
import { ReactSortable } from "react-sortablejs";
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { stateGlobal } from './../../../../../interfaces/Global';
import Icon from '@ant-design/icons';
import {
    cardLayout,
    colOrdenacaoModulo,
    colBotaoSalvarOrdenacao
} from './GridStyle';

import { FaCogs, FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router';
import axios from 'axios';
import { urlModuloItem, urlConfigOrdenacaoModulos } from '../../../../../services/request/urls';
import { getToken } from '../../../../../services/token';
// import { checkExpiredToken } from './../../../../../';

// @ts-ignore
import receitasConstitucionaisPng from './../../../../../assets/imagsIconesModulos/receitasConstitucionais.png';
// @ts-ignore
import indicesConstitucionaisPng from './../../../../../assets/imagsIconesModulos/indicesConstitucionais.png';
// @ts-ignore
import certidoesPng from './../../../../../assets/imagsIconesModulos/certidoes.png';
// @ts-ignore
import receitasArrecadacoesMunicipaisPng from './../../../../../assets/imagsIconesModulos/dollar.png';
// @ts-ignore
import indicadoresGerenciaisPng from './../../../../../assets/imagsIconesModulos/indicadoresGerenciais.png';
// @ts-ignore
import fluxoDeCaixaPng from './../../../../../assets/imagsIconesModulos/fluxoDeCaixa.png';
// @ts-ignore
import previaFiscalPng from './../../../../../assets/imagsIconesModulos/previaFiscal.png';
// @ts-ignore
import frotaPng from './../../../../../assets/imagsIconesModulos/frota.png';
// @ts-ignore
import processosJuridicosPng from './../../../../../assets/imagsIconesModulos/processosJuridicos.png';
// @ts-ignore
import tranferegovPng from './../../../../../assets/imagsIconesModulos/icone_tranferegov.png';
// @ts-ignore
import empresasPng from './../../../../../assets/imagsIconesModulos/empresas.png';
// @ts-ignore
import empregosPng from './../../../../../assets/imagsIconesModulos/empregos.png';
// @ts-ignore
import combustivelPng from './../../../../../assets/imagsIconesModulos/gasPump.png';
// @ts-ignore
import personsPng from './../../../../../assets/imagsIconesModulos/persons.png';
// @ts-ignore
import penPng from './../../../../../assets/imagsIconesModulos/pen.png';
// @ts-ignore
import heartbeatPng from './../../../../../assets/imagsIconesModulos/heartbeat.png';
// @ts-ignore
import trofeuPng from './../../../../../assets/imagsIconesModulos/itrophy.png';
// @ts-ignore
import imgDSPPng from './../../../../../assets/imagsIconesModulos/icone-dsp.png';
// @ts-ignore
import integracoesPng from './../../../../../assets/imagsIconesModulos/icone_integracao.png';
// @ts-ignore
import assistenciaSocialPng from './../../../../../assets/imagsIconesModulos/icone_assistencia_social.png';
// @ts-ignore
import imgFileFaturaPng from './../../../../../assets/imagsIconesModulos/fileFatura.png';
// @ts-ignore
import agendaPng from './../../../../../assets/imagsIconesModulos/agenda.png';
// @ts-ignore
import tarefasPng from './../../../../../assets/imagsIconesModulos/tarefas.png';
// @ts-ignore
import IconePlanoGovernoPng from './../../../../../assets/imagsIconesModulos/IconePlanoGoverno.png';

const IconCogs = (props: any) => <Icon {...props} component={FaCogs} ></Icon>;

const { Title, Text } = Typography;
const { Content } = Layout;

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>;


interface interfaceModuloList {
    id: number,
    nome: string,
    descricao: null | string,
    path: string,
    status: boolean,
    ordem: number,
    modo_estado: boolean
}

interface interfaceModulosReq {
    "id": number,
    "func": string,
    "nome": string
}

const nomeFunc = [
    "TCEObrigacoesEstaduais",
    "CaucObrigacoesFederais",
    "ReceitasConstitucionais",
    "IndicesConstitucionais",
    "ListCertidoes",
    "IndicadoresGerenciais",
    "FluxoDeCaixa",
    "PreviaFiscal",
    "Frota",
    "ProcessosJuridicos",
    "Siconv",
    "Empresas",
    "Empregos",
    "Combustivel",
    "Saude",
    "Educacao",
    "DespesaComPessoal",
    "Ranking",
    "ListDSP",
    "Receitas",
    "Integracoes",
    "AssistenciaSocial",
    "Agenda",
    "Tarefas",
    "PlanoDeGoverno",
]

const Ordenacao: React.FC = () => {

    const propsState = useSelector((state: stateGlobal) => state.global);

    const refDadosObj = useRef<any>(null);

    const history = useHistory();

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<number>>([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [dadosObj, setDadosObj] = useState<Array<{ id: string, nome: string, func: string }>>([]);

    //executa apenas ao iniciar o component
    useEffect(() => {

        setLoadingTable(true)
        const buscaDeDados = async () => {
            try {
                // let result = await axios.get(urlModuloItem,
                //     { headers: { 'Authorization': 'Bearer ' + getToken() } });
                // setDadosTabelaState(result.data);
                setLoadingTable(false);

            } catch (error) {
                setLoadingTable(false)
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        const verificaSeDadosFuncEIgual = async () => {
            try {
                let result = await axios.get(urlConfigOrdenacaoModulos,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });

                let objSalvar: any = []
                result.data.forEach((elem: interfaceModulosReq, index: number) => {

                    let encontrou = false
                    nomeFunc.forEach((nome) => {
                        if (elem.func === nome) {

                            encontrou = true
                        }
                    })

                    if (encontrou) {
                        objSalvar.push(elem)
                    }

                })

                setDadosObj(objSalvar)

            } catch (error) {

            }
        }

        verificaSeDadosFuncEIgual();
        // buscaDeDados();

    }, []);

    const onClickVoltarConfigOfSystem = useCallback((): void => {
        history.push("/configuracoesdosistema");
    }, []);

    useEffect(() => {
        refDadosObj.current = dadosObj

    }, [dadosObj]);

    //executa apenas quando desmonta o component
    // const comonentExecutaAoDestroy = useEffect(() => () => {

    // }, []);

    //executa apenas quando salvar
    const salvarDados = useCallback(() => {
        setLoadingTable(true)
        const buscaDeDados = async () => {
            try {

                await axios.post(urlConfigOrdenacaoModulos,
                    refDadosObj.current,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });


                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram salvos com sucesso!',
                });
                setLoadingTable(false);
                onClickVoltarConfigOfSystem();

            } catch (error) {

                let msgErro: any = (error as Error);
                setLoadingTable(false)
                notification.error({
                    message: 'Erro',
                    description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel salvar!',
                });
            }
        }

        buscaDeDados();

    }, []);

    //executa apenas quando salvar
    const TCEObrigacoesEstaduais = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <Text style={{
                        fontSize: 22,
                        /* Blue 2 */
                        color: '#2D9CDB',
                    }}
                        strong
                    >
                        TCE
                    </Text>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Obrigações
                    </Text>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text>
                </div>
            </>
        )

    }, []);

    //executa apenas quando salvar
    const CaucObrigacoesFederais = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <Text style={{
                        fontSize: 22,
                        /* Blue 2 */
                        color: '#2D9CDB',
                    }}
                        strong
                    >
                        CAUC
                    </Text>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Obrigações
                    </Text>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        federais
                    </Text>
                </div>
            </>
        )

    }, []);

    //executa apenas quando salvar
    const ReceitasConstitucionais = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 50
                    }} src={receitasConstitucionaisPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Receitas
                    </Text>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Constitucionais
                    </Text>
                </div>
            </>
        )

    }, []);

    //executa apenas quando salvar
    const IndicesConstitucionais = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 50
                    }} src={indicesConstitucionaisPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Índeices
                    </Text>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        constitucionais
                    </Text>
                </div>
            </>
        )

    }, []);

    //executa apenas quando salvar
    const ListCertidoes = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 50
                    }} src={certidoesPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Certidões
                    </Text>
                    {/* <Text style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text> */}
                </div>
            </>
        )

    }, []);


    //executa apenas quando salvar
    const Receitas = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 50,
                        filter: 'invert(52%) sepia(98%) saturate(1458%) hue-rotate(173deg) brightness(92%) contrast(86%)'
                    }} src={receitasArrecadacoesMunicipaisPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Arrecadações Municipais
                    </Text>
                    {/* <Text style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text> */}
                </div>
            </>
        )

    }, []);


    //executa apenas quando salvar
    const IndicadoresGerenciais = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 50
                    }} src={indicadoresGerenciaisPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Indicadores
                    </Text>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        gerenciais
                    </Text>
                </div>
            </>
        )

    }, []);


    //executa apenas quando salvar
    const FluxoDeCaixa = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 50
                    }} src={fluxoDeCaixaPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Fluxo de Caixa
                    </Text>
                    {/* <Text style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text> */}
                </div>
            </>
        )

    }, []);


    //executa apenas quando salvar
    const PreviaFiscal = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 50
                    }} src={previaFiscalPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Prévia fiscal
                    </Text>
                    {/* <Text style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text> */}
                </div>
            </>
        )

    }, []);


    //executa apenas quando salvar
    const Frota = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 50
                    }} src={frotaPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Veículos
                    </Text>
                    {/* <Text style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text> */}
                </div>
            </>
        )

    }, []);

    //executa apenas quando salvar
    const ProcessosJuridicos = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 50
                    }} src={processosJuridicosPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Processos
                    </Text>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Jurídicos
                    </Text>
                </div>
            </>
        )

    }, []);

    //executa apenas quando salvar
    const Siconv = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 50
                    }} src={tranferegovPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Transferegov
                    </Text>
                    {/* <Text style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text> */}
                </div>
            </>
        )

    }, []);

    //executa apenas quando salvar
    const Empresas = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 50
                    }} src={empresasPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Empresas
                    </Text>
                    {/* <Text style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text> */}
                </div>
            </>
        )

    }, []);

    //executa apenas quando salvar
    const Empregos = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 50
                    }} src={empregosPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Empregos
                    </Text>
                    {/* <Text style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text> */}
                </div>
            </>
        )

    }, []);

    //executa apenas quando salvar
    const Combustivel = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 45,
                        filter: 'invert(52%) sepia(98%) saturate(1458%) hue-rotate(173deg) brightness(92%) contrast(86%)'
                    }} src={combustivelPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Combustível
                    </Text>
                    {/* <Text style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text> */}
                </div>
            </>
        )

    }, []);

    const Saude = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 45,
                        filter: 'invert(52%) sepia(98%) saturate(1458%) hue-rotate(173deg) brightness(92%) contrast(86%)'
                    }} src={heartbeatPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Saúde
                    </Text>
                    {/* <Text style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text> */}
                </div>
            </>
        )

    }, []);

    const Educacao = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 45,
                        filter: 'invert(52%) sepia(98%) saturate(1458%) hue-rotate(173deg) brightness(92%) contrast(86%)'
                    }} src={penPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Educacao
                    </Text>
                    {/* <Text style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text> */}
                </div>
            </>
        )

    }, []);

    const DespesaComPessoal = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 45,
                        filter: 'invert(52%) sepia(98%) saturate(1458%) hue-rotate(173deg) brightness(92%) contrast(86%)'
                    }} src={personsPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Despesa com Pessoal
                    </Text>
                    {/* <Text style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text> */}
                </div>
            </>
        )

    }, []);

    const Ranking = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 45,
                        filter: 'invert(52%) sepia(98%) saturate(1458%) hue-rotate(173deg) brightness(92%) contrast(86%)'
                    }} src={trofeuPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Rankings
                    </Text>
                    {/* <Text style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text> */}
                </div>
            </>
        )

    }, []);

    const ListDSP = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 50,
                        filter: 'invert(52%) sepia(98%) saturate(1458%) hue-rotate(173deg) brightness(92%) contrast(86%)'
                    }} src={imgFileFaturaPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 8
                        // top: 20,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Pré-Análise para Crédito
                    </Text>
                    {/* <Text style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text> */}
                </div>
            </>
        )

    }, []);

    const Integracoes = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 50,
                    }} src={integracoesPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 8
                        // top: 20,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Integrações
                    </Text>
                    {/* <Text style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text> */}
                </div>
            </>
        )

    }, []);

    //executa apenas quando salvar
    const AssistenciaSocial = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 45,
                        filter: 'invert(52%) sepia(98%) saturate(1458%) hue-rotate(173deg) brightness(92%) contrast(86%)'
                    }} src={assistenciaSocialPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 8,
                        top: 15,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Assistência Social
                    </Text>
                    {/* <Text style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text> */}
                </div>
            </>
        )

    }, []);

    const Tarefas = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 50,
                    }} src={tarefasPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 8
                        // top: 20,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Tarefas
                    </Text>
                    {/* <Text style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text> */}
                </div>
            </>
        )

    }, []);

    const Agenda = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 50,
                    }} src={agendaPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 8
                        // top: 20,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Agenda
                    </Text>
                    {/* <Text style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text> */}
                </div>
            </>
        )

    }, []);

    const PlanoDeGoverno = useCallback(() => {
        return (
            <>
                <div style={{
                    flexDirection: 'row',
                    //  justifyContent: 'space-between'
                }}>
                    <img style={{
                        width: 50,
                    }} src={IconePlanoGovernoPng}></img>

                </div>
                <div style={{ width: '100%' }}>
                    <Text strong type="secondary" style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 8
                        // top: 20,
                        // color: propsState.theme ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        Plano De Governo
                    </Text>
                    {/* <Text style={{
                        // fontStyle: 'normal',
                        // fontWeight: 'bold',
                        fontSize: 13,
                        // lineHeight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        top: 15,
                        // color: propsState.theme === 'light' ? '#D4D4D4' : '#4F4F4F',
                    }}>
                        estaduais
                    </Text> */}
                </div>
            </>
        )

    }, []);

    return (
        <>
            <Layout style={{ margin: -25 }}>
                <div style={{
                    backgroundColor: propsState.theme === 'light' ? '#fff' : '#141414',
                    padding: 22
                }}>
                    <Row>
                        <Col {...cardLayout}>
                            <Title level={3}><IconCogs style={{ fontSize: 35 }} /> Configurações do Sistema</Title>
                            <Breadcrumb>
                                <Breadcrumb.Item><a onClick={onClickVoltarConfigOfSystem}>Configurações do Sistema</a></Breadcrumb.Item>
                                <Breadcrumb.Item>Módulo Ordenação</Breadcrumb.Item>
                            </Breadcrumb>
                            <Divider style={{
                                marginBottom: 10
                            }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end'
                        }} {...colBotaoSalvarOrdenacao}>
                            <Button
                                key="submit"
                                type="primary"
                                style={{
                                    marginBottom: 10
                                }}
                                // loading={loadingFormAdd}
                                onClick={salvarDados}
                            >
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col {...colBotaoSalvarOrdenacao}>

                        {/* <ReactSortable
                        style={{
                            borderTop: 1,
                            borderBottom: 0,
                            borderLeft: 0,
                            borderRight: 0,
                            borderColor: '#ccc',
                            borderStyle: 'solid',
                        }}
                        list={dadosObj}
                        setList={setDadosObj}
                        animation={150}
                    >
                        {dadosObj.map((item, index) => (
                            <React.Fragment key={item.id + 'fragment'}>
                                <List.Item key={item.id + 'lisitem'} style={{
                                    borderTop: 0,
                                    borderBottom: 1,
                                    borderLeft: 0,
                                    borderRight: 0,
                                    borderColor: '#ccc',
                                    borderStyle: 'solid',
                                    // display: 'flex',
                                    // flexDirection: 'column',
                                    // paddingLeft: 30,
                                    // paddingRight: 10,
                                }}>
                                    <Title style={{
                                        marginBottom: 0
                                    }} level={4} key={item.id + 'tipo'}>
                                        {(index + 1) + '. ' + item.nome}
                                    </Title>
                                </List.Item>
                            </React.Fragment>
                        ))}
                    </ReactSortable> */}

                        <ReactSortable
                            style={{
                                // display: 'grid',
                                // gridColumnGap: 20,
                                // gridTemplateColumns: 'auto auto',
                                // backgroundColor: '#ccc',
                                // padding: 5
                            }}
                            list={dadosObj}
                            setList={setDadosObj}
                            animation={150}
                            className='ant-row'
                        >
                            {dadosObj.map((item, index) => (
                                <Col key={'colun' + index} style={{
                                    marginLeft: 17,
                                    borderRadius: 8,
                                    backgroundColor: propsState.theme === 'light' ? '#fff' : '#141414',
                                    boxShadow: '4px 4px 4px 4px rgba(0, 0, 0, 0.2)',
                                    // marginLeft: '1%',
                                    marginTop: '3%',
                                    // marginBot: hp('1.1'),
                                    padding: 10,
                                }} {...colOrdenacaoModulo}>

                                    {
                                        eval(item.func)()
                                    }
                                </Col>
                            ))}
                        </ReactSortable>
                    </Col>
                </Row>
            </Layout>
        </>
    );
}

export default Ordenacao;
