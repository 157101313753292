import React, { useEffect, useState, useCallback } from 'react';
import { Route, RouteProps as ReactDOMRouteProps, Redirect } from 'react-router-dom';
import LayoutDashboard from '../../layouts/LayoutDashboard';
import { checkExpiredToken, getToken } from './../../services/token';
import { useDispatch } from 'react-redux';
import * as LoginActions from '../../store/modules/login/actions';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
// import AuthLayout from '~/pages/_layouts/auth'
// import DefaultLayout from '~/pages/_layouts/default'

// import { store } from '~/store'

interface RouteProps extends ReactDOMRouteProps {
    isPrivate?: boolean;
    isAuth?: boolean;
    component: React.ComponentType;
}

const RouteWrapper: React.FC<RouteProps> = ({
    component: Component,//para poder ultilizar a sintax reacct
    isAuth,
    ...rest//pega o resto
}) => {
    //pegando os states redux auth e desestruturando para state signed
    // const { signed } = store.getState().auth//saber se o user esta logado
    const dispatch = useDispatch();
    const history = useHistory();

    const [tempoAnterior, setTempoAnterior] = useState(new Date().getTime());

    //comonentDidUpdate
    useEffect(() => {

        if (checkExpiredToken(getToken())) {
            localStorage.removeItem('@GovFacil:token');
            dispatch(LoginActions.loginAlterSingOut());
            notification.error({
                key: "sessaoExpirada",
                message: 'Sessão expirada',
                description: "Sua sessão expirou, realize o login novamente!",
            })
        }
    }, [rest, dispatch]);

    const onTempoOcioso = useCallback(() => {

        let horaAgora = new Date().getTime();
        // let dezMinutos = 600000; //10minutos
        let dezMinutos = 3600000; //60minutos

        let diferencaDeTempo = horaAgora - tempoAnterior;

        if (diferencaDeTempo > dezMinutos) {

                localStorage.removeItem('@GovFacil:token');
                dispatch(LoginActions.loginAlterSingOut());
                notification.error({
                    key: "sessaoExpirada",
                    message: 'Sessão expirada',
                    description: "Sua sessão expirou, realize o login novamente!",
                });

                history.push('/');
                //para poder atualizar a variavel state
                setTempoAnterior(horaAgora);

        } else {
            setTempoAnterior(horaAgora);
        }
    }, [tempoAnterior, dispatch, history]);

    //se ele estiver logdo e a rota nao for privada
    if (isAuth) {
        return (
            <Route
                {...rest}
                render={props => (
                    <Component />
                )}
            />
        )
    }

    if (checkExpiredToken(getToken())) {
        return (
            <Route
                {...rest}
                render={props => (
                    <Redirect to="/" />
                )}
            />
        )
    }

    //see ele esta logado e esta tentando acessar uma rota privada
    // const Layout = signed ? DefaultLayout : AuthLayout   //se ele estiver logado e um layout se nao e outro layout
    return (
        <Route
            {...rest}
            render={props => (
                <div onClick={onTempoOcioso} onKeyPress={onTempoOcioso}>
                    <LayoutDashboard>
                        <Component />
                    </LayoutDashboard>
                 </div>
            )}
        />
    )
}

RouteWrapper.defaultProps = {
    isAuth:
        false//caso nao tenha valor ele e false
}

export default RouteWrapper;
