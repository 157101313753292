import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
    Table, Button, notification, Typography, Row, Col,
    Select, Tooltip, Modal, Divider, Breadcrumb, Spin, Form
} from 'antd';
import locale from 'antd/es/locale/pt_BR';
import {
    SettingOutlined,
    FileTextOutlined,
    ExclamationCircleOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import axios from 'axios';
import {
    urlDadosAppIntegracao,
    urlDadosAppIntegracaoMunicipio
} from '../../../../services/request/urls';

import { useSelector } from 'react-redux';
import { stateGlobal } from '../../../../interfaces/Global';

import { getToken } from './../../../../services/token';

import {
    removeAcento
} from './../../../../utils/RemoveAcentos';

import { useGetColumnSearchProps } from '../../../../hooks/FilterTable/stringCLearFiltros';
import { useHistory, useParams } from 'react-router';
import './styles.css'

const { Text, Title } = Typography;

interface IReqDadosIntegracoesAll {
    "_id": string,
    "empresa": string,
    "pathIntegracao": string | null,
    "path_integracao_painel": string | null,
    "logo_light": string | null,
    "logo_dark": string | null,
    "modulos": Array<
        {
            "nome": string,
            "path": string
        }
    >,
    "integracoes": Array<any>
}
interface IReqDadosIntegracoesByMunicipio {
    "_id": string,
    "id_ibge": number,
    "integracoes": Array<IReqDadosIntegracoesAll>
}

const ListIntegracoesPorMunicipio: React.FC = () => {

    const { idCidade }: any = useParams();

    const refContagemDoFiltro = React.useRef<Array<any>>([]);
    const refFuncaoLimparFiltrosString = useRef<any>(null);

    const propsState = useSelector((state: stateGlobal) => state.global);

    const history = useHistory();

    const refAllDadosIntegracoesByMunicipio = useRef<IReqDadosIntegracoesByMunicipio | null>();
    const refAllDadosIntegracoes = useRef<Array<IReqDadosIntegracoesAll>>([]);

    const refIdCidade = useRef<any>(null);

    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();
    const signal1 = axios.CancelToken.source();

    const [loadingTable, setLoadingTable] = useState(false);
    const [listIntegracoesByMunicipio, setListIntegracoesByMunicipio] = useState<IReqDadosIntegracoesByMunicipio | null>();
    const [listAllIntegracoesFilter, setListAllIntegracoesFilter] = useState<Array<IReqDadosIntegracoesAll>>([]);

    const refFormModalAddIntegracoes = useRef<any>(null);
    const [modalAddIntegracoes, setModalAddIntegracoes] = useState(false);
    const [loadingFormAddIntegracoes, setLoadingFormAddIntegracoes] = useState(false);

    const [valorLsStorage, setValorLsStorage] = useState<null | { nome: string, id: number }>(null);

    const buscaDadosIntegracoesByMunicipio = useCallback((id: any): void => {

        const buscaDeDadosContratos = async () => {
            try {

                setLoadingTable(true)
                let result = await axios.get(urlDadosAppIntegracaoMunicipio + '/' + id,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });


                refAllDadosIntegracoesByMunicipio.current = result.data

                setListIntegracoesByMunicipio(result.data);
                setLoadingTable(false)


            } catch (error) {

                setLoadingTable(false)

                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Atenção',
                        description:
                            msgErro?.response?.data?.message ? msgErro?.response?.data?.message :
                                'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosContratos();

    }, []);

    const buscaDadosAllIntegracoes = useCallback((): void => {

        const buscaDeDadosContratos = async () => {
            try {

                setLoadingFormAddIntegracoes(true)
                let result = await axios.get(urlDadosAppIntegracao,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                refAllDadosIntegracoes.current = result.data

                setLoadingFormAddIntegracoes(false)


            } catch (error) {

                setLoadingFormAddIntegracoes(false)

                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Atenção',
                        description:
                            msgErro?.response?.data?.message ? msgErro?.response?.data?.message :
                                'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosContratos();

    }, []);

    //executa apenas ao iniciar o component
    useEffect(() => {

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:ListInteracoesByMunicipio');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }


        if (localUserData) {
            setValorLsStorage(localUserData)
        }


        if (idCidade) {
            refIdCidade.current = idCidade
            buscaDadosIntegracoesByMunicipio(idCidade);
        }
        buscaDadosAllIntegracoes();

    }, [idCidade]);

    //executa apenas quando desmonta o component
    useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
        signal1.cancel("Requisicao cancelada!")
    }, []);

    const encontrarIntegracaoPorId = useCallback((id): IReqDadosIntegracoesAll => {

        const recebeFilter = refAllDadosIntegracoes.current.filter((element, index) => (
            element._id === id
        ))

        return recebeFilter[0]

    }, []);

    const clickRemoveIntegracoes = useCallback((integracao: IReqDadosIntegracoesAll): void => {

        const reqRemoverIntegracao = async () => {
            try {
                setLoadingFormAddIntegracoes(true);

                // if (
                //     refAllDadosIntegracoesByMunicipio.current?.integracoes
                //     && refAllDadosIntegracoesByMunicipio.current?.integracoes.length === 1
                // ) {
                //     setLoadingFormAddIntegracoes(false);
                //     notification.error({
                //         message: 'Erro',
                //         description:
                //             'As integrações não podem ficar vazias!',
                //     });
                //     return
                // }


                const integracoesParaSalvar = refAllDadosIntegracoesByMunicipio.current?.integracoes.filter((element, index) => (
                    element._id !== integracao._id
                ))


                let obj = {
                    integracoes: integracoesParaSalvar
                }
                // urlDadosAppIntegracaoMunicipio
                await axios.put(urlDadosAppIntegracaoMunicipio + '/' + refIdCidade.current, obj,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Integrações salvas com sucesso!',
                });

                setLoadingFormAddIntegracoes(false);

                buscaDadosIntegracoesByMunicipio(refIdCidade.current);
                // buscaDadosAllIntegracoes();

                clickCancelModalAddIntegracoes();
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingFormAddIntegracoes(false);
                notification.error({
                    message: 'Erro',
                    description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel salvar, entre em contato com suporte!',
                });
            }
        }

        reqRemoverIntegracao();


    }, []);

    const onClickRemoverIntegracao = useCallback((integracao: IReqDadosIntegracoesAll): void => {
        Modal.confirm({
            title: 'Cuidado',
            icon: <ExclamationCircleOutlined />,
            content: integracao.empresa + ' - Tem certeza que você deseja remover esta integração?',
            okText: 'Sim',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                clickRemoveIntegracoes(integracao);
            },
            onCancel() {
            },
        });
    }, []);

    const columns = [
        {
            title: 'Empresa',
            dataIndex: 'empresa',
            ...useGetColumnSearchProps('empresa', refFuncaoLimparFiltrosString),
            width: '20%',
            sorter: (a: any, b: any) => a.empresa.localeCompare(b.empresa),
            showSorterTooltip: false,
            render: (empresa: string, record: any) => {
                return (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            <Text
                                style={{
                                    marginLeft: 10
                                }}
                            >
                                {empresa}
                            </Text>

                        </div>
                    </>
                )
            }
        },
        {
            title: 'Ações',
            dataIndex: 'path_integracao_painel',
            width: '5%',
            showSorterTooltip: false,
            render: (path_integracao_painel: string | null, record: IReqDadosIntegracoesAll) => {
                return (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            {
                                typeof path_integracao_painel === 'string' ?
                                    <Tooltip title="Personalizar" color="blue" key="editargold">
                                        <Button
                                            type="primary"
                                            style={{
                                                marginRight: 5
                                            }}
                                            onClick={() => {
                                                history.push(path_integracao_painel + '/' + idCidade);
                                            }}
                                        >
                                            <SettingOutlined />
                                        </Button>
                                    </Tooltip>
                                    :
                                    <></>
                            }
                            {
                                <Tooltip title="Excluir" color="red" key="removerIntegracao">
                                    <Button
                                        type="primary"
                                        danger
                                        onClick={() => {
                                            onClickRemoverIntegracao(record)
                                        }}
                                    >
                                        <DeleteOutlined />
                                    </Button>
                                </Tooltip>
                            }

                        </div>
                    </>
                )
            }
        },
    ];

    const onClickVoltarConfigOfSystem = useCallback((): void => {
        history.push("/integracoes");
    }, [history]);

    const clickCancelModalAddIntegracoes = useCallback(() => {
        setModalAddIntegracoes(false);
        refFormModalAddIntegracoes.current.resetFields();
    }, []);

    const clickSubmitModalAddIntegracoes = useCallback((): void => {

        const reqSalvarIntegracoes = async (values: any, isEdit = false) => {
            try {
                setLoadingFormAddIntegracoes(true);

                if (isEdit) {
                    // urlDadosAppIntegracaoMunicipio
                    await axios.put(urlDadosAppIntegracaoMunicipio + '/' + idCidade, values,
                        { headers: { 'Authorization': 'Bearer ' + getToken() } });
                } else {
                    await axios.post(urlDadosAppIntegracaoMunicipio, values,
                        { headers: { 'Authorization': 'Bearer ' + getToken() } });
                }

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Integrações salvas com sucesso!',
                });

                setLoadingFormAddIntegracoes(false);

                buscaDadosIntegracoesByMunicipio(refIdCidade.current);
                // buscaDadosAllIntegracoes();

                clickCancelModalAddIntegracoes();
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingFormAddIntegracoes(false);
                notification.error({
                    message: 'Erro',
                    description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel salvar, entre em contato com suporte!',
                });
            }
        }

        refFormModalAddIntegracoes.current?.validateFields()
            .then((values: any) => {

                let arrIntegracoes: any = [];

                //para funcionar pela nova rota de enviar os que ja existem e acrescentar os que não tem.
                if (refAllDadosIntegracoesByMunicipio.current?.integracoes) {
                    arrIntegracoes = [...refAllDadosIntegracoesByMunicipio.current.integracoes]
                }

                for (let index = 0; index < values.integracoes.length; index++) {
                    const integracaoID = values.integracoes[index];
                    arrIntegracoes.push(encontrarIntegracaoPorId(integracaoID))
                }

                let obj = {
                    id_ibge: idCidade,
                    integracoes: arrIntegracoes
                }

                if (refAllDadosIntegracoesByMunicipio.current?.integracoes &&
                    refAllDadosIntegracoesByMunicipio.current?.integracoes.length > 0) {
                    // Editar
                    reqSalvarIntegracoes(obj, true);
                } else {
                    reqSalvarIntegracoes(obj);
                }

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [idCidade]);

    return (
        <>
            <Row>
                <Col span={24}>
                    <Title level={3}><FileTextOutlined style={{ fontSize: 25 }} />{' Listagem de Integrações - ' + valorLsStorage?.nome}</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item><a href='#' onClick={onClickVoltarConfigOfSystem}>Municípios</a></Breadcrumb.Item>
                        <Breadcrumb.Item>Integrações</Breadcrumb.Item>
                    </Breadcrumb>
                    <Divider />
                </Col>
            </Row>
            <div
                style={{
                    position: 'fixed',
                    zIndex: 2,
                    backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                    width: '100%',
                    // backgroundColor: 'blue',
                    paddingTop: 14,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24
                }}
            >
                <Row
                // style={{ marginBottom: 16 }}
                >
                    <Col>
                        <Button
                            type="primary"
                            className="botaoAdicionarIntegracao_kjhsdvfckahwevoik"
                            onClick={() => {
                                let obj: any = []

                                refAllDadosIntegracoes.current.forEach((integracao) => {
                                    let haveIntegracao = false
                                    if (listIntegracoesByMunicipio && listIntegracoesByMunicipio.integracoes.length > 0) {
                                        for (let index = 0; index < listIntegracoesByMunicipio.integracoes.length; index++) {
                                            const elementIntegracaoF = listIntegracoesByMunicipio.integracoes[index];

                                            if (elementIntegracaoF.empresa === integracao.empresa) {
                                                haveIntegracao = true;
                                                break;
                                            }
                                        }
                                    }

                                    if (!haveIntegracao) {
                                        obj.push(integracao)
                                    }
                                })

                                setListAllIntegracoesFilter(obj)
                                setModalAddIntegracoes(true);
                            }}

                            style={{ marginRight: 5 }}
                        >
                            Adicionar
                        </Button>
                    </Col>

                </Row>

            </div>
            <div
                style={{
                    marginTop: 50
                }}
                className='marginTopTables'
            >

                <Table
                    rowKey={(record) => record._id}
                    loading={loadingTable}
                    columns={columns}
                    dataSource={
                        (listIntegracoesByMunicipio && listIntegracoesByMunicipio.integracoes.length > 0) ?
                            listIntegracoesByMunicipio.integracoes :
                            []
                    }
                    scroll={{ x: 350 }}
                    onChange={
                        (pagination, filters, sorter, extra) => {
                            refContagemDoFiltro.current = extra.currentDataSource
                        }
                    }
                    title={(registros) => {
                        return (
                            <div
                                style={{
                                    backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                    padding: 10,
                                    margin: -8,
                                    marginBottom: 0.5
                                }}
                            >
                                <Text>
                                    {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                        (listIntegracoesByMunicipio && listIntegracoesByMunicipio.integracoes.length > 0)
                                            ?
                                            listIntegracoesByMunicipio?.integracoes.length : '0' :
                                        refContagemDoFiltro.current.length} num total de ${(listIntegracoesByMunicipio && listIntegracoesByMunicipio.integracoes.length > 0)
                                            ? listIntegracoesByMunicipio?.integracoes.length : '0'} `}
                                </Text>
                            </div>
                        )
                    }}
                    footer={(registros) => {
                        return (
                            <div
                                style={{
                                    textAlign: 'end'
                                }}
                            >
                                <Text>
                                    {/* {`Total: ${listIntegracoes.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                        (listIntegracoesByMunicipio && listIntegracoesByMunicipio.integracoes.length > 0)
                                            ?
                                            listIntegracoesByMunicipio?.integracoes.length : '0' :
                                        refContagemDoFiltro.current.length} num total de ${(listIntegracoesByMunicipio && listIntegracoesByMunicipio.integracoes.length > 0)
                                            ?
                                            listIntegracoesByMunicipio?.integracoes.length : '0'} `}
                                </Text>
                            </div>
                        )
                    }}
                    size="small"
                    pagination={{
                        locale: { items_per_page: '' },
                        showSizeChanger: true,
                        defaultPageSize: 100,
                        position: ['topRight', 'bottomRight'],
                        pageSizeOptions: ['10', '20', '50', '100']
                    }}
                    locale={locale.Table}
                />
            </div>
            <Modal
                title="Adicionar Integração"
                visible={modalAddIntegracoes}
                className="modalAddIntegracoes"
                onOk={clickSubmitModalAddIntegracoes}
                onCancel={clickCancelModalAddIntegracoes}
                footer={[
                    <Button key="back" disabled={loadingFormAddIntegracoes} onClick={clickCancelModalAddIntegracoes}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary"
                        loading={loadingFormAddIntegracoes}
                        onClick={clickSubmitModalAddIntegracoes}
                    >
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingFormAddIntegracoes}>
                    <Form
                        ref={refFormModalAddIntegracoes}
                        name="formModalAddAddIntegracoes"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                        onFinishFailed={() => { }}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="integracoes"
                                    label="Integrações"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor selecione uma integração!',
                                        },
                                    ]}
                                    style={{ margin: 0, marginBottom: 15 }}
                                >
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Selecione..."
                                        mode="multiple"
                                        loading={loadingFormAddIntegracoes}
                                        notFoundContent={loadingFormAddIntegracoes ? <Spin size="small" /> : null}
                                        filterOption={(input: any, option: any) => {
                                            let textDigit = removeAcento(input)
                                            let listCargo = removeAcento(option?.children);
                                            return listCargo.indexOf(textDigit) >= 0
                                        }}
                                    >
                                        {
                                            listAllIntegracoesFilter.map((item) => {
                                                return (
                                                    <Select.Option
                                                        value={item._id}
                                                        key={item._id}
                                                    >
                                                        {item.empresa}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
}

export default ListIntegracoesPorMunicipio;
