export function userAddStepMenu(nome: string) {
    return {
        type: '@user/MENU_STEP',
        payload: { nome },
    };
}
export function formularioRefUsers(formulario: any) {
    return {
        type: '@user/FORMULARIOREFUSERS',
        payload: {
            formulario: formulario
        },
    };
}

//Steps

export function stepProximo(current: number|null) {
    return {
        type: '@user/STEPPROXIMO',
        payload: { current },
    };
}
export function stepAnterior(current: number|null) {
    return {
        type: '@user/STEPANTERIOR',
        payload: { current },
    };
}
export function setMaxLenghtStep(current: number) {
    return {
        type: '@user/SETMAXLENGHTsTEP',
        payload: { current },
    };
}

export function setBotaoAddContrato(botaoAddContrato: boolean) {
    return {
        type: '@user/SETBOTAOADDCONTRATO',
        payload: { botaoAddContrato },
    };
}

export function setSpinnerStep(spinnerSteps: boolean) {
    return {
        type: '@user/SPINNERSTEPUSER',
        payload: { spinnerSteps },
    };
}
