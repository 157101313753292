import React, { useState, useCallback, useEffect } from 'react';
import {
    Table, Input, Button, Space, notification, Row, Col,
    Modal, Typography
} from 'antd';
import Highlighter from 'react-highlight-words';
import locale from 'antd/es/locale/pt_BR';
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { urlProcessosJuridicos, urlProcessosJuridicosAtualizar } from '../../services/request/urls';
import { getToken } from './../../services/token';
import Icon from '@ant-design/icons';
// import { useForm } from 'antd/lib/form/Form';


import {
    removeAcento
} from './../../utils/RemoveAcentos';

import { FaSearch } from 'react-icons/fa';
import { stateGlobal } from '../../interfaces/Global';

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>;


const { Text } = Typography;


interface interfaceListProcessosJuridicos {
    id: number,
    usuario: string,
    senha: string,
    idTipo: number,
    tipo: string,
    created_at: string,
    cidadeId: number,
    cidadeUUID: string,
    cidadeNome: string,
    estadoId: number,
    estadoNome: string
}

const ListagemProcessosJuridicos: React.FC = () => {

    // const formRef = useRef<any>(null);
    // formRef = React.createRef();

    const refContagemDoFiltro = React.useRef<Array<any>>([]);

    const propsState = useSelector((state: stateGlobal) => state.global);

    const history = useHistory();
    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();
    const signal1 = axios.CancelToken.source();

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string>>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dadosTabelaState, setDadosTabelaState] = useState<Array<interfaceListProcessosJuridicos>>([]);
    const [hasSelectedAdd, setHasSelectedAdd] = useState(true);
    const [hasSelectedView, setHasSelectedView] = useState(false);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);

    //executa apenas ao iniciar o component
    useEffect(() => {
        setLoadingTable(true)
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlProcessosJuridicos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal1.token
                    });

                setDadosTabelaState(result.data);
                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDados();

    }, []);

    //executa apenas quando desmonta o component
    const comonentExecutaAoDestroy = useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
        signal1.cancel("Requisicao cancelada!")
    }, []);

    const buscaDadosUsuariosAndSetTable = useCallback((): void => {

        setLoadingTable(true)
        const buscaDeDadosUsuarios = async () => {
            try {
                let result = await axios.get(urlProcessosJuridicos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setDadosTabelaState(result.data);
                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosUsuarios();

    }, []);

    //Search da tabela de listagem

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, [searchText]);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);

    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {

                let trecord = removeAcento(record[dataIndex].toString());

                return record[dataIndex] ? trecord.includes(removeAcento(value)) : false

            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn, handleSearch, handleReset]);

    const getColumnSearchPropsData = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let texto: string | undefined = ''
                if (record[dataIndex]) texto = moment(record[dataIndex]).format('DD/MM/YYYY HH:mm')

                return (
                    texto ? texto.toString().toLowerCase().includes(value.toLowerCase()) : false
                )
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    // fim do search da tabela de listagem

    const onClickImportar = useCallback((): void => {

        const buscaDeDados = async () => {

            if (selectedRowKeys.length == 1) {

                setLoadingTable(true)

                let objSelecionado: any = {}
                dadosTabelaState.forEach((dados): void => {
                    let convertId: string = dados.id.toString();
                    if (convertId == selectedRowKeys[0]) {
                        objSelecionado = dados
                    }
                })

                try {

                    await axios.get(urlProcessosJuridicosAtualizar + '/' + objSelecionado.id, {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                    notification.success({
                        message: 'Sucesso',
                        description: "Dados importados com sucesso!",
                    });

                    setLoadingTable(false)
                } catch (error) {
                    setLoadingTable(false)
                    let msgErro: any = (error as Error);
                    notification.error({
                        message: 'Erro',
                        description:
                            msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }

            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState]);


    //abrir modal
    const navigateAdicionar = useCallback((): void => {
        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:processosJuridicos');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@GovFacil:processosJuridicos');
        }

        history.push("/cidadesUser/adicionar");
    }, []);

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeys.includes(record.id.toString())) {
            let filter = selectedRowKeys.filter((val) => {
                return val != record.id.toString()
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(true);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length == 0) {
                setSelectedRowKeys([record.id.toString()]);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
            } else {
                let teste = selectedRowKeys.concat(record.id.toString());
                setSelectedRowKeys(teste);
                setHasSelectedAdd(false);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
            }
        }
    }, [selectedRowKeys]);

    //abrir navegar editar
    const clickBotaoEditar = useCallback((): void => {

        const buscaDeDados = async () => {

            if (selectedRowKeys.length == 1) {

                let objSelecionado: any = {}
                dadosTabelaState.forEach((dados): void => {
                    let convertId: string = dados.id.toString();
                    if (convertId == selectedRowKeys[0]) {
                        objSelecionado = dados
                    }
                })

                try {

                    localStorage.setItem('@GovFacil:processosJuridicos', JSON.stringify(objSelecionado));

                    history.push("/cidadesUser/adicionar");
                } catch (error) {
                    let msgErro: any = (error as Error);
                    notification.error({
                        message: 'Erro',
                        description:
                            msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }

            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState]);

    //checkbox da tabela de listagem
    const onSelectChange = useCallback((selectedRowKeys): void => {
        if (selectedRowKeys.length > 1) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
        } else if (selectedRowKeys.length === 0) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(true);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
        } else {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(true);
            setHasSelectedEdit(true);
        }
    }, [selectedRowKeys]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '1%'
    };

    const columns = [
        {
            title: 'Cidade',
            dataIndex: 'cidadeNome',
            ...getColumnSearchProps('cidadeNome'),
            width: '13%',
        },
        {
            title: 'Estado',
            dataIndex: 'estadoNome',
            ...getColumnSearchProps('estadoNome'),
            width: '8%',
            // sorter: (a: any, b: any) => a.email.length - b.email.length,
        },
        {
            title: 'Tipo',
            dataIndex: 'tipo',
            ...getColumnSearchProps('tipo'),
            width: '10%',
            // sorter: (a, b) => a.age - b.age //para quando é numero
        },
        {
            title: 'Usuario',
            dataIndex: 'usuario',
            ...getColumnSearchProps('usuario'),
            width: '10%',
            // sorter: (a, b) => a.age - b.age //para quando é numero
        },
        {
            title: 'Senha',
            dataIndex: 'senha',
            ...getColumnSearchProps('senha'),
            width: '10%',
            // sorter: (a, b) => a.age - b.age //para quando é numero
        },
        {
            title: 'Criado em',
            dataIndex: 'created_at',
            ...getColumnSearchPropsData('created_at'),
            width: '5%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.created_at) {
                    aMonMen = moment(a.created_at).unix()
                } else {
                    aMonMen = 0
                }
                if (b.created_at) {
                    bMonMen = moment(b.created_at).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            render: (criado: any) => (
                moment(criado).format('DD/MM/YYYY')
            ),
        },
    ];

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    zIndex: 2,
                    // background: propsState.theme === "light" ? '#fff' : '#141414',
                    //
                    // width: '80%',
                    paddingTop: 24,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24
                }}
            >
                <Row style={{ marginBottom: 0 }}>
                    <Col>
                        <Button loading={loadingTable} type="primary" className="botaoAdicionarProcessoJuridico" disabled={!hasSelectedAdd} onClick={navigateAdicionar} >
                            Adicionar
                        </Button>
                        <Button loading={loadingTable} type="primary" className="botaoEditarProcessoJuridico" onClick={clickBotaoEditar} disabled={!hasSelectedEdit} >
                            Editar
                        </Button>
                        <Button loading={loadingTable} className="botaoViewProcessoJuridico" type="primary" onClick={onClickImportar} disabled={!hasSelectedView} >
                            Importar
                        </Button>
                    </Col>
                </Row>
            </div>
            <Table
                style={{
                    marginTop: 50
                }}
                className='marginTopTables'
                rowKey={(record) => record.id.toString()}
                loading={loadingTable}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onclickTable(record, rowIndex) }, // click row
                        onDoubleClick: event => { }, // double click row
                        onContextMenu: event => { }, // right button click row
                        onMouseEnter: event => { }, // mouse enter row
                        onMouseLeave: event => { }, // mouse leave row
                    };
                }}
                rowSelection={rowSelection}
                columns={columns} dataSource={dadosTabelaState}
                scroll={{ x: 1000 }}
                size="small"
                onChange={
                    (pagination, filters, sorter, extra) => {
                        refContagemDoFiltro.current = extra.currentDataSource
                    }
                }
                title={(registros) => {
                    return (
                        <div
                            style={{
                                backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                padding: 10,
                                margin: -8,
                                marginBottom: 0.5
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: 'end'
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                pagination={{
                    locale: { items_per_page: '' },
                    showSizeChanger: true,
                    defaultPageSize: 100,
                    position: ['topRight', 'bottomRight'],
                    pageSizeOptions: ['10', '20', '50', '100']
                }}
                locale={locale.Table}
            />
        </>
    );
}

export default ListagemProcessosJuridicos;
